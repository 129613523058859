import React, { ReactNode } from 'react';
import UserPersonalDataForm from './UserPersonalDataForm';
import { useSwrAgent } from '../../api/useSwrAgent';
import { twMerge } from '../../index';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import { mutate } from 'swr';
import getUrls from '../../api/getUrls';

interface IUserPersonalDataLayoutProps {
  children: ReactNode;
  wrapperClassName?: string;
}
const UserPersonalDataLayout = ({ children, wrapperClassName }: IUserPersonalDataLayoutProps) => {
  const { data: hasPersonalData, isLoading } = useSwrAgent().Users.HasUserPersonalData();

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : !hasPersonalData ? (
        <div className={twMerge(wrapperClassName)}>
          <UserPersonalDataForm
            onSave={async () => {
              mutate(getUrls.User.hasUserPersonalData);
            }}
          />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default UserPersonalDataLayout;
