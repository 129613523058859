import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';

export default observer(() => {
  return (
    <div>
      <p style={styles.headingTextStyle}>
        E-bony to zakupy z odroczoną płatnością – robisz zakupy teraz, płacisz później.
      </p>
      <p style={styles.textStyle}>
        • limit w wysokości 400 zł
        <br />• dogodny termin spłaty
        <br />• bez dodatkowych kosztów
        <br />• bez prowizji i oprocentowania
      </p>
      <p style={styles.headingTextStyle}>Biedronka</p>
      <p style={styles.textStyle}>• do wykorzystania w sklepie stacjonarnym</p>
      <p style={styles.headingTextStyle}>Hebe</p>
      <p style={styles.textStyle}>• do wykorzystania w sklepie online i aplikacji mobilnej Hebe</p>
      <p style={styles.headingTextStyle}>Rossmann</p>
      <p style={styles.textStyle}>• do wykorzystania w sklepie stacjonarnym</p>
      <p style={styles.headingTextStyle}>Lidl</p>
      <p style={styles.textStyle}>• do wykorzystania w sklepie stacjonarnym</p>
      <p style={styles.headingTextStyle}>Jak skorzystać z E-bonów? To proste:</p>
      <p style={styles.textStyle}>
        1. Otrzymujesz dostęp do zakupionych E-bonów w aplikacji. E-bony do Biedronki, Rossmanna i Hebe mają postać
        cyfr, zaś do Lidla – kodu kreskowego.
        <br />
        2. Podczas zakupów w:
        <br />
        • Biedronce podajesz numer E-bonu kasjerowi lub wpisujesz go bezpośrednio do terminala w opcji E-kody
        <br />
        • Rossmannie podajesz numer E-bonu kasjerowi lub wpisujesz go bezpośrednio do terminala, a następnie
        potwierdzasz transakcję PIN-em
        <br />
        • Hebe numer E-bonu wpisujesz ręcznie na stronie sklepu lub w aplikacji mobilnej, a następnie potwierdzasz
        transakcję PIN-em
        <br />
        • Lidlu skanujesz kod kreskowy
        <br />
        3. Gotowe - zakupy zostały opłacone.
      </p>
      <p style={styles.headingTextStyle}>Jak spłacić E-bony?</p>
      <p style={styles.textStyle}>
        Wykonaj przelew z należną kwotą do zapłaty na dedykowany rachunek bankowy. Numer rachunku znajdziesz w umowie
        ramowej oraz w podsumowaniu zamówienia w aplikacji. Każdorazowo otrzymasz drogą elektroniczną potwierdzenie
        złożenia zamówienia w formie noty księgowej.
      </p>
    </div>
  );
});
