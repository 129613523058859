import React, { useEffect, useState } from 'react';

interface ICustomToastProps {
  onClose: () => void;
  duration?: number;
  text: string;
}

const CustomBlueToast: React.FC<ICustomToastProps> = ({ text, onClose, duration = 3000 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          onClose();
          return 100;
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, duration / 100);

    return () => {
      clearInterval(interval);
    };
  }, [duration, onClose, text]);

  return (
    <div
      className={
        'tw-fixed tw-top-1/4 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-bg-[#00a3b4] tw-text-white tw-rounded-md tw-p-4 tw-pr-6 tw-w-11/12 sm:tw-w-1/2 tw-max-w-full sm:tw-max-w-md tw-z-50'
      }>
      <div className={'tw-flex tw-gap-4 tw-font-semibold tw-text-base tw-whitespace-pre-wrap'}>{text}</div>
      <button onClick={onClose} style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}>
        x
      </button>
      <div style={{ height: '3px', backgroundColor: '#fff', marginTop: '10px' }}>
        <div style={{ height: '100%', width: `${progress}%`, backgroundColor: '#999' }} />
      </div>
    </div>
  );
};

export default CustomBlueToast;
