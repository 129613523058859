import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from '../../store/store';
import RangeSelectCash from '../../components/Loan/RangeLoan/RangeLoan';
import CustomerDataContractFormLoan from '../../components/Loan/CustomerDataContractFormLoan/CustomerDataContractFormLoan';
import ProgressLoan from '../../components/Loan/ProgressLoan/ProgressLoan';
import LoanMain from './LoanMain';
import ReckoningLoan from '../../components/Loan/ReckoningLoan/ReckoningLoan';
import { runInAction } from 'mobx';

export default observer(() => {
  const { loansStore } = useStore();

  useEffect(() => {
    runInAction(() => {
      loansStore.loanMainLoadings = true;
      loansStore.availableBenefitId = null;
    });
  }, []);

  return (
    <>
      <LoanMain>
        <div>
          {loansStore.hasLoanPermission ? (
            <div>
              {/* <div style={styles.containerBackground as React.CSSProperties}>
                    <div style={styles.textStyle as React.CSSProperties}>
                      Tankuj swobodnie przez cały miesiąc, a zapłać później.
                    </div>
                    <div style={styles.containerPhoto as React.CSSProperties}>
                      <Link to={PathRoute.CIRCLE_K} style={styles.link as React.CSSProperties}>
                        <p style={styles.link as React.CSSProperties}>Dowiedz się więcej</p>
                      </Link>
                    </div>
                  </div> */}
              <RangeSelectCash />
            </div>
          ) : (
            <div>
              {loansStore.isInProgress ? (
                <ProgressLoan />
              ) : (
                <div>
                  <div>{loansStore.isInReckoning ? <ReckoningLoan /> : <CustomerDataContractFormLoan />}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </LoanMain>
    </>
  );
});
