export enum WalletNauContractStatusE {
  NewNotCreated = 0,
  New = 1,
  Waiting = 2,
  Active = 3,
  Reckoning = 4,
  Fraud = 5,
  Canceled = 6,
}

export const WalletNauNewContractStatuses = [WalletNauContractStatusE.NewNotCreated, WalletNauContractStatusE.New];
