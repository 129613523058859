import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Image } from 'react-bootstrap';

export interface IIconWithTextRowProps {
  src: string;
  text: string;
}
export default observer((props: IIconWithTextRowProps) => {
  return (
    <div className={'d-flex align-items-center'}>
      <div style={styles.iconWrapper}>
        <Image src={props.src} style={{ marginRight: '20px', height: '39px' } as React.CSSProperties} />
      </div>
      {props.text}
    </div>
  );
});
