const Article = {
  getArticle: '/v1/articles/articles',
};

const BirthdayCodes = {
  getUserBirthdayCodeCampaigns: '/BirthdayCode/GetUserBirthdayCodeCampaigns',
  getUserBirthdayCodes: '/BirthdayCode/GetUserBirthdayCodes',
};

const Cashback = {
  getCashbackBenefitInfo: '/Cashback/GetCashbackBenefitInfo',
  getCashbackBalance: '/Cashback/GetCashbackBalance',
};

const DiscountCodes = {
  checkHasInstitutionalDiscountCodesByUser: '/DiscountCode/CheckHasInstitutionalDiscountCodesByUser',
  getUserDiscountCodeContractStatus: '/DiscountCode/GetDiscountCodeContractStatus',
  getAllUserDiscountCodeContracts: '/DiscountCode/GetAllUserDiscountCodeContractStatuses',
  getUserDiscountCodes: '/DiscountCode/GetUserDiscountCodes',
  checkHasNotSettledSettlements: '/DiscountCode/CheckHasNotSettledSettlements',
};

const User = {
  getUserAvailableBenefits: '/User/getUserAvailableBenefit',
  getUserMarketingData: '/User/GetUserMarketingData',
  hasUserPersonalData: '/User/HasUserPersonalData',
  isUserAssignedToInstitutionAsDirector: '/User/IsUserAssignedToInstitutionAsDirector',
  getUserProfileNew: '/User/GetUserProfileNew',
};

const Payments = {
  getUserPayments: '/Payment/GetUserPayments',
};

const Wallet = {
  getWalletContractStatus: '/Wallet/GetWalletContractStatus',
};

const CinemaVouchers = {
  getUserCinemaVouchers: '/CinemaVoucher/GetUserCinemaVouchers',
};

const SportCards = {
  getSportCardsForUser: '/SportCard/GetSportCardsForUser',
  checkSportCardIsAvailableInInstitution: '/SportCard/CheckSportCardIsAvailableInInstitution',
};

const getUrls = {
  Article,
  BirthdayCodes,
  Cashback,
  DiscountCodes,
  CinemaVouchers,
  User,
  Payments,
  Wallet,
  SportCards,
};

export default getUrls;
