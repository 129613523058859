import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { PrivateRouteProps } from './types';
import { PathRoute } from '../constants/pathRoute/Route';
import { useStore } from '../store/store';
import InstitutionSelectList from '../containers/InstitutionSelectList/InstitutionSelectList';
import { DISABLE_APPROVALS } from '../constants/sessionStorageKeys';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  exact,
  isLogged,
  isLoggedToInst,
  hasApprovals,
  path,
}) => {
  const { userStore, notificationStore } = useStore();
  const location = useLocation();
  if (isLogged && isLoggedToInst) {
    notificationStore.getNotificationsUser();
  }
  const redirectUrl = location.pathname !== '/' ? `${location.pathname}${location.search}` : '';
  if (!isLogged) {
    const loginPath = `${PathRoute.LOGIN}${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;
    return <Redirect to={loginPath} />;
  } else if (userStore.isLoggedInstAdmin) {
    return <Redirect to={PathRoute.ADMIN_DASHBOARD} />;
  } else if (!isLoggedToInst) {
    return <InstitutionSelectList></InstitutionSelectList>;
  } else if (!hasApprovals && hasApprovals !== null && !sessionStorage.getItem(DISABLE_APPROVALS)) {
    const approvalsPath = `${PathRoute.APPROVALS}${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;
    return <Redirect to={approvalsPath} />;
  } else {
    return <Route component={component} exact={exact} path={path} />;
  }
};
export default PrivateRoute;
