export const myMarginTop = { marginTop: '10px' };

export const accordionBody = {
  background: '#FFF',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '25px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '20px',
} as React.CSSProperties;

export const font = {
  fontSize: '16px',
} as React.CSSProperties;

export const displayBetween = {
  display: 'flex',
  justifyContent: 'space-between',
} as React.CSSProperties;

export const fontHigh = {
  fontWeight: '700',
};

export const orderBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  cursor: 'pointer',
} as React.CSSProperties;

export const orderHeading = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '10px',
  cursor: 'pointer',
} as React.CSSProperties;

export const orderTitle = {
  fontSize: '16px',
  fontWeight: 400,
} as React.CSSProperties;

export const orderDetails = {
  fontSize: '14px',
  fontWeight: 300,
  paddingLeft: '20px',
} as React.CSSProperties;

export const orderAmount = {
  fontSize: '20px',
  fontWeight: 500,
  paddingLeft: '20px',
  color: '#0bc268',
} as React.CSSProperties;

export const orderText = {
  padding: '10px',
  color: '#626D80',
} as React.CSSProperties;
