import * as Yup from 'yup';
import { EmploymentType } from '../../../helpers/EmploymentTypeSelect';
import ValidationHelper from '../../../helpers/ValidationHelper';

export const initialValues = {
  id: 0,
  employmentType: 0,
  employmentDate: '',
  contractStartDate: '',
  contractEndDate: '',
  monthlyNetIncome: '',
  companyName: '',
  profession: '',
  availableBenefitIsInstitutional: false,
  error: null,
};

export const validationSchema = Yup.object().shape({
  employmentType: Yup.number()
    .nullable()
    .min(1, 'Wskaż rodzaj zatrudnienia')
    .max(5, 'Wskaż poprawny rodzaj zatrudnienia')
    .required('Pole jest wymagane'),
  employmentDate: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? '')),
  //contractStartDate: Yup.string().required("Pole jest wymagane").test("date-test", "Nieprawidłowa data", (value) => ValidationHelper.checkDate(value)),
  contractEndDate: Yup.string()
    .nullable()
    .when('employmentType', (employmentType) =>
      employmentType != EmploymentType.EmploymentContractIndefinitePeriod
        ? Yup.string()
            .nullable()
            .required('Pole jest wymagane')
            .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? ''))
        : Yup.string().nullable(),
    ),

  companyName: Yup.string()
    .nullable()
    .when('availableBenefitIsInstitutional', {
      is: false,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  monthlyNetIncome: Yup.number()
    .nullable()
    .when('availableBenefitIsInstitutional', {
      is: false,
      then: Yup.number().nullable().integer('Podaj wartość liczbową').required('Pole jest wymagane'),
    }),
  //profession: Yup.string().required("Pole jest wymagane")
});
