export const selfStyles = {
  marginBottom: 0,
  alignSelf: 'center',
  textAlign: 'center',
  fontWeight: '400',
  lineHeight: '1.2',
  fontSize: '20px',
  color: '#00635C',
};

export const heightTop = { height: '150px', margin: '30px' };

export const linkBtn = { marginTop: '30px' };
export const containerRegister = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '8vh',
};
