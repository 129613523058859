import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import IconWithTextRow from './IconWithTextRow';

export default observer(() => {
  return (
    <div style={styles.contentBody}>
      <p style={styles.headingTextStyle}>Aplikacja Klub NAU to szereg unikalnych ofert. Dzięki niej zyskujesz:</p>
      <div style={styles.innerContentContainer}>
        <IconWithTextRow src={'/assets/gasoline.svg'} text={'Rabaty na paliwo'} />
        <IconWithTextRow src={'/assets/eCodes.svg'} text={'E-bony m.in. do Biedronki, Hebe, Lidla i Rossmanna'} />
        <IconWithTextRow src={'/assets/car-green.svg'} text={'rabat na ubezpieczenie komunikacyjne'} />
        <IconWithTextRow src={'/assets/viewMore.svg'} text={'i wiele, wiele więcej!'} />
      </div>
      <div className={'px-lg-4'}>
        <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.NAU_CLUB)}>
          Sprawdź
        </Button>
      </div>
    </div>
  );
});
