import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';
import UserPersonalDataLayout from '../../../components/UserPersonalData/UserPersonalDataLayout';
import CashbackPage from './CashbackPage';

const CashbackLayout = () => {
  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3' style={styles.container}>
        <SubpageHeader iconHeight={40} iconSrc={'/assets/cashbackIcon.svg'} title={'Zwrot składki'} />
        <div className={'tw-px-2 tw-py-6 sm:tw-px-6'}>
          <UserPersonalDataLayout>
            <CashbackPage />
          </UserPersonalDataLayout>
        </div>
      </div>
    </CustomerDashboard>
  );
};

export default CashbackLayout;
