import { observer } from 'mobx-react-lite';
import React from 'react';
import { CinemaVoucherM } from '../../../types/cinemaVoucher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { Image } from 'react-bootstrap';
import CopyClipboardIcon from '../../Icons/CopyClipboardIcon';
import { toast } from 'react-toastify';
import MyLightGrayArrowsDisclosure from '../../Disclosure/MyLightGrayArrowsDisclosure';
import { CinemaCompanyId } from '../../../enums/cinemaCompanyId.enum';
import { useStore } from '../../../store/store';
import FileHelper from '../../../helpers/FileHelper';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface ICinemaVoucherListElementProps {
  cinemaVoucher: CinemaVoucherM;
  changeCinemaVoucherUsed: (code: CinemaVoucherM) => void;
}

export default observer((props: ICinemaVoucherListElementProps) => {
  const { cinemaVoucherStore } = useStore();
  const code = props.cinemaVoucher;
  const getCinemaCompanyLogoImage = () => {
    if (code.companyId === CinemaCompanyId.Multikino || code.companyId === CinemaCompanyId.MultikinoOnlyWarsaw) {
      return <Image alt='logo' className={'tw-w-[60%]'} src='/assets/cinemaVouchers/logo_multikino.png' />;
    }
    if (code.companyId === CinemaCompanyId.Helios) {
      return <Image alt='logo' className={'tw-w-[60%]'} src='/assets/cinemaVouchers/logo_helios.png' />;
    }
    if (code.companyId === CinemaCompanyId.CinemaCity || code.companyId === CinemaCompanyId.CinemaCityWithWarsaw) {
      return <Image alt='logo' className={'tw-w-[60%] -tw-mb-3'} src='/assets/cinemaVouchers/logo_cinema_city.png' />;
    }
    return <span>Brak loga</span>;
  };

  const downloadVoucherFile = (id: number) => {
    cinemaVoucherStore
      .downloadVoucherFile(id)
      .then((resp) => {
        const fileName = 'Kupon_' + code.number + '.pdf';
        return FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .catch(() => {
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };

  return (
    <div>
      <div className={'tw-flex tw-justify-center'}>
        <div className={'tw-relative tw-w-fit'}>
          <svg
            className={'tw-max-w-full'}
            height='139.105'
            style={{ fontSize: '18px' }}
            viewBox='0 0 363 139.105'
            width='363'>
            <defs>
              <clipPath id='clip-path'>
                <rect data-name='Rectangle 415' fill='none' height='139.105' id='Rectangle_415' width='363' />
              </clipPath>
            </defs>
            <g data-name='Group 949' id='Group_949' transform='translate(-34 -238.468)'>
              <g data-name='Group 944' id='Group_944' transform='translate(34 238.468)'>
                <g clipPath='url(#clip-path)' data-name='Group 943' id='Group_943'>
                  <path
                    d='M204.628,138.766H413.885c8.159,0,14.774-5.872,14.774-13.116V15.616c0-7.244-6.615-13.116-14.774-13.116H204.628c0,8.846-8.077,16.017-18.041,16.017V122.749C196.551,122.749,204.628,129.92,204.628,138.766Z'
                    data-name='Path 3938'
                    fill='none'
                    id='Path_3938'
                    stroke='#00736d'
                    strokeWidth='5'
                    transform='translate(-67.257 -1.081)'
                  />
                  <path
                    d='M17.274,138.766H85.181c0-8.846,8.077-16.017,18.041-16.017V18.517c-9.964,0-18.041-7.171-18.041-16.017H17.274C9.115,2.5,2.5,8.372,2.5,15.616V125.65C2.5,132.894,9.115,138.766,17.274,138.766Z'
                    data-name='Path 3939'
                    fill='none'
                    id='Path_3939'
                    stroke='#00736d'
                    strokeWidth='5'
                    transform='translate(-0.901 -1.081)'
                  />
                  <path
                    d='M172.289,30.71h1.279v2.033h-1.279Zm0,7.143h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279V63.4h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Zm0,10.22h1.279v5.11h-1.279Z'
                    data-name='Path 3940'
                    fill='#00736d'
                    id='Path_3940'
                    transform='translate(-62.103 -13.274)'
                  />
                </g>
              </g>
            </g>
          </svg>
          {code.isUsed && <div className={'tw-bg-white tw-bg-opacity-75 tw-absolute tw-inset-0 tw-z-10'}></div>}
          <div className={'tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-between '}>
            <div
              className={'tw-w-[28%]  tw-text-nau-green-dark tw-text-[11px] tw-cursor-pointer tw-z-20'}
              onClick={() => props.changeCinemaVoucherUsed(code)}>
              <div className='tw-flex tw-justify-center '>
                <FontAwesomeIcon color='#00635C' icon={code.isUsed ? faSquareCheck : faSquare} size='3x' />
              </div>
              <div className={'tw-flex tw-justify-center'}>wykorzystany</div>
            </div>
            <div
              className={
                'tw-w-[67%] tw-relative tw-text-nau-green-dark tw-text-[11px] tw-h-full tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center'
              }>
              {getCinemaCompanyLogoImage()}
              <div
                className={'tw-text-lg tw-flex tw-gap-1 tw-items-center tw-cursor-pointer'}
                onClick={() => {
                  navigator.clipboard.writeText(code.number);
                  toast.success('Skopiowano numer kuponu');
                }}>
                {code.number} <CopyClipboardIcon className={'tw-text-nau-green-dark tw-h-4 tw-w-4'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {code.companyId === CinemaCompanyId.Multikino && (
        <div className={'tw-flex tw-justify-center tw-mt-2 '}>
          <div className={'tw-max-w-[360px] tw-text-nau-green-dark tw-text-center tw-leading-snug'}>
            Kupon ważny na terenie całej Polski
            <div>(z wyjątkiem Warszawy i Pruszkowa)</div>
          </div>
        </div>
      )}
      {code.companyId === CinemaCompanyId.MultikinoOnlyWarsaw && (
        <div className={'tw-flex tw-justify-center tw-mt-2 '}>
          <div className={'tw-max-w-[360px] tw-text-nau-green-dark tw-text-center tw-leading-snug tw-text-pretty'}>
            Kupon ważny na terenie Warszawy <span className={'tw-whitespace-nowrap'}>i Pruszkowa</span>
          </div>
        </div>
      )}
      <div className={'tw-flex tw-justify-center tw-mt-2 '}>
        <div className={'tw-border tw-rounded-lg tw-overflow-hidden tw-border-gray-300 tw-max-w-[360px] tw-flex-1 '}>
          <MyLightGrayArrowsDisclosure title={'Szczegóły'}>
            <div className={'tw-p-2'}>
              <div className={'tw-flex tw-flex-col tw-items-center tw-text-nau-green-dark'}>
                <div className={'tw-mb-0.5'}>Numer kuponu</div>
                <div
                  className={'tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-2xl tw-cursor-pointer'}
                  onClick={() => {
                    navigator.clipboard.writeText(code.number);
                    toast.success('Skopiowano numer kuponu');
                  }}>
                  {code.number}
                  <CopyClipboardIcon className={'tw-text-nau-green-dark'} />
                </div>
              </div>
              <div className={'tw-flex tw-w-full tw-justify-center tw-text-nau-green-dark tw-mt-4 '}>
                <div className={'tw-flex tw-items-center  tw-justify-between tw-max-w-[350px] tw-flex-1'}>
                  <div className={'tw-flex tw-flex-col tw-items-center tw-gap-2'}>
                    <div className={''}>Data zakupu</div>
                    <div className={'tw-font-bold'}>{code.getDate ? code.getDate : '-'}</div>
                  </div>
                  <div className={'tw-flex tw-flex-col tw-items-center tw-gap-2'}>
                    <div className={''}>Termin ważności</div>
                    <div className={'tw-font-bold'}>{code.expirationDate ? code.expirationDate : 'bezterminowy'}</div>
                  </div>
                </div>
              </div>
              <div
                className={
                  'tw-flex tw-justify-center tw-items-center tw-text-nau-green-dark tw-font-semibold tw-mt-4 tw-mb-2 cursor-pointer'
                }
                onClick={() => downloadVoucherFile(code.id)}>
                Pobierz kupon <FontAwesomeIcon className={'tw-ml-1'} icon={faDownload} />
              </div>
            </div>
          </MyLightGrayArrowsDisclosure>
        </div>
      </div>
    </div>
  );
});
