import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import { CinemaVoucherTabsE } from '../../../enums/cinemaVoucherTabs.enums';
import UserCinemaVouchers from '../UserCinemaVouchers/UserCinemaVouchers';
import { twMerge } from 'tailwind-merge';
import CinemaVouchersInstruction from '../CinemaVouchersInstruction/CinemaVouchersInstruction';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { useLocation } from 'react-router-dom';
import CinemaVoucherCartLayout from '../CinemaVoucherCart/CinemaVoucherCartLayout';

export default observer(() => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderView = params.get('orderView') === 'true';

  const [activeTab, setActiveTab] = useState<number>(orderView ? CinemaVoucherTabsE.Cart : CinemaVoucherTabsE.Codes);
  const { data: codes } = useSwrAgent().CinemaVouchers.GetUserCinemaVouchers();
  const [initialCodesChecked, setInitialCodesChecked] = useState<boolean>(false);

  const renderTab = (tab: number) => {
    if (tab == CinemaVoucherTabsE.Codes) {
      return <UserCinemaVouchers />;
    } else if (tab == CinemaVoucherTabsE.Cart) {
      return <CinemaVoucherCartLayout />;
    }
  };

  useEffect(() => {
    if (codes && !initialCodesChecked) {
      if (Array.isArray(codes) && (codes.length === 0 || codes.every((code) => code.isUsed))) {
        setActiveTab(CinemaVoucherTabsE.Cart);
      }
      setInitialCodesChecked(true);
    }
  }, [codes]);

  return (
    <>
      <div>
        <div className='col-md-6 offset-md-3'>
          <div style={styles.tabsBox}>
            <div>
              <h1
                className={twMerge(
                  'tw-flex tw-items-center tw-justify-center tw-px-2 tw-text-center tw-text-3xl tw-text-white sm:tw-text-4xl',
                )}>
                <Image
                  src='/assets/cinemaVoucher.svg'
                  style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
                />
                Kupony do kina
              </h1>
            </div>
            <div
              style={
                {
                  paddingTop: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                } as React.CSSProperties
              }>
              <span
                onClick={() => setActiveTab(CinemaVoucherTabsE.Codes)}
                style={activeTab == CinemaVoucherTabsE.Codes ? styles.activeTabLink : styles.tabLink}>
                Moje kupony
              </span>
              <span
                onClick={() => setActiveTab(CinemaVoucherTabsE.Cart)}
                style={activeTab == CinemaVoucherTabsE.Cart ? styles.activeTabLink : styles.tabLink}>
                Zamów
              </span>
            </div>
          </div>
          {renderTab(activeTab)}
        </div>
        <div className='col-md-6 offset-md-3 tw-mt-4'>
          <CinemaVouchersInstruction />
        </div>
      </div>
    </>
  );
});
