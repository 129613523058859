import { makeAutoObservable } from 'mobx';

export default class SideBarStore {
  isMainCustomerToggled: boolean | null = window.localStorage.getItem('MainCustomerToggle') == 'true';
  isProfileToggled = false;
  isMobileView: boolean = window.innerWidth < 992;
  isBigView: boolean = window.innerWidth > 1200;
  isSmallMobileView: boolean = window.innerWidth < 576;
  constructor() {
    makeAutoObservable(this);
  }

  get checkIsMainCustomerToggled() {
    if (this.isMainCustomerToggled != null) return this.isMainCustomerToggled;
    return window.localStorage.getItem('MainCustomerToggle') == 'true';
  }

  get checkIsProfileToggled() {
    return this.isProfileToggled;
  }
  get checkIsMobileView() {
    return this.isMobileView;
  }
  get checkIsBigView() {
    return this.isBigView;
  }

  toggleMainCustomer = () => {
    if (this.isMainCustomerToggled != null) {
      this.isMainCustomerToggled = !this.isMainCustomerToggled;
    } else {
      const isMainCustomerToggled = window.localStorage.getItem('MainCustomerToggle') == 'true';
      this.isMainCustomerToggled = !isMainCustomerToggled;
    }
    window.localStorage.setItem('MainCustomerToggle', this.isMainCustomerToggled.toString());
  };

  setClosedMainCustomer = () => {
    this.isMainCustomerToggled = false;
    window.localStorage.setItem('MainCustomerToggle', this.isMainCustomerToggled.toString());
  };

  toggleProfile = () => {
    this.isProfileToggled = !this.isProfileToggled;
  };

  setClosedProfile = () => {
    this.isProfileToggled = false;
  };
}
