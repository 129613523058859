import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { UserInstitution } from '../../../types/user';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Capacitor } from '@capacitor/core';

export default observer((props: { userId: number }) => {
  const { userStore, modalStore, loansStore } = useStore();
  const [userInst, setUserInst] = useState<UserInstitution | null>(null);
  const [loanPaymentDates, setloanPaymentDates] = useState<MySelectInputOptions[]>([]);
  const [userLoanPaymentDate] = useState<number | null>(null);

  useEffect(() => {
    const userInstitution = userStore.getUserInstitution();
    setUserInst(userInstitution);
    if (userInstitution != null && userInstitution != undefined) {
      loansStore
        .getLoanPaymentDates(userInstitution.institutionId)
        .then((data) => {
          setloanPaymentDates(data);
        })
        .catch((error) => {
          console.log(`Error in promises ${error}`);
          toast.error('Wystąpił błąd przy pobieraniu danych');
        });
    }
  }, [userStore]);
  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        loanPaymentDateId: userLoanPaymentDate,
        salary: '2000',
        error: null,
      }}
      //validationSchema={ChangeLimitSchema}
      onSubmit={({ salary, loanPaymentDateId }, { setSubmitting, setErrors, setFieldError }) => {
        loansStore
          .addLoanBenefit(props.userId, userInst?.institutionId ?? 0, Number(salary), Number(loanPaymentDateId))
          .then(() => {
            setSubmitting(false);
            toast.success('Benefit został dodany.');
            handleClose();
            if (Capacitor.isNativePlatform()) {
              const path = history.location.pathname;
              history.push(PathRoute.PUSTY);
              history.push(path);
            } else {
              window.location.reload();
            }
          })
          .catch((err) => {
            // @ts-ignore
            if (Array.isArray(err)) {
              err.forEach((element) => {
                const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                // @ts-ignore
                setFieldError(fieldName, element.message);
                //setFieldTouched(fieldName);
                setSubmitting(false);
              });
            } else {
              setErrors({ error: err });
              setSubmitting(false);
            }
          });
      }}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Podaj wynagrodzenie miesięczne dla użytkownika (brutto)</span>
            </p>
            <MyTextInput
              className='inputNumberHideArrow'
              min='2000'
              name={'salary'}
              placeholder={'Wynagrodzenie'}
              type={'number'}
            />
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Podaj Dzień wypłaty wynagrodzenia dla użytkownika</span>
            </p>
            <MySelectInput
              className='inputNumberHideArrow'
              name={'loanPaymentDateId'}
              options={loanPaymentDates}
              placeholder={'Dzień miesiąca'}
              value={userLoanPaymentDate}
            />
            <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Anuluj'
            buttonTextSubmit='Zapisz'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
