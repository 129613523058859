import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import ApplicationLoanHistory from '../../components/Loan/ApplicationLoanHistory/ApplicationLoanHistory';
import { useStore } from '../../store/store';
import LoanMain from './LoanMain';

export default observer(() => {
  const { loansStore } = useStore();

  useEffect(() => {
    loansStore.loanMainLoadings = true;
    loansStore.availableBenefitId = null;
  }, []);

  return (
    <>
      <LoanMain>
        <div>
          {loansStore.hasLoanPermission ? <ApplicationLoanHistory /> : <h4>Obecnie nie masz dostępu do historii</h4>}
        </div>
      </LoanMain>
    </>
  );
});
