import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { useStore } from '../../../store/store';
import * as style from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { Benefit } from '../../../enums/benefits.enums';
import { history } from '../../../index';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import { DiscountCodeNewContractStatuses } from '../../../enums/discountCodeContractStatus.enums';
import ApprovalsForm from '../../../components/ApprovalsForm/ApprovalsForm';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    discountCodeStore
      .getUserDiscountCodeContractStatus()
      .then((contractStatus) => {
        if (!DiscountCodeNewContractStatuses.includes(contractStatus ?? 0)) {
          history.push(PathRoute.DISCOUNT_CODE);
          return;
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3' style={style.container}>
            <DiscountCodeHeader />
            <div style={style.contentBox}>
              <ApprovalsForm
                benefitId={Benefit.DiscountCode}
                hideHeader={true}
                redirectPath={PathRoute.USER_CONTRACT_DISCOUNT_CODE}
              />
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
