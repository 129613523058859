import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { UserInstitution } from '../../../types/user';
import { useStore } from '../../../store/store';
import AdminDashboard from '../../../containers/AdminDashboard/AdminDashboard';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import ReactPaginate from 'react-paginate';
import { MonthlyLoanApplicationForInstitutionM } from '../../../types/loans';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import UserLoanApplicationsPerMonthForAdmin from './UserLoanApplicationsPerMonthForAdmin';
import FileHelper from '../../../helpers/FileHelper';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';

export default observer(() => {
  const { userStore, loansStore, modalStore } = useStore();
  const [loanApplications, setLoanApplications] = useState<MonthlyLoanApplicationForInstitutionM[]>([]);
  const [loanApplicationsSum, setLoanApplicationsSum] = useState<string>('');
  const [userInst, setUserInst] = useState<UserInstitution | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [pesel, setPesel] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [months, setMonths] = useState<MySelectInputOptions[]>([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
    getLoanApplicationsMonthSelect().then((resp) => {
      getLoanApplicationsForInstitution(undefined, resp);
      getLoanApplicationsSumForInstitution(resp);
    });
  }, []);
  const downloadLoanApplicationsForInstitution = async () => {
    let userInstitution = userInst;
    if (userInstitution == null) {
      userInstitution = userStore.getUserInstitution();
    }
    if (userInstitution != null) {
      const request = {
        institutionId: userInstitution.institutionId,
        loanApplicationMonth: month,
        firstName: firstName,
        lastName: lastName,
        pesel: pesel,
        sorting: '',
        offset: 0,
        limit: 0,
      };
      return loansStore.downloadLoanApplicationsForInstitution(
        request.institutionId,
        request.loanApplicationMonth,
        request.firstName,
        request.lastName,
        request.pesel,
        request.sorting,
        request.offset,
        request.limit,
      );
    }
  };
  const getLoanApplicationsForInstitution = async (offset?: number, loanApplicationMonth?: string) => {
    setLoading(true);
    let userInstitution = userInst;
    if (userInstitution == null) {
      userInstitution = userStore.getUserInstitution();
    }
    if (userInstitution != null) {
      setLoanApplications([]);
      const request = {
        institutionId: userInstitution.institutionId,
        loanApplicationMonth: loanApplicationMonth ?? month,
        firstName: firstName,
        lastName: lastName,
        pesel: pesel,
        sorting: '',
        offset: offset ?? itemOffset,
        limit: 50,
      };
      return loansStore
        .getLoanApplicationsForInstitution(
          request.institutionId,
          request.loanApplicationMonth,
          request.firstName,
          request.lastName,
          request.pesel,
          request.sorting,
          request.offset,
          request.limit,
        )
        .then((resp) => {
          if (resp != undefined && Array.isArray(resp.records)) {
            setLoanApplications(resp.records);
            setItemsCount(resp.totalRecordCount);
            setPageCount(Math.ceil(resp.totalRecordCount / 50));
          }
          setLoading(false);
        });
    }
  };
  const getLoanApplicationsSumForInstitution = async (loanApplicationMonth?: string) => {
    let userInstitution = userInst;
    if (userInstitution == null) {
      userInstitution = userStore.getUserInstitution();
    }
    if (userInstitution != null) {
      setLoanApplications([]);
      const request = {
        institutionId: userInstitution.institutionId,
        loanApplicationMonth: loanApplicationMonth ?? month,
      };
      return loansStore
        .getLoanApplicationsSumForInstitution(request.institutionId, request.loanApplicationMonth)
        .then((resp) => {
          setLoanApplicationsSum(resp);
        });
    }
  };

  const getLoanApplicationsMonthSelect = async () => {
    let userInstitution = userInst;
    if (userInstitution == null) {
      userInstitution = userStore.getUserInstitution();
    }
    if (userInstitution != null) {
      return loansStore.getLoanApplicationsMonthSelect(userInstitution.institutionId).then((resp) => {
        if (resp != undefined) {
          setMonths(resp);
          if (resp.length > 1) {
            setMonth(resp[1].value.toString());
            return resp[1].value.toString();
          }
        } else {
          setMonths([]);
        }
      });
    }
  };
  const handlePageClick = (event: any) => {
    // @ts-ignore
    const newOffset = (event.selected * 20) % itemsCount;
    setItemOffset(newOffset);
    getLoanApplicationsForInstitution(newOffset);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    setFirstName(firstName);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    setLastName(lastName);
  };

  const handlePeselChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pesel = event.target.value;
    setPesel(pesel);
  };
  const handleMonthChange = (event: string) => {
    setMonth(event);
    getLoanApplicationsForInstitution(undefined, event);
    getLoanApplicationsSumForInstitution(event);
  };
  const handleDownload = () => {
    downloadLoanApplicationsForInstitution().then((item) => {
      if (item != undefined) {
        const fileName = `Raport_miesięczny_${month != '' ? month : 'wszystkie'}.xlsx`;
        FileHelper.downloadFileFromBlob(item, fileName);
      }
    });
  };
  return (
    <AdminDashboard>
      {loading && <LoadingComponent />}
      <div className='row d-md-flex align-items-center justify-content-between w-100'>
        {loading ? (
          <h2 className='m-2 col-sm-12 col-lg-12'>
            Rozliczenia {month != '' ? `z ${month.slice(0, month.length - 3)}` : 'z wszystkich miesięcy'}:
          </h2>
        ) : (
          <h2 className='m-2 col-sm-12 col-lg-12'>
            Rozliczenia {month != '' ? `z ${month.slice(0, month.length - 3)}` : 'z wszystkich miesięcy'}
            {loanApplicationsSum != '' ? `: ${loanApplicationsSum} zł` : loanApplicationsSum}
          </h2>
        )}
      </div>
      <div className={'row'} style={{ width: '100%', marginBottom: '10px', fontWeight: 400 }}>
        <Formik
          initialValues={{
            InstitutionId: userInst != null ? userInst.institutionId : 0,
            loanApplicationMonth: month,
            firstName: firstName,
            lastName: lastName,
            pesel: pesel,
            sorting: '',
            offset: 0,
            limit: 50,
          }}
          onSubmit={(values, { setSubmitting }) =>
            getLoanApplicationsForInstitution(undefined, values.loanApplicationMonth)
              .then(() => {
                setSubmitting(false);
                setLoading(false);
              })
              .catch(() => {
                setSubmitting(false);
                setLoading(false);
              })
          }>
          {({ isSubmitting }) => (
            <Form className={'d-md-md-flex align-items-center col-md-9'} style={{}}>
              <div className={'d-md-flex'}>
                <div style={{ marginRight: '10px', minWidth: '180px' } as React.CSSProperties}>
                  <MySelectInput
                    name='loanApplicationMonth'
                    onChange={(e) => {
                      handleMonthChange(e != null ? e.toString() : '');
                    }}
                    options={months}
                    placeholder='Miesiąc'
                    value={month}
                  />
                </div>
                <div style={{ marginRight: '10px' } as React.CSSProperties}>
                  <MyTextInput name='firstName' onChange={handleFirstNameChange} placeholder='Imię' value={firstName} />
                </div>
                <div style={{ marginRight: '10px' } as React.CSSProperties}>
                  <MyTextInput
                    name='lastName'
                    onChange={handleLastNameChange}
                    placeholder='Nazwisko'
                    value={lastName}
                  />
                </div>
                <div style={{ marginRight: '10px' } as React.CSSProperties}>
                  <MyTextInput name='pesel' onChange={handlePeselChange} placeholder='Pesel' value={pesel} />
                </div>
                <div className={'col-md-3'}>
                  {isSubmitting ? (
                    <Button className='btn mt-3 btn-mini' type='submit'>
                      <span className='m-1'>
                        <Spinner
                          animation='grow'
                          aria-hidden='true'
                          as='span'
                          role='status'
                          size='sm'
                          variant='light'
                        />
                      </span>
                      Szukam…
                    </Button>
                  ) : (
                    <Button className='btn mt-3 btn-mini btn-mobile-full-width' type='submit'>
                      Szukaj
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={'d-md-flex align-items-center col-md-3'}>
          {!loading && (
            <Button className='btn btn-mini mt-3 btn-mobile-full-width' onClick={handleDownload}>
              Pobierz listę wypłat - {month != '' ? month.slice(0, month.length - 3) : 'wszystkie'}
            </Button>
          )}
        </div>
      </div>
      <ul className='list-group' style={{ fontSize: '14px', width: '100%' } as React.CSSProperties}>
        {loading ? (
          'Szukam...'
        ) : (
          <>
            {loanApplications != undefined && loanApplications.length > 0
              ? loanApplications.map((loanApplication) => (
                  <>
                    <li
                      className='list-group-item row d-md-flex align-items-center'
                      key={loanApplication.userId + loanApplication.loanApplicationMonth}
                      style={{ fontWeight: 400, color: '#464646' }}>
                      <div className='row'>
                        <div className='col-md-12 row d-md-flex align-items-end justify-content-between'>
                          <div className='row align-items-center'>
                            <div className='col-12 col-md-4'>
                              <h6 style={{ margin: 0 } as React.CSSProperties}>
                                {loanApplication.firstName} {loanApplication.lastName}
                              </h6>
                            </div>
                            <div className='col-12 col-md-2'>
                              <b>Miesiąc: </b>
                              {loanApplication.loanApplicationMonth}
                            </div>
                            <div className='col-12 col-md-2'>
                              <b>Pesel: </b>
                              {loanApplication.pesel}
                            </div>
                            <div className='col-12 col-md-2'>
                              <b>Kwota: </b>
                              {loanApplication.amount} zł
                            </div>
                            <div className='col-12 col-md-2'>
                              <Button
                                className='w-100 btn-mini'
                                onClick={() =>
                                  modalStore.openModal(
                                    <UserLoanApplicationsPerMonthForAdmin
                                      institutionId={userInst?.institutionId ?? 0}
                                      month={loanApplication.loanApplicationMonth}
                                      userId={loanApplication.userId}
                                    />,
                                    'lg',
                                    'Szczegóły rozliczenia',
                                  )
                                }>
                                Zobacz szczegóły
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ))
              : 'Brak wyników dla wybranego miesiąca'}
          </>
        )}
      </ul>
      <div id={'pagination'}>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          previousLabel='<'
          renderOnZeroPageCount={undefined}
        />
      </div>
    </AdminDashboard>
  );
});
