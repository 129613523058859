import { observer } from 'mobx-react-lite';
import React from 'react';
import { DiscountCodeM } from '../../../types/discountCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import * as styles from './styles';

interface IDiscountCodeListElementProps {
  discountCode: DiscountCodeM;
  downloadDiscountCodeBarcode: (codeId: number, codeNumber: string) => void;
  changeDiscountCodeUsed: (code: DiscountCodeM) => void;
}

export default observer((props: IDiscountCodeListElementProps) => {
  const code = props.discountCode;

  return (
    <div
      style={
        {
          border: '2px solid #00635C',
          padding: '20px',
          borderRadius: '10px',
          color: '#fff',
          margin: '10px 0',
          opacity: code.isUsed ? '0.3' : '1',
        } as React.CSSProperties
      }>
      <div
        style={
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            color: '#00635C',
          } as React.CSSProperties
        }>
        <div style={{ color: '#00635C', display: 'flex', alignItems: 'center' } as React.CSSProperties}>
          <FontAwesomeIcon color='#00635C' icon={faMoneyBill} size='lg' />
          {code.pin?.trim() && (
            <p style={{ marginLeft: '10px', marginBottom: 0 } as React.CSSProperties}>PIN: {code.pin}</p>
          )}
        </div>
        <div style={{ color: '#00635C' } as React.CSSProperties}>
          {code.hasBarcode ? (
            <div
              onClick={() => props.downloadDiscountCodeBarcode(code.id, code.number)}
              style={styles.barcodeDownloadButton}>
              Pobierz E-bon
            </div>
          ) : (
            <div style={{ color: '#00635C' } as React.CSSProperties}>{code.number}</div>
          )}
        </div>
      </div>
      <div
        style={
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            color: '#00635C',
          } as React.CSSProperties
        }>
        <div
          style={
            {
              color: '#00635C',
              fontSize: '26px',
              padding: '16px 0',
              maxWidth: 'calc(100% - 70px)',
            } as React.CSSProperties
          }>
          {code.companyName}
          {code.description && (
            <div
              style={
                {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  color: '#00635C',
                  marginTop: '10px',
                } as React.CSSProperties
              }>
              <div style={{ color: '#00635C', fontSize: '10px' } as React.CSSProperties}>{code.description}</div>
            </div>
          )}
        </div>
        <div style={{ color: '#00635C', fontSize: '26px', padding: '16px 0' } as React.CSSProperties}>
          {code.amount != '0' ? code.amount + ' zł' : ''}
        </div>
      </div>
      <div
        style={
          {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            color: '#00635C',
          } as React.CSSProperties
        }>
        <span
          onClick={() => props.changeDiscountCodeUsed(code)}
          style={
            {
              color: '#00635C',
              fontSize: '12px',
              cursor: 'pointer',
              fontWeight: '400',
            } as React.CSSProperties
          }>
          wykorzystany
          <span className='m-1'>
            <FontAwesomeIcon color='#00635C' icon={code.isUsed ? faSquareCheck : faSquare} size='lg' />
          </span>
        </span>
      </div>
      <div
        style={
          {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            color: '#00635C',
          } as React.CSSProperties
        }>
        <div style={{ color: '#00635C', fontSize: '10px', marginBottom: '-15px' } as React.CSSProperties}>
          termin ważności: {code.expirationDate ? code.expirationDate : 'bezterminowy'}
        </div>
      </div>
    </div>
  );
});
