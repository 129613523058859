import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { DiscountCodeCampaignM } from '../../../types/discountCode';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import DiscountCodeLimitEnd from '../DiscountCodeLimitEnd/DiscountCodeLimitEnd';
import { ErrorMessagesE } from '../../../enums/errorMessages.enums';
import UnpaidDiscountCodeSettlements from '../UnpaidDiscountCodeSettlements/UnpaidDiscountCodeSettlements';
import { useSwrAgent } from '../../../api/useSwrAgent';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [campaigns, setCampaigns] = useState<DiscountCodeCampaignM[]>([]);
  const [sum, setSum] = useState<number>(0);
  const [userLimit, setUserLimit] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { data: unpaidSettlements, isLoading, mutate } = useSwrAgent().DiscountCodes.CheckHasNotSettledSettlements();

  useEffect(() => {
    discountCodeStore
      .getAvailableDiscountCodeTypes()
      .then((resp) => {
        let limit = 0;
        resp.forEach((element) => {
          element.sum = 0;
          limit += element.limitLeft;
        });
        setUserLimit(limit);
        setCampaigns(resp);
        setLoading(false);
      })
      .catch((err) => {
        for (let index = 0; index < err.length; index++) {
          toast.error(err[index]);
        }
      });
  }, []);

  const increment = (idx: number, campaignId: number) => {
    const newCampaigns = [...campaigns];
    const campaign = newCampaigns.filter((x) => x.id == campaignId)[0];
    let amount = campaign.sum;
    amount += campaign.types[idx].amount;
    const availableFunds = campaign.limitLeft - amount;
    if (campaign.types[idx].quantity >= campaign.types[idx].availableQuantity) {
      toast.error('Nie można wybrać więcej kodów tego typu');
    } else if (availableFunds < 0) {
      toast.error('Wartość wybranego kodu jest większa od dostepnego limitu');
    } else {
      campaign.types[idx].quantity += 1;
      campaign.sum = amount;
      setCampaigns(newCampaigns);
    }
    let campaignsSum = 0;
    newCampaigns.forEach((element) => {
      campaignsSum += element.sum;
    });
    setSum(campaignsSum);
  };
  const decrement = (idx: number, campaignId: number) => {
    const newCampaigns = [...campaigns];
    const campaign = newCampaigns.filter((x) => x.id == campaignId)[0];
    let amount = campaign.sum;
    amount -= campaign.types[idx].amount;
    if (campaign.types[idx].quantity >= 1) {
      campaign.types[idx].quantity -= 1;
      campaign.sum = amount;
      setCampaigns(newCampaigns);
    }
    let campaignsSum = 0;
    newCampaigns.forEach((element) => {
      campaignsSum += element.sum;
    });
    setSum(campaignsSum);
  };

  const sendOrder = () => {
    if (sum > 0) {
      setIsSubmitting(true);
      discountCodeStore
        .orderDiscountCodes(campaigns)
        .then((resp) => {
          setIsSubmitting(false);
          history.push(PathRoute.CONFIRM_DISCOUNT_CODE_ORDER + '/' + resp);
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error == ErrorMessagesE.DiscountCodeTechnicalBreak) {
            history.push(PathRoute.DISCOUNT_CODE_TECHNICAL_BREAK);
          } else {
            toast.error(error);
          }
        });
    } else {
      toast.error('Należy wybrać kody do zamówienia.');
    }
  };
  return (
    <div>
      {unpaidSettlements ? (
        <UnpaidDiscountCodeSettlements />
      ) : (
        <div>
          {userLimit == 0 ? (
            <DiscountCodeLimitEnd />
          ) : (
            <div
              id='DiscountCodes'
              style={
                {
                  background: '#fff',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  marginTop: '0',
                  padding: '10px',
                } as React.CSSProperties
              }>
              {loading ? (
                <LoadingComponentPartial />
              ) : (
                <div>
                  {campaigns.length > 0 &&
                    campaigns.map((campaign) => (
                      <div key={campaign.id}>
                        <p>Dostępny limit w ramach kampanii: {campaign.limit} zł</p>
                        <h4
                          style={
                            {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              paddingTop: '10px',
                              fontWeight: '400',
                              color: '#747474',
                            } as React.CSSProperties
                          }>
                          Pozostały limit: {campaign.limitLeft - campaign.sum} zł
                        </h4>
                        {campaign.types.length > 0 &&
                          campaign.types.map((code, idx) => (
                            <div
                              key={idx}
                              style={
                                {
                                  background: '#0bc268',
                                  padding: '20px',
                                  borderRadius: '10px',
                                  color: '#fff',
                                  margin: '10px 0',
                                } as React.CSSProperties
                              }>
                              <div
                                style={
                                  {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                  } as React.CSSProperties
                                }>
                                <div>
                                  <h5>{code.companyName}</h5>
                                  <div style={{ fontSize: '16px', fontWeight: '300' } as React.CSSProperties}>
                                    E-bon na kwotę {code.amount} zł
                                  </div>
                                </div>
                                <div className='d-flex'>
                                  <button onClick={() => decrement(idx, campaign.id)} style={styles.incrementButton}>
                                    -
                                  </button>
                                  <span style={styles.quantity}>{code.quantity}</span>
                                  <button onClick={() => increment(idx, campaign.id)} style={styles.decrementButton}>
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  {isSubmitting ? (
                    <Button className='btn-full-width' disabled={true}>
                      <span className='m-1'>
                        <Spinner
                          animation='grow'
                          aria-hidden='true'
                          as='span'
                          role='status'
                          size='sm'
                          variant='light'
                        />
                      </span>
                      Potwierdzanie zamówienia…
                    </Button>
                  ) : (
                    <Button className='btn-full-width' onClick={() => sendOrder()}>
                      Zamawiam - {sum} zł
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
