import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as styles from './styles';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import ConfirmEmailBar from '../../components/ConfirmEmailBar/ConfirmEmailBar';
import { Benefit } from '../../enums/benefits.enums';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';
import CircleKApplicationForCardChangeOperator from './CircleKApplicationForChangeOperatorCard/CircleKApplicationForCardChangeOperator';

export default observer(() => {
  const { circleKStore, userStore, menuStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasApprovals, setApprovals] = useState<boolean>(true);

  useEffect(() => {
    if (userStore.isActive) {
      userStore.hasRequiredApprovalsForBenefit(Benefit.CircleK).then((resp) => {
        setApprovals(resp);
        if (resp) {
          circleKStore
            .getCircleKCardsByUserId()
            .then(() => {
              circleKStore
                .getCircleKMyExpensesByUserId()
                .then(() => {
                  circleKStore
                    .getCircleKSettlements()
                    .then(() => {
                      setLoading(false);
                      userStore.checkEmailIsConfirmed();
                      circleKStore.getCircleKBalanceByUserId();
                    })
                    .catch((error) => {
                      console.log(`Error in promises ${error}`);
                      setLoading(false);
                      toast.error('Wystąpił błąd przy pobieraniu rozliczeń');
                    });
                })
                .catch((error) => {
                  console.log(`Error in promises ${error}`);
                  setLoading(false);
                  toast.error('Wystąpił błąd przy pobieraniu transakcji');
                });
            })
            .catch((error) => {
              console.log(`Error in promises ${error}`);
              setLoading(false);
              toast.error('Wystąpił błąd przy pobieraniu kart');
            });
        } else {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <>
      {userStore.isLoggedIn ? (
        userStore.isActive ? (
          <>
            <LoadingComponent content='Ładowanie...' visible={loading} />
            <CustomerDashboard>
              {!hasApprovals ? (
                <div className='col-md-6 offset-md-3'>
                  <ApprovalsForm benefitId={Benefit.CircleK} />
                </div>
              ) : (
                <div className='col-md-6 offset-md-3'>
                  {!userStore.isEmailConfirmed && <ConfirmEmailBar />}
                  <div
                    className='d-flex flex-column align-items-center justify-content-between w-100'
                    style={styles.mainDivEmptyCard as React.CSSProperties}>
                    <div style={styles.container}>
                      <CircleKApplicationForCardChangeOperator />
                    </div>
                  </div>
                </div>
              )}
            </CustomerDashboard>
          </>
        ) : (
          history.push(PathRoute.AUTHORIZE_TOKEN)
        )
      ) : (
        history.push(PathRoute.LOGIN)
      )}
    </>
  );
});
