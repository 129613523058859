import * as React from 'react';
import * as styles from './styles';
import { Image } from 'react-bootstrap';
import CinemaVoucherHeader from '../../../components/CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';
import { PathRoute } from '../../../constants/pathRoute/Route';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import MyButton from '../../../components/Buttons/MyButton/MyButton';

export const CinemaVoucherPromoDirectorOrderSuccess = () => {
  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3' style={styles.container as React.CSSProperties}>
        <CinemaVoucherHeader />

        <div style={styles.flexColumnPadding}>
          <p style={styles.wayStyles as React.CSSProperties}>Dziękujemy za zakup!</p>
          <p className={'tw-text-center tw-text-2xl tw-text-nau-green-dark'}>
            Dziękujemy za odebranie kuponów. Znajdziesz je w swojej skrzynce e-mail. Kolejne kupony możesz nabyć,
            logując się w Klub NAU.
          </p>
        </div>
        <div style={styles.flexCenter as React.CSSProperties}>
          <Image src='assets/popcorn.svg' />
        </div>
        <div className={'tw-flex tw-w-full tw-justify-center tw-p-4'}>
          <MyButton
            className={'tw-mt-8 tw-w-full'}
            onClick={() => window.open(PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO, '_self', 'location=no')}
            variant={'primary'}>
            Przejdź do "Moje kupony"
          </MyButton>
        </div>
      </div>
    </CustomerDashboard>
  );
};
