export const mainContainer = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '8vh',
};

export const titleHeader = {
  color: 'teal',
  textAlign: 'center',
  marginBottom: '50px',
  marginTop: '4vh',
};

export const linkText = {
  textDecoration: 'auto',
  fontSize: '16px',
};

export const labelError = {
  marginTop: 5,
  color: 'red',
};
