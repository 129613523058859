import React, { ReactNode, useEffect, useState } from 'react';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import ApprovalsForm from './ApprovalsForm';
import { Benefit } from '../../enums/benefits.enums';
import { useStore } from '../../store/store';
import { twMerge } from '../../index';

interface IUserPersonalDataLayoutProps {
  children: ReactNode;
  wrapperClassName?: string;
  benefitId: Benefit;
}
export const BenefitApprovalsLayout = ({ children, wrapperClassName, benefitId }: IUserPersonalDataLayoutProps) => {
  const { userStore } = useStore();
  const [hasBenefitApprovals, setHasBenefitApprovals] = useState<boolean>(true);

  const [areApprovalsLoading, setAreApprovalsLoading] = useState<boolean>(true);

  useEffect(() => {
    setAreApprovalsLoading(true);
    userStore
      .hasRequiredApprovalsForBenefit(benefitId)
      .then((resp) => {
        setHasBenefitApprovals(resp);
      })
      .finally(() => {
        setAreApprovalsLoading(false);
      });
  }, []);

  return (
    <>
      {areApprovalsLoading ? (
        <LoadingComponent />
      ) : !hasBenefitApprovals ? (
        <div className={twMerge(wrapperClassName)}>
          <div className={'tw-text-md tw-mb-4 tw-font-bold tw-text-nau-green-dark'}>
            Zaakceptuj zgody marketingowe, aby przejść dalej
          </div>
          <ApprovalsForm allRequired={true} benefitId={benefitId} hideHeader={true} />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};
