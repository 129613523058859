import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as styles from './styles';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import ReactCodeInput from 'react-code-input';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { useStore } from '../../../store/store';
import { LoanApplicationUserData } from '../../../types/loans';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap/cjs';
import { GetApprovals } from './Approvals';
import FileHelper from '../../../helpers/FileHelper';
import useResendSmsTimer from '../../../hooks/useResendSmsTimer';

export default observer(() => {
  const { loansStore, fileStore } = useStore();
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [loanUserData, setLoanUserData] = React.useState<LoanApplicationUserData | null>(null);
  const { id }: any = useParams();
  const [, setDownloading] = useState<boolean>(false);
  const [firstApprove, setCheckedFirstApprove] = useState<boolean>(false);
  const [secondApprove, setCheckedSecondApprove] = useState<boolean>(false);
  const [thirdApprove, setCheckedThirdApprove] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const { remainingSecondsSmsDisabled, setIsResendSmsDisabled, isResendSmsDisabled, enableResendSms } =
    useResendSmsTimer();

  const handleChangeFirstApprove = (e: any) => {
    if (e.target.checked) {
      setCheckedFirstApprove(true);
    } else {
      setCheckedFirstApprove(false);
    }
  };

  const handleChangeSecondApprove = (e: any) => {
    if (e.target.checked) {
      setCheckedSecondApprove(true);
    } else {
      setCheckedSecondApprove(false);
    }
  };
  const handleChangeThirdApprove = (e: any) => {
    if (e.target.checked) {
      setCheckedThirdApprove(true);
    } else {
      setCheckedThirdApprove(false);
    }
  };
  useEffect(() => {
    loansStore.getLoanApplicationUserData(id).then((resp) => {
      if (resp != undefined) {
        setLoanUserData(resp);
        const amount = Number(resp.loanAmount.replace(',', '.').replace(/\s+/g, ''));
        const paymentAmount = Number(resp.paymentAmount.replace(',', '.').replace(/\s+/g, ''));
        const totalAmount = amount + paymentAmount;
        setTotalAmount(totalAmount.toFixed(2).replace('.', ','));
      }
      requestLoanAuthorizationToken();
    });
    if (!loansStore.availableBenefitIsInstitutional) {
      setCheckedThirdApprove(true);
    }
  }, []);
  const handleChangePin = (e: any) => setPin(e);
  const sendLoanApplication = () => {
    setIsSubmitting(true);
    loansStore
      .verifyLoanApplicationToken(id, pin)
      .then(() => {
        if (loanUserData?.loanAmount) {
          const amount = parseInt(loanUserData?.loanAmount.replace(/\s+/g, ''));
          loansStore.setLoanAmount(amount);
        }
        setIsSubmitting(false);
        history.push(PathRoute.WAY_CASH);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };
  const requestLoanAuthorizationToken = () => {
    if (isResendSmsDisabled) {
      toast.error(`Proszę poczekać ${remainingSecondsSmsDisabled} sekund na wysłanie kolejnego kodu.`);
      return;
    }
    setIsResendSmsDisabled(true);
    loansStore
      .requestLoanAuthorizationToken(id)
      .catch(() => {
        enableResendSms();
      })
      .finally(() => setLoading(false));
  };
  const handlePdf = () => {
    setDownloading(true);
    fileStore
      .getLoanApplicationFormPdf(id)
      .then((resp) => {
        const fileName = 'formularz_aplikacyjny_' + id + '.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  const handleContractPdf = () => {
    setDownloading(true);
    fileStore
      .getLoanApplicationContractPdf(id, loansStore.availableBenefitIsInstitutional)
      .then((resp) => {
        const fileName = 'umowa_' + id + '.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div style={styles.containerBackground as React.CSSProperties}>
            <div style={styles.containerFirstSlider as React.CSSProperties}>
              <p style={styles.Loan as React.CSSProperties}>Wypłata od ręki</p>
            </div>
            <div style={styles.containerConfirm as React.CSSProperties}>
              <div style={styles.flexStart as React.CSSProperties}>
                <Image
                  onClick={() => history.push(PathRoute.LOAN)}
                  src='/assets/arrow-Left.svg'
                  style={styles.imageArrowLeft as React.CSSProperties}
                />
                <p style={styles.confirmTitle as React.CSSProperties}>Potwierdzenie</p>
              </div>
              <div style={styles.marginTop as React.CSSProperties}>
                <p style={styles.LoanAmount as React.CSSProperties}>Kwota Wypłaty od ręki</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{loanUserData?.loanAmount}</p>
                  <p style={styles.marginLeftPLN as React.CSSProperties}>PLN</p>
                </div>
              </div>
              <div style={styles.marginTop as React.CSSProperties}>
                <p style={styles.textStyle as React.CSSProperties}>Całkowita opłata za wypłatę</p>
                <div style={styles.containerAmountTax as React.CSSProperties}>
                  <p style={styles.textWeight as React.CSSProperties}>{loanUserData?.paymentAmount}</p>
                  <p style={styles.marginLeftPLN as React.CSSProperties}>PLN</p>
                </div>
              </div>
              {loansStore.availableBenefitIsInstitutional ? null : (
                <div style={styles.containerCenter as React.CSSProperties}>
                  <p>Umowa Wypłaty od ręki</p>
                  <div style={styles.displayFlex as React.CSSProperties}>
                    <p style={styles.marginBottomLeft as React.CSSProperties}>
                      <a onClick={() => handleContractPdf()} style={styles.hrefLink as React.CSSProperties}>
                        Twoja umowa
                      </a>{' '}
                    </p>
                    <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
                  </div>
                </div>
              )}

              <div style={styles.flexWeightCenter as React.CSSProperties}>
                <p>Podaj KOD SMS</p>
              </div>
            </div>
            <div style={styles.pinContainer as React.CSSProperties}>
              <ReactCodeInput
                fields={6}
                inputMode='numeric'
                name='numeric'
                onChange={(e) => handleChangePin(e)}
                type='password'
                value={pin}
              />
            </div>
            <div onClick={() => requestLoanAuthorizationToken()} style={styles.textSMS as React.CSSProperties}>
              <p>Wyślij ponownie SMS</p>
            </div>
            <div style={styles.autoContainerHeight as React.CSSProperties}>
              <p style={styles.fontColor as React.CSSProperties}>Przelew na konto</p>
              <div style={styles.displayFlex as React.CSSProperties}>
                <p style={styles.colorBottomWeight as React.CSSProperties}>Właściciel konta:</p>
                <p style={styles.accountMember as React.CSSProperties}>
                  {loanUserData?.firstName + ' ' + loanUserData?.lastName}
                </p>
              </div>
              <div style={styles.flexWrap as React.CSSProperties}>
                <p style={styles.accountNumber as React.CSSProperties}>Numer konta:</p>
                <p style={styles.accountMember as React.CSSProperties}>PL{loanUserData?.bankAccountNumber}</p>
              </div>
              <div style={styles.containerTime as React.CSSProperties}>
                <p>Czas realizacji:</p>
                <p style={styles.accountMember as React.CSSProperties}>Przelew zwykły</p>
              </div>
            </div>
            {GetApprovals(
              firstApprove,
              handleChangeFirstApprove,
              handlePdf,
              secondApprove,
              handleChangeSecondApprove,
              loansStore,
              thirdApprove,
              handleChangeThirdApprove,
              handleContractPdf,
              totalAmount,
            )}
            <div style={styles.flexCenter as React.CSSProperties}>
              {isSubmitting ? (
                <Button disabled={true} style={styles.widthButton as React.CSSProperties}>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Zapisywanie…
                </Button>
              ) : (
                <Button
                  disabled={!firstApprove || !secondApprove || !thirdApprove}
                  onClick={() => sendLoanApplication()}
                  style={styles.widthButton as React.CSSProperties}>
                  Wypłać
                </Button>
              )}
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
