export default class StringHelpers {
  static formatBankAccount = (number: string) => {
    if (number == null) {
      return '';
    }
    return [
      number.slice(0, 2),
      number.slice(2, 6),
      number.slice(6, 10),
      number.slice(10, 14),
      number.slice(14, 18),
      number.slice(18, 22),
      number.slice(22, 26),
      number.slice(26, 30),
    ].join(' ');
  };
  static formatCardNumber = (number: string) => {
    if (number == null) {
      return '';
    }
    const parts = [];
    let i = 0;
    while (i < number.length) {
      const sliceTo = i + 4 < number.length -1 ? i + 4 : number.length;
      parts.push(number.slice(i, sliceTo));
      i += 4;
    }
    return parts.join(' ');
  };
}
