interface ErrorResponse {
  fieldName: string;
  message: string;
}

export function handleErrorResponse(errors: any, setFieldError: (field: string, message: string | undefined) => void) {
  if (Array.isArray(errors)) {
    const fieldsErrors = errors as ErrorResponse[];
    for (const error of fieldsErrors) {
      const fieldKeys = error.fieldName.split('.');
      const fieldName = fieldKeys.map((x) => x.charAt(0).toLowerCase() + x.slice(1)).join('.');
      setFieldError(fieldName, error.message);
    }
  }
}
