import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { UserWalletLimit, WalletDataFromCurrentMonth, WalletNauContractData } from '../../types/walletNau';

export default class WalletNauStore {
  isBlocked = false;
  availableBenefitIsInstitutional: boolean | null = null;
  userWalletDataFromCurrentMonth: WalletDataFromCurrentMonth | null = null;
  walletLimits: UserWalletLimit = {
    limit: 0,
    remainingLimit: 0,
    remainingDays: 0,
    commission: 0,
    margin: 0,
    specialOfferType: 0,
  };
  constructor() {
    makeAutoObservable(this);
  }
  getLoggedUserLimit = async () => {
    try {
      const response = await agent.WalletNau.getLoggedUserLimit();
      runInAction(() => {
        this.walletLimits = response;
      });
    } catch (e) {
      console.error(e);
    }
  };

  getUserWalletDataFromCurrentMonth = async () => {
    try {
      const response = await agent.WalletNau.getWalletApplicationsFromCurrentMonth();
      runInAction(() => {
        // @ts-ignore
        this.userWalletDataFromCurrentMonth = response;
      });
    } catch (e) {
      console.error(e);
    }
  };

  getWalletContractStatus = async () => {
    try {
      return await agent.WalletNau.getWalletContractStatus();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  saveWalletNauContractUserData = async (walletNauContractData: WalletNauContractData) => {
    try {
      return await agent.WalletNau.saveWalletNauContractUserData(walletNauContractData);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getWalletNauContractUserData = async () => {
    try {
      return agent.WalletNau.getWalletContractUserData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  saveWalletNauContractFile = async (formData: FormData) => {
    return agent.WalletNau.saveWalletContractFile(formData);
  };

  createWalletApplication = async (amount: number) => {
    try {
      return agent.WalletNau.createWalletApplication(amount);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getWalletApplicationUserData = async (walletApplicationId: number) => {
    try {
      return await agent.WalletNau.getWalletApplicationUserData(walletApplicationId);
    } catch (e) {
      console.error(e);
    }
  };

  requestWalletAuthorizationToken = async (walletApplicationId: number) => {
    try {
      return agent.WalletNau.requestWalletAuthorizationToken(walletApplicationId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  verifyWalletApplicationToken = async (walletApplicationId: number, pin: string) => {
    try {
      return agent.WalletNau.verifyWalletApplicationToken(walletApplicationId, pin);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
