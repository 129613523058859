import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import MyFooterContact from '../../components/MyFooterContact/MyFooterContact';
import ValidationHelper from '../../helpers/ValidationHelper';
import { useStore } from '../../store/store';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import RegexHelpers from '../../helpers/RegexHelpers';
import { KlOrderFormM, KlProduct } from '../../types/kl';
import MyDateInput from '../../components/FormControls/MyDateInput/MyDateInput';
import MyCheckbox from '../../components/FormControls/MyCheckbox/MyCheckbox';
import MyRadioButton from '../../components/FormControls/MyRadioButton/MyRadioButton';
import _ from 'lodash';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import LoadingComponentCustom from '../../components/Loadings/LoadingComponentCustom/LoadingComponentCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormikScrollToFieldError } from '../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import KlRRInfo from './KlRRInfo';
import { KlInsuranceProductInformation } from './KlInsuranceProductInformation';
import { KlInsuranceExtensionInfo } from './KlInsuranceExtensionInfo';
import KlVariantChoice from './KlVariantChoice';
import { toast } from 'react-toastify';
import { KlVariantEnum } from '../../enums/klVariants.enums';
import { openLinkInAppBrowser } from '../../utils/linkUtils';

export default observer(() => {
  const { klStore, modalStore } = useStore();
  const [mainIsVerified, setMainIsVerified] = useState<boolean>(false);
  const [verification, setVerification] = useState<boolean>(false);
  const [sum, setSum] = useState<number | undefined>(undefined);
  const freeDays = 14;
  const [order, setOrder] = useState<KlOrderFormM>({
    customerId: 0,
    policyPositionId: 0,
    variantId: 0,
    startDate: undefined,
    endDate: undefined,
    country: '',
    insurerSportExtension: false,
    insurerDiseaseExtension: false,
    insurerDiseaseExtensionAvailable: false,
    insurerFirstName: '',
    insurerLastName: '',
    insurerPesel: '',
    insurerPhone: '',
    insurerEmail: '',
    insurerStreet: '',
    insurerHouseNumber: '',
    insurerFlatNumber: '',
    insurerPostCode: '',
    insurerCity: '',
    mainIsInsured: false,
    availableVariant: undefined,
    variantOptions: [],
    positions: [],
    policyPersonUbrId: 0,
    approvalBonVoyage1: false,
    approvalBonVoyage2: false,
    approvalBonVoyage3: false,
    approvalBonVoyage4: false,
    approvalInterrisk1: false,
    approvalInterrisk2: false,
    approvalInterrisk3: false,
    approvalInterrisk4: false,
    approvalInterrisk5: false,
    approvalMarketing1: false,
    approvalMarketing2: false,
    approvalMarketingEmail: false,
    approvalMarketingSms: false,
    approvalMarketingPhone: false,
    approvalMarketingIvr: false,
  });
  const [selectAllApprovalsState, setSelectAllApprovalsState] = useState<'all' | 'some' | 'none'>('none');
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

  const handleApprovalChange = (name: keyof KlOrderFormM, isChecked: boolean) => {
    const newOrder = _.cloneDeep(order);
    newOrder[name] = isChecked;

    let approvalsCount = 0;
    let approvalsCheckedCount = 0;

    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        approvalsCount++;
        if (newOrder[key as keyof KlOrderFormM]) {
          approvalsCheckedCount++;
        }
      }
    });
    if (approvalsCount === approvalsCheckedCount) {
      setSelectAllApprovalsState('all');
    } else if (approvalsCheckedCount === 0) {
      setSelectAllApprovalsState('none');
    } else {
      setSelectAllApprovalsState('some');
    }
    setOrder(newOrder);
  };

  const handleSelectAllApprovals = () => {
    const newOrder = _.cloneDeep(order);
    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        newOrder[key as keyof KlOrderFormM] = true;
      }
    });
    setOrder(newOrder);
    setSelectAllApprovalsState('all');
  };

  const handleDeselectAllApprovals = () => {
    const newOrder = _.cloneDeep(order);
    Object.keys(newOrder).forEach((key) => {
      if (key.startsWith('approval')) {
        newOrder[key as keyof KlOrderFormM] = false;
      }
    });
    setOrder(newOrder);
    setSelectAllApprovalsState('none');
  };

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.indeterminate = selectAllApprovalsState === 'some';
    }
  }, [selectAllApprovalsState]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    const newOrder = {
      ...order,
      [name]: type == 'checkbox' ? checked : value,
    };
    setOrder(newOrder);
    calculateSum(newOrder);
    if (mainIsVerified) {
      if (name === 'insurerPesel' || name === 'startDate' || name === 'insurerEmail') {
        getMainInsuredData(newOrder);
      }
    } else if (name === 'insurerPesel' || name === 'insurerEmail') {
      checkInsuredPersonByPesel(newOrder);
    }
  };
  const handleChangeCoInsured = (e: React.ChangeEvent<HTMLInputElement>, index: number, name: string) => {
    const { value, checked, type } = e.target;
    const newOrder = _.cloneDeep(order);
    const positions = newOrder.positions.map((position, i) => {
      if (i === index) {
        return {
          ...position,
          [name]: type == 'checkbox' ? checked : value,
        };
      }
      return position;
    });
    newOrder.positions = positions;
    setOrder(newOrder);
    calculateSum(newOrder);
    if (name == 'pesel') {
      getCoInsuredData(newOrder, index);
    }
  };
  const handleChangeStartDate = (e: Date | null) => {
    const offset = new Date(e as Date).getTimezoneOffset();
    const dateUTC = new Date((e as Date).getTime() - offset * 60 * 1000).toISOString().replace('Z', '');
    const newOrder = _.cloneDeep(order);
    newOrder.startDate = dateUTC;
    if (newOrder.startDate && newOrder.endDate && newOrder.endDate < newOrder.startDate) {
      newOrder.startDate = newOrder.endDate;
      toast.error('Data wyjazdu nie może być późniejsza niż data powrotu');
    }
    setOrder(newOrder);
    calculateSum(newOrder);
    getMainInsuredData(newOrder);
  };
  const handleChangeEndDate = (e: Date | null) => {
    const offset = new Date(e as Date).getTimezoneOffset();
    const dateUTC = new Date((e as Date).getTime() - offset * 60 * 1000).toISOString().replace('Z', '');
    const newOrder = _.cloneDeep(order);
    newOrder.endDate = dateUTC;
    if (newOrder.startDate && newOrder.endDate && newOrder.endDate < newOrder.startDate) {
      newOrder.endDate = newOrder.startDate;
      toast.error('Data powrotu nie może być wcześniejsza niż data wyjazdu');
    }
    setOrder(newOrder);
    calculateSum(newOrder);
    getMainInsuredData(newOrder);
  };
  const handleChangeCountry = (e: string) => {
    const newOrder = _.cloneDeep(order);
    newOrder.country = e;
    setOrder(newOrder);
    calculateSum(newOrder);
    getMainInsuredData(newOrder);
  };
  const handleChangeMainIsInsured = (e: string) => {
    const isChecked = JSON.parse(e);
    const newOrder = _.cloneDeep(order);
    newOrder.mainIsInsured = isChecked;
    setOrder(newOrder);
    calculateSum(newOrder);
  };
  const addPosition = () => {
    try {
      let positionsCopy = _.cloneDeep(order.positions);
      if (positionsCopy == null) {
        positionsCopy = [];
      }
      positionsCopy.push({
        policyPersonUbrId: 0,
        firstName: '',
        lastName: '',
        pesel: '',
        sportExtension: false,
        diseaseExtension: false,
        diseaseExtensionAvailable: false,
        isMain: false,
        isVerified: false,
        variantId: 0,
        availableVariant: undefined,
        variantOptions: [],
      });
      const newOrder = _.cloneDeep(order);
      newOrder.positions = positionsCopy;
      setOrder(newOrder);
      calculateSum(newOrder);
    } catch (error) {
      return false;
    }
  };
  const removePosition = (index: number) => {
    return () => {
      let positionsCopy = _.cloneDeep(order.positions);
      if (positionsCopy == null) {
        positionsCopy = [];
      }
      positionsCopy.splice(index, 1);
      const newOrder = _.cloneDeep(order);
      newOrder.positions = positionsCopy;
      setOrder(newOrder);
      calculateSum(newOrder);
    };
  };
  const setPositionVariant = (order: KlOrderFormM, availableVariant: KlProduct, index: number) => {
    let positionsCopy = order.positions;
    if (positionsCopy == null) {
      positionsCopy = [];
    }
    positionsCopy[index].availableVariant = availableVariant;
    positionsCopy[index].variantId = availableVariant.id;
    const newOrder = order;
    newOrder.positions = positionsCopy;
    setOrder(newOrder);
    calculateSum(newOrder);
    getNextPosition(newOrder, index + 1);
  };

  const setMainInsuredVariant = (order: KlOrderFormM, availableVariant: KlProduct) => {
    const newOrder = order;
    newOrder.availableVariant = availableVariant;
    newOrder.variantId = availableVariant.id;
    setOrder(newOrder);
    calculateSum(newOrder);
    getNextPosition(newOrder, 0);
  };

  const getMainInsuredData = (values: KlOrderFormM) => {
    let variantOptions: KlProduct[] = [];
    if (
      values.insurerPesel == null ||
      values.insurerPesel == '' ||
      values.insurerEmail == null ||
      values.insurerEmail == '' ||
      values.startDate == null ||
      values.endDate == null ||
      values.country == '' ||
      values.country == null
    ) {
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!RegexHelpers.peselRegExp.test(values.insurerPesel)) {
      setMainIsVerified(false);
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!ValidationHelper.checkEmail(values.insurerEmail)) {
      setMainIsVerified(false);
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    setMainIsVerified(false);
    setVerification(true);
    klStore
      .getInsuredPersonData(
        values.insurerPesel,
        values.startDate,
        values.endDate,
        values.country,
        values.insurerEmail,
        null,
      )
      .then((response) => {
        if (response != null) {
          if (response.policyUnpaid == true || response.policyPositionId == 0 || response.policyPersonUbrId == 0) {
            setMainIsVerified(false);
            const newOrder = _.cloneDeep(values);
            newOrder.errorMessage = 'Nie spełniasz warunków skorzystania z produktu. Skontaktuj się z infolinią.';
            setOrder(newOrder);
          } else {
            const newOrder = _.cloneDeep(values);
            newOrder.policyPositionId = response.policyPositionId;
            newOrder.policyPersonUbrId = response.policyPersonUbrId;
            newOrder.customerId = response.customerId;
            newOrder.variantId = response.availableVariant.id;
            newOrder.insurerDiseaseExtensionAvailable = response.diseaseExtensionAvailable;
            newOrder.errorMessage = undefined;
            newOrder.availableVariant = response.availableVariant;
            newOrder.variantOptions = response.variantOptions;
            setOrder(newOrder);
            calculateSum(newOrder);
            setMainIsVerified(true);
            variantOptions = response.variantOptions;
            if (variantOptions.length > 1) {
              modalStore.openModal(
                <KlVariantChoice
                  idx={undefined}
                  order={newOrder}
                  setVariant={setMainInsuredVariant}
                  variantOptions={variantOptions}
                />,
                'lg',
                null,
              );
            } else {
              getNextPosition(newOrder, 0);
            }
          }
        }
      })
      .catch((err) => {
        const newOrder = _.cloneDeep(values);
        newOrder.errorMessage = err;
        setOrder(newOrder);
        calculateSum(newOrder);
        setMainIsVerified(false);
      })
      .finally(() => {
        setVerification(false);
      });
  };
  const checkInsuredPersonByPesel = (values: KlOrderFormM) => {
    if (
      values.insurerPesel == null ||
      values.insurerPesel == '' ||
      values.insurerEmail == null ||
      values.insurerEmail == ''
    ) {
      setMainIsVerified(false);
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!RegexHelpers.peselRegExp.test(values.insurerPesel)) {
      setMainIsVerified(false);
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!ValidationHelper.checkEmail(values.insurerEmail)) {
      setMainIsVerified(false);
      const newOrder = _.cloneDeep(values);
      newOrder.errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    setMainIsVerified(false);
    setVerification(true);
    klStore
      .checkInsuredPersonByPesel(values.insurerPesel, values.insurerEmail, true)
      .then((response) => {
        setMainIsVerified(response.isVerified);
        if (response.showRRModal) {
          modalStore.openModal(<KlRRInfo />, 'lg', null);
        }
      })
      .catch((err) => {
        setMainIsVerified(false);
      })
      .finally(() => {
        setVerification(false);
      });
  };
  const getNextPosition = (values: KlOrderFormM, index: number) => {
    if (index < values.positions.length) {
      getCoInsuredData(values, index);
    }
  };
  const getCoInsuredData = (values: KlOrderFormM, index: number) => {
    let variantOptions: KlProduct[] = [];
    const position = values.positions[index];
    if (
      position.pesel == null ||
      position.pesel == '' ||
      values.insurerEmail == null ||
      values.insurerEmail == '' ||
      values.startDate == null ||
      values.endDate == null ||
      values.country == '' ||
      values.country == null
    ) {
      const newOrder = _.cloneDeep(values);
      newOrder.positions[index].policyPersonUbrId = 0;
      newOrder.positions[index].isVerified = false;
      newOrder.positions[index].errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!RegexHelpers.peselRegExp.test(position.pesel)) {
      const newOrder = _.cloneDeep(values);
      newOrder.positions[index].policyPersonUbrId = 0;
      newOrder.positions[index].isVerified = false;
      newOrder.positions[index].errorMessage = undefined;
      setOrder(newOrder);
      return;
    }
    if (!ValidationHelper.checkEmail(values.insurerEmail)) {
      return;
    }
    setVerification(true);
    klStore
      .getInsuredPersonData(
        position.pesel,
        values.startDate,
        values.endDate,
        values.country,
        values.insurerEmail,
        values.policyPersonUbrId,
      )
      .then((response) => {
        if (response != null) {
          if (response.policyUnpaid == true || response.policyPositionId == 0 || response.policyPersonUbrId == 0) {
            const newOrder = _.cloneDeep(values);
            newOrder.positions[index].policyPersonUbrId = 0;
            newOrder.positions[index].isVerified = false;
            setOrder(newOrder);
            calculateSum(newOrder);
            newOrder.positions[index].errorMessage =
              'Współubezpieczony nie spełnia warunków skorzystania z produktu. Skontaktuj się z infolinią.';
          } else {
            const newOrder = _.cloneDeep(values);
            newOrder.positions[index].policyPersonUbrId = response.policyPersonUbrId;
            newOrder.positions[index].isVerified = true;
            newOrder.positions[index].diseaseExtensionAvailable = response.diseaseExtensionAvailable;
            newOrder.positions[index].availableVariant = response.availableVariant;
            newOrder.positions[index].variantOptions = response.variantOptions;
            newOrder.positions[index].variantId = response.availableVariant.id;
            newOrder.positions[index].errorMessage = undefined;
            setOrder(newOrder);
            calculateSum(newOrder);
            variantOptions = response.variantOptions;
            if (variantOptions.length > 1) {
              modalStore.openModal(
                <KlVariantChoice
                  idx={index}
                  order={newOrder}
                  setVariant={setPositionVariant}
                  variantOptions={variantOptions}
                />,
                'lg',
                null,
              );
            } else {
              getNextPosition(newOrder, index + 1);
            }
          }
        }
      })
      .catch((err) => {
        const newOrder = _.cloneDeep(values);
        newOrder.positions[index].policyPersonUbrId = 0;
        newOrder.positions[index].isVerified = false;
        newOrder.positions[index].errorMessage = err;
        setOrder(newOrder);
        calculateSum(newOrder);
      })
      .finally(() => {
        setVerification(false);
      });
  };
  const calculateSum = (order: KlOrderFormM) => {
    let sum = 0;
    if (order.availableVariant != undefined) {
      const days =
        order.startDate && order.endDate
          ? Math.floor(
              (new Date(order.endDate).getTime() - new Date(order.startDate).getTime()) / (1000 * 60 * 60 * 24),
            ) + 1
          : 0;
      let mainInsuredSum = 0;
      if (order.mainIsInsured) {
        if (order.variantId == KlVariantEnum.PartiallyFree) {
          mainInsuredSum = order.availableVariant.basePrice * (days - freeDays);
        } else {
          mainInsuredSum = order.availableVariant.basePrice * days;
        }
        if (order.insurerSportExtension) {
          mainInsuredSum += order.availableVariant.sportPrice * days;
        }
        if (order.insurerDiseaseExtension) {
          mainInsuredSum += order.availableVariant.diseasePrice * days;
        }
        sum += mainInsuredSum;
      }
      order.positions.forEach((position) => {
        let coInsuredSum = 0;
        if (position.availableVariant != undefined) {
          if (position.variantId == KlVariantEnum.PartiallyFree) {
            coInsuredSum += position.availableVariant.basePrice * (days - freeDays);
          } else {
            coInsuredSum += position.availableVariant.basePrice * days;
          }
          if (position.sportExtension) {
            coInsuredSum += position.availableVariant.sportPrice * days;
          }
          if (position.diseaseExtension) {
            coInsuredSum += position.availableVariant.diseasePrice * days;
          }
        }
        sum += coInsuredSum;
      });
    }
    setSum(sum);
  };
  const redirectAfterPayment = (link: string): void => {
    history.push(link);
  }
  const openPaymentPage = async (link: string) => {
    openLinkInAppBrowser(link, false, PathRoute.PAYMENT_THANK_YOU_PAGE, redirectAfterPayment);
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={order}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          klStore
            .saveOrder(order)
            .then((response) => {
              if (response != null) {
                if (response.redirectLink != null) {
                  openPaymentPage(response.redirectLink);
                } else {
                  history.push(PathRoute.KL_THANK_YOU_PAGE);
                }
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={Yup.object({
          insurerFirstName: Yup.string().required('Pole jest wymagane'),
          insurerLastName: Yup.string().required('Pole jest wymagane'),
          insurerPesel: Yup.string()
            .required('Pole jest wymagane')
            .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
            .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
          insurerEmail: Yup.string().required('Pole jest wymagane').email('Adres email jest niepoprawny'),
          insurerPhone: Yup.string()
            .required('Pole jest wymagane')
            .matches(RegexHelpers.phoneRegExp, 'Numer telefonu jest niepoprawny'),
          insurerStreet: Yup.string().required('Pole jest wymagane'),
          insurerHouseNumber: Yup.string().required('Pole jest wymagane'),
          insurerFlatNumber: Yup.string(),
          insurerPostCode: Yup.string()
            .required('Pole jest wymagane')
            .matches(RegexHelpers.postCodeRegExp, 'Kod pocztowy jest niepoprawny'),
          insurerCity: Yup.string().required('Pole jest wymagane'),
          startDate: Yup.date()
            .required('Pole jest wymagane')
            .min(new Date(new Date().setDate(new Date().getDate())), 'Należy wskazać datę z przyszłości')
            .max(Yup.ref('endDate'), 'Data wyjazdu nie może być późniejsza niż data powrotu'),
          endDate: Yup.date()
            .required('Pole jest wymagane')
            .min(new Date(new Date().setDate(new Date().getDate())), 'Należy wskazać datę z przyszłości'),
          country: Yup.string().required('Pole jest wymagane'),
          mainIsInsured: Yup.boolean().required('Pole jest wymagane'),
          approvalBonVoyage1: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalBonVoyage2: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalBonVoyage3: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalBonVoyage4: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalInterrisk1: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalInterrisk2: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalInterrisk3: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalInterrisk4: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalInterrisk5: Yup.boolean().oneOf([true], 'Zgoda jest wymagana'),
          approvalMarketing1: Yup.boolean().oneOf([true], 'Oświadczenie jest wymagane'),
          approvalMarketing2: Yup.boolean().oneOf([true], 'Oświadczenie jest wymagane'),
          positions: Yup.array().of(
            Yup.object().shape({
              firstName: Yup.string().required('Pole jest wymagane'),
              lastName: Yup.string().required('Pole jest wymagane'),
              pesel: Yup.string()
                .required('Pole jest wymagane')
                .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
                .test('control-sum-test', 'Nieprawidłowy PESEL', (value) =>
                  ValidationHelper.checkPeselControlSum(value),
                ),
            }),
          ),
        })}>
        {({ handleSubmit, isSubmitting, errors, values, isValid, dirty, setFieldValue }) => (
          <>
            {verification && <LoadingComponentCustom content='Weryfikacja danych' />}
            <Form autoComplete='off' className='ui form tw-mb-[70px]' onSubmit={handleSubmit}>
              <div className='tw-relative tw-z-10 tw-mx-auto tw-max-w-3xl tw-rounded-lg tw-bg-white/95 tw-px-4 tw-py-4'>
                <div
                  className={'tw-mb-10 tw-text-2xl tw-font-semibold tw-text-nau-green-light sm:tw-text-3xl'}
                  style={{ textAlign: 'center' }}>
                  Zgłoszenie wyjazdu zagranicznego dla ubezpieczonych w programie Bezpieczna Rodzina
                </div>
                {mainIsVerified && order.availableVariant != undefined && (
                  <div className={'tw-my-8 tw-flex tw-justify-center'}>
                    <h6
                      className={'tw-border-2 tw-border-nau-green-dark tw-p-2'}
                      style={{ color: 'teal', textAlign: 'center' }}>
                      {order.availableVariant?.name} - {order.availableVariant?.basePrice.toString()} zł/dzień
                    </h6>
                  </div>
                )}
                <h6 className={'tw-mb-2'}>Główny ubezpieczony:</h6>
                <div className='tw-grid tw-grid-cols-4 tw-gap-4'>
                  <div className={'tw-col-span-4 '}>
                    <MyTextInput
                      name='insurerPesel'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                      }}
                      placeholder='PESEL'
                    />
                    {order.errorMessage && (
                      <div className='tw-mb-2 tw-mt-2 tw-text-sm tw-text-red-600'>{order.errorMessage}</div>
                    )}
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-2'}>
                    <MyTextInput
                      name='insurerEmail'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                      }}
                      placeholder='E-mail'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-2'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerPhone'
                      onChange={handleChange}
                      placeholder='Telefon'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-2'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerFirstName'
                      onChange={handleChange}
                      placeholder='Imię'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-2'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerLastName'
                      onChange={handleChange}
                      placeholder='Nazwisko'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-2'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerStreet'
                      onChange={handleChange}
                      placeholder='Ulica'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-1'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerHouseNumber'
                      onChange={handleChange}
                      placeholder='Numer domu'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-1'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerFlatNumber'
                      onChange={handleChange}
                      placeholder='Numer mieszkania'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-3'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      name='insurerCity'
                      onChange={handleChange}
                      placeholder='Miejscowość'
                    />
                  </div>
                  <div className={'tw-col-span-4 sm:tw-col-span-1'}>
                    <MyTextInput
                      disabled={!mainIsVerified}
                      mask={'99-999'}
                      name='insurerPostCode'
                      onChange={handleChange}
                      placeholder='Kod pocztowy'
                    />
                  </div>
                </div>
                <div className={'tw-mb-8 tw-mt-8 tw-text-3xl tw-font-bold tw-text-nau-gray-dark'}>
                  1. Dane wyjeżdzających
                </div>
                <h6 className={'tw-mb-2 tw-mt-10'}>Członkowie rodziny:</h6>
                <p className={'tw-leading-6'} style={{ fontSize: '0.9em' } as React.CSSProperties}>
                  Z pakietu Ochrona w Podróży może skorzystać osoba ubezpieczona w Programie Bezpieczna Rodzina:
                  <ul style={{ listStyle: 'outside' } as React.CSSProperties}>
                    <li>główny ubezpieczony do 70. roku życia,</li>
                    <li>partner/współmałżonek głównego ubezpieczonego do 70. roku życia,</li>
                    <li>dziecko głównego ubezpieczonego do 30 roku życia.</li>
                  </ul>
                </p>
                <p className={'tw-mt-4 tw-leading-6'} style={{ fontSize: '0.9em' } as React.CSSProperties}>
                  Pakiet jest <b>bezpłatny</b> pod warunkiem, że jest to pierwszy wyjazd w roku polisowym, trwający nie
                  dłużej niż 14 dni do krajów Europy oraz Basenu Morza Śródziemnego (Turcja, Egipt, Tunezja, Maroko,
                  Wyspy Kanaryjskie) bez rozszerzeń ochrony ubezpieczeniowej o następstwa chorób przewlekłych lub
                  amatorskiego uprawiania narciarstwa, snowboardingu, narciarstwa wodnego lub windsurfingu.
                </p>
                <div className={'tw-flex tw-flex-col tw-gap-4'}>
                  {order.positions.map((position, index) => {
                    return (
                      <div key={index}>
                        <br />
                        <div
                          style={
                            {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            } as React.CSSProperties
                          }>
                          <div className={'tw-my-8 tw-flex tw-items-center'}>
                            <h6>Ubezpieczony {index + 1}:</h6>
                            {order.positions[index].availableVariant &&
                              order.variantId != order.positions[index].variantId && (
                                <h6
                                  className={'tw-border-nau-green-dark tw-p-2'}
                                  style={{ color: 'teal', textAlign: 'center' }}>
                                  {order.positions[index].availableVariant?.name} -{' '}
                                  {order.positions[index].availableVariant?.basePrice.toString()} zł/dzień
                                </h6>
                              )}
                          </div>
                          <div
                            className='tw-text-red-600'
                            onClick={removePosition(index)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              fontWeight: '500',
                              fontSize: '1em',
                            }}>
                            <FontAwesomeIcon icon={faMinusCircle} size='lg' />
                            <span style={{ marginLeft: '10px' }}>Usuń</span>
                          </div>
                        </div>
                        <div className={'tw-flex tw-flex-col tw-gap-4'}>
                          <MyTextInput
                            name={`positions[${index}].firstName`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeCoInsured(e, index, 'firstName')
                            }
                            placeholder='Imię'
                          />
                          <MyTextInput
                            name={`positions[${index}].lastName`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCoInsured(e, index, 'lastName')}
                            placeholder='Nazwisko'
                          />
                          <MyTextInput
                            name={`positions[${index}].pesel`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCoInsured(e, index, 'pesel')}
                            placeholder='PESEL'
                          />
                        </div>

                        {position.errorMessage && (
                          <div className='tw-mb-2 tw-mt-2 tw-text-sm tw-text-red-600'>{position.errorMessage}</div>
                        )}
                        <MyCheckbox hidden={true} name={`positions[${index}].isMain`} value={true} />
                        {position.isVerified && (
                          <div>
                            <div className={'tw-mb-2 tw-mt-6'}>Rozszerzenie ubezpieczenia</div>
                            <div className={'tw-flex tw-flex-col tw-gap-2'}>
                              <MyCheckbox
                                className={'tw-text-nau-green-dark'}
                                label={`+ amatorskie narciarstwo, snowboarding, windsurfing, narty wodne (${order.positions[index].availableVariant?.sportPrice.toString()} zł/dzień)`}
                                name={`positions[${index}].sportExtension`}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleChangeCoInsured(e, index, 'sportExtension')
                                }
                                variant={'big'}
                              />
                              {position.diseaseExtensionAvailable && (
                                <MyCheckbox
                                  className={'tw-text-nau-green-dark'}
                                  disabled={!position.diseaseExtensionAvailable}
                                  label={`+ choroby przewlekłe (${order.positions[index].availableVariant?.diseasePrice.toString()} zł/dzień)`}
                                  name={`positions[${index}].diseaseExtension`}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleChangeCoInsured(e, index, 'diseaseExtension')
                                  }
                                  variant={'big'}
                                />
                              )}
                            </div>
                            <div className={'tw-mt-4'}>
                              <KlInsuranceExtensionInfo />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className={'tw-mt-4'}>
                  <div
                    className=''
                    onClick={() => {
                      if (mainIsVerified) {
                        addPosition();
                      }
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#0bc268',
                      cursor: 'pointer',
                      fontWeight: '500',
                      fontSize: '1.1em',
                      opacity: mainIsVerified ? '1' : '0.5',
                    }}>
                    <FontAwesomeIcon icon={faPlusCircle} size='lg' />
                    <span style={{ marginLeft: '10px' }}>
                      Dodaj {order.positions.length > 0 ? 'następnego' : ''} członka rodziny
                    </span>
                  </div>
                </div>

                <div className={'tw-mb-8 tw-mt-12 tw-text-3xl tw-font-bold tw-text-nau-gray-dark'}>
                  2. Dane dotyczące wyjazdu
                </div>

                <div className='tw-grid tw-gap-4 sm:tw-grid-cols-2'>
                  <div className=''>
                    <div className={'tw-mb-2'}>Data wyjazdu</div>
                    <MyDateInput
                      disabled={!mainIsVerified}
                      name='startDate'
                      onChange={(e) => handleChangeStartDate(e)}
                      placeholder={'rrrr-mm-dd'}
                      value={order.startDate}
                    />
                  </div>
                  <div className=' '>
                    <div className={'tw-mb-2'}>Data powrotu</div>
                    <MyDateInput
                      disabled={!mainIsVerified}
                      name='endDate'
                      onChange={(e) => handleChangeEndDate(e)}
                      placeholder={'rrrr-mm-dd'}
                      value={order.endDate}
                    />
                  </div>
                </div>

                <div className={'tw-mb-2 tw-mt-6'}>Miejsce wyjazdu</div>

                <MyRadioButton
                  className='tw-flex tw-flex-col tw-gap-2'
                  disabled={!mainIsVerified}
                  label='Miejsce wyjazdu'
                  name='country'
                  onChange={(e: string) => handleChangeCountry(e)}
                  options={[
                    {
                      value: 'Europe',
                      label:
                        'Wszystkie kraje europejskie i wybrane kraje basenu Morza Śródziemnego (Turcja, Egipt, Tunezja, Maroko, Wyspy Kanaryjskie)',
                    },
                    { value: 'World', label: 'Świat' },
                  ]}
                />
                <>
                  {mainIsVerified && order.availableVariant != undefined && (
                    <>
                      <div className={'tw-mb-2 tw-mt-6'}>Czy Główny Ubezpieczony wyjeżdża?</div>

                      <MyRadioButton
                        label='Czy Główny Ubezpieczony wyjeżdża?'
                        name='mainIsInsured'
                        onChange={(e: string) => handleChangeMainIsInsured(e)}
                        options={[
                          { value: 'true', label: 'Tak' },
                          { value: 'false', label: 'Nie' },
                        ]}
                        value={order.mainIsInsured}
                      />
                      {order.mainIsInsured && (
                        <>
                          <div className={'tw-mb-2 tw-mt-6'}>Rozszerzenie ubezpieczenia głównego ubezpieczonego</div>
                          <div className={'tw-flex tw-flex-col tw-gap-2'}>
                            <MyCheckbox
                              className={'tw-text-nau-green-dark'}
                              label={`+ amatorskie narciarstwo, snowboarding, windsurfing, narty wodne (${order.availableVariant?.sportPrice.toString()} zł/dzień)`}
                              name='insurerSportExtension'
                              onChange={handleChange}
                              variant={'big'}
                            />
                            {order.insurerDiseaseExtensionAvailable && (
                              <MyCheckbox
                                className={'tw-text-nau-green-dark'}
                                disabled={!order.insurerDiseaseExtensionAvailable}
                                label={`+ choroby przewlekłe (${order.availableVariant?.diseasePrice.toString()} zł/dzień)`}
                                name='insurerDiseaseExtension'
                                onChange={handleChange}
                                variant={'big'}
                              />
                            )}
                          </div>
                          <div className={'tw-mt-4'}>
                            <KlInsuranceExtensionInfo />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>

                <div className={'tw-mb-8 tw-mt-12 tw-text-3xl tw-font-bold tw-text-nau-gray-dark'}>
                  3. Informacje o produkcie ubezpieczeniowym
                </div>
                <KlInsuranceProductInformation />

                <div className={'tw-mb-8 tw-mt-12 tw-text-3xl tw-font-bold tw-text-nau-gray-dark'}>
                  4. Zgody i oświadczenia
                </div>
                <div className={'tw-flex tw-items-center tw-gap-2'}>
                  <input
                    checked={selectAllApprovalsState === 'all'}
                    className={
                      'cursor-pointer focus:ring-transparent tw-h-6 tw-w-6 tw-rounded tw-border-2 tw-border-nau-green-dark tw-text-nau-green-dark tw-ring-0 focus:tw-outline-0 focus:tw-ring-0 focus:tw-ring-transparent focus:tw-ring-offset-0'
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleSelectAllApprovals();
                      } else {
                        handleDeselectAllApprovals();
                      }
                    }}
                    ref={selectAllCheckboxRef}
                    type='checkbox'
                  />
                  <span className={'tw-text-xl'}>Zaznacz wszystkie zgody</span>
                </div>
                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 1</h6>
                <p className={'tw-text-bold tw-leading-6'}>
                  <b>
                    Zgoda na przystąpienie do grupowego ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach
                    Programu Bezpieczna Rodzina
                  </b>
                </p>
                <br />
                <MyCheckbox
                  checked={values.approvalBonVoyage1}
                  className={'tw-text-nau-green-dark'}
                  label={`Wyrażam zgodę na przystąpienie do grupowego ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach Programu Bezpieczna Rodzina (BR) zawartego przez NAU Profit Sp. z o.o. z siedzibą w Warszawie, przy ul. Smulikowskiego 6/8 jako ubezpieczającym, z InterRisk Towarzystwem Ubezpieczeń S.A. Vienna Insurance Group z siedzibą w Warszawie przy ul. Noakowskiego 22 (zwane dalej InterRisk).`}
                  name={`approvalBonVoyage1`}
                  onChange={(event) => {
                    handleApprovalChange('approvalBonVoyage1', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 2</h6>
                <br />
                <MyCheckbox
                  checked={values.approvalBonVoyage2}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Jednocześnie oświadczam, iż przed wyrażeniem zgody na objęcie mnie ochroną ubezpieczeniową i opłacenie składki ubezpieczeniowej otrzymałem/am Ogólne Warunki Ubezpieczenia kosztów leczenia za granicą Bon Voyage zatwierdzone uchwałą nr 05/06/07/2021 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 06.07.2021r. (zwane dalej OWU) oraz postanowienia dodatkowe i odmienne od niniejszych OWU, informację o której mowa w art.17 ustawy o działalności ubezpieczeniowej i reasekuracyjnej, informację dotyczącą możliwości i procedury złożenia i rozpatrzenia skarg i reklamacji, organu właściwego do ich rozpatrzenia oraz pozasądowego rozpatrywania sporów i je akceptuję.'
                  }
                  name={`approvalBonVoyage2`}
                  onChange={(event) => {
                    setFieldValue('approvalBonVoyage2', event.target.checked);
                    handleApprovalChange('approvalBonVoyage2', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 3</h6>
                <br />
                <MyCheckbox
                  checked={values.approvalBonVoyage3}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Oświadczam, że otrzymałem ustandaryzowany dokument zawierający informacje o produkcie ubezpieczeniowym i jestem świadomy/a, że dokument ten ma charakter informacyjny, a pełne informacje o produkcie ubezpieczeniowym zawarte są w Ogólnych Warunkach Ubezpieczenia.'
                  }
                  name={`approvalBonVoyage3`}
                  onChange={(event) => {
                    handleApprovalChange('approvalBonVoyage3', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 4</h6>
                <MyCheckbox
                  checked={values.approvalBonVoyage4}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Oświadczam, że otrzymałem/am klauzule informacyjne o przetwarzaniu danych osobowych zgodnie z RODO (Rozporządzeniem o Ochronie Danych Osobowych) w NAU Profit Sp. z o.o., NAU S.A. oraz InterRisk Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group.'
                  }
                  name={`approvalBonVoyage4`}
                  onChange={(event) => {
                    handleApprovalChange('approvalBonVoyage4', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 5</h6>
                <MyCheckbox
                  checked={values.approvalInterrisk1}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Wyrażam zgodę, iż moje dane osobowe oraz dane osobowe wskazanych przeze mnie członków rodziny mogą być przekazywane do InterRisk Towarzystwa Ubezpieczeń S.A. Vienna Insurance Group w celu zawarcia i wykonywania grupowej umowy ubezpieczenia kosztów leczenia za granicą Bon Voyage w ramach programu „Bezpieczna Rodzina”.'
                  }
                  name={`approvalInterrisk1`}
                  onChange={(event) => {
                    handleApprovalChange('approvalInterrisk1', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 6</h6>
                <MyCheckbox
                  checked={values.approvalInterrisk2}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Wyrażam zgodę na przetwarzanie przez InterRisk TU S.A. Vienna Insurance Group moich danych osobowych o stanie zdrowia w sposób zautomatyzowany w ramach profilowania klientów przed zawarciem umowy ubezpieczenia na potrzeby oceny ryzyka ubezpieczeniowego oraz na ich przetwarzanie w celu wykonania umowy ubezpieczenia.'
                  }
                  name={`approvalInterrisk2`}
                  onChange={(event) => {
                    handleApprovalChange('approvalInterrisk2', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 7</h6>
                <MyCheckbox
                  checked={values.approvalInterrisk3}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od podmiotów wykonujących działalność leczniczą w rozumieniu powszechnie obowiązujących przepisów, które udzielały lub będą udzielać mi świadczeń zdrowotnych, informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikacją danych przeze mnie danych o stanie mojego zdrowia, ustaleniem prawa do świadczenia z zawartej umowy ubezpieczenia i wysokością tego świadczenia, w zakresie określonym w ustawie o działalności ubezpieczeniowej i reasekuracyjnej. Równocześnie wyrażam zgodę na udostępnienie InterRisk TU S.A. Vienna Insurance Group przez wyżej wymienione podmioty dokumentacji medycznej.'
                  }
                  name={`approvalInterrisk3`}
                  onChange={(event) => {
                    handleApprovalChange('approvalInterrisk3', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 8</h6>
                <MyCheckbox
                  checked={values.approvalInterrisk4}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Wyrażam zgodę na udostępnianie - na żądanie InterRisk TU S.A. Vienna Insurance Group - moich danych osobowych przetwarzanych przez inne zakłady ubezpieczeń w zakresie potrzebnym do oceny ryzyka ubezpieczeniowego i weryfikacji podanych przeze mnie danych, ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, a także do udzielania posiadanych informacji o przyczynie mojej śmierci lub informacji niezbędnych do ustalenia prawa uprawnionego z umowy ubezpieczenia do świadczenia i jego wysokości.'
                  }
                  name={`approvalInterrisk4`}
                  onChange={(event) => {
                    handleApprovalChange('approvalInterrisk4', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 9</h6>
                <MyCheckbox
                  checked={values.approvalInterrisk5}
                  className={'tw-text-nau-green-dark'}
                  label={
                    'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od Narodowego Funduszu Zdrowia o informacjach o nazwach i adresach świadczeniodawców, którzy udzielili mi świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym będącym podstawą ustalenia odpowiedzialności InterRisk TU S.A. Vienna Insurance Group oraz wysokości odszkodowania lub świadczenia.'
                  }
                  name={`approvalInterrisk5`}
                  onChange={(event) => {
                    handleApprovalChange('approvalInterrisk5', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 10</h6>
                <MyCheckbox
                  checked={values.approvalMarketing1}
                  className={'tw-text-nau-green-dark'}
                  label={'Dystrybutor ubezpieczeń przeprowadził analizę wymagań i potrzeb ubezpieczającego'}
                  moreInfoText={
                    'Dystrybutor ubezpieczeń (tj. NAU) przed zawarciem umowy ubezpieczenia grupowego w ramach Programu BR, do której chcę przystąpić, przeprowadził analizę wymagań i potrzeb ubezpieczającego (tj. NAU Profit Sp. z o.o. ) w zakresie ochrony ubezpieczeniowej w ramach ubezpieczenia Odpowiedzialności Cywilnej w Życiu Prywatnym, Odpowiedzialności Cywilnej i Materialnej Nauczycieli oraz Pozostałych Pracowników Szkoły lub Odpowiedzialności Cywilnej Dyrektora Placówki Oświatowej (Zastępcy Dyrektora oraz Osoby Pełniącej Obowiązki Dyrektora)'
                  }
                  name={`approvalMarketing1`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketing1', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 11</h6>
                <MyCheckbox
                  checked={values.approvalMarketing2}
                  className={'tw-text-nau-green-dark'}
                  label={'Dystrybutor ubezpieczeń przeprowadził analizę wymagań i potrzeb ubezpieczającego'}
                  moreInfoText={
                    'Dystrybutor ubezpieczeń (tj. NAU) przed zawarciem umowy ubezpieczenia grupowego w ramach Programu BR, do którego chcę przystąpić, przeprowadził analizę wymagań i potrzeb Ubezpieczającego (tj. NAU Profit Sp. z o.o. ) w zakresie ochrony ubezpieczeniowej w ramach ubezpieczenia mienia.'
                  }
                  name={`approvalMarketing2`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketing2', event.target.checked);
                  }}
                  variant={'big'}
                />

                <h6 className={'tw-mb-2 tw-mt-6'}>Zgoda nr. 12</h6>
                <p className={'tw-text-bold tw-leading-6'}>
                  <b>
                    Dzięki tej zgodzie będziemy mogli przesłać Panu/Pani, za pomocą wiadomości elektronicznej lub
                    połączenia głosowego, i udzielać niezbędnych informacji dotyczących ochrony ubezpieczeniowej w
                    ramach programu Bezpieczna Rodzina oraz przedstawiać ofertę za pomocą wiadomości elektronicznej lub
                    połączenia głosowego.
                  </b>
                </p>

                <br />
                <MyCheckbox
                  checked={values.approvalMarketingEmail}
                  className={'tw-text-nau-green-dark'}
                  label={`wiadomości elektroniczne e-mail`}
                  name={`approvalMarketingEmail`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketingEmail', event.target.checked);
                  }}
                  variant={'big'}
                />
                <br />
                <MyCheckbox
                  checked={values.approvalMarketingSms}
                  className={'tw-text-nau-green-dark'}
                  label={`wiadomości elektroniczne SMS/MMS`}
                  name={`approvalMarketingSms`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketingSms', event.target.checked);
                  }}
                  variant={'big'}
                />
                <br />
                <MyCheckbox
                  checked={values.approvalMarketingPhone}
                  className={'tw-text-nau-green-dark'}
                  label={`połączenia głosowe (rozmowa telefoniczna)`}
                  name={`approvalMarketingPhone`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketingPhone', event.target.checked);
                  }}
                  variant={'big'}
                />
                <br />
                <MyCheckbox
                  checked={values.approvalMarketingIvr}
                  className={'tw-text-nau-green-dark'}
                  label={`połączenia głosowe (komunikaty głosowe IVR)`}
                  name={`approvalMarketingIvr`}
                  onChange={(event) => {
                    handleApprovalChange('approvalMarketingIvr', event.target.checked);
                  }}
                  variant={'big'}
                />

                <MyButton
                  className='tw-mt-8 tw-w-full'
                  disabled={!order.positions.every((x) => x.isVerified) || !mainIsVerified}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'primary'}>
                  Zamów {!!sum && `- ${sum} zł`}
                </MyButton>
              </div>
              <FormikScrollToFieldError />
            </Form>
            <MyFooterContact />
          </>
        )}
      </Formik>
    </>
  );
});
