import * as React from 'react';
import { useState } from 'react';
import * as styles from './styles';
import { Button, Image } from 'react-bootstrap';
import Slider from '../../Slider/Slider';
import WalletNauStore from '../../../store/walletNauStore/walletNauStore';
import { Spinner } from 'react-bootstrap/cjs';
import { CheckIcon } from '@heroicons/react/24/solid';

export function GetViewForLimitBiggerThanZero(props: {
  walletNauStore: WalletNauStore;
  loading: boolean;
  value: number;
  setShowValueInput: React.Dispatch<React.SetStateAction<boolean>>;
  showValueInput: boolean;
  handleValueOnBlur: (e: any) => void;
  handleChangeInputRange: (e: any) => void;
  totalPayoutFee: string;
  sendWalletApplication: () => Promise<void>;
  handleValueOnChange: (e: any) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSendApplication = () => {
    setIsSubmitting(true);
    props.sendWalletApplication().finally(() => setIsSubmitting(false));
  };
  return (
    <div>
      <p style={styles.availableText as React.CSSProperties}>dostępne</p>
      <div>
        <p style={styles.limitValue as React.CSSProperties}>
          {!props.loading ? props.walletNauStore.walletLimits.remainingLimit : ''}
          <span style={styles.currencyStyles as React.CSSProperties}>PLN</span>
        </p>
      </div>
      <div style={styles.secondContainerSlider as React.CSSProperties}>
        <div
          style={
            {
              width: `${Number((props.value * 100) / (props.walletNauStore.walletLimits.remainingLimit - 100))}%`,
              ...styles.limitBackground,
            } as React.CSSProperties
          }
        />
        {props.showValueInput ? (
          <CheckIcon
            className={'tw-absolute tw-left-[73%] tw-top-[30%] tw-z-10 tw-h-7 tw-w-7 tw-cursor-pointer tw-text-white'}
            onClick={() => props.setShowValueInput(!props.showValueInput)}
          />
        ) : (
          <Image
            className={'tw-cursor-pointer'}
            onClick={() => props.setShowValueInput(!props.showValueInput)}
            src='/assets/pen.svg'
            style={styles.imagePen as React.CSSProperties}
          />
        )}

        <div style={styles.valueLimit as React.CSSProperties}>
          {props.showValueInput ? (
            <>
              <input
                id='loanValueInput'
                max={props.walletNauStore.walletLimits.remainingLimit}
                min={100}
                onBlur={props.handleValueOnBlur}
                onChange={props.handleValueOnChange}
                style={styles.valueInput}
                type='number'
                value={props.value}
              />
            </>
          ) : (
            <p className={'tw-z-10'}>
              {props.value} <span className={'tw-text-lg'}>PLN</span>
            </p>
          )}
        </div>

        <Image className={''} src='/assets/money.svg' style={styles.moneySvg as React.CSSProperties} />
      </div>
      <Slider
        max={props.walletNauStore.walletLimits.remainingLimit}
        min={100}
        onChange={props.handleChangeInputRange}
        value={props.value}
      />
      <div style={styles.heightContainer as React.CSSProperties}>
        <div style={styles.displayCenter as React.CSSProperties}>
          Całkowita opłata za wypłatę:
          <div>&nbsp;</div>
          <p style={styles.currencyFont as React.CSSProperties}> {props.totalPayoutFee} </p>
          <div>&nbsp;</div>
          PLN
        </div>
        <div>&nbsp;</div>
        <Image src={'/assets/arrowRightCash.svg'} style={styles.flexHeight as React.CSSProperties} />
      </div>
      <div style={styles.displayJustify as React.CSSProperties}>
        {isSubmitting ? (
          <Button disabled={true} style={styles.widthButton as React.CSSProperties}>
            <span className='m-1'>
              <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
            </span>
            Zapisywanie...
          </Button>
        ) : (
          <Button onClick={() => onSendApplication()} style={styles.widthButton as React.CSSProperties}>
            Wypłać
          </Button>
        )}
      </div>
    </div>
  );
}
