import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { Button, Image, Spinner } from 'react-bootstrap';
import CinemaVoucherHeader from '../CinemaVoucherHeader/CinemaVoucherHeader';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { CinemaVoucherOrderDataM } from '../../../types/cinemaVoucher';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import agent from '../../../api/agent';
import { openLink, openLinkInAppBrowser } from '../../../utils/linkUtils';

export default observer(() => {
  const { id }: any = useParams();
  const { cinemaVoucherStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [order, setOrder] = useState<CinemaVoucherOrderDataM>({} as CinemaVoucherOrderDataM);

  const handleConfirm = async () => {
    sendOrder();
  };

  const sendOrder = () => {
    setIsSubmitting(true);
    if (order.orderPromoId === CinemaVoucherOrderPromo.InstitutionDirectorPromo) {
      agent.CinemaVouchers.orderDirectorPromoCinemaVouchers(id)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else {
      cinemaVoucherStore
        .orderCinemaVouchers(id)
        .then((resp) => {
          openPaymentPage(resp);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    }
  };
  const redirectAfterPayment = (link: string): void => {
    history.push(link);
  }
  const openPaymentPage = async (link: string) => {
    openLinkInAppBrowser(link, false, PathRoute.PAYMENT_THANK_YOU_PAGE, redirectAfterPayment);
  };
  useEffect(() => {
    cinemaVoucherStore
      .getCinemaVoucherOrderData(id)
      .then((resp) => {
        setOrder(resp);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }, []);
  return (
    <CustomerDashboard>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <div className='col-md-6 offset-md-3 tw-h-full tw-rounded-3xl tw-bg-white tw-text-black tw-shadow-lg'>
          <CinemaVoucherHeader />
          <div className='tw-px-6 tw-py-4 tw-leading-normal'>
            <div
              className={'tw-mb-4 tw-flex tw-cursor-pointer tw-items-center tw-gap-2'}
              onClick={() => history.push(`${PathRoute.CINEMA_VOUCHER}?orderView=true`)}>
              <Image className={'tw-rotate-180'} src='/assets/arrow-Left.svg' />
              <div>Edycja zamówienia</div>
            </div>
            <p className='tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-green-dark'>POTWIERDZENIE DANYCH</p>
            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-2'>
              <div>
                <p className='tw-pb-1 tw-pt-4 tw-text-lg tw-font-medium tw-text-nau-green-dark'>Imię i nazwisko:</p>
                <p>
                  {order.firstName} {order.lastName}
                </p>
              </div>
              <div>
                <p className='tw-pb-1 tw-text-lg tw-font-medium tw-text-nau-green-dark'>Adres e-mail:</p>
                <p>{order.email}</p>
              </div>

              <div>
                <p className='tw-pb-1 tw-text-lg tw-font-medium tw-text-nau-green-dark'>Kupony:</p>
                <div>
                  {order.vouchers.map((x, idx) => (
                    <p className='tw-pb-2 tw-text-left' key={idx}>
                      {x.companyName + ' ' + x.details}
                    </p>
                  ))}
                  {order.orderPromoId != CinemaVoucherOrderPromo.InstitutionDirectorPromo && (
                    <p className='tw-pb-2 tw-text-left'>+ Opłata serwisowa 1 zł</p>
                  )}
                </div>
              </div>

              <div>
                <p className='tw-mt-4 tw-text-lg tw-font-medium tw-text-nau-green-dark'>Suma:</p>
                <p>{order.sum}</p>
              </div>
            </div>
          </div>
          <div className='tw-flex tw-p-4'>
            {isSubmitting ? (
              <Button className='tw-w-full' disabled={true}>
                <span className='tw-m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Potwierdzanie zamówienia…
              </Button>
            ) : (
              <Button className='tw-w-full' onClick={handleConfirm}>
                Kup teraz
              </Button>
            )}
          </div>
        </div>
      )}
    </CustomerDashboard>
  );
});
