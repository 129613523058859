import React, { useEffect, useState } from 'react';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import HouseIcon from '../../components/Icons/HouseIcon';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { Form, Formik } from 'formik';
import { initialValues } from '.';
import { PropertyInsuranceBasicFormValues } from '../../types/propertyInsurance';
import RegexHelpers from '../../helpers/RegexHelpers';
import * as Yup from 'yup';
import { useStore } from '../../store/store';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import PropertyInsuranceExternalForm from '../../components/PropertyInsuranceExternal/PropertyInsuranceExternalForm/PropertyInsuranceExternalForm';

const PropertyInsuranceExternalPage = () => {
  const { userStore } = useStore();
  const [isFormView, setIsFormView] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [step, setStep] = useState<number>(2);
  const [formValues, setFormValues] = useState<PropertyInsuranceBasicFormValues>(initialValues);

  useEffect(() => {
    const user = userStore.userData;
    formValues.firstName = user?.firstName ?? '';
    formValues.lastName = user?.lastName ?? '';
    formValues.phone = user?.phone ?? '';
  }, []);

  const handleChangeValue = (name: string, value: any) => {
    if (value === 'true' || value === 'false') {
      const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
      setFormValues({ ...formValues, [name]: booleanValue });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
  });
  return (
    <>
      {isSent ? (
        <PropertyInsuranceExternalForm values={formValues} isSent={true} />
      ) : (
        <>
          {isFormView ? (
            <PropertyInsuranceExternalForm values={formValues} isSent={false} />
          ) : (
            <>
              <div className='tw-relative tw-z-10 tw-mx-auto tw-max-w-3xl tw-rounded-lg tw-bg-white/95 tw-px-4 tw-py-4'>
                <Formik
                  enableReinitialize
                  initialValues={formValues}
                  onSubmit={async (values, { setErrors, setSubmitting }) => {
                    try {
                      const valuesCopy = { ...formValues };
                      setFormValues(valuesCopy);
                      setIsFormView(true);
                    } catch (error) {
                      console.error('Error sending request', error);
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                  validationSchema={validationSchema}>
                  {({ isSubmitting, errors }) => (
                    <Form className='tw-w-full '>
                      {step === 1 && (
                        <>
                          <h2 className='tw-text-xl tw-font-semibold '>
                            Ubezpieczenie majątkowe - ochrona finansowa Twojego domu lub mieszkania.
                          </h2>
                          <div className='tw-mb-8 tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-base tw-font-normal'>
                            <div>
                              Zapewniamy ochronę od szerokiego wachlarza ryzyk, przy jednoczesnym dopasowaniu oferty do
                              indywidualnych potrzeb.
                            </div>
                          </div>
                          <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-base tw-font-normal'>
                            <div>● wybór najkorzystniejszej oferty spośród dostępnych na rynku,</div>
                            <div>● współpracujemy z 20 towarzystwami ubezpieczeniowymi</div>
                            <div>● 100% online</div>
                          </div>
                          <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-xs tw-font-normal'>
                            <b>Dla klientów posiadających grupowe ubezpieczenie na życie</b> (zawarte za pośrednictwem
                            NAU S.A. w placówce oświatowej){' '}
                            <b>
                              przysługuje możliwość skorzystania z programu ZWROTU SKŁADKI - promocja wynosi maksymalnie
                              25% od wyliczonej składki
                            </b>{' '}
                            (w granicach zgromadzonych w skarbonce środków).
                          </div>
                          <div className='tw-mb-4'>
                            <MyButton
                              className={'tw-mt-8 tw-w-full'}
                              onClick={() => setStep(2)}
                              type='button'
                              variant={'greenDark'}>
                              Dalej
                            </MyButton>
                          </div>
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <div className={'tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'}>
                            Chcesz, aby agent przygotował dla Ciebie ofertę ubezpieczenia majątkowego?
                          </div>
                          <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-2'>
                            <div className='tw-flex'>
                              <div className='tw-w-1/2 tw-pr-1.5'></div>
                              <div className='tw-w-1/2 tw-pl-1.5'></div>
                            </div>
                            <MyTextInput
                              placeholder='Imię'
                              name='firstName'
                              onChange={(e) => handleChangeValue('firstName', e.target.value)}
                            />
                            <MyTextInput
                              placeholder='Nazwisko'
                              name='lastName'
                              onChange={(e) => handleChangeValue('lastName', e.target.value)}
                            />
                            <MyTextInput
                              placeholder='Numer telefonu'
                              name='phone'
                              onChange={(e) => handleChangeValue('phone', e.target.value)}
                              mask={MaskHelpers.phone}
                            />
                          </div>
                          <div className='tw-mb-4'>
                            <MyButton
                              className={'tw-mt-4 tw-w-full'}
                              type='submit'
                              variant={'primary'}
                              isLoading={isSubmitting}
                              loadingText={'Wysyłanie...'}
                              disabled={isSubmitting}>
                              Wypełnij formularz
                            </MyButton>
                          </div>
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PropertyInsuranceExternalPage;
