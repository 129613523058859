import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import * as styles from './styles';
import { useStore } from '../../../store/store';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import { GetViewForLimitBiggerThanZero } from './GetViewForLimitBiggerhanZero';
import { GetViewForZeroLimit } from './GetViewForZeroLimit';
import { UserWalletFromCurrentMonth } from './UserWalletFromCurrentMonth';
import { GetViewForBlocked } from './GetViewForBlocked';
import WalletNauHeader from '../WalletNauHeader/DiscountCodeHeader';
import WalletInstructionAccordion from '../WalletInstructionAccordion/WalletInstructionAccordion';

export default observer(() => {
  const { walletNauStore } = useStore();
  const [value, setValue] = React.useState(100);
  const [showValueInput, setShowValueInput] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const loadData = () => {
      const userWalletDataFromCurrentMonth = walletNauStore.getUserWalletDataFromCurrentMonth();
      const loggedUserLimit = walletNauStore.getLoggedUserLimit();
      return Promise.allSettled([userWalletDataFromCurrentMonth, loggedUserLimit]);
    };

    loadData().finally(() => {
      setLoading(false);
    });
  }, []);

  const handleChangeInputRange = (e: any) => {
    setValue(e.target.value);
  };

  const handleValueOnBlur = (e: any) => {
    let value = e.target.value;
    if (value > walletNauStore.walletLimits.remainingLimit) {
      value = walletNauStore.walletLimits.remainingLimit;
    }

    if (value < 100) {
      value = 100;
    }

    setValue(value);
  };

  const handleValueOnChange = (e: any) => {
    if (e.target.value != '0') {
      setValue(e.target.value);
    }
  };

  const sendWalletApplication = async () => {
    if (value < 100) {
      toast.error('Minimalna kwota wypłaty to 100 zł.');
      return;
    }
    if (value > walletNauStore.walletLimits.remainingLimit) {
      toast.error('Wnioskowana kwota jest większa od pozostałego limitu.');
      return;
    }
    await walletNauStore.createWalletApplication(value).then((resp) => {
      history.push(PathRoute.CONFIRM_WALLET_PAYOUT + '/' + resp);
    });
  };

  const getTotalPayoutFee = () => {
    const commission = value * walletNauStore.walletLimits.commission;
    const margin = ((value * walletNauStore.walletLimits.margin) / 365) * walletNauStore.walletLimits.remainingDays;
    return (Math.floor((commission + margin) * 100) / 100).toFixed(2).replace('.', ',');
  };

  return (
    <>
      <LoadingComponent visible={loading} />
      <div style={styles.containerBackground as React.CSSProperties}>
        <WalletNauHeader />
        {walletNauStore.isBlocked ? (
          GetViewForBlocked()
        ) : (
          <div>
            {walletNauStore.walletLimits.remainingLimit != null && walletNauStore.walletLimits.remainingLimit < 100 ? (
              GetViewForZeroLimit(loading, walletNauStore)
            ) : (
              <GetViewForLimitBiggerThanZero
                handleChangeInputRange={handleChangeInputRange}
                handleValueOnBlur={handleValueOnBlur}
                handleValueOnChange={handleValueOnChange}
                loading={loading}
                sendWalletApplication={sendWalletApplication}
                setShowValueInput={setShowValueInput}
                showValueInput={showValueInput}
                totalPayoutFee={getTotalPayoutFee()}
                value={value}
                walletNauStore={walletNauStore}
              />
            )}
            {(walletNauStore.userWalletDataFromCurrentMonth?.walletApplication.length ?? 0) > 0 &&
              UserWalletFromCurrentMonth(walletNauStore)}
          </div>
        )}
      </div>
      <div className='discountCodes'>
        <div className='discountCodes-instructions'>
          <WalletInstructionAccordion />
        </div>
      </div>
    </>
  );
});
