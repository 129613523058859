import { observer } from 'mobx-react-lite';
import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import ButtonOpenLinkNewTab from '../../../components/Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';
import { useStore } from '../../../store/store';
import FileHelper from '../../../helpers/FileHelper';

export default observer(() => {
  const { userStore } = useStore();
  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3' style={styles.container}>
        <div style={styles.greenHeader}>
          <div>
            <h1 style={{ color: '#fff', textAlign: 'center' } as React.CSSProperties}>Trzynastka</h1>
          </div>
          <div
            style={
              {
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              } as React.CSSProperties
            }></div>
        </div>
        <div style={styles.bodyWithPadding as React.CSSProperties}>
          <p style={styles.headingTextStyle}>Trzynastka to mini pożyczka gotówkowa.</p>
          <p style={styles.textStyle}>
            • kwota pożyczki do 3 000 zł
            <br />• oprocentowanie 0%
            <br />
            {userStore.isUserElectroMachinist || userStore.isUserJusticeSystem || userStore.isUserSolidarityPodbeskidzie
              ? '• prowizja 0%'
              : '• prowizja 4,94%'}
            <br />• jednorazowa spłata do 01.04.2024
            <br />• 100% online
          </p>
          <p style={styles.headingTextStyle}>Jak skorzystać z mini pożyczki?</p>
          <p style={styles.textStyle}>
            1. Wypełnij formularz online
            <br />
            2. Dział Pożyczek zweryfikuje Twój wniosek
            <br />
            3. Po pozytywnej weryfikacji otrzymasz przelew na konto bankowe
          </p>
          <div className={'px-lg-4'}>
            {userStore.isUserElectroMachinist ||
            userStore.isUserJusticeSystem ||
            userStore.isUserSolidarityPodbeskidzie ? (
              <ButtonOpenLinkNewTab buttonText={'Złóż wniosek'} link={PathRoute.THIRTEEN_CASHMIR_VIP} />
            ) : (
              <ButtonOpenLinkNewTab buttonText={'Złóż wniosek'} link={PathRoute.THIRTEEN_CASHMIR} />
            )}
          </div>
          {(userStore.isUserElectroMachinist ||
            userStore.isUserJusticeSystem ||
            userStore.isUserSolidarityPodbeskidzie) && (
            <p
              className={'cursor-pointer'}
              onClick={() =>
                FileHelper.downloadFile(PathRoute.THIRTEEN_VIP_REGULATIONS, 'Regulamin-Promocji-Trzynastka-Vip.pdf')
              }
              style={
                {
                  fontWeight: '700',
                  fontSize: '14px',
                } as React.CSSProperties
              }>
              Regulamin promocji
            </p>
          )}
        </div>

        <div style={styles.bodyWithPadding as React.CSSProperties}>
          <div
            className={
              'tw-gap-1 tw-flex tw-flex-col tw-text-xs tw-italic tw-font-light tw-text-[#00635C] tw-mb-2.5 tw-leading-normal'
            }>
            Czas trwania oferty od 13.11.2023 r. do 29.02.2024 r.
            <div>*RRSO</div>
            <div>
              Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty pożyczek gotówkowych
              pn. „Trzynastka” wynosi 12,85 %. Okres obowiązywania umowy: 147 dni (spłata do 01.04.2024 r.); całkowita
              kwota pożyczki 2 500,00 zł; oprocentowanie stałe 0,00 %, całkowity koszt zobowiązania spłacanego w jednej
              racie: 2 624,75 zł (w tym prowizja: 124,75 zł, odsetki: 0,00 zł); całkowita kwota do zapłaty: 2 624,75 zł.
              Kalkulacja została dokonana na dzień 06.11.2023 roku na reprezentatywnym przykładzie. Przyznanie pożyczki
              zależy od wyniku oceny zdolności kredytowej wnioskodawcy. Założenia przyjęte do obliczenia RRSO: − Umowa
              Pożyczki będzie obowiązywała przez czas, na który została zawarta, a Pożyczkodawca i Pan/Pani wypełnią
              zobowiązania wynikające z umowy w terminach w niej określonych.
            </div>
            <div>
              Niniejszy materiał ma wyłącznie charakter informacyjny i nie stanowi oferty w rozumieniu art. 66 KC.
            </div>
            <div>
              Ostateczna decyzja dot. możliwości skorzystania z Trzynastka uzależniona jest od pozytywnej oceny
              zdolności kredytowej.
            </div>
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
});
