import * as React from 'react';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import { twMerge } from 'tailwind-merge';

interface IBirthdayCodeHeaderProps {
  text?: string;
}

const BirthdayCodeHeader = (props: IBirthdayCodeHeaderProps) => (
  <div style={styles.tabsBox}>
    <div>
      <h3 className={twMerge('tw-flex tw-justify-center tw-items-center tw-text-white tw-text-center tw-px-2')}>
        <Image
          src='/assets/birthdayCodes.svg'
          style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
        />
        {props.text ?? 'Twoje prezenty'}
      </h3>
    </div>
    <div
      style={
        {
          paddingTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        } as React.CSSProperties
      }></div>
  </div>
);

export default BirthdayCodeHeader;
