import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function UnblockIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      data-name='Group 477'
      height='19'
      id='Group_477'
      viewBox='0 0 16.625 19'
      width='16.625'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 249' fill='currentColor' height='19' id='Rectangle_249' width='16.625' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Group 476' id='Group_476'>
        <path
          d='M16.106,10.019a1.716,1.716,0,0,1,.519,1.262v5.938A1.773,1.773,0,0,1,14.844,19H1.781a1.725,1.725,0,0,1-1.262-.519A1.718,1.718,0,0,1,0,17.219V11.281a1.716,1.716,0,0,1,.519-1.262A1.718,1.718,0,0,1,1.781,9.5h.891V5.714A5.533,5.533,0,0,1,4.324,1.67,5.377,5.377,0,0,1,8.293,0a5.477,5.477,0,0,1,3.99,1.651,5.4,5.4,0,0,1,1.67,3.988v.594a.887.887,0,0,1-.891.891H11.875a.887.887,0,0,1-.89-.891V5.64a2.547,2.547,0,0,0-.8-1.892,2.641,2.641,0,0,0-1.911-.779,2.505,2.505,0,0,0-1.856.8,2.638,2.638,0,0,0-.779,1.911V9.5h9.2a1.716,1.716,0,0,1,1.262.519'
          data-name='Path 3539'
          fill='currentColor'
          id='Path_3539'
        />
      </g>
    </svg>
  );
}
