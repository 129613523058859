import React from 'react';
import PdfIcon from '../../components/Icons/PdfIcon';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import FileHelper from '../../helpers/FileHelper';

interface Document {
  title: string;
  description: string;
  fileName: string;
}

const documents: Document[] = [
  {
    title: 'Karta Produktu oraz OWU',
    description: 'Dokument zawierający informacje o produkcie ubezpieczeniowym oraz kartę produktu',
    fileName: 'Bon Voyage_od 22.08.2021.pdf',
  },
  {
    title: 'Postanowienia Dodatkowe',
    description: '',
    fileName: 'KL_postanowienia dod i odmienne_Program Bezpieczna Rodzina.pdf',
  },
  {
    title: 'Klauzula RODO',
    description: 'Dokument zawierający informacje o RODO',
    fileName: 'Klauzula RODO.pdf',
  },
  {
    title: 'OID',
    description: 'Obowiązki informacyjne dystrybutora',
    fileName: 'OID.pdf',
  },
];

export const KlInsuranceProductInformation = () => {
  return (
    <div className='tw-mx-auto'>
      <div className='tw-grid tw-auto-rows-fr tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 '>
        {documents.map((doc, index) => (
          <div
            className='tw-flex  tw-flex-col tw-items-center tw-gap-4 tw-rounded-[30px] tw-bg-white tw-p-4 tw-text-center tw-shadow-[0_12px_30px_#0000001a]'
            key={index}>
            <div className={'tw-flex tw-flex-col tw-gap-2'}>
              <div className={' tw-flex tw-justify-center'}>
                <PdfIcon className={'tw-h-10 tw-text-nau-green-light'} />
              </div>
              <div className='tw-text-base tw-font-bold tw-text-nau-green-light'>{doc.title}</div>
              {doc.description && <div className='tw-text-sm tw-font-normal tw-text-gray-600'>{doc.description}</div>}
            </div>

            <div className={'tw-flex tw-grow tw-flex-col tw-justify-end'}>
              <MyButton
                onClick={() => FileHelper.downloadFile('/assets/kl/' + doc.fileName, doc.fileName)}
                type={'button'}
                variant={'primary'}>
                Pobierz
              </MyButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
