import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useStore } from '../../../store/store';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { UserInstitution } from '../../../types/user';
import { toast } from 'react-toastify';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Capacitor } from '@capacitor/core';

export default observer((props: { userId: number; salary: number }) => {
  const { modalStore, loansStore, userStore } = useStore();
  const handleClose = () => modalStore.closeModal();
  const [userInst, setUserInst] = useState<UserInstitution | null>(null);

  useEffect(() => {
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
  }, [userStore]);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        salary: props.salary,
      }}
      onSubmit={(values, { setSubmitting }) => {
        loansStore
          .changeLoanUserSalary(props.userId, userInst?.institutionId ?? 0, values.salary)
          .then(() => {
            setSubmitting(false);
            toast.success('Zmieniono wysokość wynagrodzenia');
            handleClose();
            if (Capacitor.isNativePlatform()) {
              const path = history.location.pathname;
              history.push(PathRoute.PUSTY);
              history.push(path);
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            toast.error(error);
            setSubmitting(false);
          });
      }}>
      {({ isSubmitting }) => (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Form style={{ width: '100%' }}>
              <MyTextInput
                className='inputNumberHideArrow'
                min='2000'
                name={'salary'}
                placeholder={'Wynagrodzenie (brutto)'}
                type={'number'}
              />
              <div
                style={{
                  marginTop: '15px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}>
                <Button onClick={handleClose} variant='secondary'>
                  Anuluj
                </Button>
                {isSubmitting ? (
                  <Button type='submit' variant='primary'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Zapisywanie...
                  </Button>
                ) : (
                  <Button type='submit' variant='primary'>
                    Zapisz
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </>
      )}
    </Formik>
  );
});
