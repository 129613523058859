export const marginBottomField = {
  marginBottom: '2px',
} as React.CSSProperties;

export const headingText = {
  color: '#00635c',
  textAlign: 'center',
  padding: 0,
  margin: 0,
} as React.CSSProperties;
export const subheadingText = {
  color: '#00635c',
  textAlign: 'center',
  fontSize: '18px',
  padding: 0,
  margin: 0,
} as React.CSSProperties;

export const paragraphText = {
  color: '#00635c',
  textAlign: 'left',
  paddingTop: '20px',
  fontSize: '14px',
} as React.CSSProperties;

export const goBackLink = {
  width: '30px',
} as React.CSSProperties;

export const header = {
  width: 'calc(100% - 30px)',
} as React.CSSProperties;

export const formTitle = {
  color: '#00635c',
  textAlign: 'left',
  padding: 0,
  margin: 0,
  paddingBottom: '5px',
  fontSize: '14px',
} as React.CSSProperties;

export const incrementButton = {
  background: '#00635c',
  borderTopLeftRadius: '15px',
  borderBottomLeftRadius: '15px',
  width: '30px',
  height: '30px',
  color: '#fff',
  border: 'none',
  padding: '5px',
} as React.CSSProperties;

export const decrementButton = {
  background: '#00635c',
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  width: '30px',
  height: '30px',
  color: '#fff',
  border: 'none',
  padding: '5px',
} as React.CSSProperties;

export const quantity = {
  background: '#fff',
  width: '50px',
  color: '#00635c',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as React.CSSProperties;

export const tabLink = {
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  width: '33.3%',
  textAlign: 'center',
} as React.CSSProperties;
export const activeTabLink = {
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '5px solid #0bc268',
  width: '33.3%',
  textAlign: 'center',
} as React.CSSProperties;

export const tabsBox = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  paddingTop: '20px',
  background: 'linear-gradient(to right,#00635c, #0bc268)',
} as React.CSSProperties;
