import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import Header from '../../components/Header/Header';
import CustomerMianNavbar from '../../components/CustomerNavBar/CustomerMainNavBar';
import ProfileMenu from '../../components/ProfileMenu/ProfileMenu';
import { useStore } from '../../store/store';
import ConfirmEmailBar from '../../components/ConfirmEmailBar/ConfirmEmailBar';
import { useSwrAgent } from '../../api/useSwrAgent';
import { Container } from 'react-bootstrap';

interface ICustomerDashboardProps {
  children: ReactNode;
}
export default observer(({ children }: ICustomerDashboardProps) => {
  const { userStore, cinemaVoucherStore } = useStore();
  const { data: userMarketingData, isLoading } = useSwrAgent().Users.GetUserMarketingData();

  useEffect(() => {
    userStore.checkUpdateRequired().then((resp) => {
      if (userStore.hasPesel == null) {
        userStore.checkHasPesel();
      }
      if (resp) {
        userStore.logout();
      }
    });
  }, []);

  useEffect(() => {
    if (cinemaVoucherStore.companies.length === 0) {
      cinemaVoucherStore.getAvailableCinemaVoucherTypes().then((resp) => {
        cinemaVoucherStore.setCompanies(resp);
      });
    }
  }, []);
  return (
    <div style={{ backgroundColor: ' #ececec ', minHeight: 'calc(100vh - 5em)' }}>
      <Container>
        <CustomerMianNavbar />
        <ProfileMenu />
        <Header />
        {!userStore.isEmailConfirmed && (
          <div style={{ height: '30px' }}>
            <ConfirmEmailBar />
          </div>
        )}

        {userMarketingData?.isTeacherPromo && (
          <div style={{ height: '30px' }}>
            <div
              style={{
                position: 'fixed',
                left: 0,
                width: '100vw',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#00635C',
                zIndex: 50,
                top: !userStore.isEmailConfirmed ? '105px' : '75px',
              }}>
              <div style={{ color: 'white' }}>Nauczyciel VIP</div>
            </div>
          </div>
        )}

        <main style={{ paddingTop: '15px', paddingBottom: '15px' }}>{children}</main>
      </Container>
    </div>
  );
});
