import * as React from 'react';
import { twMerge } from '../..';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';

interface ISportCardHeaderProps {
  className?: string;
}

export const SportCardHeader = (props: ISportCardHeaderProps) => (
  <SubpageHeader
    headerClassName={twMerge('tw-text-3xl sm:tw-text-4xl', props.className)}
    iconHeight={40}
    iconSrc={'/assets/sportMan.svg'}
    title={'Karty sportowe'}
  />
);
