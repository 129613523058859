import * as React from 'react';
import * as styles from './styles';
import { Image } from 'react-bootstrap';
import WalletNauStore from '../../../store/walletNauStore/walletNauStore';

export function GetViewForZeroLimit(loading: boolean, walletNauStore: WalletNauStore): React.ReactNode {
  return (
    <div>
      <p style={styles.availableText as React.CSSProperties}>dostępne</p>
      <div>
        <p style={styles.limitValue as React.CSSProperties}>
          {!loading ? walletNauStore.walletLimits.remainingLimit : ''}
          <span style={styles.currencyStyles as React.CSSProperties}>PLN</span>
        </p>
      </div>
      <div className='row col-12'>
        <Image
          src='assets\calendar.svg'
          style={{
            marginBottom: '40px',
            marginTop: '40px',
            height: '200px',
            textAlign: 'center',
          }}
        />
      </div>
      <div className='row col-12'>
        <h1 style={{ textAlign: 'center', color: 'green' }}>Limit wypłat w tym miesiącu został wykorzystany.</h1>
      </div>
      <div className='row col-12'>
        <div style={{ textAlign: 'center' }}>
          <p style={styles.lastMonth as React.CSSProperties}>
            Obecnie nie posiadasz wymaganej kwoty minimalnej, umożliwiającej wypłatę środków w wysokości 100 zł. Po
            okresie rozliczeniowym przywrócimy Ci możliwość wypłat. Niewykorzystane środki nie przechodzą na kolejny
            miesiąc.
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p style={styles.lastMonth as React.CSSProperties}>
            Do odnowienia limitu zostało{' '}
            <span style={{ fontWeight: 'bold' }}>{walletNauStore.walletLimits.remainingDays} dni</span>
          </p>
        </div>
      </div>
    </div>
  );
}
