export const label = {
  display: 'flex',
  alignItems: 'flex-end',
  margin: '10px',
} as React.CSSProperties;

export const labelAllApprovals = {
  display: 'flex',
  alignItems: 'flex-end',
  padding: '10px',
  paddingLeft: '0',
  color: '#949494',
} as React.CSSProperties;

export const labelApproval = {
  display: 'flex',
  margin: '10px',
};
export const labelText = {
  fontSize: '14px',
  paddingLeft: '10px',
  paddingTop: '4px',
} as React.CSSProperties;

export const labelTextAllApprovals = {
  fontSize: '18px',
  paddingLeft: '10px',
  paddingTop: '4px',
} as React.CSSProperties;

export const labelTextPhoneMsg = {
  fontSize: '14px',
  paddingLeft: '25px',
  paddingTop: '4px',
} as React.CSSProperties;
