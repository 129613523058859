import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../store/store';
import { InstitutionRole } from '../../enums/roles.enums';
import { InstitutionSelectItemProps } from './types';

export default observer(({ item }: InstitutionSelectItemProps) => {
  const { userStore } = useStore();
  return (
    <div className='institution-select-item' onClick={() => userStore.setUserInstitution(item)}>
      <div className='d-flex align-items-center justify-content-center'>
        <FontAwesomeIcon icon={item.roleId == InstitutionRole.Admin ? faUserGear : faUser} size='2x' />
        {item.roleId == InstitutionRole.Admin ? (
          <span className='p-3'>{`${item.userInstitutionName} - ${item.role}`}</span>
        ) : (
          <span className='p-3'>{`Profil indywidualny`}</span>
        )}
      </div>
    </div>
  );
});
