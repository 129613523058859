import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import useQuery from '../../../hooks/useQuery';
import LogoHeader from '../../../components/LogoHeader/LogoHeader';
import { Form, Formik } from 'formik';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import * as Yup from 'yup';
import { ICashbackSopPolicyGroupDto } from '../../../types/cashback';
import { CASHBACK_POLICY_DATA } from '../../../constants/sessionStorageKeys';

const CashbackUserRegister = () => {
  const { cashbackStore } = useStore();
  const query: any = useQuery();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableCashbackPolicies, setAvailableCashbackPolicies] = useState<ICashbackSopPolicyGroupDto[]>([]);
  const queryCashbackHash = query.get('reg');

  const handlePoliciesNotFoundForRegon = () => {
    toast.error('Link jest nieprawidłowy lub zwrot składki wygasł');
    history.push(PathRoute.PUSTY);
  };

  useEffect(() => {
    if (queryCashbackHash) {
      cashbackStore
        .getCashbackPoliciesByCashbackHash(queryCashbackHash)
        .then((policies) => {
          policies = policies ?? [];
          if (policies && policies?.length === 0) {
            handlePoliciesNotFoundForRegon();
            return;
          }
          if (policies && policies.length === 1) {
            sessionStorage.setItem(CASHBACK_POLICY_DATA, JSON.stringify(policies[0]));
            history.push(PathRoute.LOGIN);
          } else {
            setAvailableCashbackPolicies(policies);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);
  return (
    <>
      {isLoading && <LoadingComponent />}
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8vh',
          }}>
          <LogoHeader />
        </div>
        <h2
          style={{
            color: 'teal',
            textAlign: 'center',
            marginBottom: '50px',
            marginTop: '4vh',
          }}>
          {'Wybierz numer swojej polisy'}
        </h2>
        <Formik
          enableReinitialize
          initialValues={{ cashbackSopPolicyId: '' }}
          onSubmit={(values) => {
            const policy = availableCashbackPolicies.find(
              (policy) => policy.policyId.toString() == values.cashbackSopPolicyId,
            );
            if (policy) {
              sessionStorage.setItem(CASHBACK_POLICY_DATA, JSON.stringify(policy));
              history.push(PathRoute.LOGIN);
            }
          }}
          validationSchema={Yup.object().shape({
            cashbackSopPolicyId: Yup.string().required('Pole jest wymagane'),
          })}>
          {({ handleSubmit, values }) => (
            <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
              <div className={'tw-mx-auto tw-max-w-xl'}>
                <MySelectInput
                  className='colorBlack'
                  label='Numer polisy'
                  name={`cashbackSopPolicyId`}
                  options={availableCashbackPolicies.map((policy) => {
                    return {
                      value: policy.policyId,
                      label: policy.policyNumber,
                    };
                  })}
                  placeholder='Numer polisy'
                  value={values.cashbackSopPolicyId}
                />

                <Button className='btn-full-width tw-mt-4' type='submit' variant='primary'>
                  Dalej
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default CashbackUserRegister;
