import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import KlThankYouPageImage from './KlThankYouPageImage';
import { Image } from 'react-bootstrap';

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <LoadingComponent visible={isLoading} />
      <div>
        <div className={'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 sm:tw-flex-row'}>
          <div>
            <Image alt='logo' className={'tw-h-[68px]'} src='/assets/logo.svg' />
          </div>
          <div className={'tw-flex tw-flex-col tw-gap-2 tw-text-center tw-font-semibold tw-text-nau-sea-green'}>
            <div className={' tw-text-4xl'}>Dziękujemy</div>
            <div className={' tw-text-3xl'}>za złożenie zamówienia!</div>
          </div>
        </div>
        <p className={'tw-my-8 tw-text-center tw-text-lg tw-text-nau-green-dark'}>
          Certyfikat ubezpieczenia zostanie wysłany na Twój adres e-mail.
        </p>
        <div className={'tw-flex tw-items-center tw-justify-center tw-gap-8'}>
          <KlThankYouPageImage />
        </div>
      </div>
    </>
  );
});
