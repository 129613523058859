import { Spinner } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import { LoadingComponentProps } from './types';

export default function LoadingComponent({ content = 'Ładowanie...', visible = true }: LoadingComponentProps) {
  return (
    <Fade in={visible} unmountOnExit>
      <div className='loading-container'>
        <Spinner animation='grow' role='status' variant='light'>
          <span className='visually-hidden'>{content}</span>
        </Spinner>
      </div>
    </Fade>
  );
}
