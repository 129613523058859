import { observer } from 'mobx-react-lite';
import KlThankYouPageImage from './ThankYouPage/KlThankYouPageImage';
import { Image } from 'react-bootstrap';

export default observer(() => {
  return (
    <>
      <div>
        <div className={'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 sm:tw-flex-row'}>
          <div>
            <Image alt='logo' className={'tw-h-[50px]'} src='/assets/logo.svg' />
          </div>
          <div className={'tw-flex tw-flex-col tw-gap-2 tw-text-center tw-font-semibold tw-text-nau-sea-green'}>
            <div className={' tw-text-2xl'}>Szanowni Państwo,</div>
          </div>
        </div>
        <div className={'tw-my-6 tw-text-justify tw-text-nau-green-dark'}>
          uprzejmie informujemy, iż posiadają Państwo ubezpieczenie Rodzina Razem, w towarzystwie PZU i jego zakres nie
          obejmuje możliwości skorzystania z ubezpieczenia w podróży. Nasi agenci mogą przygotować dla Państwa
          propozycję innego, indywidualnego ubezpieczenia w podróży. Jeśli są Państwo zainteresowani taką ofertą,
          prosimy o informację zwrotną. Umówimy wtedy kontakt z ekspertem ds. ubezpieczeń, który zaproponuje odpowiednią
          i dostosowaną do Państwa potrzeb ofertę.
          <div className={'tw-mt-4'}>Z poważaniem,</div>
          <div>Dział Sprzedaży i Obsługi Klienta</div>
          <div>tel. 22 696 72 70</div>
          <div>bok@nau.pl</div>
        </div>
        <div className={'tw-flex tw-items-center tw-justify-center tw-gap-8'}>
          <KlThankYouPageImage className={'tw-max-w-[500px]'} />
        </div>
      </div>
    </>
  );
});
