import { observer } from 'mobx-react-lite';
import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import KlOrderForm from './KlOrderForm';

export default observer(() => {
  return (
    <CustomerDashboard>
      <KlOrderForm />
    </CustomerDashboard>
  );
});
