import * as Yup from 'yup';
import RegexHelpers from '../../helpers/RegexHelpers';

export const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().email('Adres email jest niepoprawny').required('Pole jest wymagane'),
  phone: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Niepoprawny format. Numer powinien zawierać 9 cyfr')
    .required('Pole jest wymagane'),
  regulations: Yup.boolean()
    .nullable()
    .when('firstLogin', {
      is: true,
      then: Yup.boolean().nullable().oneOf([true], 'Zgoda jest wymagana').required('Zgoda jest wymagana'),
    }),
  privacyPolicy: Yup.boolean()
    .nullable()
    .when('firstLogin', {
      is: true,
      then: Yup.boolean().nullable().oneOf([true], 'Zgoda jest wymagana').required('Zgoda jest wymagana'),
    }),
});
