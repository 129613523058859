import { makeAutoObservable } from 'mobx';
import agent from '../../api/agent';

export default class FileStore {
  constructor() {
    makeAutoObservable(this);
  }

  getMyCircleKFiles = async () => {
    try {
      return await agent.Files.getMyCircleKFiles();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getMyDiscountCodeFiles = async () => {
    try {
      return await agent.Files.getMyDiscountCodeFiles();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getMyLoansFiles = async () => {
    try {
      return await agent.Files.getMyLoansFiles();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getMyWalletFiles = async () => {
    try {
      return await agent.Files.getMyWalletFiles();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getMyWalletApplicationFiles = async () => {
    try {
      return await agent.Files.getMyWalletApplicationFiles();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getFile = async (id: number) => {
    try {
      return await agent.Files.getFile(id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getLoansFile = async (clientId: string, documentId: string) => {
    try {
      return await agent.Files.getLoansFile(clientId, documentId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getWalletFile = async (clientId: string, documentId: string) => {
    try {
      return await agent.Files.getWalletFile(clientId, documentId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getCircleKPdf = async (id: number) => {
    try {
      return await agent.Files.getCircleKPdf(id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getLoanApplicationFormPdf = async (loanContractId: number) => {
    try {
      return await agent.Files.getLoanApplicationFormPdf(loanContractId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getLoanApplicationContractPdf = async (loanContractId: number, availableBenefitIsInstitutional: boolean | null) => {
    try {
      return await agent.Files.getLoanApplicationContractPdf(loanContractId, availableBenefitIsInstitutional);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getDiscountCodeContractPdf = async () => {
    try {
      return await agent.Files.getDiscountCodeContractPdf();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getDiscountCodeOrderPdf = async (id: string) => {
    try {
      return await agent.Files.getDiscountCodeOrderPdf(id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getDiscountCodeBarcode = async (id: number) => {
    try {
      return await agent.Files.getDiscountCodeBarcode(id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getBirthdayCodeBarcode = async (id: number) => {
    try {
      return await agent.Files.getBirthdayCodeBarcode(id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getWalletApplicationFormPdf = async (walletApplicationId: number) => {
    try {
      return await agent.Files.getWalletApplicationFormPdf(walletApplicationId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getWalletApplicationContractPdf = async (walletContractId: number) => {
    try {
      return await agent.Files.getWalletApplicationContractPdf(walletContractId);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
