import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import { history } from '../../../index';

import MaskHelpers from '../../../helpers/MaskHelpers';
import { Countries } from '../../../enums/countries.enums';

import { initialValues, validationSchema } from './index';
import { CircleKContractFormValues } from '../../../types/circleK';
import { CountryEnum } from '../../../enums/country.enum';
import { toast } from 'react-toastify';
import CardDataFormPart from './CardDataFormPart/CardDataFormPart';

import { FuelCardCompany } from '../../../enums/fuelCardCompany.enums';

import { cardCompanyArray, limitCardArray } from '../../../helpers/ArraySelect';
import { handleErrorResponse } from '../../../utils/apiErrorHandle';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import CircleKContractSignForm from '../../../components/CircleK/CircleKContractSignForm/CircleKContractSignForm';
import MyTextMaskedInput from '../../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import MyDateInput from '../../../components/FormControls/MyDateInput/MyDateInput';
import { FormikScrollToFieldError } from '../../../components/FormikScrollToFieldError/FormikScrollToFieldError';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { AddressPromptingForm } from '../../../components/Address/AddressPromptingForm';

export default observer(() => {
  const { modalStore, circleKStore } = useStore();
  const handleClose = () => modalStore.closeModal();

  const [loading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<CircleKContractFormValues>(initialValues);

  useEffect(() => {
    circleKStore
      .getUserCircleKContractFormOperatorChange()
      .then((resp) => {
        const cards = resp.cards
          ? resp.cards.map((card) => {
              return {
                ...card,
                companyId: cardCompanyArray.some((c) => c.value === card.companyId)
                  ? card.companyId
                  : FuelCardCompany.BP,
                limit: limitCardArray.some((item) => item.value == card.limit) ? card.limit : 0,
              };
            })
          : [
              {
                limit: 0,
                licensePlateNumber: '',
                companyId: FuelCardCompany.BP,
                confirmed: false,
              },
            ];

        const respContract = {
          name: resp.name ?? '',
          surname: resp.surname ?? '',
          bankAccountNumber: resp.bankAccountNumber ?? '',
          bankAccountNumberForReturns: resp.bankAccountNumberForReturns ?? '',
          pesel: resp.pesel ?? '',
          postCode: resp.postCode ?? '',
          place: resp.place ?? '',
          street: resp.street ?? '',
          houseNumber: resp.houseNumber ?? '',
          flatNumber: resp.flatNumber ?? '',
          country: resp.country != null && resp.country != 0 ? resp.country : CountryEnum.Polska,
          phone: resp.phone ?? '',
          email: resp.email ?? '',
          contractId: resp.contractId ?? 0,
          cards: cards,
          versionNumber: resp.versionNumber ?? 3,
          carInsuranceEndDate: resp.carInsuranceEndDate ?? '',
          maxLimit: resp.maxLimit ?? 900,
          sopInstitutionId: resp.sopInstitutionId ?? undefined,
          id: resp.id,
        };
        setContract(respContract);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <LoadingComponent content='Ładowanie...' visible={loading} />
      <Formik
        enableReinitialize
        initialValues={contract}
        onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
          const saveContractFunction = () => circleKStore.createCircleKCardOperatorChange(values);

          saveContractFunction()
            .then((resp) => {
              handleClose();
              const contractId = typeof resp === 'number' ? resp : values.contractId;
              modalStore.openModal(
                <CircleKContractSignForm contractId={contractId} onSave={() => history.push(PathRoute.FUEL_CARDS)} />,
                'lg',
                'Podpisywanie umowy',
              );
            })
            .catch((err) => {
              handleErrorResponse(err, setFieldError);
              setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}>
        {({ handleSubmit, errors, values, isSubmitting }) => (
          <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
            <h6>Podpisywanie nowej umowy</h6>
            <div className={'d-flex flex-column gap-2'}>
              <MyTextInput disabled={true} label='Imię' name={`name`} />
              <MyTextInput disabled={true} label='Nazwisko' name={`surname`} />
            </div>
            <div className={'d-flex flex-column gap-2'}>
              <MyTextMaskedInput hidden={true} label='PESEL' mask={MaskHelpers.pesel} name={`pesel`} type={'hidden'} />
              <MyTextMaskedInput
                label='Nr konta klienta do zwrotu środków'
                mask={MaskHelpers.bankAccountNumber}
                name={`bankAccountNumberForReturns`}
              />
              <MyTextMaskedInput label='Numer telefonu komórkowego' mask={MaskHelpers.phone} name={`phone`} />
              <AddressPromptingForm
                cityFormikName={'place'}
                flatNoFormikName={'flatNumber'}
                houseNoFormikName={'houseNumber'}
                postCodeFormikName={'postCode'}
                streetFormikName={'street'}
              />
              <MySelectInput
                className='colorBlack'
                label='Kraj'
                name={`country`}
                options={Countries}
                placeholder='Kraj'
                value={values.country}
              />
              <MyDateInput
                label='Data końca ubezpieczenia komunikacyjnego'
                name='carInsuranceEndDate'
                value={values.carInsuranceEndDate}
              />
            </div>
            {values.cards != undefined && (
              <div style={{ marginTop: '20px' } as React.CSSProperties}>
                <b>Karty przy umowie</b>
                {values.cards.map((x, idx) => {
                  return <CardDataFormPart idx={idx} key={idx} maxLimit={contract.maxLimit}></CardDataFormPart>;
                })}
              </div>
            )}
            <div
              style={{
                marginTop: '20px',
              }}>
              <div className={'d-flex flex-column flex-md-row justify-content-md-end gap-2'}>
                {isSubmitting ? (
                  <Button disabled={true} type='submit' variant='primary'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Ładowanie...
                  </Button>
                ) : (
                  <Button type='submit' variant='primary'>
                    Przejdź do pliku umowy
                  </Button>
                )}
              </div>
            </div>
            <FormikScrollToFieldError />
          </Form>
        )}
      </Formik>
    </>
  );
});
