import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import { useStore } from '../../../store/store';
import { ErrorMessage, Form, Formik } from 'formik';
import { CircleKCardModel } from '../../../types/circleK';
import * as styles from './styles';
import _ from 'lodash';
import * as Yup from 'yup';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import { limitCardArray } from '../../../helpers/ArraySelect';
import FileHelper from '../../../helpers/FileHelper';
import agent from '../../../api/agent';
import MyButton from '../../Buttons/MyButton/MyButton';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import { PathRoute } from '../../../constants/pathRoute/Route';
import MaskHelpers from '../../../helpers/MaskHelpers';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { toast } from 'react-toastify';

const ChangeLimitSchema = Yup.object().shape({
  limit: Yup.number().positive().integer().min(1, 'Limit musi być wiekszy od 0').required('Wprowadź limit'),
  regulations: Yup.bool().oneOf([true], 'Zgoda jest wymagana'),
});

export default observer((props: { card: CircleKCardModel }) => {
  const { modalStore, fileStore, circleKStore } = useStore();
  const [isSignAnnexView, setIsSignAnnexView] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadChangeLimitAnnex = (newLimit: number) => {
    setIsDownloading(true);
    agent.Files.getFuelCardLimitChangeAnnexPdf(props.card.cardId, newLimit)
      .then((resp) => {
        const fileName = 'umowa_nau_aneks_zmiana_limitu.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };
  const handleClose = () => modalStore.closeModal();

  return (
    <div style={_.merge({}, styles.divSpacing as React.CSSProperties)}>
      <Formik
        initialValues={{
          limit: '',
          regulations: false,
          token: '',
          error: null,
        }}
        onSubmit={({ limit, token }, { setSubmitting }) => {
          if (isSignAnnexView) {
            agent.CircleKCard.signFuelCardLimitChangeAnnex(props.card.cardId, Number(limit), token)
              .then(() => {
                modalStore.closeModal();
                circleKStore.getCircleKCardsByUserId();
                toast.success('Zmiana limitu nastąpi do końca następnego dnia roboczego.');
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            agent.CircleKCard.generateTokenToChangeFuelCardLimit(props.card.cardId, Number(limit))
              .then(() => {
                setIsSignAnnexView(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
        validationSchema={ChangeLimitSchema}>
        {({ handleSubmit, isSubmitting, errors, values }) => (
          <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
            <div style={_.merge({}, styles.center as React.CSSProperties)}>
              <p className={'tw-text-3xl tw-text-nau-sea-green'}>
                Wniosek o zmianę limitu karty {props.card.licensePlateNumber}
              </p>
            </div>

            <div
              style={_.merge({}, styles.center as React.CSSProperties, styles.actualLimitStyle as React.CSSProperties)}>
              <p className={'tw-text-2xl tw-text-nau-gray'}>Aktualny limit miesięczny</p>
              <p className={'tw-text-2xl tw-text-nau-sea-green'}>{props.card.limit} zł</p>
            </div>

            <div>
              <p className={'tw-mb-1 tw-text-xl tw-text-nau-sea-green'}>
                <span>Wnioskowany nowy limit dla karty </span>
                <span>{props.card.licensePlateNumber}</span>
              </p>
              <MySelectInput
                className='inputNumberHideArrow tw-z-50'
                disabled={!!values.limit}
                name={'limit'}
                options={limitCardArray.filter((x) => x.value > props.card.limit)}
                placeholder={'Podaj kwotę nowego limitu'}
                value={values.limit}
              />
              <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />
            </div>

            {values.limit && (
              <div className={'tw-mt-8'}>
                <div>
                  <div className={'tw-font-normal'}>
                    Zapoznaj się z treścią aneksu do umowy i podpisz go za pomocą hasła z sms.
                  </div>
                  <div className={'tw-my-6 tw-flex tw-justify-center'}>
                    <MyButton
                      className={'tw-w-full'}
                      isLoading={isDownloading}
                      onClick={() => downloadChangeLimitAnnex(Number(values.limit))}
                      type={'button'}
                      variant={'primary'}>
                      Pobierz aneks
                    </MyButton>
                  </div>
                  <div className={'tw-mb-4 tw-font-normal'}>Hasło do pobrania aneksu to Twój numer PESEL.</div>
                  <MyCheckbox
                    label={
                      <div>
                        Oświadczam, że zapoznałem/zapoznałam się z treścią{' '}
                        <span
                          className='approvals-link'
                          onClick={() => window.open(`${PathRoute.REGULATIONS_CIRCLE_K}`, '_system', 'location=yes')}>
                          „Regulaminu Karta Paliwowa zniżka na paliwo”
                        </span>
                      </div>
                    }
                    name={`regulations`}
                    value={values.regulations}
                  />
                  <div className={'tw-mt-4 tw-font-normal'}>
                    Zmiana limitu nastąpi do końca następnego dnia roboczego.
                  </div>
                </div>
                {!isSignAnnexView && (
                  <div className={'tw-my-6 tw-flex tw-justify-center'}>
                    <MyButton className={'tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
                      Wygeneruj hasło sms do podpisania aneksu
                    </MyButton>
                  </div>
                )}
              </div>
            )}

            {isSignAnnexView && (
              <div className={'tw-my-6'}>
                <div className={'tw-mb-4 tw-font-normal'}>
                  Hasło zostało wysłane na numer telefonu zawarty w umowie, powinieneś otrzymać je w ciągu kilkunastu
                  sekund.
                </div>
                <MyTextInput mask={MaskHelpers.token} name={`token`} placeholder='Kod z sms' />
                <div className={'tw-my-4 tw-flex tw-justify-center'}>
                  <MyButton className={'tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
                    Podpisz aneks
                  </MyButton>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
});
