export enum EmploymentType {
  EmploymentContractIndefinitePeriod = 1,
  EmploymentContractSpecifiedTime,
  ContractMandateContractSpecificWork,
  Retirement,
  Pension,
}

export const employmentTypeInstitutionalArray = [
  { label: 'Umowa o pracę na czas nieokreślony', value: EmploymentType.EmploymentContractIndefinitePeriod },
  { label: 'Umowa o pracę na czas określony', value: EmploymentType.EmploymentContractSpecifiedTime },
  { label: 'Umowa zlecenie/Umowa o dzieło', value: EmploymentType.ContractMandateContractSpecificWork },
];

export const employmentTypeArray = [
  ...employmentTypeInstitutionalArray,
  { label: 'Emerytura', value: EmploymentType.Retirement },
  { label: 'Renta', value: EmploymentType.Pension },
];
export const walletEmploymentTypeArray = [
  ...employmentTypeInstitutionalArray,
  { label: 'Emerytura', value: EmploymentType.Retirement },
];
export const employmentTypesWithoutEndDateArray = [
  EmploymentType.EmploymentContractIndefinitePeriod,
  EmploymentType.Retirement,
  EmploymentType.Pension,
];
