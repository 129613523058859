import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { InstitutionWithAddressFromSopDTO } from '../../types/institution';
import agent from '../../api/agent';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MySelectInput from '../../components/FormControls/MySelectInput/MySelectInput';
import * as Yup from 'yup';
import { ICinemaVoucherDirectorFormValues } from '../../types/cinemaVoucher';
import { mutate } from 'swr';
import getUrls from '../../api/getUrls';
import { CinemaVoucherOrderPromo } from '../../enums/cinemaVoucherOrderPromo.enum';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { useSwrAgent } from '../../api/useSwrAgent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import MaskHelpers from '../../helpers/MaskHelpers';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import { UserPromotionE } from '../../enums/userPromotion.enums';

const citySearchValidationSchema = Yup.object().shape({
  directorFirstName: Yup.string().required('Pole jest wymagane'),
  directorLastName: Yup.string().required('Pole jest wymagane'),
  zipCode: Yup.string(),
  place: Yup.string(),
  regon: Yup.string().when('otherInst', {
    is: true,
    then: Yup.string().required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
});
export const CinemaVoucherPromoDirectorTeachersDayForm = () => {
  const [areCitiesLoading, setAreCitiesLoading] = useState<boolean>(false);
  const [citiesFromApi, setCitiesFromApi] = useState<string[]>([]);
  const [institutions, setInstitutions] = useState<InstitutionWithAddressFromSopDTO[]>([]);

  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();

  const loadCities = (zipCode: string, place: string, onlyCities: boolean) => {
    setAreCitiesLoading(true);
    if (!zipCode && !place) {
      setAreCitiesLoading(false);
      return;
    }
    if (zipCode.length > 0 && zipCode.length <= 2) {
      setAreCitiesLoading(false);
      return;
    }
    if (place.length > 0 && place.length <= 2) {
      setAreCitiesLoading(false);
      return;
    }
    agent.Institution.getInstitutionsByAddressFromSop(zipCode, place)
      .then((resp) => {
        setCitiesFromApi([...new Set(resp.map((obj) => obj.place))]);
        if (!onlyCities) {
          setInstitutions(resp);
        }
      })
      .finally(() => {
        setAreCitiesLoading(false);
      });
  };

  const cleanStreet = (street?: string) => {
    if (!street) {
      return '';
    }
    return street.replaceAll('ul.', '').replaceAll('Ul.', '').trim();
  };

  return (
    <div className={'col-md-6 offset-md-3 tw-rounded-lg tw-bg-white tw-p-4'}>
      <LoadingComponent content='Ładowanie...' visible={isLoadingUserProfile} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          directorFirstName: userProfile?.firstName ?? '',
          directorLastName: userProfile?.lastName ?? '',
          zipCode: '',
          place: '',
          street: '',
          institutionSopId: 0,
          // otherInst: false,
          regon: '',
        }}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const result = institutions.find(
            (obj) =>
              (!values.street || cleanStreet(obj.street) == cleanStreet(values.street)) &&
              obj.id == values.institutionSopId,
          );

          if (!result) {
            setSubmitting(false);
            toast.error('Wystąpił błąd');
            return;
          }

          try {
            const validationResult = await agent.CinemaVouchers.validateDirectorCinemaPromoWithinInstitution(
              values.directorFirstName,
              values.directorLastName,
              result.regon,
              UserPromotionE.CinemaVouchersSchoolDirectorsTeachersDay,
            );
            if (validationResult.hasVoucherBeenCollected) {
              history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_EXPIRED);
              return;
            }
            if (!validationResult.isDirectorNameValid) {
              history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_BAD_DATA);
              return;
            }
          } catch (error) {
            setSubmitting(false);
            return;
          }

          const requestBody: ICinemaVoucherDirectorFormValues = {
            directorFirstName: values.directorFirstName,
            directorLastName: values.directorLastName,
            institutionSopId: values.institutionSopId,
            institutionFullName: result.fullName,
            institutionRegon: result.regon,
          };

          agent.Institution.addDirectorToInstitution(requestBody)
            .then(() => {
              mutate(getUrls.User.isUserAssignedToInstitutionAsDirector);
              mutate(
                `/CinemaVoucher/CheckUserHasCinemaVoucherOrderWithPromo?promoId=${CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo}`,
              );
            })
            .finally(() => {
              setSubmitting(false);
              setAreCitiesLoading(false);
            });
        }}
        validationSchema={citySearchValidationSchema}>
        {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => (
          <Form autoComplete='off' onChange={(event) => console.log(event)} onSubmit={handleSubmit}>
            <div className={'tw-mx-auto  tw-pb-8 tw-text-center tw-text-2xl tw-text-nau-green-dark '}>
              <div>Aby odebrać</div>
              <div className={'tw-whitespace-nowrap tw-font-bold'}>2 darmowe kupony</div>
              <div>uzupełnij poniższe dane</div>
            </div>
            <div className={'tw-flex tw-flex-col tw-gap-4 tw-font-normal'}>
              <MyTextInput disabled={!!userProfile?.firstName} name='directorFirstName' placeholder='Imię' />
              <MyTextInput disabled={!!userProfile?.lastName} name='directorLastName' placeholder='Nazwisko' />
            </div>

            <div className={'tw-mx-auto  tw-mt-8 tw-text-center tw-text-2xl tw-text-nau-green-dark '}>
              <div>Dane placówki</div>
            </div>
            <div className={'tw-mt-4 tw-flex tw-flex-col tw-justify-center tw-gap-4'}>
              <div className={'tw-flex tw-w-full tw-justify-center tw-gap-4 tw-font-normal'}>
                <MyTextInput
                  mask={MaskHelpers.postCode}
                  name='zipCode'
                  onChange={(value) => {
                    const newZipCode = value.target.value.replaceAll('_', '');
                    if (
                      value.target.value.replaceAll('_', '').replaceAll('-', '') !=
                      values.zipCode.replaceAll('_', '').replaceAll('-', '')
                    ) {
                      setFieldValue('place', '');
                      setFieldValue('street', '');
                      setFieldValue('institutionSopId', 0);
                      loadCities(newZipCode, '', false);
                    }
                  }}
                  placeholder='Kod pocztowy'
                  wrapperClassName={'tw-max-w-[150px]'}
                />
                <div className={'tw-grow'}>
                  <MySelectInput
                    bodyPortal={true}
                    className={'tw-w-full'}
                    isLoading={areCitiesLoading}
                    name={'place'}
                    onChange={(val) => {
                      setFieldValue('street', '');
                      setFieldValue('institutionSopId', 0);
                      loadCities(values.zipCode.replaceAll('_', ''), val as string, false);
                    }}
                    onInputChange={(val) => {
                      loadCities(values.zipCode.replaceAll('_', ''), val as string, true);
                    }}
                    options={citiesFromApi.map((city) => ({ value: city, label: city }))}
                    placeholder={'Miasto'}
                    value={values.place}
                  />
                </div>
              </div>
              {institutions && institutions.length > 0 && (
                <div className={'tw-flex tw-w-full tw-flex-col   tw-gap-4 tw-font-normal'}>
                  <div className={'tw-w-full'}>
                    <MySelectInput
                      bodyPortal={true}
                      className={'tw-w-full'}
                      isClearable={true}
                      isLoading={areCitiesLoading}
                      name={'street'}
                      onChange={() => setFieldValue('institutionSopId', 0)}
                      options={[...new Set(institutions.map((obj) => cleanStreet(obj.street)))].map((street) => ({
                        value: street,
                        label: street,
                      }))}
                      placeholder={'Ulica'}
                      value={values.street}
                    />
                  </div>
                  <div className={'tw-w-full'}>
                    <MySelectInput
                      bodyPortal={true}
                      className={'tw-w-full'}
                      isClearable={true}
                      isLoading={areCitiesLoading}
                      name={'institutionSopId'}
                      options={institutions
                        .filter((obj) => !values.street || cleanStreet(obj.street) == cleanStreet(values.street))
                        .filter(
                          (obj, index, self) =>
                            index === self.findIndex((t) => t.id === obj.id && t.fullName === obj.fullName),
                        )
                        .map((obj) => ({ value: obj.id, label: obj.fullName }))}
                      placeholder={'Placówka'}
                      value={values.institutionSopId}
                    />
                  </div>
                  {/* <MyCheckbox */}
                  {/*   label={'Mojej placówki nie ma na liście'} */}
                  {/*   name={'otherInst'} */}
                  {/*   onChange={(event) => { */}
                  {/*     setIsOtherInst(event.target.checked); */}
                  {/*   }} */}
                  {/* /> */}
                </div>
              )}
            </div>

            {!!values.institutionSopId && (
              <div className={'tw-mt-8 tw-flex tw-w-full'}>
                <MyButton className={'tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'greenDark'}>
                  Odbierz
                </MyButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {/* {isOtherInst && ( */}
      {/*   <div className={'tw-mt-8 tw-w-full'}> */}
      {/*     <div className={'tw-mx-auto  tw-pb-2 tw-text-center tw-text-2xl tw-text-nau-green-dark '}> */}
      {/*       <div>Dodaj swoją placówkę</div> */}
      {/*     </div> */}
      {/*     <AddInstitution */}
      {/*       customAddInstitutionText={'Odbierz'} */}
      {/*       onAddInstitution={(newSopId: number, fullName: string, regon: string) => { */}
      {/*         return new Promise((resolve, reject) => { */}
      {/*           const requestBody: ICinemaVoucherDirectorFormValues = { */}
      {/*             directorFirstName: directorFirstName, */}
      {/*             directorLastName: directorLastName, */}
      {/*             institutionSopId: newSopId, */}
      {/*             institutionFullName: fullName, */}
      {/*             institutionRegon: regon, */}
      {/*           }; */}

      {/*           agent.Institution.addDirectorToInstitution(requestBody).then((resp) => { */}
      {/*             mutate(getUrls.User.isUserAssignedToInstitutionAsDirector); */}
      {/*             mutate( */}
      {/*               `/CinemaVoucher/CheckUserHasCinemaVoucherOrderWithPromo?promoId=${CinemaVoucherOrderPromo.InstitutionDirectorPromo}`, */}
      {/*             ); */}
      {/*             resolve(); */}
      {/*           }); */}
      {/*         }); */}
      {/*       }} */}
      {/*     /> */}
      {/*   </div> */}
      {/* )} */}
    </div>
  );
};
