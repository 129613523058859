import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../store/store';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import * as styles from './styles';
import { toast } from 'react-toastify';
import { InstitutionRole } from '../../enums/roles.enums';
import MyTextMaskedInput from '../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import { UserInstitution } from '../../types/user';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import MySelectInput from '../FormControls/MySelectInput/MySelectInput';
import { MySelectInputOptions } from '../FormControls/MySelectInput/types';
import _ from 'lodash';

export default observer(() => {
  const { userStore, loansStore } = useStore();
  const [authGuid, setAuthGuid] = useState<string | null>(null);
  const [institution, setInstitution] = useState<UserInstitution | null>(null);
  const [phoneId, setPhoneId] = useState<number | null>(null);
  const [authorizationPhones, setAuthorizationPhones] = useState<MySelectInputOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSent, setSent] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);

  useEffect(() => {
    const userInstitution = userStore.getUserInstitution();
    if (
      userInstitution == null ||
      userInstitution.institutionId <= 0 ||
      userInstitution.roleId != InstitutionRole.Admin
    ) {
      toast.error('Proszę zalogować się do placówki jako admin.');
      return;
    }
    setInstitution(userInstitution);
    loansStore
      .getAuthorizationPhones(userInstitution.institutionId)
      .then((data) => {
        setAuthorizationPhones(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych');
      });
  }, []);
  const handlePhoneChange = (event: number) => {
    setPhoneId(event);
  };
  const sendSms = () => {
    if (phoneId != null && phoneId != 0 && institution != null) {
      setSending(true);
      userStore
        .requestAdminAuthorizatonToken(institution.institutionId, phoneId)
        .then((resp) => {
          setAuthGuid(resp);
          setSent(true);
          setSending(false);
        })
        .catch(() => setSending(false));
    } else {
      setSending(false);
    }
  };
  return (
    <div>
      {loading && <LoadingComponent />}
      <Formik
        initialValues={{ token: '', error: null }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          if (authGuid != null && institution != null) {
            userStore.authorizeInstitutionAdmin(values.token, institution.institutionId, authGuid).catch((error) => {
              setErrors({ error: error });
              setSubmitting(false);
            });
          } else {
            setSubmitting(false);
          }
        }}>
        {({ handleSubmit, isSubmitting, errors: { error } }) => (
          <Form autoComplete='off' className='ui form col-md-6 offset-md-3' onSubmit={handleSubmit}>
            <h2 style={styles.titleHeader as React.CSSProperties}>Autoryzacja sms</h2>
            {!isSent && (
              <div>
                <p
                  style={_.merge(
                    {},
                    styles.marginBottomZero as React.CSSProperties,
                    styles.applicationText as React.CSSProperties,
                    { lineHeight: 'normal' },
                  )}>
                  <span>Wybierz numer telefonu do autoryzacji</span>
                </p>
                <MySelectInput
                  className='inputNumberHideArrow'
                  name={'phone'}
                  onChange={(e) => {
                    handlePhoneChange(e != null ? Number(e) : 0);
                  }}
                  options={authorizationPhones}
                  placeholder={'Nr telefonu'}
                  value={phoneId}
                />
                {isSending ? (
                  <Button className='btn-full-width mt-3' type='submit'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Wysyłam…
                  </Button>
                ) : (
                  <Button className='btn-full-width mt-3' onClick={() => sendSms()} type='button'>
                    Wyślij sms
                  </Button>
                )}
              </div>
            )}
            {isSent && (
              <div>
                <MyTextMaskedInput mask={MaskHelpers.token} name='token' placeholder='Hasło z tokena SMS' />
                <ErrorMessage name='error' render={() => <label style={styles.labelError}>{error}</label>} />
                {isSubmitting ? (
                  <Button className='btn-full-width mt-3' type='submit'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Wysyłam…
                  </Button>
                ) : (
                  <Button className='btn-full-width mt-3' type='submit'>
                    Wyślij
                  </Button>
                )}
              </div>
            )}
            <MyFooterContact />
          </Form>
        )}
      </Formik>
    </div>
  );
});
