import React from 'react';
import { openLink } from '../../../utils/linkUtils';
import { twMerge } from '../../../index';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';

interface Subscription {
  name: string;
  employeePrice: string;
  companionPrice: string;
}
const subscriptions: Subscription[] = [
  { name: 'MultiSport Plus - nielimitowany', employeePrice: '181,00 zł', companionPrice: '199,00 zł' },
  { name: 'MultiSport Classic', employeePrice: '162,00 zł', companionPrice: '179,00 zł' },
  { name: 'MultiSport Light - 8 wizyt', employeePrice: '90,00 zł', companionPrice: '124,00 zł' },
  { name: 'Kids Aqua', employeePrice: '59,00 zł', companionPrice: '' },
  { name: 'Kids', employeePrice: '99,00 zł', companionPrice: '' },
  { name: 'Senior', employeePrice: '109,00 zł', companionPrice: '' },
  { name: 'Student', employeePrice: '149,00 zł', companionPrice: '' },
];
export const SportCardMultisportSubscriptionInfo: React.FC = () => {
  const formatPrice = (price: string) => {
    const [whole, fraction] = price.split(',');
    return (
      <>
        <strong>{whole}</strong>
        {fraction && <sup>{fraction.replaceAll('zł', '')}</sup>}zł
      </>
    );
  };

  return (
    <div className=' tw-bg-white'>
      <ul className='tw-list-inside tw-list-disc tw-pb-10 tw-text-nau-sea-green'>
        <li>Ponad 5 500 obiektów sportowych w całej Polsce.</li>
        <li>Możliwość wyboru spośród ponad 45 aktywności sportowych i rekreacyjnych.</li>
        <li>
          Dostępne obiekty rekreacyjno-sportowe można wyszukiwać na stronie:{' '}
          <span
            className='tw-cursor-pointer tw-text-blue-500'
            onClick={() => openLink('http://www.kartamultisport.pl/obiekty')}>
            http://www.kartamultisport.pl/obiekty
          </span>
        </li>
      </ul>
      <div className=''>
        <div className='tw-rounded-xl tw-bg-white tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'>
          <SubpageHeader headerClassName={twMerge('tw-text-xl ')} title={'Miesięczna cena abonamentu MultiSport:'} />
          <div className='tw-overflow-x-auto'>
            <table className='tw-w-full tw-table-auto '>
              <thead>
                <tr className='tw-divide-x tw-divide-gray-300'>
                  <th className='tw-px-4 tw-py-2 tw-text-left'>Abonament</th>
                  <th className='tw-bg-nau-sea-green/10 tw-px-4 tw-py-2 tw-text-left'>Pracownik</th>
                  <th className='tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-left'>Osoba towarzysząca</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription, index) => (
                  <tr className='tw-divide-x tw-divide-gray-300 tw-border-t' key={index}>
                    <td className='tw-px-4 tw-py-2 tw-text-nau-sea-green'>{subscription.name}</td>
                    <td
                      className={twMerge(
                        'tw-px-4 tw-py-2 tw-text-center tw-text-nau-sea-green',
                        subscription.companionPrice && 'tw-bg-nau-sea-green/10',
                      )}
                      colSpan={subscription.companionPrice ? 1 : 2}>
                      {formatPrice(subscription.employeePrice)}
                    </td>
                    {subscription.companionPrice && (
                      <td className='tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-nau-sea-green'>
                        {formatPrice(subscription.companionPrice)}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
