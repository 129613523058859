import React from 'react';
import styles from './styles.module.scss';
import { Image } from 'react-bootstrap';

export default function AllPaymentsPaid() {
  return (
    <div className={styles.container}>
      <Image className={styles.circleCheck} src='assets/checkInCircle.svg' />
      <div className={styles.header}>Wszystko opłacone</div>
    </div>
  );
}
