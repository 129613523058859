import * as React from 'react';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import Config from '../../Config';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import ButtonOpenLinkNewTab from '../../components/Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';

const TechnicalBreak = () => (
  <div className='col-md-6 offset-md-3 d-flex flex-column align-items-center'>
    <div style={styles.containerRegister as React.CSSProperties}>
      <LogoHeader />
    </div>
    <p style={styles.selfStyles as React.CSSProperties}>
      Przepraszamy, trwa aktualizacja aplikacji mobilnej.
      <br />
      Skorzystaj z aplikacji w przeglądarce internetowej.
    </p>
    <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
    <ButtonOpenLinkNewTab buttonText={'Przejdź do aplikacji'} link={Config.appUrl.toString().replace('api/', '')} />
  </div>
);

export default TechnicalBreak;
