import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { UserInstitution } from '../../../types/user';
import { ErrorMessage, Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';

export default observer((props: { institutionId: number }) => {
  const { userStore, modalStore, loansStore } = useStore();
  const [, setUserInst] = useState<UserInstitution | null>(null);
  const [daysOfMonth, setDaysOfMonth] = useState<MySelectInputOptions[]>([]);

  useEffect(() => {
    const days = daysOfMonth;
    for (let index = 1; index < 29; index++) {
      const day = { value: index, label: index.toString() };
      days.push(day);
    }
    setDaysOfMonth(days);
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
  }, [userStore]);
  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        dayOfMonth: null,
        institutionId: props.institutionId,
        error: null,
      }}
      //validationSchema={ChangeLimitSchema}
      onSubmit={({ dayOfMonth }, { setSubmitting }) => {
        loansStore
          .addLoanPaymentDate(props.institutionId, Number(dayOfMonth))
          .then(() => {
            setSubmitting(false);
            toast.success('Data została zapisana.');
            handleClose();
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Wybierz dzień miesiąca</span>
            </p>
            <MySelectInput
              className='inputNumberHideArrow'
              label={'Dzień miesiąca'}
              name={'dayOfMonth'}
              options={daysOfMonth}
              placeholder={'Dzień miesiąca'}
            />
            <ErrorMessage name='error' render={() => <label style={styles.labelError}>{errors.error}</label>} />
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Anuluj'
            buttonTextSubmit='Zapisz'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
