import React from 'react';
import * as styles from './styles';
import { UserMarketingData } from '../../../types/user';
import { TradeUnionTypesE } from '../../../enums/tradeUnionTypes.enums';
import { UserPromotionE } from '../../../enums/userPromotion.enums';

interface IWalletNauInstructionContentProps {
  userMarketingData: UserMarketingData;
}
const getMargin = (userMarketingData: UserMarketingData) => {
  let interestRate;
  if (
    userMarketingData.isZnp ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.ElectroMachinist ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.JusticeSystem ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.SolidarityPodbeskidzie ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.Miners ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.ZnpRybnik ||
    userMarketingData.tradeUnionId === TradeUnionTypesE.Opzz ||
    userMarketingData.isTeacherPromo ||
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolStuff)
  ) {
    interestRate = (
      <>
        <br />● oprocentowanie 9,90%*
      </>
    );
  } else {
    interestRate = (
      <>
        <br />● oprocentowanie 19,99%
      </>
    );
  }

  return interestRate;
};
const WalletNauInstructionContent = (props: IWalletNauInstructionContentProps) => {
  return (
    <div>
      <p style={styles.headingTextStyle}>Portfel NAU to środki na nieprzewidziane wydatki.</p>
      <p style={styles.textStyle}>
        ● miesięczna kwota wypłaty do 2 500 zł
        {getMargin(props.userMarketingData)}
        <br />● opłata w wysokości 5% za każdą dokonaną wypłatę
        <br />● realizacja wypłat 24/7**
        <br />● jednorazowa wypłata przyznanych środków lub wypłaty częściowe do wysokości przyznanego limitu
        <br />● spłata należności w okresach miesięcznych
      </p>
      <p style={styles.headingTextStyle}>Jak skorzystać z Portfela NAU?</p>
      <p className={'mb-0'} style={styles.textStyle}>
        1. Wypełnij formularz online
        <br />
        2. NAU zweryfikuje Twój wniosek
        <br />
        3. Po pozytywnej weryfikacji otrzymasz przelew na konto bankowe
      </p>
    </div>
  );
};

export default WalletNauInstructionContent;
