export const container = {
  width: '100%',
  borderRadius: '20px',
  backgroundColor: '#fff',
  cursor: 'pointer',
};

export const tabsBox = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  background: '#FB7A32',
} as React.CSSProperties;

export const sectionHeader = {
  color: '#fff',
  textAlign: 'center',
  padding: '20px 0',
} as React.CSSProperties;

export const bodyContainer = {
  padding: '20px',
};
export const benefitsStyles = {
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  height: 'fit-content',
};

export const lowerTextStyles = {
  width: '100%',
  marginTop: '20px',
};

export const bolderTextStyles = {
  fontSize: '16px',
  paddingTop: '5px',
  color: '#00635C',
  fontWeight: '600',
};
export const normalTextStyles = {
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '400',
};
export const headingTextStyles = {
  fontSize: '20px',
  lineHeight: '1.2',
  paddingTop: '5px',
  color: '#00635C',
  fontWeight: '600',
};

export const firstContainerStyles = {
  height: '100px',
  margin: '5px',
  borderRadius: '15px',
  backgroundColor: '#FB7A32',
};

export const containerStyles = {
  padding: '0',
};
export const containerUnavailableStyles = {
  padding: '0',
  opacity: '0.5',
};

export const secondContainerStyles = {
  height: '90%',
  width: '94%',
  borderRadius: '15px',
  border: '3px solid #fff',
  position: 'relative',
  left: '3%',
  top: '5%',
  backgroundColor: '#FB7A32',
};

export const imageStyles = {
  marginTop: '5px',
  marginLeft: '5px',
  height: '25px',
};
export const imageStylesThirteen = {
  marginTop: '5px',
  marginBottom: '-5px',
  marginLeft: '5px',
  height: '30px',
};
export const flexContainerText = {
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'end',
  textAlign: 'end',
};

export const textCardStyles = {
  fontSize: '24px',
  fontWeight: '600',
  color: '#fff',
  marginRight: '10px',
  marginBottom: '5px',
  textAlign: 'right',
};
export const descriptionCardStyles = {
  fontSize: '12px',
  fontWeight: '400',
  color: '#fff',
  marginRight: '10px',
  marginBottom: '30px',
  textAlign: 'right',
};

export const tabLink = {
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  textAlign: 'center',
} as React.CSSProperties;

export const activeTabLink = {
  ...tabLink,
  color: '#fff',
  fontWeight: '300',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '3px solid #fff',
  textAlign: 'center',
} as React.CSSProperties;

export const displayFlex = { display: 'flex', justifyContent: 'start' };
