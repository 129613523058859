export const Countries = [
  { value: 1, label: 'Haiti' },
  { value: 2, label: 'Hiszpania' },
  { value: 3, label: 'Honduras' },
  { value: 4, label: 'Hongkong' },
  { value: 5, label: 'Indie' },
  { value: 6, label: 'Indonezja' },
  { value: 7, label: 'Irak' },
  { value: 8, label: 'Iran' },
  { value: 9, label: 'Irlandia' },
  { value: 10, label: 'Islandia' },
  { value: 11, label: 'Izrael' },
  { value: 12, label: 'Jamajka' },
  { value: 13, label: 'Japonia' },
  { value: 14, label: 'Jemen' },
  { value: 15, label: 'Jordania' },
  { value: 16, label: 'Kajmany' },
  { value: 17, label: 'Kambodża' },
  { value: 18, label: 'Kamerun' },
  { value: 19, label: 'Kanada' },
  { value: 20, label: 'Katar' },
  { value: 21, label: 'Kazachstan' },
  { value: 22, label: 'Kenia' },
  { value: 23, label: 'Kirgistan' },
  { value: 24, label: 'Kiribati' },
  { value: 25, label: 'Kolumbia' },
  { value: 26, label: 'Komory' },
  { value: 27, label: 'Kongo' },
  { value: 28, label: 'Kongo, Republika Demokratyczna' },
  { value: 29, label: 'Koreańska Republika Ludowo-Demokratyczna' },
  { value: 30, label: 'Kosowo' },
  { value: 31, label: 'Kostaryka' },
  { value: 32, label: 'Kuba' },
  { value: 33, label: 'Kuwejt' },
  { value: 34, label: 'Laos' },
  { value: 35, label: 'Lesotho' },
  { value: 36, label: 'Liban' },
  { value: 37, label: 'Liberia' },
  { value: 38, label: 'Libia' },
  { value: 39, label: 'Liechtenstein' },
  { value: 40, label: 'Litwa' },
  { value: 41, label: 'Luksemburg' },
  { value: 42, label: 'Łotwa' },
  { value: 43, label: 'Macedonia' },
  { value: 44, label: 'Madagaskar' },
  { value: 45, label: 'Majotta' },
  { value: 46, label: 'Makau' },
  { value: 47, label: 'Malawi' },
  { value: 48, label: 'Malediwy' },
  { value: 49, label: 'Malezja' },
  { value: 50, label: 'Mali' },
  { value: 51, label: 'Malta' },
  { value: 52, label: 'Mariany Północne' },
  { value: 53, label: 'Maroko' },
  { value: 54, label: 'Mauretania' },
  { value: 55, label: 'Mauritius' },
  { value: 56, label: 'Meksyk' },
  { value: 57, label: 'Melilla' },
  { value: 58, label: 'Mikronezja' },
  { value: 59, label: 'Mołdowa' },
  { value: 60, label: 'Mongolia' },
  { value: 61, label: 'Montserrat' },
  { value: 62, label: 'Mozambik' },
  { value: 63, label: 'Myanmar (Burma)' },
  { value: 64, label: 'Namibia' },
  { value: 65, label: 'Nauru' },
  { value: 66, label: 'Nepal' },
  { value: 67, label: 'Niderlandy' },
  { value: 68, label: 'Niemcy' },
  { value: 69, label: 'Niger' },
  { value: 70, label: 'Nigeria' },
  { value: 71, label: 'Nikaragua' },
  { value: 72, label: 'Niue' },
  { value: 73, label: 'Norfolk' },
  { value: 74, label: 'Norwegia' },
  { value: 75, label: 'Nowa Kaledonia' },
  { value: 76, label: 'Nowa Zelandia' },
  { value: 77, label: 'Oman' },
  { value: 78, label: 'Pakistan' },
  { value: 79, label: 'Palau' },
  { value: 80, label: 'Panama' },
  { value: 81, label: 'Papua Nowa Gwinea' },
  { value: 82, label: 'Paragwaj' },
  { value: 83, label: 'Peru' },
  { value: 84, label: 'Pitcairn' },
  { value: 85, label: 'Polinezja Francuska' },
  { value: 86, label: 'Polska' },
  { value: 87, label: 'Portugalia' },
  { value: 88, label: 'Rep.Połud.Afryki' },
  { value: 89, label: 'Rep.Środkowoafryańska' },
  { value: 90, label: 'Republika Czeska' },
  { value: 91, label: 'Republika Korei' },
  { value: 92, label: 'Rosja' },
  { value: 93, label: 'Rumunia' },
  { value: 94, label: 'Rwanda' },
  { value: 95, label: 'Sahara Zachodnia' },
  { value: 96, label: 'Saint Barthelemy' },
  { value: 97, label: 'Salwador' },
  { value: 98, label: 'Samoa' },
  { value: 99, label: 'Samoa Amerykańskie' },
  { value: 100, label: 'San Marino' },
  { value: 101, label: 'Senegal' },
  { value: 102, label: 'Serbia' },
  { value: 108, label: 'Francja' },
  { value: 109, label: 'Gabon' },
  { value: 110, label: 'Gambia' },
  { value: 111, label: 'Ghana' },
  { value: 112, label: 'Gibraltar' },
  { value: 113, label: 'Grecja' },
  { value: 114, label: 'Grenada' },
  { value: 115, label: 'Grenlandia' },
  { value: 116, label: 'Gruzja' },
  { value: 117, label: 'Guam' },
  { value: 118, label: 'Gujana' },
  { value: 103, label: 'Seszele' },
  { value: 104, label: 'Sierra Leone' },
  { value: 105, label: 'Singapur' },
  { value: 106, label: 'Słowacja' },
  { value: 107, label: 'Słowenia' },
  { value: 119, label: 'Afganistan' },
  { value: 120, label: 'Albania' },
  { value: 121, label: 'Algieria' },
  { value: 122, label: 'Andora' },
  { value: 123, label: 'Angola' },
  { value: 124, label: 'Anguilla' },
  { value: 125, label: 'Antarktyda' },
  { value: 126, label: 'Antigua i Barbuda' },
  { value: 127, label: 'Arabia Saudyjska' },
  { value: 128, label: 'Argentyna' },
  { value: 129, label: 'Armenia' },
  { value: 130, label: 'Aruba' },
  { value: 131, label: 'Australia' },
  { value: 132, label: 'Austria' },
  { value: 133, label: 'Azerbejdżan' },
  { value: 134, label: 'Bahamy' },
  { value: 135, label: 'Bahrajn' },
  { value: 136, label: 'Bangladesz' },
  { value: 137, label: 'Barbados' },
  { value: 138, label: 'Belgia' },
  { value: 139, label: 'Belize' },
  { value: 140, label: 'Benin' },
  { value: 141, label: 'Bermudy' },
  { value: 142, label: 'Bhutan' },
  { value: 143, label: 'Białoruś' },
  { value: 144, label: 'Boliwia' },
  { value: 145, label: 'Bośnia i Hercegowina' },
  { value: 146, label: 'Botswana' },
  { value: 147, label: 'Brazylia' },
  { value: 148, label: 'Brunei Darussalam' },
  { value: 149, label: 'Bułgaria' },
  { value: 150, label: 'Burkina Faso' },
  { value: 151, label: 'Burundi' },
  { value: 152, label: 'Ceuta' },
  { value: 153, label: 'Chile' },
  { value: 154, label: 'Chiny' },
  { value: 155, label: 'Chorwacja' },
  { value: 156, label: 'Curaçao' },
  { value: 157, label: 'Cypr' },
  { value: 158, label: 'Czad' },
  { value: 159, label: 'Czarnogóra' },
  { value: 160, label: 'Dania' },
  { value: 161, label: 'Dominika' },
  { value: 162, label: 'Dominikana' },
  { value: 163, label: 'Dżibuti' },
  { value: 164, label: 'Egipt' },
  { value: 165, label: 'Ekwador' },
  { value: 166, label: 'Erytrea' },
  { value: 167, label: 'Estonia' },
  { value: 168, label: 'Etiopia' },
  { value: 169, label: 'Falklandy' },
  { value: 170, label: 'Fidżi Republika' },
  { value: 171, label: 'Filipiny' },
  { value: 172, label: 'Finlandia' },
  { value: 173, label: 'Gwatemala' },
  { value: 174, label: 'Gwinea' },
  { value: 175, label: 'Gwinea-Bissau' },
  { value: 176, label: 'Gwinea Równikowa' },
  { value: 177, label: 'Somalia' },
  { value: 178, label: 'Sri Lanka' },
  { value: 179, label: 'Stany Zjedn. Ameryki' },
  { value: 180, label: 'Suazi' },
  { value: 181, label: 'Sudan' },
  { value: 182, label: 'Sudan Południowy' },
  { value: 183, label: 'Surinam' },
  { value: 184, label: 'Syria' },
  { value: 185, label: 'Szwajcaria' },
  { value: 186, label: 'Szwecja' },
  { value: 187, label: 'Tadżykistan' },
  { value: 188, label: 'Tajlandia' },
  { value: 189, label: 'Tajwan' },
  { value: 190, label: 'Tanzania' },
  { value: 191, label: 'Togo' },
  { value: 192, label: 'Tokelau' },
  { value: 193, label: 'Tonga' },
  { value: 194, label: 'Trynidad i Tobago' },
  { value: 195, label: 'Tunezja' },
  { value: 196, label: 'Turcja' },
  { value: 197, label: 'Turkmenistan' },
  { value: 198, label: 'Tuvalu' },
  { value: 199, label: 'Uganda' },
  { value: 200, label: 'Ukraina' },
  { value: 201, label: 'Urugwaj' },
  { value: 202, label: 'Uzbekistan' },
  { value: 203, label: 'Vanuatu' },
  { value: 204, label: 'Wallis i Futuna' },
  { value: 205, label: 'Watykan' },
  { value: 206, label: 'Wenezuela' },
  { value: 207, label: 'Węgry' },
  { value: 208, label: 'Wielka Brytania' },
  { value: 209, label: 'Wietnam' },
  { value: 210, label: 'Włochy' },
  { value: 211, label: 'Wschodni Timor' },
  { value: 212, label: 'Wyb.Kości Słoniowej' },
  { value: 213, label: 'Wyspy Marshalla' },
  { value: 214, label: 'Wyspy Owcze' },
  { value: 215, label: 'Wyspy Salomona' },
  { value: 216, label: 'Zambia' },
  { value: 217, label: 'Zielony Przylądek' },
  { value: 218, label: 'Zimbabwe' },
  { value: 219, label: 'Zjedn.Emiraty Arabskie' },
];
