import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function NauMobileIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} viewBox='0 0 221.496 73.768' xmlns='http://www.w3.org/2000/svg'>
      <g id='NAU_mobile_Horizontal_White' transform='translate(110.748 36.884)'>
        <g id='Group_1495' transform='translate(-110.468 -36.604)'>
          <g id='Group_1494' transform='translate(18.206 18.206)'>
            <path
              d='M236.7,64.8a7.9,7.9,0,1,0,7.894,7.894A7.893,7.893,0,0,0,236.7,64.8Zm0,11.073a3.175,3.175,0,1,1,3.171-3.179A3.176,3.176,0,0,1,236.7,75.87Zm-9.7-4.179A6.917,6.917,0,0,0,215.514,66.5,6.888,6.888,0,0,0,211,64.775a7.229,7.229,0,0,0-6.974,7.269v7.99a.578.578,0,0,0,.578.578h3.527a.578.578,0,0,0,.578-.578V71.691a2.234,2.234,0,1,1,4.467,0v8.343a.578.578,0,0,0,.578.578h3.534a.578.578,0,0,0,.578-.578s-.008-8.268-.008-8.343a2.238,2.238,0,1,1,4.476,0v8.343a.578.578,0,0,0,.578.578h3.527a.578.578,0,0,0,.578-.578V71.691Zm41-.967h-3.527a.578.578,0,0,0-.578.578v8.731a.578.578,0,0,0,.578.578H268a.578.578,0,0,0,.578-.578V71.3A.578.578,0,0,0,268,70.724Zm-6.068.673a7.822,7.822,0,0,0-10.862-5.786V58.156a.578.578,0,0,0-.578-.578h-3.527a.578.578,0,0,0-.578.578V72.413a8.078,8.078,0,0,0,7.576,8.195A7.84,7.84,0,0,0,261.935,71.4Zm-7.709,4.356A3.148,3.148,0,1,1,257.37,72.6,3.151,3.151,0,0,1,254.226,75.753ZM274.48,57.578h-3.527a.578.578,0,0,0-.578.578V80.034a.578.578,0,0,0,.578.578h3.527a.578.578,0,0,0,.578-.578V58.156A.578.578,0,0,0,274.48,57.578Zm-5.9,9.538a2.342,2.342,0,1,0-2.341,2.341A2.342,2.342,0,0,0,268.581,67.116Zm23.906,3.745a8.084,8.084,0,0,0-.748-1.943,7.8,7.8,0,0,0-4.2-3.639,8.008,8.008,0,0,0-8.406,1.865,7.916,7.916,0,0,0,5.65,13.468h3.813a.539.539,0,0,0,.54-.539V76.417a.539.539,0,0,0-.54-.539h-3.946a4.613,4.613,0,0,1-.814-.133,3.765,3.765,0,0,1-.9-.448,3.182,3.182,0,0,1,3.671-5.2,1.568,1.568,0,0,1,.266.2,2.821,2.821,0,0,1,.549.631l-2.608.034h-.067a1.818,1.818,0,0,0-.432.066H284.3a1.73,1.73,0,0,0-.947.681,1.7,1.7,0,0,0-.249,1.462,3.1,3.1,0,0,0,.183.415l.033.05a1.1,1.1,0,0,0,.249.3l.083.083a.954.954,0,0,0,.133.1.574.574,0,0,0,.15.1,1.788,1.788,0,0,0,.415.166l.116.016c.05.017.117.017.167.034h.266l.017-.017s6.93-.094,7.2-.094.424-.086.471-.415a9.941,9.941,0,0,0,.1-1,4.16,4.16,0,0,0,0-.648A7.8,7.8,0,0,0,292.487,70.861Z'
              fill='#fff'
              id='Path_4786'
              transform='translate(-108.175 -43.798)'
            />
            <g id='Group_1493'>
              <path
                d='M120.537,32.988a.922.922,0,0,1,.922.922V53.55A16.257,16.257,0,0,1,99.208,68.656l-1.5-2.864L91.325,53.55l-.7-1.34-1.233-2.369c-.01-.01,0-.01,0-.01l-.437-.825V33.91a.922.922,0,0,1,.922-.922h9.155a.922.922,0,0,1,.922.922V53.319a5.41,5.41,0,0,0,4.727,5.458,5.248,5.248,0,0,0,5.768-5.227V33.91a.922.922,0,0,1,.922-.922Zm-72.367.034c-8.622.553-15.183,8.018-15.183,16.657v19.2a.922.922,0,0,0,.922.922h9.156a.922.922,0,0,0,.922-.922V49.23a5.25,5.25,0,0,1,5.758-5.219,5.391,5.391,0,0,1,4.736,5.439V68.831a.165.165,0,0,0,.311.076L57.9,62.977l5.728-10.922L65.1,49.23l.369-.709A16.251,16.251,0,0,0,48.171,33.022Zm48.1,35.43L95.15,66.316l-.01-.01L88.985,54.492,78.035,33.513a.922.922,0,0,0-1.635,0L65.491,54.356l-7.38,14.1a.922.922,0,0,0,.817,1.349h9.623a.912.912,0,0,0,.808-.489L72.4,63.5,76.7,55.295a.554.554,0,0,1,.982,0l4.306,8.254,2.524,4.835.483.926a.911.911,0,0,0,.809.491h9.65A.922.922,0,0,0,96.268,68.452Z'
                fill='#fff'
                id='Path_4787'
                transform='translate(-32.988 -32.988)'
              />
            </g>
          </g>
          <rect
            fill='none'
            height='73.207'
            id='Rectangle_481'
            opacity='0.001'
            stroke='#a3a3a3'
            strokeWidth='0.56'
            width='220.936'
          />
        </g>
      </g>
    </svg>
  );
}
