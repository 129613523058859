import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { Button, Image, Spinner } from 'react-bootstrap';
import * as styles from '../DocumentsLoan/styles';
import * as style from './styles';
import { history } from '../../../index';
import { TypeVerify } from './types';
import Checkbox from '../../Checkbox/Checkbox';
import { useStore } from '../../../store/store';
import { useParams } from 'react-router-dom';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import BoolToString from '../../../helpers/BoolToString';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { Capacitor } from '@capacitor/core';
import { CameraSource } from '@capacitor/camera';
import { openCamera } from '../../../utils/cameraUtils';

const VerifyLoan = () => {
  const { loansStore, userStore } = useStore();
  const { id }: any = useParams();
  const [checked, setChecked] = useState(true);
  const [type, setType] = useState(TypeVerify.BANK);
  const [file, setFile] = useState<string | Blob>('');
  const [fileName, setFileName] = useState<string | Blob>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [availableBenefitIsInstitutional, setAvailableBenefitIsInstitutional] = useState<boolean | null>(
    loansStore.availableBenefitIsInstitutional,
  );

  useEffect(() => {
    loansStore
      .checkBenefitIsInstitutionalByContractId(id)
      .then((resp) => {
        setAvailableBenefitIsInstitutional(resp);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const saveFile = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const setFileFromCamera = (blob: Blob) => {
    setFile(blob);
    setFileName(`photo_${Date.now()}.jpg`);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    await formData.append('id', id);
    await formData.append('formFile', file);
    await formData.append('fileName', fileName);
    await formData.append('fileCategory', type);
    await formData.append('benefitIsInstitutional', BoolToString.ToString(availableBenefitIsInstitutional));
    setIsSubmitting(true);
    await loansStore
      .saveLoanContractFile(formData)
      .then(() => {
        userStore.getCurrentUser().then(() => {
          history.push(PathRoute.LOAN);
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        toast.error(error.message);
      });
  };

  const handleChange = (e: any) => {
    setType(e.target.id);
    if (e.target.id !== TypeVerify.BANK) {
      setChecked(true);
      setFile('');
    } else if (e.target.id !== TypeVerify.STATEMENT) {
      setChecked(true);
      setFile('');
    } else {
      setChecked(!checked);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div style={style.mainContainer as React.CSSProperties}>
            <div style={style.flexContainer as React.CSSProperties}>
              <Image
                onClick={() => history.goBack()}
                src='/assets/arrow-Left.svg'
                style={style.transformIcon as React.CSSProperties}
              />
              <div style={style.centerFlexContainer as React.CSSProperties}>
                <label style={style.colorCenter as React.CSSProperties}>Wypłata od ręki</label>
                {availableBenefitIsInstitutional ? null : (
                  <p style={style.verifyText as React.CSSProperties}>Weryfikacja zatrudnienia</p>
                )}
              </div>
            </div>

            {availableBenefitIsInstitutional ? (
              <>
                Mamy wszystkie potrzebne dane, wystarczy że wyślesz a po zatwierdzeniu ich przez naszego pracownika
                będziesz mógł cieszyć się "Wypłatą od ręki".
              </>
            ) : (
              <>
                <Image src='/assets/personDocument.svg' style={style.photoStyles as React.CSSProperties} />
                <Checkbox
                  checked={checked}
                  handleChange={handleChange}
                  id={TypeVerify.STATEMENT}
                  text='Weryfikacja za pomocą Oświadczenia o zatrudnieniu w placówce oświatowej'
                  type={type}
                />
                {type === TypeVerify.STATEMENT ? (
                  <div>
                    {Capacitor.isNativePlatform() ? (
                      <div className='d-flex'>
                        <div style={style.cordovaFileBox as React.CSSProperties}>
                          <Image
                            onClick={() => openCamera(CameraSource.Camera, setFileFromCamera)}
                            src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                            style={style.heightSecondPhoto as React.CSSProperties}
                          />
                          <div style={style.uploadFile as React.CSSProperties}>Wykonaj zdjęcie</div>
                        </div>
                        <div style={style.cordovaFileBox as React.CSSProperties}>
                          <input
                            capture
                            onChange={saveFile}
                            style={style.inputHidden as React.CSSProperties}
                            type='file'
                          />
                          <Image
                            src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
                            style={style.heightSecondPhoto as React.CSSProperties}
                          />
                          <div style={style.uploadFile as React.CSSProperties}>Załącz zdjęcie</div>
                        </div>
                      </div>
                    ) : (
                      <div style={style.positionRelative as React.CSSProperties}>
                        <input
                          capture
                          onChange={saveFile}
                          style={style.inputHidden as React.CSSProperties}
                          type='file'
                        />
                        <Image
                          src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                          style={style.heightSecondPhoto as React.CSSProperties}
                        />
                        <div style={style.uploadFile as React.CSSProperties}>
                          Wykonaj lub załącz zdjęcie Oświadczenia
                        </div>
                        <div style={style.greyDot} />
                      </div>
                    )}
                  </div>
                ) : null}
                <div style={style.flexCenter as React.CSSProperties}> lub</div>
                <Checkbox
                  checked={checked}
                  handleChange={handleChange}
                  id={TypeVerify.BANK}
                  text='Weryfikacja za pomocą Wyciągu z konta bankowego z ostatnich 3 miesięcy'
                  type={type}
                />
                {type === TypeVerify.BANK ? (
                  <>
                    <div style={style.positionRelative as React.CSSProperties}>
                      <input
                        accept='application/pdf'
                        capture
                        onChange={saveFile}
                        style={style.inputHidden as React.CSSProperties}
                        type='file'
                      />
                      <Image
                        src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
                        style={style.heightSecondPhoto as React.CSSProperties}
                      />
                    </div>
                    <div style={style.uploadFile as React.CSSProperties}>Załącz plik PDF z Wyciągiem z konta</div>
                  </>
                ) : null}
              </>
            )}

            {isSubmitting ? (
              <Button style={{ marginTop: '2rem' }} type='submit' variant='primary'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Trwa zapisywanie pliku...
              </Button>
            ) : (
              <Button
                disabled={!availableBenefitIsInstitutional && !file}
                onClick={uploadFile}
                style={{ marginTop: '2rem' }}
                type='submit'
                variant='primary'>
                Wyślij
              </Button>
            )}

            {availableBenefitIsInstitutional ? null : (
              <div style={styles.autoInfo as React.CSSProperties}>
                <div style={style.flexRelative as React.CSSProperties}>
                  <Image src='/assets/infoGreen.svg' style={styles.flexGreen as React.CSSProperties} />
                  <p style={style.absoluteCenter as React.CSSProperties}>
                    Czemu muszę udowodnić że jestem pracownikiem oświaty?
                  </p>
                </div>
                <p style={styles.textInfoEvidence as React.CSSProperties}>
                  Oferta 'Wypłata od ręki' jest skierowana tylko do pracowników oświaty, dzięki czemu możemy
                  zaproponować wyjątkowo korzystną ofertę i minimum formalności. Jednorazowo przesyłasz świadectwo
                  zatrudnienia w oświacie, a otrzymujesz limit, który możesz wykorzystać wtedy, kiedy potrzebujesz.
                </p>
              </div>
            )}
          </div>
        </CustomerDashboard>
      )}
    </>
  );
};

export default VerifyLoan;
