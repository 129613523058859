import { Link } from 'react-router-dom';
import { barContainer, barLink } from './styles';
import { PathRoute } from '../../constants/pathRoute/Route';

export default function ConfirmEmailBar() {
  return (
    <>
      <div style={{ ...barContainer, top: '75px' }}>
        <Link style={barLink} to={PathRoute.VERIFY_EMAIL}>
          Potwierdź swój adres email
        </Link>
      </div>
    </>
  );
}
