import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import RejectedDiscountCodeContract from '../../components/DiscountCode/RejectedDiscountCodeContract/RejectedDiscountCodeContract';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { WalletNauContractStatusE } from '../../enums/WalletNauContractStatus.enums';
import DocumentsVerification from '../../components/DocumentsVerification/DocumentsVerification';
import WalletNauHeader from '../../components/WalletNau/WalletNauHeader/DiscountCodeHeader';
import RangeWallet from '../../components/WalletNau/RangeWallet/RangeWallet';
import RejectedWalletContract from '../../components/WalletNau/RejectedWalletContract/RejectedWalletContract';
import ReckoningWallet from '../../components/WalletNau/ReckoningWallet/ReckoningWallet';
import StartWalletNau from './StartWalletNau/StartWalleNau';
import UserPersonalDataLayout from '../../components/UserPersonalData/UserPersonalDataLayout';

export default observer(() => {
  const { walletNauStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [contractStatus, setContractStatus] = useState<number>(0);

  useEffect(() => {
    walletNauStore
      .getWalletContractStatus()
      .then((status) => {
        setContractStatus(status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const renderSwitch = (contractStatus: number | null) => {
    switch (contractStatus) {
      case WalletNauContractStatusE.Active:
        return <RangeWallet />;
      case WalletNauContractStatusE.Waiting:
        return (
          <DocumentsVerification
            getBottomText={() =>
              'Oczekuj na maila z załączoną umową ramową, podpisz ją i odeślij. Po aktywacji benefitu otrzymasz SMS-a.'
            }
            header={<WalletNauHeader />}
          />
        );
      case WalletNauContractStatusE.Reckoning:
        return <ReckoningWallet />;
      case WalletNauContractStatusE.Canceled:
        return <RejectedDiscountCodeContract />;
      case WalletNauContractStatusE.Fraud:
        return <RejectedWalletContract />;
      default:
        return (
          <UserPersonalDataLayout
            wrapperClassName={'tw-mx-auto tw-max-w-2xl tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
            <StartWalletNau />
          </UserPersonalDataLayout>
        );
    }
  };
  return (
    <>
      <LoadingComponent visible={loading} />
      <CustomerDashboard>{renderSwitch(contractStatus)}</CustomerDashboard>
    </>
  );
});
