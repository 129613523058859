import React from 'react';
import { Image } from 'react-bootstrap';
import styles from './styles.module.scss';
import FileHelper from '../../../../helpers/FileHelper';
import { PathRoute } from '../../../../constants/pathRoute/Route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const WalletNauInstructionBankFooter = (props: { prefix: string }) => {
  return (
    <>
      <br />
      <br />
      <div className={styles.bankAdnotation}>
        {props.prefix} dotyczy banków obsługujących przelewy w trybie natychmiastowym BlueCash
      </div>
      <br />
      <div
        className={styles.bankAdnotationLink}
        onClick={() => window.open(PathRoute.WALLET_BANKS, '_blank', 'location=no')}>
        <div className={styles.bankAdnotationLinkText}>Sprawdź dostępność w Twoim banku</div>
        <FontAwesomeIcon color='#00635c' icon={faCaretRight} size='2x' />
      </div>
    </>
  );
};

export default WalletNauInstructionBankFooter;
