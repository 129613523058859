import * as React from 'react';
import * as styles from './styles';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { LoanDataCashmir } from '../../../types/loans';

export default observer((props: { loan: LoanDataCashmir }) => {
  return (
    <div style={styles.mainContainer as React.CSSProperties}>
      <div>
        <Image src='assets/inProgress.svg' />
      </div>
      <div style={styles.containerDate as React.CSSProperties}>
        <p style={styles.textDate as React.CSSProperties}>{props.loan.loanDate}</p>
        <p style={styles.schoolText as React.CSSProperties}>{props.loan.contracNbr}</p>
      </div>
      <div>
        <p style={styles.inProgress as React.CSSProperties}>{props.loan.loanStatusName}</p>
        <p style={styles.amountCash as React.CSSProperties}>+{props.loan.payOutAmount} zł</p>
        <p style={styles.amountPayment as React.CSSProperties}>Opłata {props.loan.initialCost} zł</p>
      </div>
    </div>
  );
});
