import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as styles from './styles';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import ReactCodeInput from 'react-code-input';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { useStore } from '../../../store/store';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap/cjs';
import { DiscountCodeOrderDataM } from '../../../types/discountCode';
import FileHelper from '../../../helpers/FileHelper';
import useResendSmsTimer from '../../../hooks/useResendSmsTimer';

export default observer(() => {
  const { discountCodeStore, fileStore } = useStore();
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [discountCodeUserData, setDiscountCodeUserData] = useState<DiscountCodeOrderDataM | null>(null);
  const { id }: any = useParams();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { remainingSecondsSmsDisabled, setIsResendSmsDisabled, isResendSmsDisabled, enableResendSms } =
    useResendSmsTimer();

  useEffect(() => {
    discountCodeStore.getDiscountCodeOrderData(id).then((resp) => {
      if (resp != undefined) {
        setDiscountCodeUserData(resp);
      }
      setLoading(false);
    });
  }, []);
  const requestDiscountCodeAuthorizationToken = () => {
    if (isResendSmsDisabled) {
      toast.error(`Proszę poczekać ${remainingSecondsSmsDisabled} sekund na wysłanie kolejnego kodu.`);
      return;
    }
    setIsResendSmsDisabled(true);
    discountCodeStore
      .requestDiscountCodeOrderAuthorizationToken(id)
      .then(() => {
        toast.success('Wysłano kod sms ponownie.');
      })
      .catch(() => {
        toast.error('Wystąpił błąd.');
        enableResendSms();
      });
  };
  const handleChangePin = (e: any) => setPin(e);
  const verifyDiscountCodeOrderToken = () => {
    setIsSubmitting(true);
    discountCodeStore
      .verifyDiscountCodeOrderToken(id, pin)
      .then(() => {
        setIsSubmitting(false);
        history.push(PathRoute.DISCOUNT_CODE_ORDER_SUCCESS);
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err[0].error != undefined) {
          toast.error(err[0].error);
        } else if (err[0]) {
          toast.error(err);
        } else {
          toast.error(err);
        }
      });
  };
  const handleContractPdf = () => {
    setDownloading(true);
    if (!downloading) {
      fileStore
        .getDiscountCodeOrderPdf(id)
        .then((resp) => {
          const fileName = 'umowa_' + id + '.pdf';
          FileHelper.downloadFileFromBlob(resp, fileName);
          setDownloading(false);
        })
        .catch(() => {
          setDownloading(false);
          toast.error('Wystąpił błąd przy pobieraniu pliku');
        });
    }
  };
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3'>
            <div style={styles.tabsBox}>
              <div>
                <h1 style={{ color: '#fff', textAlign: 'center' } as React.CSSProperties}>E-bony</h1>
              </div>
              <div
                style={
                  {
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  } as React.CSSProperties
                }></div>
            </div>
            <div style={styles.containerBackground as React.CSSProperties}>
              <div style={styles.containerConfirm as React.CSSProperties}>
                <div style={styles.flexStart as React.CSSProperties}>
                  <p style={styles.confirmTitle as React.CSSProperties}>Podsumowanie</p>
                </div>
                <div style={styles.marginTop as React.CSSProperties}>
                  <p style={styles.textStyle as React.CSSProperties}>Kwota zamówienia:</p>
                  <div style={styles.containerAmountTax as React.CSSProperties}>
                    <p style={styles.textWeight as React.CSSProperties}>{discountCodeUserData?.amount}</p>
                    <p style={styles.marginLeftPLN as React.CSSProperties}>PLN</p>
                  </div>
                </div>
                <div style={styles.marginTop as React.CSSProperties}>
                  <p style={styles.textStyle as React.CSSProperties}>Liczba e-kodów:</p>
                  <div style={styles.containerAmountTax as React.CSSProperties}>
                    <p style={styles.textWeight as React.CSSProperties}>{discountCodeUserData?.quantity}</p>
                  </div>
                </div>
                <div style={styles.marginTop as React.CSSProperties}>
                  <p style={styles.textStyle as React.CSSProperties}>Data zamówienia:</p>
                  <div style={styles.containerAmountTax as React.CSSProperties}>
                    <p style={styles.textWeight as React.CSSProperties}>{discountCodeUserData?.orderDate}</p>
                  </div>
                </div>
                <div style={styles.marginTop as React.CSSProperties}>
                  <p style={styles.textStyle as React.CSSProperties}>Data spłaty:</p>
                  <div style={styles.containerAmountTax as React.CSSProperties}>
                    <p style={styles.textWeight as React.CSSProperties}>{discountCodeUserData?.paymentDate}</p>
                  </div>
                </div>
                <div style={styles.containerCenter as React.CSSProperties}>
                  <div style={styles.displayFlex as React.CSSProperties}>
                    <p style={styles.marginBottomLeft as React.CSSProperties}>
                      <a onClick={() => handleContractPdf()} style={styles.hrefLink as React.CSSProperties}>
                        Potwierdzenie zamówienia
                      </a>{' '}
                    </p>
                    <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
                  </div>
                </div>

                <div style={styles.flexWeightCenter as React.CSSProperties}>
                  <p>Podaj KOD SMS</p>
                </div>
              </div>
              <div style={styles.pinContainer as React.CSSProperties}>
                <ReactCodeInput
                  fields={6}
                  inputMode='numeric'
                  name='numeric'
                  onChange={(e) => handleChangePin(e)}
                  type='password'
                  value={pin}
                />
              </div>
              <div
                onClick={() => requestDiscountCodeAuthorizationToken()}
                style={styles.textSMS as React.CSSProperties}>
                <p>Wyślij ponownie SMS</p>
              </div>
              <div style={styles.flexCenter as React.CSSProperties}>
                {isSubmitting ? (
                  <Button disabled={true} style={styles.widthButton as React.CSSProperties}>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Potwierdzanie…
                  </Button>
                ) : (
                  <Button
                    className='btn-full-width'
                    onClick={() => verifyDiscountCodeOrderToken()}
                    style={styles.widthButton as React.CSSProperties}>
                    Potwierdź zamówienie
                  </Button>
                )}
              </div>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
