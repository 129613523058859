import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function ShieldIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='53.899' viewBox='0 0 45.246 53.899' width='45.246'>
      <defs>
        <clipPath id='clip-path_shdfshyyesgdf'>
          <rect data-name='Rectangle 403' fill='none' height='53.899' id='Rectangle_403' width='45.246' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path_shdfshyyesgdf)' data-name='Group 874' id='Group_874'>
        <path
          d='M45.134,8.1a.908.908,0,0,0-.792-.817C44.21,7.262,30.962,5.547,23.14.161a.909.909,0,0,0-1.031,0C14.286,5.547,1.037,7.262.9,7.278A.908.908,0,0,0,.112,8.1,57.317,57.317,0,0,0,1.759,25.54c2.18,8.793,7.49,20.829,20.412,28.238a.911.911,0,0,0,.9,0C36,46.369,41.308,34.333,43.487,25.54A57.282,57.282,0,0,0,45.134,8.1 M30.87,18.844l1.285,1.285-13.4,13.4-5.668-5.668,1.285-1.285,4.383,4.382Z'
          data-name='Path 3896'
          fill='currentColor'
          id='Path_3896'
          stroke='none'
          transform='translate(0 0)'
        />
      </g>
    </svg>
  );
}
