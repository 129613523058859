import * as React from 'react';
import * as styles from './styles';
import WalletNauStore from '../../../store/walletNauStore/walletNauStore';
import CashOut from '../CashOut/CashOut';

export function UserWalletFromCurrentMonth(walletNauStore: WalletNauStore): React.ReactNode {
  return (
    <div>
      <div style={styles.paddingTop as React.CSSProperties}>
        <p style={styles.lastMonth as React.CSSProperties}>Ostatni miesiąc</p>
        <p style={styles.colorWeight as React.CSSProperties}>
          {walletNauStore.userWalletDataFromCurrentMonth?.currentMonth}{' '}
          {walletNauStore.userWalletDataFromCurrentMonth?.currentYear}
        </p>
      </div>
      {walletNauStore.userWalletDataFromCurrentMonth?.walletApplication.map((wallet) => (
        <CashOut key={wallet.walletLoanCashmirId.toString()} wallet={wallet} />
      ))}
    </div>
  );
}
