import * as React from 'react';
import { Button, Image } from 'react-bootstrap';
import { history } from '../../../index';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../DiscountCodeHeader/DiscountCodeHeader';

const handleConfirm = async () => {
  history.push(PathRoute.PUSTY);
};
const RejectedDiscountCodeContractBody = () => (
  <div style={styles.flexColumnContainer as React.CSSProperties}>
    <Image src='assets/clock.svg' style={styles.heightTop as React.CSSProperties} />
    <p style={styles.selfStyles as React.CSSProperties}>
      Niestety nie możemy zawrzeć z Tobą umowy. Spróbuj ponownie później
    </p>
    <Button onClick={handleConfirm} style={styles.top as React.CSSProperties}>
      Strona główna
    </Button>
  </div>
);

export default RejectedDiscountCodeContractBody;
