import { observer } from 'mobx-react-lite';
import React from 'react';
import * as styles from './styles';
import CinemaVoucherHeader from '../CinemaVoucherHeader/CinemaVoucherHeader';
import { Button } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';

export default observer(() => {
  return (
    <div>
      <div className='col-md-6 offset-md-3' style={styles.container as React.CSSProperties}>
        <CinemaVoucherHeader />
        <div style={styles.flexColumnPadding}>
          <div style={styles.width70}>
            <div style={styles.infoSection}>
              Nie jesteś upoważniony do skorzystania z kuponów. <br />
              Zadzwoń do Biura Obsługi Klienta pod nr. tel 22 696 72 70, by dowiedzieć się więcej.
            </div>
          </div>
        </div>
        <div style={styles.flexPadding as React.CSSProperties}>
          <Button
            onClick={() => window.open(PathRoute.PULPIT, '_self', 'location=no')}
            style={styles.width90 as React.CSSProperties}>
            Wróć
          </Button>
        </div>
      </div>
    </div>
  );
});
