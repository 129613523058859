export const container = {
  backgroundColor: 'white',
  borderRadius: '36px',
  color: 'black',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
};

export const containerSecond = {
  height: '80px',
  width: '100%',
  borderRadius: '36px 36px 0px 0px',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
  display: 'flex',
  justifyContent: 'center',
};

export const Loan = {
  marginBottom: 0,
  alignSelf: 'center',
  color: '#fff',
  fontSize: '32px',
  fontFamily: 'Poppins',
  lineHeight: '42px',
};

export const autoInfo = {
  height: 'auto',
  width: '100%',
  borderRadius: '10px',
  background: '#f5f5f5',
  margin: '0 auto',
  marginTop: '10px',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
};

export const flexGreen = {
  alignSelf: 'flex-start',
  marginRight: '10px',
  left: '17%',
};

export const textInfo = {
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '500',
  marginBottom: '0',
  textAlign: 'center',
};

export const textInfoEvidence = {
  fontSize: '12px',
  color: '#626D80',
  fontWeight: '400',
  marginBottom: '0',
  textAlign: 'left',
  paddingLeft: '25px',
};

export const containerPhoto = {
  backgroundImage: `url(./../assets/documentsPhoto.svg)`,
  height: '230px',
  position: 'relative',
};

export const containerRelative = {
  height: '100%',
  background: 'rgb(0,83,78,0.3)',
  position: 'relative',
};

export const textStyles = {
  fontSize: '24px',
  fontWeight: '600',
  width: '220px',
  color: '#fff',
  top: '60%',
  left: '5%',
  lineHeight: '1.2',
  position: 'absolute',
};

export const widthLineHeight = {
  padding: '15px',
  width: '90%',
  lineHeight: '1.2',
};

export const monthText = {
  color: '#00635C',
  fontSize: '16px',
  marginBottom: 0,
  fontWeight: '400',
};

export const paddingSize = {
  padding: '15px',
  fontSize: '24px',
  lineHeight: '1.3',
};

export const bottomWeight = {
  color: '#00635C',
  fontWeight: '600',
  marginBottom: 0,
};

export const paddingWidthDiv = {
  padding: '15px',
  paddingTop: '0',
  width: '100%',
  lineHeight: '1.2',
};

export const colorText = {
  color: '#00635C',
  fontSize: '16px',
  fontWeight: '400',
};

export const bottomStylesText = {
  color: '#00635C',
  fontSize: '16px',
  marginBottom: 0,
  fontWeight: '400',
};

export const flexContainer = {
  display: 'flex',
  marginBottom: '10px',
  position: 'relative',
};

export const documentText = {
  marginBottom: 0,
  alignSelf: 'center',
  position: 'absolute',
  left: '20%',
  color: '#00635C',
  fontWeight: '600',
};

export const flexContainerBottom = {
  display: 'flex',
  marginBottom: '20px',
  position: 'relative',
};

export const textCenter = {
  marginBottom: 0,
  alignSelf: 'center',
  position: 'absolute',
  left: '10%',
  color: '#0BC268',
  fontWeight: '600',
  fontSize: '16px',
};

export const columnFlex = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px',
  flexDirection: 'column',
};

export const bottomSize = { marginBottom: '20px', fontSize: '16px' };

export const fontSize = { fontSize: '16px' };
