import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useStore } from '../../../store/store';
import { LoanBenefitManagment } from '../../../types/user';
import * as styles from './styles';
import BlockBenefit from './BlockBenefit';
import LoadingComponentModal from '../../Loadings/LoadingComponentModal/LoadingComponentModal';
import UserLoanBenefitPaymentDate from './UserLoanBenefitPaymentDate';
import { employmentTypeArray } from '../../../helpers/EmploymentTypeSelect';
import UnlockBenefit from './UnlockBenefit';
import EditUserLoanSalary from './EditUserLoanSalary';

export default observer((props: { userId: number; benefitId: number; institutionId: number }) => {
  const { loansStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [loanBenefitInfo, setloanBenefitInfo] = useState<LoanBenefitManagment | null>(null);

  useEffect(() => {
    loansStore
      .getLoanBenefitManagmentData(props.userId, props.benefitId, props.institutionId)
      .then((data) => {
        setloanBenefitInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych');
      });
  }, []);

  const handleClose = () => modalStore.closeModal();

  const employmentType = () => {
    if (loanBenefitInfo == null) {
      return 'BRAK';
    }

    const type = employmentTypeArray.find((element) => {
      return element.value === loanBenefitInfo.loanContractEmploymentData.employmentType;
    });

    if (type == undefined) {
      return 'BRAK';
    }

    return type.label;
  };

  return (
    <div style={styles.mainContainer as React.CSSProperties}>
      {loading ? (
        <LoadingComponentModal content='Ładowanie...' roundAllCorners={false} />
      ) : (
        <div>
          {loanBenefitInfo ? (
            <>
              <div>
                <h5>Limit: {loanBenefitInfo.limit}</h5>
                <h5>Wypłata: {loanBenefitInfo.salary}</h5>
              </div>

              <div style={{ marginTop: '15px' }}>
                <Button
                  className='btn-mini col-12 mt-1'
                  onClick={() =>
                    modalStore.openModal(
                      <UserLoanBenefitPaymentDate institutionId={props.institutionId} userId={props.userId} />,
                      'lg',
                      'Ustaw datę wypłaty wynagrodzenia dla użytkownika',
                    )
                  }
                  style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                  Ustaw datę wypłaty wynagrodzenia
                </Button>
                <Button
                  className='btn-mini col-12 mt-1'
                  onClick={() =>
                    modalStore.openModal(
                      <EditUserLoanSalary salary={loanBenefitInfo.salary} userId={props.userId} />,
                      'lg',
                      'Edytuj wysokość wynagrodzenia',
                    )
                  }
                  style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                  Edytuj wysokość wynagrodzenia
                </Button>
                {loanBenefitInfo.isActive ? (
                  <Button
                    className='btn-mini col-12 mt-1'
                    onClick={() =>
                      modalStore.openModal(<BlockBenefit userId={props.userId} />, 'lg', 'Zablokuj benefit')
                    }
                    style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                    Zablokuj benefit
                  </Button>
                ) : (
                  <Button
                    className='btn-mini col-12 mt-1'
                    onClick={() =>
                      modalStore.openModal(<UnlockBenefit userId={props.userId} />, 'lg', 'Odblokuj benefit')
                    }
                    style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                    Odblokuj benefit
                  </Button>
                )}

                <div className='mt-3' style={{ fontSize: '13px' }}>
                  <Row className='mb-2'>
                    <Col xs={6}>Telefon komórkowy</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractUserData.phone}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Seria i numer dowodu osobistego</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractUserData.documentNumber}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Data ważności dowodu osobistego</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractUserData.documentExpirationDate}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Nr rachunku bankowego</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractUserData.bankAccountNumber}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Rodzaj zatrudnienia</Col>
                    <Col xs={6}>{employmentType()}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Data zatrudnienia</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractEmploymentData.employmentDate}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col xs={6}>Data zakończenia umowy</Col>
                    <Col xs={6}>{loanBenefitInfo.loanContractEmploymentData.contractEndDate}</Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <h4> Błąd pobierania danych </h4>
          )}

          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              justifyContent: 'right',
            }}>
            <Button onClick={handleClose} variant='secondary'>
              Anuluj
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
