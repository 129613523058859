import * as React from 'react';
import { Image } from 'react-bootstrap';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import * as styles from './styles';

const ReckoningLoan = () => (
  <CustomerDashboard>
    <div style={styles.containerBackground as React.CSSProperties}>
      <div style={styles.containerFirstSlider as React.CSSProperties}>
        <p style={styles.Loan as React.CSSProperties}>Wypłata od ręki</p>
      </div>
      <div className='row col-12'>
        <Image
          src='assets\calendar.svg'
          style={{
            marginBottom: '40px',
            marginTop: '40px',
            height: '200px',
            textAlign: 'center',
          }}
        />
      </div>
      <div className='row col-12'>
        <h1 style={{ textAlign: 'center', color: '#0bc268' }}>Trwa okres rozliczeniowy.</h1>
      </div>
      <div className='row col-md-8 offset-md-2'>
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <p style={styles.lastMonth as React.CSSProperties}>
            <span style={{}}>
              Gdy pracodawca ureguluje należności za poprzedni miesiąc, przywrócimy Ci możliwość wypłaty środków w
              ramach przyznanego limitu. Prosimy o cierpliwość.
            </span>
          </p>
        </div>
      </div>
    </div>
  </CustomerDashboard>
);

export default ReckoningLoan;
