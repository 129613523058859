import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { ErrorMessage, Form, Formik } from 'formik';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { initialValues, validationSchema } from './index';
import { LoanContractUserData } from '../../../types/loans';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { User } from '../../../types/user';

export default observer(() => {
  const { loansStore, userProfileStore, userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<LoanContractUserData>(initialValues);
  const { idParam }: any = useParams();
  const [id, setId] = useState<number>(idParam);

  const user: User | undefined = userStore.getUserData();
  const [activePhonesSelectInput, setActivePhonesSelectInput] = useState<MySelectInputOptions[]>([]);

  useEffect(() => {
    userProfileStore
      .getUserProfilePhones()
      .then(() => {
        prepareActivePhonesSelectInput();
        userProfileStore
          .getUserProfile()
          .then(() => {
            prepareContractData();
          })
          .catch(() => {
            prepareContractData();
          });
      })
      .catch(() => {
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu numerów telefonów');
      });
  }, []);

  const prepareActivePhonesSelectInput = () => {
    if (userProfileStore && userProfileStore.userProfilePhones) {
      const activePhonesSelectInput: MySelectInputOptions[] = [];

      userProfileStore.userProfilePhones.forEach((item) => {
        if (item.isActive && item) {
          activePhonesSelectInput.push({
            value: item.phone,
            label: item.phone,
          });
        }
      });

      setActivePhonesSelectInput(activePhonesSelectInput);
    }
  };

  const prepareContractData = () => {
    if (idParam == 0 || idParam == undefined || idParam == null) {
      if (loansStore.availableBenefitId != null) {
        loansStore.getUserLoanContractId(loansStore.availableBenefitId).then((resp) => {
          // @ts-ignore
          setId(resp);
          // @ts-ignore
          getContractData(resp);
        });
      }
    } else if (id) {
      getContractData(id);
    }
  };

  const getContractData = (id: number) => {
    loansStore
      .getLoanContractUserData(id)
      .then((resp) => {
        setLoading(false);

        let respContract: LoanContractUserData;

        // @ts-ignore
        if (resp == '') {
          respContract = {
            id: 0,
            firstName: userProfileStore.userProfile ? userProfileStore.userProfile.firstName : '',
            lastName: userProfileStore.userProfile ? userProfileStore.userProfile.lastName : '',
            bankAccountNumber: '',
            phone: '',
            email: user ? user.username : '',
            pesel: userProfileStore.userProfile ? userProfileStore.userProfile.pesel : '',
            documentNumber: '',
            documentExpirationDate: '',
          };
        } else {
          respContract = {
            id: resp ? resp.id : 0,
            firstName: resp
              ? resp.firstName
              : userProfileStore.userProfile
              ? userProfileStore.userProfile.firstName
              : '',
            lastName: resp ? resp.lastName : userProfileStore.userProfile ? userProfileStore.userProfile.lastName : '',
            bankAccountNumber: resp ? resp.bankAccountNumber : '',
            phone: resp ? resp.phone : '',
            email: resp ? resp.email : user ? user.username : '',
            pesel:
              resp != null && resp.pesel != null && resp.pesel != '' && resp.pesel.length == 11
                ? resp.pesel
                : userProfileStore.userProfile && userProfileStore.userProfile.pesel
                ? userProfileStore.userProfile.pesel
                : '',
            documentNumber: resp ? resp.documentNumber : '',
            documentExpirationDate: resp ? resp.documentExpirationDate : '',
          };
        }

        setContract(respContract);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3'>
            <div className='container-fluid' id='Loans'>
              <div className='d-flex justify-content-center align-items-center'>
                <div style={styles.goBackLink}>
                  <Link to={PathRoute.NEW_BENEFITS}>
                    <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                  </Link>
                </div>
                <div style={styles.header}>
                  <h2 style={styles.headingText}>Wypłata od ręki</h2>
                  <p style={styles.subheadingText}>Dane klienta</p>
                </div>
              </div>
              <p style={styles.paragraphText}>Wprowadź swoje dane, niezbędne do zawarcia umowy ramowej</p>
              <br />
              <p style={styles.formTitle}>Dane osobiste</p>
              <Formik
                enableReinitialize
                initialValues={{
                  id: id,
                  firstName: contract ? contract.firstName : '',
                  lastName: contract ? contract.lastName : '',
                  bankAccountNumber: contract ? contract.bankAccountNumber : '',
                  phone: contract ? contract.phone : '',
                  email: contract ? contract.email : '',
                  pesel: contract ? contract.pesel : '',
                  documentNumber: contract ? contract.documentNumber : '',
                  documentExpirationDate: contract ? contract.documentExpirationDate : '',
                  error: null,
                }}
                onSubmit={(
                  {
                    id,
                    firstName,
                    lastName,
                    pesel,
                    phone,
                    email,
                    documentNumber,
                    documentExpirationDate,
                    bankAccountNumber,
                  },
                  { setErrors, setFieldError, setSubmitting },
                ) =>
                  loansStore
                    .saveLoanContractUserData(
                      id,
                      loansStore.availableBenefitId,
                      firstName,
                      lastName,
                      pesel,
                      phone,
                      email,
                      documentNumber,
                      documentExpirationDate,
                      bankAccountNumber,
                    )
                    .then((resp) => {
                      history.push(PathRoute.ADDRESS_CONTRACT_LOAN + '/' + resp);
                    })
                    .catch((error) => {
                      // @ts-ignore
                      if (Array.isArray(error)) {
                        error.forEach((element) => {
                          const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                          // @ts-ignore
                          setFieldError(fieldName, element.message);
                          //setFieldTouched(fieldName);
                          setSubmitting(false);
                        });
                      } else {
                        setErrors({ error: error });
                        setSubmitting(false);
                      }
                    })
                }
                validationSchema={validationSchema}>
                {({ handleSubmit, errors, values }) => (
                  <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                    <MyTextInput label={'Imię'} name={'firstName'} style={styles.marginBottomField} />
                    <MyTextInput label='Nazwisko' name={`lastName`} style={styles.marginBottomField} />
                    <MyTextMaskedInput
                      disabled={contract != null && contract.pesel != '' && contract.pesel.length == 11}
                      label='Numer PESEL'
                      mask={MaskHelpers.pesel}
                      name={`pesel`}
                      style={styles.marginBottomField}
                    />

                    <MySelectInput
                      className='colorBlack marginBottom2px'
                      label='Telefon komórkowy'
                      name={`phone`}
                      options={activePhonesSelectInput}
                      placeholder={null}
                      value={values.phone}
                    />

                    <MyTextInput label='Adres e-mail' name={`email`} style={styles.marginBottomField} type='hidden' />
                    <MyTextInput
                      label='Seria i numer dowodu osobistego'
                      name={'documentNumber'}
                      style={styles.marginBottomField}
                    />
                    <MyTextMaskedInput
                      label='Data ważności dowodu osobistego'
                      mask={MaskHelpers.fullDate}
                      name={`documentExpirationDate`}
                      placeholder='rrrr-MM-dd'
                      style={styles.marginBottomField}
                    />
                    <MyTextMaskedInput
                      label='Nr rachunku bankowego'
                      mask={MaskHelpers.bankAccountNumber}
                      name={`bankAccountNumber`}
                      style={styles.marginBottomField}
                    />
                    <ErrorMessage name='error' render={() => <label>{errors.error}</label>} />
                    <div
                      style={{
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'right',
                      }}>
                      <Button className='btn-full-width' type='submit' variant='primary'>
                        Dalej
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
