export default class RecordStatusHelper {
  static RoleTypesDisplay: { [index: number]: string } = {};

  static getRecordStatus = (status: number) => {
    return RecordStatusHelper.RoleTypesDisplay[status];
  };
}

export enum RecordStatus {
  Active = 1,
  NotActive = 2,
  Blocked = 3,
  Deleted = 4,
}

RecordStatusHelper.RoleTypesDisplay[RecordStatus.Active] = 'Aktywny';
RecordStatusHelper.RoleTypesDisplay[RecordStatus.NotActive] = 'Nieaktywny';
RecordStatusHelper.RoleTypesDisplay[RecordStatus.Blocked] = 'Zablokowany';
RecordStatusHelper.RoleTypesDisplay[RecordStatus.Deleted] = 'Skasowany';
