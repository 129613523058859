import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { ErrorMessage, Form, Formik, FormikErrors, useFormikContext } from 'formik';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import {
  EmploymentType,
  employmentTypeArray,
  employmentTypeInstitutionalArray,
} from '../../../helpers/EmploymentTypeSelect';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { initialValues, validationSchema } from './index';
import { LoanContractEmploymentData } from '../../../types/loans';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Link, useParams } from 'react-router-dom';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';

export default observer(() => {
  const { loansStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<LoanContractEmploymentData>(initialValues);
  const [availableBenefitIsInstitutional, setAvailableBenefitIsInstitutional] = useState<boolean | null>(
    loansStore.availableBenefitIsInstitutional,
  );

  const { id }: any = useParams();

  useEffect(() => {
    if (id == 0 || id == undefined || id == null) {
      if (loansStore.availableBenefitId != null) {
        loansStore.getUserLoanContractId(loansStore.availableBenefitId).then((resp) => {
          // @ts-ignore
          getContractData(resp).then(() => checkBenefitIsInstitutionalByContractId(resp));
        });
      }
    } else {
      getContractData(id).then(() => checkBenefitIsInstitutionalByContractId(id));
    }
  }, []);

  const getContractData = async (id: number) => {
    loansStore
      .getLoanContractEmploymentData(id)
      .then((resp) => {
        const respContract = {
          id: resp ? resp.id : id,
          employmentType: resp ? resp.employmentType : 0,
          employmentDate: resp ? resp.employmentDate : '',
          contractStartDate: resp ? resp.contractStartDate : '',
          contractEndDate: resp ? resp.contractEndDate : '',
          monthlyNetIncome: resp ? resp.monthlyNetIncome : '',
          companyName: resp ? resp.companyName : '',
          profession: resp ? resp.profession : '',
        };
        setContract(respContract);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const checkBenefitIsInstitutionalByContractId = (id: number) => {
    loansStore
      .checkBenefitIsInstitutionalByContractId(id)
      .then((resp) => {
        setAvailableBenefitIsInstitutional(resp);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3'>
            <div className='container-fluid' id='Loans'>
              <div className='d-flex justify-content-center align-items-center'>
                <div style={styles.goBackLink}>
                  <Link to={PathRoute.ADDRESS_CONTRACT_LOAN + '/' + id}>
                    <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                  </Link>
                </div>
                <div style={styles.header}>
                  <h2 style={styles.headingText}>Wypłata od ręki</h2>
                  <p style={styles.subheadingText}>Dane klienta</p>
                </div>
              </div>
              <p style={styles.paragraphText}>Wprowadź swoje dane, niezbędne do zawarcia umowy ramowej</p>
              <br />
              <p style={styles.formTitle}>Zatrudnienie</p>
              <Formik
                enableReinitialize
                initialValues={{
                  id: id,
                  employmentType: contract ? contract.employmentType : 0,
                  employmentDate: contract ? contract.employmentDate : '',
                  contractEndDate: contract ? contract.contractEndDate : '',
                  //contractStartDate: contract ? contract.contractStartDate : '',
                  monthlyNetIncome: contract ? contract.monthlyNetIncome : '',
                  companyName: contract ? contract.companyName : '',
                  //profession: contract ? contract.profession : '',
                  availableBenefitIsInstitutional: availableBenefitIsInstitutional ?? false,
                  error: null,
                }}
                onSubmit={(
                  {
                    id,
                    employmentType,
                    employmentDate,
                    //contractStartDate,
                    contractEndDate,
                    monthlyNetIncome,
                    companyName,
                    availableBenefitIsInstitutional,
                    //profession
                  },
                  { setErrors, setFieldError, setSubmitting },
                ) =>
                  loansStore
                    .saveLoanContractEmploymentData(
                      id,
                      employmentType,
                      employmentDate,
                      '',
                      contractEndDate,
                      monthlyNetIncome,
                      companyName,
                      '',
                      availableBenefitIsInstitutional,
                    )
                    .then(() => {
                      history.push(PathRoute.VERIFY_LOAN + '/' + id);
                    })
                    .catch((error) => {
                      console.log(error);
                      // @ts-ignore
                      if (Array.isArray(error)) {
                        error.forEach((element) => {
                          const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                          // @ts-ignore
                          setFieldError(fieldName, element.message);
                          //setFieldTouched(fieldName);
                          setSubmitting(false);
                        });
                      } else {
                        setErrors({ error: error });
                        setSubmitting(false);
                      }
                    })
                }
                validationSchema={validationSchema}>
                {({ handleSubmit, errors, values }) => (
                  <FormBody
                    availableBenefitIsInstitutional={availableBenefitIsInstitutional}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    values={values}
                  />
                )}
              </Formik>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});

interface FormBodyProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement> | undefined) => void;
  errors: FormikErrors<{
    id: any;
    employmentType: number;
    employmentDate: string;
    contractEndDate: string;
    //contractStartDate: string;
    monthlyNetIncome: string;
    companyName: string;
    //profession: string;
    error: null;
  }>;
  values: {
    id: any;
    employmentType: number;
    employmentDate: string;
    contractEndDate: string;
    //contractStartDate: string;
    monthlyNetIncome: string;
    companyName: string;
    //profession: string;
    error: null;
  };
  availableBenefitIsInstitutional: boolean | null;
}

function FormBody(props: FormBodyProps) {
  const formikProps = useFormikContext();

  const [contractEndDateInputType, setContractEndDateInputType] = useState<string>('');
  const [contractEndDateInputInitialValue, setContractEndDateInputInitialValue] = useState<string>('');
  const [employmentTypeLastValue, setEmploymentTypeLastValue] = useState<number>(0);

  const employmentTypeOnChange = (value: number) => {
    if (employmentTypeLastValue != value) {
      if (value == EmploymentType.EmploymentContractIndefinitePeriod) {
        setContractEndDateInputType('hidden');
        formikProps.setFieldValue('contractEndDate', '');
      } else if (employmentTypeLastValue == EmploymentType.EmploymentContractIndefinitePeriod) {
        setContractEndDateInputType('');
        formikProps.setFieldValue(
          'contractEndDate',
          contractEndDateInputInitialValue ? contractEndDateInputInitialValue : '',
        );
      }
    }
    setEmploymentTypeLastValue(value);
  };

  useEffect(() => {
    setContractEndDateInputInitialValue(props.values.contractEndDate);
    setEmploymentTypeLastValue(props.values.employmentType);
    employmentTypeOnChange(props.values.employmentType);
  }, []);

  return (
    <Form autoComplete='off' className='ui form' onSubmit={props.handleSubmit}>
      <MySelectInput
        label='Rodzaj zatrudnienia'
        name={`employmentType`}
        onChange={employmentTypeOnChange}
        options={props.availableBenefitIsInstitutional ? employmentTypeInstitutionalArray : employmentTypeArray}
        placeholder='Rodzaj zatrudnienia'
        value={props.values.employmentType}
      />
      <MyTextMaskedInput
        label='Data zatrudnienia'
        mask={MaskHelpers.fullDate}
        name={`employmentDate`}
        placeholder='rrrr-MM-dd'
        style={styles.marginBottomField}
      />
      {/* <MyTextMaskedInput
        name={`contractStartDate`}
        mask={MaskHelpers.fullDate}
        label="Data rozpoczęcia umowy"
        placeholder="rrrr-MM-dd"
        style={styles.marginBottomField}
      /> */}
      <MyTextMaskedInput
        label='Data zakończenia umowy'
        mask={MaskHelpers.fullDate}
        name={`contractEndDate`}
        placeholder='rrrr-MM-dd'
        style={styles.marginBottomField}
        type={contractEndDateInputType}
      />
      {!props.availableBenefitIsInstitutional && (
        <div>
          <MyTextInput
            label={'Dochód miesięczny netto'}
            name={'monthlyNetIncome'}
            style={styles.marginBottomField}
            type={'text'}
          />
          <MyTextInput label='Nazwa pracodawcy' name={`companyName`} style={styles.marginBottomField} />
        </div>
      )}
      {/* <MyTextInput
        name={`profession`}
        label="Grupa zawodowa"
        style={styles.marginBottomField}
      /> */}
      <ErrorMessage name='error' render={() => <label>{props.errors.error}</label>} />
      <div
        style={{
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'right',
        }}>
        <Button className='btn-full-width' type='submit' variant='primary'>
          Dalej
        </Button>
      </div>
    </Form>
  );
}
