import { Spinner } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import { LoadingComponentProps } from './types';
import React from 'react';

export default function LoadingComponentCustom({
  content = 'Ładowanie...',
  visible = true,
  variant = 'dark',
}: LoadingComponentProps) {
  return (
    <Fade in={visible} unmountOnExit={true}>
      <div
        className='d-flex justify-content-center flex-column align-items-center'
        style={
          {
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            background: 'rgba(255,255,255, 0.5)',
            zIndex: 9999,
          } as React.CSSProperties
        }>
        <div>
          <Spinner animation='grow' role='status' variant={variant}></Spinner>
        </div>
        <span style={{ color: '#000', opacity: '0.8', paddingTop: '10px' } as React.CSSProperties}>{content}</span>
      </div>
    </Fade>
  );
}
