import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function PdfIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='50.132' viewBox='0 0 42.561 50.132' width='42.561'>
      <defs></defs>
      <g transform='translate(-479.219 -649)'>
        <path
          d='M264.612,243.866H260.98v-1.982h3.632a1.567,1.567,0,0,0,1.566-1.566v-3.633h1.982v3.633A3.551,3.551,0,0,1,264.612,243.866Zm28.114-8.243V273.13a3.552,3.552,0,0,1-3.547,3.548H259.906a3.552,3.552,0,0,1-3.548-3.548V240.565a3.522,3.522,0,0,1,1.04-2.509l4.944-4.942a3.524,3.524,0,0,1,2.508-1.039h24.329A3.552,3.552,0,0,1,292.726,235.623Zm-1.982,0a1.568,1.568,0,0,0-1.565-1.566H264.85a1.555,1.555,0,0,0-1.107.459l-4.944,4.942a1.555,1.555,0,0,0-.459,1.107V273.13a1.568,1.568,0,0,0,1.566,1.566h29.273a1.568,1.568,0,0,0,1.565-1.566Zm4.627-9.077H271.043a3.522,3.522,0,0,0-2.508,1.039l-2.5,2.5,1.4,1.4,2.5-2.495a1.555,1.555,0,0,1,1.107-.459h24.328a1.568,1.568,0,0,1,1.566,1.566V267.6a1.568,1.568,0,0,1-1.566,1.566H294.3v1.982h1.066a3.552,3.552,0,0,0,3.548-3.548V230.094A3.552,3.552,0,0,0,295.371,226.546ZM266.28,252.223v0Z'
          fill={'currentColor'}
          transform='translate(222.861 422.454)'
        />
        <g transform='translate(487.256 672.818)'>
          <path
            d='M471.478,15268.426v3.021h-2.084v-8.615h3.376a3.28,3.28,0,0,1,2.339.762,2.7,2.7,0,0,1,.8,2.054,2.835,2.835,0,0,1-.36,1.433,2.491,2.491,0,0,1-1.066.986,3.749,3.749,0,0,1-1.718.36Zm2.315-2.779q0-1.134-1.255-1.133h-1.06v2.241h1.06Q473.793,15266.755,473.793,15265.646Z'
            fill={'currentColor'}
            transform='translate(-469.394 -15262.831)'
          />
          <path
            d='M499.342,15269.369a3.8,3.8,0,0,1-1.56,1.529,4.979,4.979,0,0,1-2.394.548h-3.254v-8.615h3.254a5.057,5.057,0,0,1,2.4.537,3.739,3.739,0,0,1,1.554,1.511,4.921,4.921,0,0,1,0,4.49Zm-2.242-.432a2.776,2.776,0,0,0,0-3.632,2.635,2.635,0,0,0-1.9-.646h-.987v4.922h.987A2.638,2.638,0,0,0,497.1,15268.938Z'
            fill={'currentColor'}
            transform='translate(-484.592 -15262.831)'
          />
          <path
            d='M524.681,15262.831v1.658h-3.534v1.889h2.73v1.584h-2.73v3.484h-2.084v-8.615Z'
            fill={'currentColor'}
            transform='translate(-502.589 -15262.831)'
          />
        </g>
      </g>
    </svg>
  );
}
