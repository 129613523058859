import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';

export default observer(() => {
  return (
    <div>
      <p style={styles.headingTextStyle}>Biedronka</p>
      <p style={styles.textStyle}>
        • ważność: bezterminowa
        <br />• wykorzystanie: sklepy stacjonarne
      </p>

      <p style={styles.headingTextStyle}>Rossmann</p>
      <p style={styles.textStyle}>• wykorzystanie: sklepy stacjonarne</p>

      <p style={styles.headingTextStyle}>Jak skorzystać z E-bonów? To proste:</p>
      <p style={styles.textStyle}>
        1. E-bon ma postać cyfr. Numer znajdziesz na górze strony.
        <br />
        2. Podczas zakupów w:
        <br />
        • Biedronce - podaj numer E-bonu kasjerowi lub wpisz go bezpośrednio do terminala w opcji E-kody
        <br />
        • Rossmannie - podaj numer E-bonu kasjerowi lub wpisz go ręcznie w kasie samoobsługowej, a następnie potwierdź
        transakcję PIN-em
        <br />
        3. Gotowe - zakupy zostały opłacone.
      </p>
      <div className={'px-lg-4'}>
        <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.NAU_CLUB)}>
          Sprawdź pozostałe benefity
        </Button>
      </div>
    </div>
  );
});
