import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import CinemaVoucherHeader from '../../components/CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';
import { Button } from 'react-bootstrap';
import { PathRoute } from '../../constants/pathRoute/Route';

export const CinemaVoucherPromoLinkExpiredPage = () => {
  return (
    <>
      <header
        className='navbar fixed-top navbar-expand-lg'
        style={
          {
            width: '100vw',
            height: '75px',
            background: 'rgb(255, 255, 255)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            border: 'none',
            position: 'fixed',
            top: '0px',
            right: '0px',
            zIndex: '50',
          } as React.CSSProperties
        }>
        <div className='d-flex align-items-center'>
          <img
            alt='logo'
            src='/assets/logo.svg'
            className='logo cursor-pointer'
            style={
              {
                width: 'auto',
                height: '35px',
                background: 'none',
                padding: '0px',
                border: 'none',
              } as React.CSSProperties
            }
          />
        </div>
      </header>
      <div
        style={
          {
            backgroundColor: 'rgb(236, 236, 236)',
            minHeight: 'calc(-5em + 100vh)',
            paddingTop: '15px',
          } as React.CSSProperties
        }>
        <div className={'tw-mx-auto tw-max-w-2xl '}>
          <CinemaVoucherHeader />
          <div className={'tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6'}>
            <div className={'tw-text-center  tw-text-nau-sea-green'}>
              <div className={'tw-text-xl tw-font-semibold'}>Link nieaktywny.</div>
              <div className={'tw-mt-8 tw-text-xl tw-font-semibold'}>Niestety ten link stracił ważność.</div>
              <div className={'tw-mt-8 tw-text-xl tw-font-semibold'}>W przypadku pytań prosimy o kontakt:</div>
              <div className={'tw-mt-8 tw-text-lg'}>
                • Numer telefonu – <span className={'tw-font-semibold'}>22 696 72 70</span>
              </div>
              <div className={'tw-text-lg'}>
                • Adres e-mail – <span className={'tw-font-semibold'}>bok@nau.pl</span>
              </div>
              <Button
                onClick={() => window.open(PathRoute.PUSTY, '_self', 'location=no')}
                className='btn btn-full-width mt-3'>
                Powrót
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
