import { observer } from 'mobx-react-lite';
import './Slider.css';

interface SliderProps {
  value: number;
  onChange: (e: any) => void;
  max: number;
  min?: number;
}

export default observer(({ value, onChange, max, min }: SliderProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
      <input
        className='slider'
        id='myRange'
        max={max}
        min={min ?? '0'}
        name='volume'
        onChange={(e) => onChange(e)}
        step={5}
        style={{ width: '90%' }}
        type='range'
        value={value}
      />
    </div>
  );
});
