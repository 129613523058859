import { observer } from 'mobx-react-lite';
import React from 'react';
import { useSwrAgent } from '../../api/useSwrAgent';
import { GenericCarousel } from '../../components/Carousel/GenericCarousel';
import { SportCardBox } from './SportCardBox';
import { SportCardStatus } from '../../enums/sportCard.enums';

export default observer(() => {
  const { data: userSportCards, isLoading, mutate } = useSwrAgent().SportCards.GetSportCardsForUser();

  const activeCards = userSportCards?.filter((el) => el.sportCardStatus === SportCardStatus.Active);

  return (
    <div>
      <div className={'tw-mb-6 tw-mt-2 tw-flex tw-justify-center tw-text-2xl tw-font-semibold tw-text-nau-green-dark'}>
        <div>Twoje karty</div>
      </div>

      {activeCards && activeCards.length > 0 ? (
        <GenericCarousel
          items={activeCards}
          renderItem={(item) => (
            <div className={'bg-white '}>
              <SportCardBox onSave={mutate} sportCard={item} />
            </div>
          )}
        />
      ) : (
        <div className={'tw-text-md tw-mt-4 tw-flex tw-justify-center tw-text-nau-gray'}>
          Nie posiadasz aktywnych kart sportowych
        </div>
      )}
    </div>
  );
});
