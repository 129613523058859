import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { PathRoute } from '../../../constants/pathRoute/Route';
import ButtonOpenLinkNewTab from '../../../components/Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';
import { useStore } from '../../../store/store';
import { UserActivityCodeE } from '../../../enums/UserActivityCode.enums';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';

const StartLoanNau = () => {
  const { userStore } = useStore();

  return (
    <CustomerDashboard>
      <div className='col-md-6 offset-md-3' style={styles.container}>
        <SubpageHeader iconHeight={40} iconSrc={'/assets/13.svg'} title={'Pożyczka NAU'} />
        <div style={styles.flexCenter as React.CSSProperties}>
          <div>
            <p style={styles.headingTextStyle}>Pożyczka NAU to środki na jednorazowe, większe wydatki.</p>
            <p style={styles.textStyle}>
              ● kwota pożyczki – do 15 000 zł
              <br />● okres spłaty - do 30 miesięcy
              <br />● gwarancja stałej raty
              <br />● oprocentowanie 14,99%
              <br />● prowizja 18%
              <br />● RRSO 30,57%*
              <br />● proces online – szybko, sprawnie i wygodnie
            </p>
            <p
              style={{
                ...styles.headingTextStyle,
                paddingTop: 20,
              }}>
              Jak skorzystać z Pożyczki NAU?
            </p>
            <div style={styles.textStyle}>
              1. Kliknij „Złóż wniosek”
              <br />
              2. Uzupełnij swoje dane
              <br />
              3. Oczekuj na decyzję kredytową
              <br />
              4. Podpisz umowę i korzystaj ze środków
            </div>
            <div className={'px-lg-4'}>
              <div onClick={() => userStore.registerUserActivity(UserActivityCodeE.OpenLoanNauLink)}>
                <ButtonOpenLinkNewTab buttonText={'Złóż wniosek'} link={PathRoute.LOAN_NAU_EXTERNAL_LINK} />
              </div>
            </div>
            <div className={'tw-text-xs tw-italic tw-font-light tw-text-[#00635C] tw-mt-2.5 tw-leading-normal'}>
              * Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty pożyczek gotówkowych
              wynosi 30,57 %. Okres obowiązywania umowy: 30 miesiące, równe raty miesięczne w wysokości 461,92 zł i
              ostatnia rata w wysokości 461,94 zł, całkowita kwota pożyczki 10 000,00 zł; oprocentowanie stałe: 14,99 %;
              całkowity koszt zobowiązania: 3 857,62 zł (w tym prowizja: 1 800,00 zł, odsetki: 2 057,62 zł); całkowita
              kwota do zapłaty: 13 857,62 zł. Kalkulacja została dokonana na dzień 01.03.2024 roku na reprezentatywnym
              przykładzie. Przyznanie pożyczki zależy od wyniku oceny zdolności kredytowej wnioskodawcy. Założenia
              przyjęte do obliczenia RRSO: − Umowa Pożyczki będzie obowiązywała przez czas, na który została zawarta, a
              Pożyczkodawca i Pan/Pani wypełnią zobowiązania wynikające z umowy w terminach w niej określonych.
            </div>
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
};

export default StartLoanNau;
