import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import agent from '../../api/agent';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { getNestedValue } from '../../utils/objectUtils';

interface IAddressPromptingFormProps {
  postCodeFormikName: string;
  cityFormikName: string;
  streetFormikName: string;
  houseNoFormikName: string;
  flatNoFormikName: string;
  placeholderInsteadOfLabel?: boolean;
}

export const AddressPromptingForm = (props: IAddressPromptingFormProps) => {
  const { values, submitForm, setFieldValue } = useFormikContext<any>();
  const { postCodeFormikName, cityFormikName, streetFormikName, houseNoFormikName, flatNoFormikName } = props;

  const [citySuggestions, setCitySuggestions] = React.useState<string[]>([]);
  const [streetSuggestions, setStreetSuggestions] = React.useState<string[]>([]);

  const zipCodePattern = /^\d{2}-\d{3}$/;
  const zipCodeInFormIsCorrect = zipCodePattern.test(getNestedValue(values, postCodeFormikName));

  const fetchCitySuggestions = async (cityName: string, withAutoFill?: boolean) => {
    const zipCode = getNestedValue(values, postCodeFormikName);
    const response = await agent.Address.getCities({ postcode: zipCode ?? '', city: cityName, limit: 15 });
    const filteredCities = response.filter(Boolean);
    setCitySuggestions(filteredCities);

    if (withAutoFill && filteredCities.length === 1) {
      const foundCity = filteredCities[0];
      setFieldValue(cityFormikName, foundCity);
      const streets = await fetchStreetSuggestions('', foundCity, zipCode);
      const filteredStreets = streets.filter(Boolean);
      if (filteredStreets.length === 1) {
        setFieldValue(streetFormikName, filteredStreets[0]);
      }
    } else {
      await fetchStreetSuggestions('', cityName, zipCode);
    }
  };

  const handlePostCodeChange = async (postCode: string) => {
    const response = await agent.Address.getCities({ postcode: postCode ?? '', limit: 15 });
    const filteredCities = response.filter(Boolean);
    setCitySuggestions(filteredCities);

    if (filteredCities.length === 1) {
      const foundCity = filteredCities[0];
      setFieldValue(cityFormikName, foundCity);

      const streets = await fetchStreetSuggestions('', foundCity, postCode);
      const filteredStreets = streets.filter(Boolean);

      if (filteredStreets.length === 1) {
        setFieldValue(streetFormikName, filteredStreets[0]);
      }
    }
  };

  const fetchStreetSuggestions = async (street: string, city?: string, zipCode?: string) => {
    const zipCodeForApi = zipCode ?? getNestedValue(values, postCodeFormikName);
    const cityForApi = city ?? getNestedValue(values, cityFormikName);
    const response = await agent.Address.getStreets({
      postcode: zipCodeForApi ?? '',
      city: cityForApi ?? '',
      street: street,
      limit: 15,
    });

    setStreetSuggestions(response.filter(Boolean));

    return response;
  };

  const clearAddressInfo = (city: boolean, street: boolean) => {
    if (city) {
      setCitySuggestions([]);
      setFieldValue(cityFormikName, '');
    }
    if (street) {
      setStreetSuggestions([]);
      setFieldValue(streetFormikName, '');
    }
  };

  return (
    <>
      <div className={'tw-flex tw-w-full tw-flex-col tw-gap-4 sm:tw-flex-row'}>
        <MyTextInput
          label={props.placeholderInsteadOfLabel ? undefined : 'Kod pocztowy'}
          mask={MaskHelpers.postCode}
          name={postCodeFormikName}
          onChange={(event) => {
            clearAddressInfo(true, true);
            if (zipCodePattern.test(event.target.value)) {
              handlePostCodeChange(event.target.value);
            }
          }}
          placeholder={props.placeholderInsteadOfLabel ? 'Kod pocztowy' : undefined}
          wrapperClassName={' sm:tw-w-[110px] tw-w-full'}
        />

        <MyTextInput
          disabled={!zipCodeInFormIsCorrect}
          label={props.placeholderInsteadOfLabel ? undefined : 'Miejscowość'}
          name={cityFormikName}
          onChange={(event) => {
            clearAddressInfo(false, true);
            fetchCitySuggestions(event.target.value);
          }}
          onSuggestionClick={(suggestion) => {
            clearAddressInfo(false, true);
            fetchCitySuggestions(suggestion, true);
          }}
          placeholder={props.placeholderInsteadOfLabel ? 'Miejscowość' : undefined}
          suggestions={citySuggestions}
          wrapperClassName={'tw-grow'}
        />
      </div>

      <MyTextInput
        disabled={!zipCodeInFormIsCorrect}
        label={props.placeholderInsteadOfLabel ? undefined : 'Ulica'}
        name={streetFormikName}
        onChange={(event) => {
          fetchStreetSuggestions(event.target.value);
        }}
        onSuggestionClick={(suggestion) => {
          fetchStreetSuggestions(suggestion);
        }}
        placeholder={props.placeholderInsteadOfLabel ? 'Ulica' : undefined}
        suggestions={streetSuggestions}
        wrapperClassName={'tw-w-full'}
      />

      <MyTextInput
        disabled={!zipCodeInFormIsCorrect}
        label={props.placeholderInsteadOfLabel ? undefined : 'Numer domu'}
        name={houseNoFormikName}
        placeholder={props.placeholderInsteadOfLabel ? 'Numer domu' : undefined}
        wrapperClassName={'tw-w-full'}
      />
      <MyTextInput
        disabled={!zipCodeInFormIsCorrect}
        label={props.placeholderInsteadOfLabel ? undefined : 'Numer mieszkania'}
        name={flatNoFormikName}
        placeholder={props.placeholderInsteadOfLabel ? 'Numer mieszkania' : undefined}
        wrapperClassName={'tw-w-full'}
      />
    </>
  );
};
