import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import BirthdayCodeInstruction from '../../../components/BirthdayCode/BirthdayCodeInstruction/BirthdayCodeInstruction';
import BirthdayCodeFreeCode from '../../../components/BirthdayCode/BirthdayCodeFreeCode/BirthdayCodeFreeCode';
import UserBirthdayCodes from '../../../components/BirthdayCode/UserBirthdayCodes/UserBirthdayCodes';
import { useStore } from '../../../store/store';
import { BirthdayCodeCampaignM, BirthdayCodeVerificationStatus } from '../../../types/birthdayCode';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { specialCampaignsCodes } from '../birthdayCodesLib';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [freeCodesCampaigns, setFreeCodeCampaigns] = useState<BirthdayCodeCampaignM[]>([]);

  useEffect(() => {
    discountCodeStore
      .getUserBirthdayCodeCampaigns()
      .then((response) => {
        setFreeCodeCampaigns(response.filter((campaign) => specialCampaignsCodes.includes(campaign.campaignCode)));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <CustomerDashboard>
        <LoadingComponent visible={loading} />
        <div>
          <div className='col-md-6 offset-md-3'>
            <div style={styles.tabsBox}>
              <div>
                <h1 style={styles.sectionHeader as React.CSSProperties}>
                  <Image
                    src='/assets/birthdayCodes.svg'
                    style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
                  />
                  Twoje E-bony
                </h1>
              </div>
              <div
                style={
                  {
                    padding: '0 50px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  } as React.CSSProperties
                }>
                <span style={styles.activeTabLink}>Moje E-bony</span>
              </div>
            </div>
            <UserBirthdayCodes />
          </div>
          <div className='discountCodes'>
            <div className='col-md-6 offset-md-3 discountCodes-instructions'>
              <BirthdayCodeInstruction />
            </div>
            {freeCodesCampaigns.length > 0 &&
              !freeCodesCampaigns.every(
                (item) => item.verificationStatus === BirthdayCodeVerificationStatus.Verified,
              ) && (
                <div className='col-md-6 offset-md-3 birthdayCode-instruction'>
                  <BirthdayCodeFreeCode freeCodesCampaigns={freeCodesCampaigns} />
                </div>
              )}
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
