import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import SelectBenefit from '../../components/Loan/SelectBenefit/SelectBenefit';
import { Benefit } from '../../enums/benefits.enums';
import { runInAction } from 'mobx';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';

interface ILoanMainProps {
  children: ReactNode;
}
export default observer(({ children }: ILoanMainProps) => {
  const { loansStore, userStore } = useStore();
  const [hasApprovals, setApprovals] = useState<boolean>(true);

  const getAvailableBenefit = async () => {
    const userAvailableBenefits = await userStore.getUserAvailableBenefits();
    if (userAvailableBenefits != null) {
      const loanBenefits = userAvailableBenefits.filter((x) => x.benefitTypeId == Benefit.Loan && x.isInstitutional);
      //MZ: Tymczasowo wyłączamy benefity indywidualne
      //@ts-ignore
      //var individualBenefits = user.userAvailableBenefits.filter(x => x.BenefitTypeId == Benefit.Loan && !x.IsInstitutional && x.InstitutionId != null && x.InstitutionId != undefined).sort(x=> x.InstitutionId);
      //if(individualBenefits.length > 0)
      //  loanBenefits.push(individualBenefits[0]);
      loansStore.setAvailableBenefits(loanBenefits);
      if (loanBenefits.length == 1) {
        const availableBenefitId = loanBenefits[0].userAvailableBenefitId;
        if (availableBenefitId > 0) {
          loansStore.setIsBlocked(loanBenefits[0].isBlocked);
          loansStore.setAvailableBenefitId(availableBenefitId);
          loansStore.setAvailableBenefitIsInstitutional(loanBenefits[0].isInstitutional);
          return availableBenefitId;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    runInAction(() => {
      loansStore.loanMainLoadings = true;
    });
    userStore
      .hasRequiredApprovalsForBenefit(Benefit.Loan)
      .then((resp) => {
        setApprovals(resp);
        if (resp == true) {
          getAvailableBenefit().then((benefitResp) => {
            if (benefitResp != null) {
              loansStore
                .checkUserHasPermission()
                .then(() => {
                  runInAction(() => {
                    loansStore.loanMainLoadings = false;
                  });
                })
                .catch(() => {
                  runInAction(() => {
                    loansStore.loanMainLoadings = false;
                  });
                });
            } else {
              runInAction(() => {
                loansStore.loanMainLoadings = false;
              });
            }
          });
        } else {
          runInAction(() => {
            loansStore.loanMainLoadings = false;
          });
        }
      })
      .catch(() => {
        runInAction(() => {
          loansStore.loanMainLoadings = false;
        });
      });
  }, []);

  return (
    <>
      {loansStore.loanMainLoadings ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <>
          <CustomerDashboard>
            {!hasApprovals ? (
              <div className='col-md-6 offset-md-3'>
                <ApprovalsForm benefitId={Benefit.Loan} />
              </div>
            ) : (
              <div>{loansStore.availableBenefitId != null ? <main>{children}</main> : <SelectBenefit />}</div>
            )}
          </CustomerDashboard>
        </>
      )}
    </>
  );
});
