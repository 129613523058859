import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { UserInstitution, UserListModel } from '../../types/user';
import { useStore } from '../../store/store';
import AdminDashboard from '../../containers/AdminDashboard/AdminDashboard';
import { PathRoute } from '../../constants/pathRoute/Route';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import { Form, Formik } from 'formik';
import ReactPaginate from 'react-paginate';
import UsersImport from './UserImport/UsersImport';
import { Benefit } from '../../enums/benefits.enums';
import AddBenefit from '../Loan/LoanBenefitManagement/AddBenefit';
import LoanBenefitManagement from '../Loan/LoanBenefitManagement/LoanBenefitManagement';
import LoanBenefitPaymentDates from '../Loan/LoanBenefitManagement/LoanBenefitPaymentDates';
import MySelectInput from '../FormControls/MySelectInput/MySelectInput';
import { benefitStatusArray, UserIsFiredArray } from '../../helpers/ArraySelect';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import FileHelper from '../../helpers/FileHelper';
import { Capacitor } from '@capacitor/core';

export default observer(() => {
  const { userStore, modalStore } = useStore();
  const [users, setUsers] = useState<UserListModel[]>([]);
  const [userInst, setUserInst] = useState<UserInstitution | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [benefitStatus, setBenefitStatus] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [reloading, setReloading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFiredUserFilter, setIsFiredUserFilter] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
    getInstitutionUsers();
  }, []);

  const getInstitutionUsers = async (offset?: number) => {
    let userInstitution = userInst;
    if (userInstitution == null) {
      userInstitution = userStore.getUserInstitution();
    }
    if (userInstitution != null) {
      setUsers([]);
      const request = {
        InstitutionId: userInstitution.institutionId,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        BenefitStatus: benefitStatus,
        Sorting: '',
        Offset: offset ?? itemOffset,
        Limit: 20,
        IsFired: isFiredUserFilter,
      };

      return userStore.getInstitutionUsers(request).then((resp) => {
        setLoading(false);
        setReloading(false);
        setUsers(resp.records);
        setItemsCount(resp.totalRecordCount);
        setPageCount(Math.ceil(resp.totalRecordCount / 20));
      });
    }
  };
  const handlePageClick = (event: any) => {
    // @ts-ignore
    const newOffset = (event.selected * 20) % itemsCount;
    setItemOffset(newOffset);
    getInstitutionUsers(newOffset);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    setFirstName(firstName);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    setLastName(lastName);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
  };
  const handleBenefitStatusChange = (event: number) => {
    setBenefitStatus(event);
  };

  return (
    <AdminDashboard>
      {loading && <LoadingComponent />}
      <div className='w-100'>
        <h2 className='m-2 col-sm-12 col-lg-2'>Użytkownicy</h2>

        <div className={'d-flex flex-wrap gap-3 my-3'}>
          <div className='col-12 col-lg-auto'>
            <Button
              className='w-100'
              onClick={() =>
                FileHelper.downloadFile(
                  PathRoute.ADMIN_WORKERS_IMPORT_LOAN_TEMPLATE,
                  'szablon-importu-pracowników-wypłata-od-ręki.xlsx',
                )
              }>
              Pobierz wzór importu Pracowników - wypłata od ręki
            </Button>
          </div>
          <div className='col-12 col-lg-auto'>
            <Button
              className='w-100'
              onClick={() =>
                FileHelper.downloadFile(PathRoute.ADMIN_WORKERS_IMPORT_TEMPLATE, 'szablon-importu-pracowników.xlsx')
              }>
              Pobierz wzór importu Pracowników
            </Button>
          </div>

          {!Capacitor.isNativePlatform() && (
            <>
              <div className='col-12 col-lg-auto'>
                {userInst != null && (
                  <Button
                    className='w-100'
                    onClick={() => modalStore.openModal(<UsersImport withLoanBenefit={true} />, 'lg', null)}>
                    Zaimportuj dane Pracowników - wypłata do ręki
                  </Button>
                )}
              </div>
              <div className='col-12 col-lg-auto'>
                {userInst != null && (
                  <Button
                    className='w-100'
                    onClick={() => modalStore.openModal(<UsersImport withLoanBenefit={false} />, 'lg', null)}>
                    Zaimportuj dane Pracowników
                  </Button>
                )}
              </div>
            </>
          )}
          <Link className='col-12 col-lg-auto' to={PathRoute.ADD_USER}>
            <Button className='w-100'>Dodaj dane Pracownika</Button>
          </Link>
          <div className='col-12 col-lg-auto'>
            {userInst != null && (
              <Button
                className='w-100'
                onClick={() =>
                  modalStore.openModal(
                    <LoanBenefitPaymentDates institutionId={userInst.institutionId} />,
                    'lg',
                    'Daty wypłat wynagrodzenia',
                  )
                }>
                Daty wypłat wynagrodzenia
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={'d-flex align-items-center'} style={{ width: '100%', marginBottom: '10px', fontWeight: 400 }}>
        <Formik
          initialValues={{
            InstitutionId: userInst != null ? userInst.institutionId : 0,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            BenefitStatus: benefitStatus,
            Sorting: '',
            Offset: 0,
            Limit: 20,
            IsFired: isFiredUserFilter,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setReloading(true);
            getInstitutionUsers()
              .then(() => {
                setSubmitting(false);
                setReloading(false);
              })
              .catch(() => {
                setSubmitting(false);
                setReloading(false);
              });
          }}>
          {({ isSubmitting }) => (
            <Form
              className={
                'd-flex align-items-start justify-content-between justify-content-xl-start flex-column flex-lg-row col-12'
              }>
              <div className={'d-flex flex-wrap flex-column flex-lg-row col-12 col-lg'}>
                <div className={'me-lg-2'}>
                  <MyTextInput
                    name='FirstName'
                    onChange={handleFirstNameChange}
                    placeholder='Imię'
                    style={{ height: '38px' } as React.CSSProperties}
                    value={firstName}
                  />
                </div>
                <div className={'me-lg-2'}>
                  <MyTextInput
                    name='LastName'
                    onChange={handleLastNameChange}
                    placeholder='Nazwisko'
                    style={{ height: '38px' } as React.CSSProperties}
                    value={lastName}
                  />
                </div>
                <div className={'me-lg-2'}>
                  <MyTextInput
                    name='Email'
                    onChange={handleEmailChange}
                    placeholder='E-mail'
                    style={{ height: '38px' } as React.CSSProperties}
                    value={email}
                  />
                </div>
                <div className={'me-lg-2'}>
                  <MySelectInput
                    name={'benefitStatus'}
                    onChange={(e) => {
                      handleBenefitStatusChange(e != null ? Number(e) : 0);
                    }}
                    options={benefitStatusArray}
                    placeholder={'Aktywny benefit'}
                    style={{ minWidth: '190px' } as React.CSSProperties}
                  />
                </div>
                <div className={'me-lg-2'}>
                  <MySelectInput
                    name={'isFired'}
                    onChange={(e) => {
                      setIsFiredUserFilter(e === '' ? undefined : Boolean(e));
                    }}
                    options={UserIsFiredArray}
                    placeholder={'Zatrudnienie'}
                    style={{ minWidth: '110px' } as React.CSSProperties}
                  />
                </div>
              </div>
              <div className={'col-12 col-lg-1 d-flex justify-content-end my-2 my-lg-0'} style={{ minWidth: 100 }}>
                {isSubmitting ? (
                  <Button className='btn mt-3 btn-mini' type='submit'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Szukam…
                  </Button>
                ) : (
                  <Button className='btn mt-lg-3 btn-mini' type='submit'>
                    Szukaj
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {reloading ? (
        <div className='mt-3 mb-3'>
          <span className='m-2'>
            <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
          </span>
          Szukam...
        </div>
      ) : (
        <ul className='list-group' style={{ fontSize: '14px', width: '100%' } as React.CSSProperties}>
          {users.map((user) => {
            const userLoanBenefit = user?.benefits.find((e) => e.benefitTypeId == Benefit.Loan);
            return (
              <>
                <li
                  className='list-group-item align-items-center'
                  key={user.id}
                  style={{ fontWeight: 400, color: '#464646' }}>
                  <div className='row m-0'>
                    <div className='col-12 px-0 col-md-6 row d-flex align-items-end justify-content-lg-between m-0'>
                      <div className='col-sm-12 col-lg-8 mb-2 mb-lg-0'>
                        <div>
                          <h6>{`${user.firstName} ${user.lastName}`}</h6>
                        </div>
                        Email: {user.email} | Telefon: {user.phone}
                        <br />
                        Pesel: {user.pesel}
                        <br />
                        Role: {user.roles != null && user.roles.map((role) => `${role} | `)}
                        {user.isFired && (
                          <>
                            <br />
                            <strong className='text-danger'>Zwolniony</strong>
                          </>
                        )}
                      </div>
                      <div className='col-sm-12 col-lg-4 '>
                        <Link to={`${PathRoute.EDIT_USER}/${user.userInstitutionId}`}>
                          <Button
                            className='btn-mini col-12 col-lg-auto'
                            style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                            Edytuj użytkownika
                          </Button>
                        </Link>
                      </div>
                    </div>
                    {user.loanVisible && (
                      <div className='row px-0 m-0 col-12 col-md-6 d-flex align-items-center justify-content-between'>
                        <div className='col-12 mt-3 mt-md-0'>
                          <h6>Benefity</h6>
                        </div>
                        <div className='row mx-0 col-12 mt-1'>
                          {/* <div className="row col-6">
                        <span className="col-12 mb-2 fw-bold">Ciecle-K</span>
                        {user.benefits && user.benefits.some(e => e.benefitTypeId == Benefit.CircleK) ?
                          <Button className="btn-mini col-12" style={{fontSize: "13px", padding: "5px 15px"} as React.CSSProperties} onClick={() => openBenefitModal(user.id)}>Zarządzaj</Button>
                        : <Button className="btn-mini col-12" style={{fontSize: "13px", padding: "5px 15px"} as React.CSSProperties} onClick={() => openBenefitModal(user.id)}>Dodaj</Button>
                        }
                    </div> */}
                          <div className='col-12 px-0'>
                            <span className='fw-bold mb-1'>Wypłata od ręki </span>
                            <div className='mb-1'>
                              <span>
                                {user.loanContractData !== null && user.loanContractData !== undefined
                                  ? 'Limit: ' + user.loanContractData.limit + ' | '
                                  : null}
                              </span>
                              <span>
                                {user.loanContractData !== null && user.loanContractData !== undefined
                                  ? 'Wynagrodzenie: ' + user.loanContractData.salary + ' | '
                                  : null}
                              </span>
                              <span>
                                {user.loanContractData != null && user.loanContractData.paymentDate != undefined
                                  ? 'Data wypłaty wynagrodzenia: ' +
                                    `${user.loanContractData.paymentDate} dzień miesiąca`
                                  : 'Data wypłaty wynagrodzenia: nie ustawiono'}
                              </span>
                              {user.loanContractData && !user.loanContractData.isActive && (
                                <>
                                  <br />
                                  <strong className='text-danger'>Benefit zablokowany</strong>
                                </>
                              )}
                            </div>
                          </div>
                          {userInst && userLoanBenefit ? (
                            <div className={'col-12 px-0 mt-2'}>
                              <Button
                                className='btn-mini col-12'
                                onClick={() =>
                                  modalStore.openModal(
                                    <LoanBenefitManagement
                                      benefitId={userLoanBenefit.userAvailableBenefitId}
                                      institutionId={userInst.institutionId ?? 0}
                                      userId={user.id}
                                    />,
                                    'lg',
                                    'Zarzadzaj benefitem użytkownika',
                                  )
                                }
                                style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                                Zarządzaj
                              </Button>
                            </div>
                          ) : (
                            <div className={'col-12 px-0 mt-2'}>
                              <Button
                                className='btn-mini col-12'
                                onClick={() =>
                                  modalStore.openModal(
                                    <AddBenefit userId={user.id} />,
                                    'lg',
                                    'Dodaj benefit dla użytkownika',
                                  )
                                }
                                style={{ fontSize: '13px', padding: '5px 15px' } as React.CSSProperties}>
                                Dodaj
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </>
            );
          })}
        </ul>
      )}
      {!reloading && (
        <div id={'pagination'}>
          <ReactPaginate
            breakLabel='...'
            marginPagesDisplayed={1}
            nextLabel='>'
            onPageChange={handlePageClick}
            pageCount={pageCount}
            pageRangeDisplayed={5}
            previousLabel='<'
            renderOnZeroPageCount={undefined}
          />
        </div>
      )}
    </AdminDashboard>
  );
});
