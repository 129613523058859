import { observer } from 'mobx-react-lite';
import React from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import UserPersonalDataLayout from '../../UserPersonalData/UserPersonalDataLayout';
import CinemaVoucherCart from './CinemaVoucherCart';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';

export default observer(() => {
  const { data: isUserAssignedToInstitutionAsDirector, isLoading: isLoadingIsUserAssignedToInstitutionAsDirector } =
    useSwrAgent().Users.IsUserAssignedToInstitutionAsDirector();

  const { data: hasUserCollectedFreeCinemaVouchers, isLoading: isLoadingHasUserCollectedFreeCinemaVouchers } =
    useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
      CinemaVoucherOrderPromo.InstitutionDirectorPromo,
    );
  const {
    data: hasUserCollectedFreeTeachersDayCinemaVouchers,
    isLoading: isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers,
  } = useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
    CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo,
  );

  const isDirectorPromoLayout = isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeCinemaVouchers;
  const isDirectorTeachersDayPromoLayout =
    isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeTeachersDayCinemaVouchers;

  return (
    <>
      {isLoadingIsUserAssignedToInstitutionAsDirector ||
        isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers ||
        (isLoadingHasUserCollectedFreeCinemaVouchers && <LoadingComponent />)}
      {/* Director doesn't have to enter personal data */}
      {isDirectorPromoLayout || isDirectorTeachersDayPromoLayout ? (
        <CinemaVoucherCart />
      ) : (
        <UserPersonalDataLayout wrapperClassName={' tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
          <CinemaVoucherCart />
        </UserPersonalDataLayout>
      )}
    </>
  );
});
