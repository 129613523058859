export const containerFooter = {
  position: 'absolute',
  width: '100%',
  bottom: '15px',
  left: '0',
  paddingLeft: '15px',
};
export const containerFooterFixed = {
  position: 'fixed',
  width: '100%',
  bottom: '15px',
  left: '0',
  paddingLeft: '15px',
};
export const footerDisplay = {
  display: 'flex',
  justifyContent: 'flex-start',
};

export const infoText = {
  marginBottom: '5px',
  fontSize: '16px',
  color: '#626D80',
};

export const displayNumber = {
  display: 'grid',
  gridAutoColumns: 'max-content',
};
