export const marginBottomField = {
  marginBottom: '2px',
} as React.CSSProperties;

export const headingText = {
  color: '#00635c',
  textAlign: 'center',
  padding: 0,
  margin: 0,
} as React.CSSProperties;
export const subheadingText = {
  color: '#00635c',
  textAlign: 'center',
  fontSize: '18px',
  padding: 0,
  margin: 0,
} as React.CSSProperties;

export const paragraphText = {
  color: '#00635c',
  textAlign: 'left',
  paddingTop: '20px',
  fontSize: '14px',
} as React.CSSProperties;

export const goBackLink = {
  width: '30px',
} as React.CSSProperties;

export const header = {
  width: 'calc(100% - 30px)',
} as React.CSSProperties;

export const formTitle = {
  color: '#00635c',
  textAlign: 'left',
  padding: 0,
  margin: 0,
  paddingBottom: '5px',
  fontSize: '14px',
} as React.CSSProperties;
