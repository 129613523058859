import React from 'react';
import { twMerge } from '../../../index';

const KlThankYouPageImage = (props: { className?: string }) => (
  <>
    <svg
      className={twMerge(props.className)}
      data-name='Group 1571'
      height='379.117'
      id='Group_1571'
      viewBox='0 0 635.345 379.117'
      width='635.345'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 504' fill='none' height='379.117' id='Rectangle_504' width='635.345' />
        </clipPath>
        <clipPath id='clip-path-3'>
          <rect data-name='Rectangle 497' fill='none' height='377.873' id='Rectangle_497' width='559.71' />
        </clipPath>
        <clipPath id='clip-path-4'>
          <rect data-name='Rectangle 498' fill='none' height='50.245' id='Rectangle_498' width='58.15' />
        </clipPath>
        <clipPath id='clip-path-5'>
          <rect data-name='Rectangle 499' fill='none' height='35.14' id='Rectangle_499' width='59.584' />
        </clipPath>
        <clipPath id='clip-path-6'>
          <rect data-name='Rectangle 500' fill='none' height='20.016' id='Rectangle_500' width='32.763' />
        </clipPath>
        <clipPath id='clip-path-7'>
          <rect data-name='Rectangle 501' fill='none' height='24.778' id='Rectangle_501' width='41.217' />
        </clipPath>
        <clipPath id='clip-path-8'>
          <rect data-name='Rectangle 502' fill='none' height='19.795' id='Rectangle_502' width='32.366' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Group 1570' id='Group_1570'>
        <g data-name='Group 1569' id='Group_1569'>
          <g clipPath='url(#clip-path)' data-name='Group 1568' id='Group_1568'>
            <g data-name='Group 1552' id='Group_1552' opacity='0.47' transform='translate(38.571)'>
              <g data-name='Group 1551' id='Group_1551'>
                <g clipPath='url(#clip-path-3)' data-name='Group 1550' id='Group_1550'>
                  <path
                    d='M791.736,137.123a48.989,48.989,0,0,0-13.867-17.334c-12.139-9.773-27.771-14.4-43.3-15.729-18.587-1.589-36.861,2.687-55.247.434-16.266-1.993-26.528-9.538-36.336-22.063-13.954-17.82-23.465-40.949-38.664-56.377C573.637-5.093,523.758-8.706,488.8,17.335c-21.643,16.124-35.9,40.885-42.556,67.04C442.9,97.51,441.33,111.089,437.215,124s-11.255,25.541-22.841,32.575c-14.436,8.765-32.894,7.247-49.16,2.7s-31.882-11.825-48.646-13.872c-34.858-4.257-73.241,15.541-78.639,52.476-8.784,60.11,25.527,110.6,72.09,146.825,8.032,6.249,16.371,12.125,24.881,17.748,5.418,3.58,30.44,15.416,36.873,15.416H718.626c21-2.933,37.768-20.829,37.408-42.39-.346-20.705-15-35.7-20.119-54.994-4.331-16.336.357-29.877,10.977-42.674,11.312-13.631,25.234-25.03,35.551-39.584,13.109-18.494,18.334-41.9,9.294-61.108'
                    data-name='Path 4818'
                    fill='#0aa9b0'
                    id='Path_4818'
                    transform='translate(-236.551 0)'
                  />
                </g>
              </g>
            </g>
            <path
              d='M1453.472,867.963H1266.01a11.937,11.937,0,0,1-11.937-11.937V596.021a11.937,11.937,0,0,1,11.937-11.937h187.463a11.937,11.937,0,0,1,11.937,11.937V856.027a11.937,11.937,0,0,1-11.937,11.937'
              data-name='Path 4819'
              fill='#e5efff'
              id='Path_4819'
              transform='translate(-1049.59 -488.846)'
            />
            <path
              d='M1500.572,1024.5l-.033-8.008a3.844,3.844,0,0,0-3.836-3.82h-8.008a3.806,3.806,0,0,0-3.8,3.82l.033,8.008a3.843,3.843,0,0,0,3.836,3.82h8.008a3.805,3.805,0,0,0,3.8-3.82m-14.761-8.008a2.888,2.888,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.912,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.008a2.918,2.918,0,0,1-2.911-2.9Z'
              data-name='Path 4820'
              fill='#0cb393'
              id='Path_4820'
              transform='translate(-1242.772 -847.554)'
            />
            <path
              d='M1501.39,1221.929l-.033-8.008a3.843,3.843,0,0,0-3.835-3.82h-8.008a3.805,3.805,0,0,0-3.8,3.82l.033,8.008a3.844,3.844,0,0,0,3.836,3.82h8.008a3.805,3.805,0,0,0,3.8-3.82m-14.76-8.008a2.888,2.888,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.911,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.007a2.918,2.918,0,0,1-2.912-2.9Z'
              data-name='Path 4821'
              fill='#0cb393'
              id='Path_4821'
              transform='translate(-1243.457 -1012.788)'
            />
            <path
              d='M1499.754,827.078l-.033-8.008a3.843,3.843,0,0,0-3.835-3.82h-8.008a3.8,3.8,0,0,0-3.8,3.82l.033,8.008a3.843,3.843,0,0,0,3.836,3.82h8.007a3.805,3.805,0,0,0,3.8-3.82m-14.76-8.008a2.888,2.888,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.911,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.008a2.918,2.918,0,0,1-2.911-2.9Z'
              data-name='Path 4822'
              fill='#0cb393'
              id='Path_4822'
              transform='translate(-1242.088 -682.32)'
            />
            <path
              d='M1503.024,1616.781l-.033-8.008a3.843,3.843,0,0,0-3.836-3.82h-8.008a3.805,3.805,0,0,0-3.8,3.82l.033,8.008a3.844,3.844,0,0,0,3.836,3.82h8.008a3.805,3.805,0,0,0,3.8-3.82m-14.76-8.008a2.888,2.888,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.911,2.9l.033,8.008a2.888,2.888,0,0,1-2.888,2.9h-8.007a2.917,2.917,0,0,1-2.912-2.9Z'
              data-name='Path 4823'
              fill='#0cb393'
              id='Path_4823'
              transform='translate(-1244.825 -1343.258)'
            />
            <path
              d='M1503.841,1814.2l-.033-8.008a3.844,3.844,0,0,0-3.836-3.82h-8.008a3.8,3.8,0,0,0-3.8,3.82l.033,8.008a3.843,3.843,0,0,0,3.836,3.82h8.008a3.8,3.8,0,0,0,3.8-3.82m-14.76-8.008a2.887,2.887,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.912,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.008a2.917,2.917,0,0,1-2.911-2.9Z'
              data-name='Path 4824'
              fill='#0cb393'
              id='Path_4824'
              transform='translate(-1245.509 -1508.491)'
            />
            <path
              d='M1504.658,2011.63l-.033-8.008a3.843,3.843,0,0,0-3.836-3.82h-8.008a3.806,3.806,0,0,0-3.8,3.82l.033,8.008a3.844,3.844,0,0,0,3.836,3.82h8.007a3.805,3.805,0,0,0,3.8-3.82m-14.761-8.008a2.889,2.889,0,0,1,2.888-2.9h8.008a2.917,2.917,0,0,1,2.911,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.008a2.918,2.918,0,0,1-2.912-2.9Z'
              data-name='Path 4825'
              fill='#0cb393'
              id='Path_4825'
              transform='translate(-1246.192 -1673.725)'
            />
            <path
              d='M1502.206,1419.354l-.033-8.008a3.844,3.844,0,0,0-3.836-3.82h-8.008a3.806,3.806,0,0,0-3.8,3.82l.033,8.008a3.843,3.843,0,0,0,3.836,3.82h8.008a3.805,3.805,0,0,0,3.8-3.82m-14.76-8.008a2.888,2.888,0,0,1,2.887-2.9h8.008a2.917,2.917,0,0,1,2.911,2.9l.033,8.008a2.888,2.888,0,0,1-2.887,2.9h-8.008a2.917,2.917,0,0,1-2.911-2.9Z'
              data-name='Path 4826'
              fill='#0cb393'
              id='Path_4826'
              transform='translate(-1244.14 -1178.022)'
            />
            <path
              d='M1503,1035.308l-5.541-5.518a1.837,1.837,0,1,1,2.592-2.6l2.927,2.915,6.219-6.245a1.845,1.845,0,0,1,2.614,2.6Z'
              data-name='Path 4827'
              fill='#00736d'
              id='Path_4827'
              transform='translate(-1252.833 -856.46)'
            />
            <path
              d='M1502.177,837.384l-5.542-5.518a1.837,1.837,0,1,1,2.593-2.6l2.927,2.915,6.219-6.245a1.845,1.845,0,0,1,2.614,2.6Z'
              data-name='Path 4828'
              fill='#00736d'
              id='Path_4828'
              transform='translate(-1252.147 -690.809)'
            />
            <path
              d='M1503.816,1233.3l-5.541-5.518a1.837,1.837,0,1,1,2.592-2.6l2.927,2.915,6.219-6.246a1.845,1.845,0,0,1,2.615,2.6Z'
              data-name='Path 4829'
              fill='#00736d'
              id='Path_4829'
              transform='translate(-1253.518 -1022.172)'
            />
            <path
              d='M1777.057,842.342H1674.716a1.5,1.5,0,0,1-1.491-1.485,1.482,1.482,0,0,1,1.479-1.485h102.342a1.485,1.485,0,0,1,.012,2.97'
              data-name='Path 4830'
              fill='#626d80'
              id='Path_4830'
              transform='translate(-1400.397 -702.508)'
            />
            <path
              d='M1752.172,880.544h-77.456a1.5,1.5,0,0,1-1.491-1.484,1.482,1.482,0,0,1,1.479-1.485h77.457a1.5,1.5,0,0,1,1.491,1.485,1.482,1.482,0,0,1-1.479,1.484'
              data-name='Path 4831'
              fill='#626d80'
              id='Path_4831'
              transform='translate(-1400.397 -734.482)'
            />
            <path
              d='M1714.266,1664.379h-39.55a1.5,1.5,0,0,0-1.491,1.485,1.482,1.482,0,0,0,1.479,1.485h39.55a1.5,1.5,0,0,0,1.491-1.485,1.482,1.482,0,0,0-1.479-1.485'
              data-name='Path 4832'
              fill='#626d80'
              id='Path_4832'
              transform='translate(-1400.397 -1392.994)'
            />
            <path
              d='M1719.4,1626.176h-44.689a1.5,1.5,0,0,0-1.491,1.485,1.482,1.482,0,0,0,1.479,1.485h44.689a1.485,1.485,0,0,0,.012-2.969'
              data-name='Path 4833'
              fill='#626d80'
              id='Path_4833'
              transform='translate(-1400.396 -1361.02)'
            />
            <path
              d='M1750.835,1235.744h-76.12a1.5,1.5,0,0,1-1.491-1.485,1.482,1.482,0,0,1,1.479-1.485h76.12a1.485,1.485,0,0,1,.012,2.97'
              data-name='Path 4834'
              fill='#626d80'
              id='Path_4834'
              transform='translate(-1400.396 -1031.764)'
            />
            <path
              d='M1726.627,1273.946h-51.912a1.5,1.5,0,0,1-1.491-1.485,1.481,1.481,0,0,1,1.479-1.484h51.912a1.5,1.5,0,0,1,1.491,1.484,1.481,1.481,0,0,1-1.479,1.485'
              data-name='Path 4835'
              fill='#626d80'
              id='Path_4835'
              transform='translate(-1400.397 -1063.738)'
            />
            <path
              d='M1711.675,1432.445h-36.959a1.5,1.5,0,0,1-1.491-1.485,1.482,1.482,0,0,1,1.479-1.485h36.959a1.5,1.5,0,0,1,1.491,1.485,1.482,1.482,0,0,1-1.479,1.485'
              data-name='Path 4836'
              fill='#626d80'
              id='Path_4836'
              transform='translate(-1400.397 -1196.392)'
            />
            <path
              d='M1701.595,1470.647h-26.879a1.5,1.5,0,0,1-1.491-1.485,1.481,1.481,0,0,1,1.479-1.485h26.879a1.5,1.5,0,0,1,1.491,1.485,1.481,1.481,0,0,1-1.479,1.485'
              data-name='Path 4837'
              fill='#626d80'
              id='Path_4837'
              transform='translate(-1400.397 -1228.366)'
            />
            <path
              d='M1716.089,1074.276h-41.374a1.485,1.485,0,1,0-.012,2.969h41.375a1.5,1.5,0,0,0,1.491-1.485,1.481,1.481,0,0,0-1.479-1.484'
              data-name='Path 4838'
              fill='#626d80'
              id='Path_4838'
              transform='translate(-1400.396 -899.11)'
            />
            <path
              d='M1734.268,1036.073h-59.553a1.5,1.5,0,0,0-1.491,1.485,1.482,1.482,0,0,0,1.479,1.485h59.553a1.5,1.5,0,0,0,1.491-1.485,1.482,1.482,0,0,0-1.479-1.485'
              data-name='Path 4839'
              fill='#626d80'
              id='Path_4839'
              transform='translate(-1400.396 -867.136)'
            />
            <path
              d='M1748.324,1825.846h-73.608a1.485,1.485,0,1,1-.012-2.969h73.608a1.5,1.5,0,0,1,1.491,1.485,1.481,1.481,0,0,1-1.479,1.484'
              data-name='Path 4840'
              fill='#626d80'
              id='Path_4840'
              transform='translate(-1400.397 -1525.648)'
            />
            <path
              d='M1738.244,1864.048h-63.528a1.5,1.5,0,0,1-1.491-1.485,1.481,1.481,0,0,1,1.479-1.485h63.528a1.485,1.485,0,0,1,.012,2.969'
              data-name='Path 4841'
              fill='#626d80'
              id='Path_4841'
              transform='translate(-1400.397 -1557.621)'
            />
            <path
              d='M1716.089,2022.547h-41.374a1.485,1.485,0,1,1-.012-2.969h41.375a1.5,1.5,0,0,1,1.491,1.485,1.481,1.481,0,0,1-1.479,1.485'
              data-name='Path 4842'
              fill='#626d80'
              id='Path_4842'
              transform='translate(-1400.396 -1690.275)'
            />
            <path
              d='M1738.254,2060.75h-63.538a1.5,1.5,0,0,1-1.491-1.484,1.482,1.482,0,0,1,1.479-1.485h63.539a1.5,1.5,0,0,1,1.491,1.485,1.482,1.482,0,0,1-1.479,1.484'
              data-name='Path 4843'
              fill='#626d80'
              id='Path_4843'
              transform='translate(-1400.397 -1722.25)'
            />
            <path
              d='M2060.767,1206.81c.019,0,7.9-25.364,11.273-29.283a22.776,22.776,0,0,1,2.764-2.782,2.311,2.311,0,0,1,3.8,1.8c0,.02,0,.04,0,.061-.1,4.906-1.626,9.644-3.1,14.322a47.555,47.555,0,0,1-5.937,13.05c-.108.156-.22.311-.333.465-.51.69-.969,1.331-1.434,1.915a4.754,4.754,0,0,1-7.033.451'
              data-name='Path 4844'
              fill='#e8caaa'
              id='Path_4844'
              transform='translate(-1724.749 -982.741)'
            />
            <path
              d='M2171.806,1001.44c6.514-6.912,19,2.043,14.068,10.578a8.133,8.133,0,0,1-.809,1.16,6.572,6.572,0,0,1-.993.964,5.422,5.422,0,0,1-1.18.7,5.841,5.841,0,0,1-3.192.55,5.814,5.814,0,0,1-1.563-.23,10.931,10.931,0,0,1-6.319-5.388c-1.105-2.06-1.923-4.888-.824-7.154a4.7,4.7,0,0,1,.813-1.181'
              data-name='Path 4845'
              fill='#ce6824'
              id='Path_4845'
              transform='translate(-1816.568 -836.088)'
            />
            <path
              d='M2175.156,1103.885c.646-.247,4.692-1.888,3.778-7.32L2186,1092.1s-.588,8.545,4.061,11.109c.362.2-7.061,6.963-11.7,6.708-3.556-.2-3.669-4.54-3.2-6.031'
              data-name='Path 4846'
              fill='#ecbf90'
              id='Path_4846'
              transform='translate(-1820.322 -914.027)'
            />
            <path
              d='M2199.159,1095.528l6.077-4.055-.656,1.956a6.592,6.592,0,0,1-5.243,5.078,26.228,26.228,0,0,0-.179-2.979'
              data-name='Path 4847'
              fill='#b79276'
              id='Path_4847'
              transform='translate(-1840.575 -913.503)'
            />
            <path
              d='M2182.992,1037.471a9.78,9.78,0,0,0-6.8-5.225c-4.125-1.015-4.6,2.644-4.654,7.906-.034,3.238,1.634,8,4.346,7.87s6.02-4.037,6.67-5.775c0,0,2.557-1.069,2.629-3.513.077-2.654-2.189-1.264-2.189-1.264'
              data-name='Path 4848'
              fill='#ecbf90'
              id='Path_4848'
              transform='translate(-1817.457 -863.792)'
            />
            <path
              d='M2104.42,2135.483h.015l-1.952,24.251-5.6-.056-1.336-23.878c2.965-.135,5.925-.25,8.876-.316'
              data-name='Path 4849'
              fill='#ecbf90'
              id='Path_4849'
              transform='translate(-1753.855 -1787.282)'
            />
            <path
              d='M2032.726,2288.681a87.843,87.843,0,0,1,10.885-4.815l5.6.056c-.025.324-.213,3.126-.3,4.143q-.036.414-.086.83a1.611,1.611,0,0,1-1.176,1.33c-3.3.7-10.407.175-12.505.2-2.424.026-4.441-.461-2.418-1.741'
              data-name='Path 4850'
              fill='#ecbf90'
              id='Path_4850'
              transform='translate(-1700.586 -1911.47)'
            />
            <path
              d='M2032.726,2292.478a79.682,79.682,0,0,1,8.949-4.076,24.446,24.446,0,0,0,7.378,1.288c-.04.721-.079,1.443-.142,2.171q-.036.415-.086.83a1.612,1.612,0,0,1-1.176,1.331c-3.3.694-10.407.174-12.505.2-2.424.025-4.441-.46-2.418-1.741'
              data-name='Path 4851'
              fill='#00736d'
              id='Path_4851'
              transform='translate(-1700.586 -1915.267)'
            />
            <path
              d='M2305.716,2135.483h-.015l4.4,24.251h5.6l-1.08-23.935c-2.979-.135-5.951-.25-8.908-.316'
              data-name='Path 4852'
              fill='#ecbf90'
              id='Path_4852'
              transform='translate(-1929.745 -1787.282)'
            />
            <path
              d='M2267.249,2288.681a72.687,72.687,0,0,1,10.089-4.815l5.611.056c.029.324.3,3.126.383,4.143q.033.414.05.83a1.3,1.3,0,0,1-.956,1.33c-3.183.7-10.379.175-12.474.2-2.419.026-4.517-.461-2.7-1.741'
              data-name='Path 4853'
              fill='#ecbf90'
              id='Path_4853'
              transform='translate(-1896.991 -1911.47)'
            />
            <path
              d='M2267.249,2292.478a65.647,65.647,0,0,1,8.275-4.076,26.557,26.557,0,0,0,7.592,1.288c.079.721.16,1.443.217,2.171q.033.415.05.83a1.305,1.305,0,0,1-.956,1.331c-3.183.694-10.379.174-12.474.2-2.419.025-4.517-.46-2.7-1.741'
              data-name='Path 4854'
              fill='#00736d'
              id='Path_4854'
              transform='translate(-1896.991 -1915.267)'
            />
            <path
              d='M2078.01,1365.378c-3.468-5.216-12.035-3.465-18.041-5.22-.17.344-5.644,3.828-11.89,23.829-5.412,18.267-11.477,50.694-7,102.835,5.825,1.573,16.38,2.7,25.841,2.506,0-24.739-1.345-49.146.355-73.165,2.441,25,3.937,49.4,5.726,72.291,10.023-.777,21.3-1.8,25.506-2.786-.925-10.69.716-88.381-20.494-120.289'
              data-name='Path 4855'
              fill='#626d80'
              id='Path_4855'
              transform='translate(-1706.967 -1138.378)'
            />
            <path
              d='M2105.67,1175.5c.97-4.355,4.774-14.352,4.774-14.352a44.885,44.885,0,0,1,5.253-1.777s.582,2.315,6.151,2.452a11.322,11.322,0,0,0,8.451-3.08l.019-.016c3.325.5,6.645,1.565,9.062,3.768.739.673,2.067,5.793-.085,16.681s.411,23.114.411,23.114a67.326,67.326,0,0,1-36.068,0s1.062-22.433,2.032-26.788'
              data-name='Path 4856'
              fill='#fff'
              id='Path_4856'
              transform='translate(-1760.63 -969.793)'
            />
            <path
              d='M2102.69,1288.15c.011-.224,1.072-22.463,2.026-26.749l-.448-.1c-.963,4.325-1.994,25.911-2.038,26.827Z'
              data-name='Path 4857'
              fill='#fff'
              id='Path_4857'
              transform='translate(-1759.452 -1055.64)'
            />
            <path
              d='M2097.226,1173.587a10.818,10.818,0,0,0-2.035,1.763c-1.229,1.289-3.453,5.8-4.723,8.8s-2.623,5.809-2.623,5.809-.132,2.125,2.809,6.67a13.689,13.689,0,0,0,7.142,5.685s2.112-5.07,1.263-14.764a114.892,114.892,0,0,0-1.833-13.961'
              data-name='Path 4858'
              fill='#fff'
              id='Path_4858'
              transform='translate(-1747.412 -982.228)'
            />
            <path
              d='M1990.339,1343.9c-1.209.039-10.294,4.094-12.472,4.592a21.448,21.448,0,0,1-3.887.671l.517,3.047c2.544.947,17.186.615,20.291-3.357a1.989,1.989,0,0,0,.179-.251,2.8,2.8,0,0,0,.357-.928c.465-2.575-3.9-3.808-4.985-3.773'
              data-name='Path 4859'
              fill='#ecbf90'
              id='Path_4859'
              transform='translate(-1652.113 -1124.768)'
            />
            <path
              d='M2125.674,1242.964c.014-.561.025-1.2.037-1.9a114.124,114.124,0,0,1,.719-12.67l-.289-.039a114.658,114.658,0,0,0-.723,12.7c-.011.7-.022,1.341-.036,1.9Z'
              data-name='Path 4860'
              fill='#c6dbff'
              id='Path_4860'
              transform='translate(-1778.829 -1028.062)'
            />
            <path
              d='M2316.761,1208.425c-1.089-5.2-2.157-10.48-4.545-15.139-.982-1.918-1.381-2.266-2.736-2.873-.019.006-2.255.03-2.466,7.33-.163,5.619,5.043,33.653,5.025,33.661,2.729.733,5.34-.132,5.741-3.156.107-.8.286-2.776.378-3.7q.031-.309.054-.619a56.025,56.025,0,0,0-1.451-15.5'
              data-name='Path 4861'
              fill='#ecbf90'
              id='Path_4861'
              transform='translate(-1930.84 -996.31)'
            />
            <path
              d='M2298.2,1180.242a13.014,13.014,0,0,1,2.388,3.006,73.657,73.657,0,0,1,4.164,9.073c1.082,3.071,2.562,7.085,2.562,7.085s.08,1.856-4.111,5.283c-4.327,3.54-8.1,1.683-8.1,1.683s-1.414-1.953-1.349-11.684c.1-14.51,4.442-14.446,4.442-14.446'
              data-name='Path 4862'
              fill='#fff'
              id='Path_4862'
              transform='translate(-1919.744 -987.798)'
            />
            <path
              d='M2294.233,1261.492l.236-.172-.118.086.119-.085c-.013-.02-1.384-2.082-1.321-11.6.009-1.319.058-2.565.145-3.7L2293,1246c-.088,1.145-.137,2.4-.146,3.724-.063,9.667,1.317,11.689,1.377,11.771'
              data-name='Path 4863'
              fill='#c6dbff'
              id='Path_4863'
              transform='translate(-1918.993 -1042.831)'
            />
            <path
              d='M2197.934,1411.774a2,2,0,0,0-.289-.217c-4.983-3.388-26.877-.207-32.61.562l.463,3.145a49.284,49.284,0,0,1,6.035-.078c3.387.079,17.608,2.4,19.479,2.2,1.682-.175,8.358-2.255,7.518-4.751a2.111,2.111,0,0,0-.6-.862'
              data-name='Path 4864'
              fill='#ecbf90'
              id='Path_4864'
              transform='translate(-1812.015 -1180.114)'
            />
            <path
              d='M1937.23,1355.777c.315.165,1.543-.123,1.816.056.792.519.958.988,1.732,1.627a14.149,14.149,0,0,0,2.818,1.956c1.321.523,1.922-.478,2.064-1.633s-1.7-3.335-3.621-4.715a4.349,4.349,0,0,0-2.678-.512,10.456,10.456,0,0,0-1.845.808h0c-.307.144-.683,2.2-.286,2.412'
              data-name='Path 4865'
              fill='#ecbf90'
              id='Path_4865'
              transform='translate(-1621.212 -1131.976)'
            />
            <path
              d='M1936.692,1360.985c-.162-.253-1.143-2.952.077-3.236l.4.727a5.382,5.382,0,0,1,.131,2c-.119.786-.446.761-.608.508'
              data-name='Path 4866'
              fill='#e8caaa'
              id='Path_4866'
              transform='translate(-1620.465 -1136.361)'
            />
            <path
              d='M1926.983,1338.368h0a.409.409,0,0,0-.206.541l3.443,7.66a.409.409,0,1,0,.746-.335l-3.443-7.66a.409.409,0,0,0-.54-.206'
              data-name='Path 4867'
              fill='#391f3a'
              id='Path_4867'
              transform='translate(-1612.577 -1120.11)'
            />
            <path
              d='M1939.328,1376.283a30.62,30.62,0,0,0,3.523,1.949,7.941,7.941,0,0,0,3.023.722,1.871,1.871,0,0,0,1.621-2.325,2.525,2.525,0,0,0-.284-.572c-1.49-.1-4.535-.017-4.81-.006-1.118.047-1.494-.368-2.152-.392s-1.2.46-.921.625'
              data-name='Path 4868'
              fill='#ecbf90'
              id='Path_4868'
              transform='translate(-1623.05 -1151.35)'
            />
            <path
              d='M2142.615,1409.675a14.059,14.059,0,0,1,3.8.729c1.6.609,3.232,1.082,3.232,1.082a1.58,1.58,0,0,1-.1,2.5,2.106,2.106,0,0,1-.5.3c-1.285-.754-3.771-2.533-3.993-2.7-.905-.665-1.412-.543-1.969-.893s-.776-1.04-.471-1.018'
              data-name='Path 4869'
              fill='#e8caaa'
              id='Path_4869'
              transform='translate(-1793.135 -1179.821)'
            />
            <path
              d='M2031.483,1338.713h-17.424a1.565,1.565,0,0,1-1.432-1.148l-6.339-25.526a.868.868,0,0,1,.863-1.147h17.424a1.565,1.565,0,0,1,1.432,1.147l6.339,25.526a.868.868,0,0,1-.862,1.148'
              data-name='Path 4870'
              fill='#49498e'
              id='Path_4870'
              transform='translate(-1679.124 -1097.145)'
            />
            <path
              d='M2047.883,1307.523H2041.5a1.112,1.112,0,0,1-1.018-.814.617.617,0,0,1,.613-.815h6.386a1.113,1.113,0,0,1,1.017.815.616.616,0,0,1-.613.814'
              data-name='Path 4871'
              fill='#afcafe'
              id='Path_4871'
              transform='translate(-1707.749 -1092.961)'
            />
            <path
              d='M2118.824,1414.407a17.231,17.231,0,0,0-5.49-.344c-.087.01-.091.046-.088.092.036.531.946.653,2,.795a8.606,8.606,0,0,1,2.1.457.062.062,0,0,1,.039.065.065.065,0,0,1-.052.056,23.011,23.011,0,0,0-4.48,1.1c-.084.086.3.375.37.4a2.105,2.105,0,0,0,.8-.005,23.284,23.284,0,0,0,2.548-.224c.163-.031.316-.06.455-.075.051-.006.1-.009.147-.01a.064.064,0,0,1,.063.046.062.062,0,0,1-.03.072c-2.024,1.188-2.567,1.466-2.747,1.556-.069.035-.07.035-.084.051a.134.134,0,0,0-.024.166.307.307,0,0,0,.308.152s.061,0,.092-.005a19.671,19.671,0,0,0,2.942-.946.073.073,0,0,1,.073.019.063.063,0,0,1,.006.073c-1.121,1.865-1.264,2.362-1.186,2.452a.39.39,0,0,0,.235.105l.02,0a3.972,3.972,0,0,0,.647-.647c.474-.53,1.011-1.131,1.381-1.169a6.846,6.846,0,0,1,1.717.365,5.74,5.74,0,0,0,1.734.029,17.515,17.515,0,0,0,3.207-.922v-3.024Z'
              data-name='Path 4872'
              fill='#ecbf90'
              id='Path_4872'
              transform='translate(-1768.334 -1183.425)'
            />
            <path
              d='M2261.367,1009.8a3.067,3.067,0,1,1-3.067-3.067,3.067,3.067,0,0,1,3.067,3.067'
              data-name='Path 4873'
              fill='#ce6824'
              id='Path_4873'
              transform='translate(-1887.506 -842.579)'
            />
            <path
              d='M2327.982,410.577a54.594,54.594,0,1,1,11.642,11.642l-17.387,5.746Z'
              data-name='Path 4874'
              fill='#fff'
              id='Path_4874'
              transform='translate(-1939.514 -270.871)'
            />
            <g data-name='Group 1555' id='Group_1555' opacity='0.47' transform='translate(429.124 52.329)'>
              <g data-name='Group 1554' id='Group_1554'>
                <g clipPath='url(#clip-path-4)' data-name='Group 1553' id='Group_1553'>
                  <path
                    d='M2689.051,371.179a54.486,54.486,0,0,0-53.943-49.362c-1.087,0-2.188.033-3.274.1l-.052-.881c1.1-.066,2.222-.1,3.326-.1a55.04,55.04,0,0,1,54.823,50.168Z'
                    data-name='Path 4875'
                    fill='#0aa9b0'
                    id='Path_4875'
                    transform='translate(-2631.781 -320.933)'
                  />
                </g>
              </g>
            </g>
            <path
              d='M2568.4,535.9c-1.558-1.557-4.585-.3-6.014,1.133-.548.548-4.008,4.255-8.068,8.619l-21.248-4.575a2.58,2.58,0,0,0-2.028.413l-1.86,1.309a.627.627,0,0,0,.1,1.081l18.658,8.638c-4.479,4.825-8.258,8.9-8.258,8.9l-7.957-1.23a2.58,2.58,0,0,0-2.219.725.627.627,0,0,0,.1.968l7.764,5.047,5.045,7.763a.628.628,0,0,0,.969.1,2.582,2.582,0,0,0,.725-2.217l-1.23-7.958s4.078-3.779,8.9-8.258l8.638,18.657a.626.626,0,0,0,1.08.1l1.31-1.859a2.579,2.579,0,0,0,.412-2.028l-4.575-21.248c4.365-4.06,8.071-7.52,8.619-8.068,1.43-1.429,2.691-4.457,1.134-6.015'
              data-name='Path 4876'
              fill='#00736d'
              id='Path_4876'
              transform='translate(-2116.564 -447.923)'
            />
            <g data-name='Group 1558' id='Group_1558' opacity='0.3' transform='translate(109.706 31.414)'>
              <g data-name='Group 1557' id='Group_1557'>
                <g clipPath='url(#clip-path-5)' data-name='Group 1556' id='Group_1556'>
                  <path
                    d='M721.26,227.8h-.02l-37.913-.066a10.531,10.531,0,0,1-.408-21.053,10.632,10.632,0,0,1,1.332.03,11.622,11.622,0,0,1,11.46-9.786h.021a11.534,11.534,0,0,1,3.593.573,13.2,13.2,0,0,1,23.4,7.972q.005.2.005.4a11.012,11.012,0,0,1,9.659,11.448A11.052,11.052,0,0,1,721.26,227.8m-38.2-17.534a6.943,6.943,0,0,0,.272,13.881l37.913.066h.014a7.471,7.471,0,0,0,7.544-7.065,7.42,7.42,0,0,0-7.4-7.775c-.158,0-.321,0-.49.016l-2.226.141.339-2.2a9.612,9.612,0,0,0-9.363-11.078,9.577,9.577,0,0,0-8.206,4.4l-.858,1.329-1.426-.685a7.941,7.941,0,0,0-3.451-.785h-.014a8.025,8.025,0,0,0-8.015,8c0,.092,0,.185.005.277l.082,2.509-2.4-.735A6.973,6.973,0,0,0,683.061,210.266Z'
                    data-name='Path 4877'
                    fill='#626d80'
                    id='Path_4877'
                    transform='translate(-672.815 -192.66)'
                  />
                </g>
              </g>
            </g>
            <path
              d='M2020.516,257.365a4.559,4.559,0,0,1,1.473.195c0-.059,0-.117,0-.176a5.113,5.113,0,0,1,7.312-4.614,5.939,5.939,0,0,1,10.937,3.168,6.01,6.01,0,0,1-.067.938c.1-.007.209-.011.316-.011a4.8,4.8,0,1,1-.059,9.6h-19.763a4.552,4.552,0,0,1-.145-9.1'
              data-name='Path 4878'
              fill='#fff'
              id='Path_4878'
              transform='translate(-1687.373 -209.268)'
            />
            <g data-name='Group 1561' id='Group_1561' opacity='0.3' transform='translate(602.582 269.678)'>
              <g data-name='Group 1560' id='Group_1560'>
                <g clipPath='url(#clip-path-6)' data-name='Group 1559' id='Group_1559'>
                  <path
                    d='M3722,1673.923h-19.764a6.6,6.6,0,0,1-1.569-13.015,7.735,7.735,0,0,1,13.316-4.584,6.916,6.916,0,0,1,8.246,4.912,6.346,6.346,0,0,1-.229,12.687m-19.822-9.6a3.007,3.007,0,0,0-3,3.113,3.06,3.06,0,0,0,3.063,2.9H3722a2.759,2.759,0,1,0-.8-5.4l-2.4.73.083-2.5c0-.04,0-.078,0-.118a3.321,3.321,0,0,0-3.318-3.317,3.284,3.284,0,0,0-1.428.323l-1.426.681-.855-1.33a4.148,4.148,0,0,0-7.588,2.867l.335,2.206-2.225-.145c-.066,0-.133-.007-.2-.007'
                    data-name='Path 4879'
                    fill='#626d80'
                    id='Path_4879'
                    transform='translate(-3695.583 -1653.908)'
                  />
                </g>
              </g>
            </g>
            <g data-name='Group 1564' id='Group_1564' opacity='0.3' transform='translate(534.559 58.067)'>
              <g data-name='Group 1563' id='Group_1563'>
                <g clipPath='url(#clip-path-7)' data-name='Group 1562' id='Group_1562'>
                  <path
                    d='M3311.553,380.9h-25.492a7.665,7.665,0,0,1-.243-15.326h0c.119,0,.239-.005.356,0a8.4,8.4,0,0,1,10.4-6.271,9.4,9.4,0,0,1,7.073-3.178h.111a9.5,9.5,0,0,1,9.329,8.938,7.966,7.966,0,0,1,4.044,13.612,8.068,8.068,0,0,1-5.58,2.226M3285.93,369.16a4.078,4.078,0,0,0,.131,8.154h25.492a4.47,4.47,0,0,0,4.471-4.244,4.4,4.4,0,0,0-4.4-4.552c-.093,0-.188,0-.29.01l-2.227.144.335-2.2a5.866,5.866,0,0,0-10.737-4.058l-.855,1.331-1.428-.682a4.753,4.753,0,0,0-2.066-.466,4.8,4.8,0,0,0-4.8,4.8c0,.056,0,.111,0,.167l.083,2.507-2.4-.731a4.082,4.082,0,0,0-1.321-.174Z'
                    data-name='Path 4880'
                    fill='#626d80'
                    id='Path_4880'
                    transform='translate(-3278.397 -356.122)'
                  />
                </g>
              </g>
            </g>
            <g data-name='Group 1567' id='Group_1567' opacity='0.3' transform='translate(0 96.328)'>
              <g data-name='Group 1566' id='Group_1566'>
                <g clipPath='url(#clip-path-8)' data-name='Group 1565' id='Group_1565'>
                  <path
                    d='M26.082,610.568H6.586a6.531,6.531,0,0,1-1.57-12.883,7.654,7.654,0,0,1,13.165-4.529A6.848,6.848,0,0,1,26.326,598a6.284,6.284,0,0,1-.244,12.563M6.528,601.1a2.941,2.941,0,0,0-2.94,3.045,2.994,2.994,0,0,0,3,2.839h19.5a2.7,2.7,0,0,0,.087-5.392h0a2.708,2.708,0,0,0-.874.115l-2.4.73.083-2.505c0-.038,0-.076,0-.114a3.251,3.251,0,0,0-3.248-3.248,3.217,3.217,0,0,0-1.4.315l-1.427.682-.855-1.331a4.033,4.033,0,0,0-3.471-1.87,4.068,4.068,0,0,0-3.969,4.682l.336,2.2L6.724,601.1c-.065,0-.13-.007-.2-.007'
                    data-name='Path 4881'
                    fill='#626d80'
                    id='Path_4881'
                    transform='translate(0.001 -590.774)'
                  />
                </g>
              </g>
            </g>
            <path
              d='M561.731,1161.141a6.71,6.71,0,0,0-2.17.287c0-.087.005-.173.005-.26a7.532,7.532,0,0,0-10.773-6.8,8.751,8.751,0,0,0-16.014,6.05c-.154-.01-.309-.016-.466-.016a7.073,7.073,0,1,0,.086,14.145h29.116a6.706,6.706,0,0,0,.214-13.409'
              data-name='Path 4882'
              fill='#fff'
              id='Path_4882'
              transform='translate(-439.599 -962.777)'
            />
            <path
              d='M2863.164,1188.194a8.424,8.424,0,0,0-2.719.36c0-.108.007-.216.007-.326a9.44,9.44,0,0,0-13.5-8.517,10.967,10.967,0,0,0-20.068,7.582c-.193-.012-.387-.019-.583-.019a8.863,8.863,0,1,0,.108,17.726h36.49a8.4,8.4,0,0,0,.267-16.8'
              data-name='Path 4883'
              fill='#fff'
              id='Path_4883'
              transform='translate(-2358.04 -983.132)'
            />
            <path
              d='M855.745,1567.6h2.494V1503a4.383,4.383,0,0,1,4.378-4.378h9.207A4.383,4.383,0,0,1,876.2,1503v64.6H878.7V1503a6.88,6.88,0,0,0-6.872-6.872h-9.207a6.88,6.88,0,0,0-6.872,6.872Z'
              data-name='Path 4884'
              fill='#fff'
              id='Path_4884'
              transform='translate(-716.212 -1252.178)'
            />
            <path
              d='M850.008,1498.2h4.365a3.446,3.446,0,0,1,3.442-3.443h9.207a3.447,3.447,0,0,1,3.443,3.443h4.365a7.817,7.817,0,0,0-7.807-7.808h-9.207a7.816,7.816,0,0,0-7.807,7.808'
              data-name='Path 4885'
              fill='#391f3a'
              id='Path_4885'
              transform='translate(-711.41 -1247.377)'
            />
            <path
              d='M741.592,1784.974h49.132a6.6,6.6,0,0,1,6.6,6.6v74.871a6.6,6.6,0,0,1-6.6,6.6H741.592a6.6,6.6,0,0,1-6.6-6.6v-74.871a6.6,6.6,0,0,1,6.6-6.6'
              data-name='Path 4886'
              fill='#0cb393'
              id='Path_4886'
              transform='translate(-615.15 -1493.925)'
            />
            <path
              d='M806.767,1830.373l-6.122,6.312a1.985,1.985,0,0,0,.043,2.807l1.425,1.382a1.984,1.984,0,0,0,2.807-.042l6.121-6.311a1.986,1.986,0,0,0-.043-2.808l-1.425-1.382a1.985,1.985,0,0,0-2.807.043m2.384.394,1.425,1.382a1.378,1.378,0,0,1,.087,1.885c-.019.021-.038.042-.058.063l-6.121,6.311a1.379,1.379,0,0,1-1.948.03l-1.425-1.382a1.378,1.378,0,0,1-.088-1.885c.019-.022.038-.043.058-.063l6.121-6.312a1.376,1.376,0,0,1,1.948-.03'
              data-name='Path 4887'
              fill='#fff'
              id='Path_4887'
              transform='translate(-669.628 -1531.417)'
            />
            <path
              d='M814.59,1838.167l-6.122,6.311-.027.03a.648.648,0,0,0,.042.886l1.425,1.382a.648.648,0,0,0,.917-.014l6.121-6.312.028-.03a.648.648,0,0,0-.042-.886l-1.425-1.383a.649.649,0,0,0-.917.015'
              data-name='Path 4888'
              fill='#fff'
              id='Path_4888'
              transform='translate(-676.49 -1538.28)'
            />
            <path
              d='M890.862,1883.884a.108.108,0,0,0-.115-.04.13.13,0,0,0-.028.008.105.105,0,0,0-.064.072l-2.6,7.515a.121.121,0,0,0,.138.158l7.806-1.51a.1.1,0,0,0,.028-.008.106.106,0,0,0,.064-.072.107.107,0,0,0-.023-.119Z'
              data-name='Path 4889'
              fill='#c6dbff'
              id='Path_4889'
              transform='translate(-743.25 -1576.672)'
            />
            <path
              d='M890.219,1882.151l-5.211-6a1.459,1.459,0,0,0-2.48.479l-2.6,7.515a1.458,1.458,0,0,0,1.656,1.908l7.806-1.51a1.459,1.459,0,0,0,.825-2.388m-.3,1.234a.831.831,0,0,1-.484.512.859.859,0,0,1-.158.046l-7.806,1.511a.851.851,0,0,1-.966-1.114l2.6-7.515a.829.829,0,0,1,.484-.511.894.894,0,0,1,.158-.046.832.832,0,0,1,.8.278l5.211,6.005a.831.831,0,0,1,.161.835'
              data-name='Path 4890'
              fill='#c6dbff'
              id='Path_4890'
              transform='translate(-736.387 -1569.81)'
            />
            <path
              d='M1068.592,1965.984V1959.8a4.272,4.272,0,0,0-4.267-4.267h-8.974a4.272,4.272,0,0,0-4.267,4.267v6.186h-2.431V1959.8a6.706,6.706,0,0,1,6.7-6.7h8.974a6.706,6.706,0,0,1,6.7,6.7v6.186Z'
              data-name='Path 4891'
              fill='#391f3a'
              id='Path_4891'
              transform='translate(-877.665 -1634.637)'
            />
            <path
              d='M890.53,2060.549h66.611a4.388,4.388,0,0,0,4.376-4.376v-42.787a4.388,4.388,0,0,0-4.376-4.375H890.53a4.388,4.388,0,0,0-4.376,4.375v42.787a4.388,4.388,0,0,0,4.376,4.376'
              data-name='Path 4892'
              fill='#00736d'
              id='Path_4892'
              transform='translate(-741.662 -1681.432)'
            />
            <path
              d='M1295.417,2009.011h4.255a4.388,4.388,0,0,1,4.375,4.375v4.254a8.655,8.655,0,0,1-8.63-8.629'
              data-name='Path 4893'
              fill='#ff96a9'
              id='Path_4893'
              transform='translate(-1084.193 -1681.432)'
            />
            <path
              d='M886.154,2017.64h0v-4.254a4.388,4.388,0,0,1,4.376-4.375h4.255a8.656,8.656,0,0,1-8.63,8.629'
              data-name='Path 4894'
              fill='#ff96a9'
              id='Path_4894'
              transform='translate(-741.662 -1681.432)'
            />
            <path
              d='M1304.048,2272.162h0v4.254a4.389,4.389,0,0,1-4.376,4.376h-4.254a8.656,8.656,0,0,1,8.63-8.63'
              data-name='Path 4895'
              fill='#ff96a9'
              id='Path_4895'
              transform='translate(-1084.194 -1901.676)'
            />
            <path
              d='M886.154,2272.162h0a8.656,8.656,0,0,1,8.63,8.63H890.53a4.389,4.389,0,0,1-4.376-4.376Z'
              data-name='Path 4896'
              fill='#ff96a9'
              id='Path_4896'
              transform='translate(-741.662 -1901.676)'
            />
            <path
              d='M1106.706,2082.725a1.985,1.985,0,0,1-2.633.976l-7.991-3.668a1.986,1.986,0,0,1-.976-2.633l2.3-5.008a1.986,1.986,0,0,1,2.633-.976l7.99,3.668a1.986,1.986,0,0,1,.977,2.632Zm1.806-6.259a1.364,1.364,0,0,0-.737-.829l-7.991-3.667a1.348,1.348,0,0,0-.573-.126,1.381,1.381,0,0,0-1.253.8l-2.3,5.008a1.378,1.378,0,0,0,.677,1.827l7.991,3.667a1.379,1.379,0,0,0,1.827-.677l2.3-5.008a1.373,1.373,0,0,0,.059-1'
              data-name='Path 4897'
              fill='#ff96a9'
              id='Path_4897'
              transform='translate(-916.392 -1733.51)'
            />
            <path
              d='M1111.765,2089.412a.635.635,0,0,1-.269-.06l-7.991-3.667a.648.648,0,0,1-.319-.859l2.3-5.009a.648.648,0,0,1,.86-.319l7.991,3.668a.649.649,0,0,1,.319.86l-2.3,5.007a.651.651,0,0,1-.59.379'
              data-name='Path 4898'
              fill='#ff96a9'
              id='Path_4898'
              transform='translate(-923.257 -1740.376)'
            />
            <path
              d='M1210.572,2179.8l8.777-.533a1.984,1.984,0,0,1,2.1,1.861l.121,1.982a1.985,1.985,0,0,1-1.862,2.1l-8.776.533a1.985,1.985,0,0,1-2.1-1.862l-.12-1.981a1.986,1.986,0,0,1,1.861-2.1m-1.255,2.066.12,1.981a1.378,1.378,0,0,0,1.373,1.294l.085,0,8.776-.533a1.378,1.378,0,0,0,1.292-1.458l-.12-1.981a1.379,1.379,0,0,0-1.373-1.295l-.086,0-8.776.532a1.379,1.379,0,0,0-1.292,1.46'
              data-name='Path 4899'
              fill='#ff96a9'
              id='Path_4899'
              transform='translate(-1011.622 -1823.921)'
            />
            <path
              d='M1217.517,2187.994l8.776-.532h.042a.648.648,0,0,1,.645.608l.12,1.982a.648.648,0,0,1-.608.686l-8.776.533h-.041a.648.648,0,0,1-.645-.609l-.12-1.982a.647.647,0,0,1,.607-.686'
              data-name='Path 4900'
              fill='#ff96a9'
              id='Path_4900'
              transform='translate(-1018.486 -1830.784)'
            />
            <path
              d='M1215.62,2091.247a.109.109,0,0,1,.093-.079l.029,0a.105.105,0,0,1,.085.043l5.173,6.037a.108.108,0,0,1,.022.119.109.109,0,0,1-.092.08l-7.815,1.461a.138.138,0,0,1-.029,0,.1.1,0,0,1-.085-.044.106.106,0,0,1-.022-.119Z'
              data-name='Path 4901'
              fill='#fff'
              id='Path_4901'
              transform='translate(-1015.188 -1750.191)'
            />
            <path
              d='M1204.853,2091.44l2.642-7.5a1.459,1.459,0,0,1,2.483-.464l5.173,6.038a1.458,1.458,0,0,1-.839,2.383l-7.815,1.461a1.459,1.459,0,0,1-1.644-1.919m.73,1.038a.828.828,0,0,0,.638.3.848.848,0,0,0,.164-.016l7.816-1.46a.851.851,0,0,0,.489-1.391l-5.173-6.037a.832.832,0,0,0-.639-.3.866.866,0,0,0-.164.016.831.831,0,0,0-.646.553l-2.642,7.5a.829.829,0,0,0,.157.836'
              data-name='Path 4902'
              fill='#fff'
              id='Path_4902'
              transform='translate(-1008.325 -1743.329)'
            />
            <path
              d='M2571.991,2083.493h-85.1V2046.26a9.836,9.836,0,0,1,9.836-9.835h65.43a9.835,9.835,0,0,1,9.836,9.835Z'
              data-name='Path 4903'
              fill='#626d80'
              id='Path_4903'
              transform='translate(-2081.39 -1704.376)'
            />
            <path
              d='M2577.461,2109.1h-81.532a.221.221,0,0,1,0-.442h81.532a.221.221,0,1,1,0,.442'
              data-name='Path 4904'
              fill='#00736d'
              id='Path_4904'
              transform='translate(-2088.771 -1764.835)'
            />
            <path
              d='M2662.979,2016.865h-33.266a1.6,1.6,0,0,0,0,3.209h33.266a1.6,1.6,0,0,0,0-3.209'
              data-name='Path 4905'
              fill='#98514c'
              id='Path_4905'
              transform='translate(-2199.583 -1688.005)'
            />
            <path
              d='M2661.689,1987.144H2631a2.9,2.9,0,0,1,0-5.79h30.684a2.9,2.9,0,0,1,0,5.79'
              data-name='Path 4906'
              fill='#ff96a9'
              id='Path_4906'
              transform='translate(-2199.584 -1658.285)'
            />
            <path
              d='M2662.979,1961.673h-33.266a1.6,1.6,0,0,0,0,3.209h33.266a1.6,1.6,0,0,0,0-3.209'
              data-name='Path 4907'
              fill='#49498e'
              id='Path_4907'
              transform='translate(-2199.583 -1641.813)'
            />
            <path
              d='M2678.5,1931.952h-23.386a2.567,2.567,0,0,1-2.559-2.56v-.671a2.566,2.566,0,0,1,2.559-2.559H2678.5a2.567,2.567,0,0,1,2.559,2.559v.671a2.567,2.567,0,0,1-2.559,2.56'
              data-name='Path 4908'
              fill='#6766aa'
              id='Path_4908'
              transform='translate(-2220.041 -1612.092)'
            />
            <path
              d='M2814.353,1869.523h-6.121v1.051h12.241v-1.051Z'
              data-name='Path 4909'
              fill='#e5efff'
              id='Path_4909'
              transform='translate(-2350.336 -1564.689)'
            />
            <path
              d='M2820.472,1875.971H2808.23a12.4,12.4,0,0,0,.426,2.541c.451,1.977,1.627,17.373,1.652,18.474s.05,1.252.5,1.252h7.084c.45,0,.475-.15.5-1.252s1.2-16.5,1.652-18.474a12.276,12.276,0,0,0,.426-2.541'
              data-name='Path 4910'
              fill='#f26881'
              id='Path_4910'
              transform='translate(-2350.334 -1570.085)'
            />
            <path
              d='M2829.3,2012.535h-7.479a.2.2,0,0,0-.2.2v2.838a.9.9,0,0,0,.9.9h6.069a.9.9,0,0,0,.9-.9v-2.838a.2.2,0,0,0-.2-.2'
              data-name='Path 4911'
              fill='#e5efff'
              id='Path_4911'
              transform='translate(-2361.548 -1684.381)'
            />
            <path
              d='M2859.747,2008.323h-10.276a.454.454,0,0,1-.454-.454v-5.07H2860.2v5.07a.454.454,0,0,1-.454.454'
              data-name='Path 4912'
              fill='#e33656'
              id='Path_4912'
              transform='translate(-2384.471 -1676.233)'
            />
            <path
              d='M2849.016,1983.256v-1.67a2.138,2.138,0,0,1,2.138-2.138h6.908a2.138,2.138,0,0,1,2.137,2.138v1.67Z'
              data-name='Path 4913'
              fill='#fff'
              id='Path_4913'
              transform='translate(-2384.47 -1656.69)'
            />
          </g>
        </g>
      </g>
    </svg>
  </>
);
export default KlThankYouPageImage;
