import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CustomerDashboard from '../../containers/CustomerDashboard/CustomerDashboard';
import { Container } from 'react-bootstrap';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as styles from './styles';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import UserProfileEdit from '../../components/UserProfile/UserProfileEdit/UserProfileEdit';
import UserProfileShow from '../../components/UserProfile/UserProfileShow/UserProfileShow';
import UserProfilePhones from '../../components/UserProfile/UserProfilePhonesShow/UserProfilePhonesShow';
import { UserProfileState } from '../../components/UserProfile/UserProfileState';

export default observer(() => {
  const { userProfileStore, userStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [userProfileState, setUserProfileState] = useState<UserProfileState>(UserProfileState.ShowProfile);

  useEffect(() => {
    refredhData();
  }, []);

  const refredhData = () => {
    setLoading(true);

    userProfileStore
      .getUserProfile()
      .then(() =>
        userProfileStore
          .getUserProfilePhones()
          .then(() => {
            setLoading(false);
            if (!ifProfileIsSupplemented()) {
              setUserProfileState(UserProfileState.EditProfile);
            }
          })
          .catch(() => {
            setLoading(false);
            toast.error('Wystąpił błąd przy pobieraniu numerów telefonów');
          }),
      )
      .catch(() => {
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych profilowych');
      });
  };

  const refreshView = (userProfileState: UserProfileState, refresh: boolean) => {
    if (refresh) {
      refredhData();
    }

    setUserProfileState(userProfileState);
  };

  const ifProfileIsSupplemented = () => {
    return (
      userProfileStore.userProfile &&
      userProfileStore.userProfile.firstName &&
      userProfileStore.userProfile.firstName.trim() &&
      userProfileStore.userProfile.lastName &&
      userProfileStore.userProfile.lastName.trim() &&
      userProfileStore.userProfile.pesel &&
      userProfileStore.userProfile.pesel.trim()
    );
  };

  const renderComponent = () => {
    if (userProfileStore.userProfile && userProfileStore.userProfilePhones) {
      switch (userProfileState) {
        case UserProfileState.ShowProfile:
          return <UserProfileShow refreshView={refreshView} userProfile={userProfileStore.userProfile} />;
        case UserProfileState.EditProfile:
          return <UserProfileEdit refreshView={refreshView} userProfile={userProfileStore.userProfile} />;
        case UserProfileState.ShowPhones:
          return <UserProfilePhones refreshView={refreshView} userProfilePhones={userProfileStore.userProfilePhones} />;
        default:
          return <UserProfileShow refreshView={refreshView} userProfile={userProfileStore.userProfile} />;
      }
    }
  };

  return (
    <>
      {userStore.isLoggedIn ? (
        userStore.isActive ? (
          <>
            <LoadingComponent content='Ładowanie...' visible={loading} />
            <CustomerDashboard>
              <div className='col-md-6 offset-md-3'>
                <div
                  className='d-flex flex-column align-items-center justify-content-between w-100'
                  style={styles.mainDivEmptyCard as React.CSSProperties}>
                  <Container className='userProfile' fluid>
                    {renderComponent()}
                  </Container>
                </div>
              </div>
            </CustomerDashboard>
          </>
        ) : (
          history.push(PathRoute.AUTHORIZE_TOKEN)
        )
      ) : (
        history.push(PathRoute.LOGIN)
      )}
    </>
  );
});
