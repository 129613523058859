import React from 'react';
import { openLink } from '../../../utils/linkUtils';
import { twMerge } from '../../../index';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';

export const SportCardPzuSubscriptionInfo: React.FC = () => {
  return (
    <div className=' tw-bg-white'>
      {/* Features List */}
      <ul className='tw-mb-4 tw-list-inside tw-list-disc tw-text-nau-sea-green'>
        <li>Ponad 3 600 obiektów sportowych w całej Polsce.</li>
        <li>Ponad 30 różnych rodzajów zajęć.</li>
        <li>
          Dostępne obiekty rekreacyjno-sportowe można wyszukiwać na stronie:{' '}
          <span
            className='tw-cursor-pointer tw-text-blue-500'
            onClick={() => openLink('https://sport.pzu.pl/znajdz-obiekt')}>
            https://sport.pzu.pl/znajdz-obiekt
          </span>
        </li>
      </ul>

      <div className='tw-mb-4 tw-pt-8 tw-text-nau-sea-green'>
        <h2 className='tw-text-xl tw-font-bold '>Abonament Komfort</h2>
        <p className=''>
          - obowiązuje limit <strong>8 wejść w miesiącu</strong>
        </p>
      </div>

      <div className='tw-mb-4 tw-rounded-xl tw-border tw-border-gray-300 tw-bg-white tw-text-black tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'>
        <SubpageHeader headerClassName={twMerge('tw-text-xl ')} title={'Miesięczna cena abonamentu PZU SPORT:'} />
        <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-200'}>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Ciebie</td>
            <td className=''>68 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Osoby Towarzyszącej</td>
            <td className=''>119 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Dziecka*</td>
            <td className=''>75 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Dziecka Basenowego*</td>
            <td className=''>48 zł</td>
          </div>
        </div>
      </div>

      <p className='tw-mb-6  tw-text-xs tw-text-nau-sea-green'>
        * Oferta dla dzieci jest ważna do osiągnięcia 18 roku życia.
      </p>

      <div className='tw-mb-4 tw-pt-8 tw-text-nau-sea-green'>
        <h2 className='tw-text-xl tw-font-bold '>Abonament Optymalny</h2>
        <p className=''>
          - obowiązuje limit <strong>1 wejście dziennie</strong>
        </p>
      </div>

      {/* Pricing Table */}
      <div className='tw-mb-4 tw-rounded-xl tw-border tw-border-gray-300 tw-bg-white tw-text-black tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'>
        <SubpageHeader headerClassName={twMerge('tw-text-xl ')} title={'Miesięczna cena abonamentu PZU SPORT:'} />
        <div className={'tw-flex tw-flex-col tw-divide-y tw-divide-gray-200'}>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Ciebie</td>
            <td className=''>105 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Osoby Towarzyszącej</td>
            <td className=''>149 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Dziecka*</td>
            <td className=''>85 zł</td>
          </div>
          <div className={'p-2 tw-flex tw-justify-between'}>
            <td className=''>Dla Dziecka Basenowy*</td>
            <td className=''>54 zł</td>
          </div>
        </div>
      </div>

      <p className='tw-mb-6  tw-text-xs tw-text-nau-sea-green'>
        * Oferta dla dzieci jest ważna do osiągnięcia 18 roku życia.
      </p>

      {/* Additional Info */}
      <div className='tw-space-y-4  tw-text-nau-sea-green'>
        <p>
          <strong>Abonament PZU SPORT</strong> możesz zamówić do 15. dnia miesiąca, a opłata musi zostać dokonana
          najpóźniej do 18. dnia miesiąca, aby abonament był aktywny od 1. dnia kolejnego miesiąca. Zamówienia opłacone
          po 18. dniu miesiąca zostaną aktywowane tak, jakby zostały złożone w następnym miesiącu. Na przykład,
          abonament opłacony 18 października 2024 r. będzie ważny od 1 listopada 2024 r., a abonament opłacony 20
          października 2024 r. będzie ważny od 1 grudnia 2024 r.
        </p>
        <p>
          Do korzystania z PZU SPORT i dedykowanego modułu w aplikacji <strong>mojePZU mobile</strong> niezbędny jest
          telefon z systemem Android (od wersji 8.0) lub iOS (od wersji 15).
        </p>
      </div>
    </div>
  );
};
