import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { MyTextMaskedInputProps } from './types';
import InputMask from 'react-input-mask';

export default function MyTextMaskedInput(props: MyTextMaskedInputProps) {
  const [field, { error, touched }] = useField(props.name);

  return (
    <Form.Group>
      {props.type && props.type == 'hidden' ? null : !props.hiddenLabel && <Form.Label>{props.label}</Form.Label>}
      <InputMask
        className={
          touched && !!error ? `form-control is-invalid ${props.className}` : `form-control ${props.className}`
        }
        {...field}
        {...props}
      />

      {props.type && props.type == 'hidden' ? null : (
        <Form.Control.Feedback type='invalid'>
          {touched && error ? <div className='tw-mt-1 tw-text-left tw-text-sm tw-text-red-600'>{error}</div> : null}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
