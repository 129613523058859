export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
};

export const widthButton = {
  margin: '0 20px',
};
