import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import MyButton from '../Buttons/MyButton/MyButton';
import * as Yup from 'yup';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import agent from '../../api/agent';
import { UserAddress, UserProfileNewModel } from '../../types/user';
import _ from 'lodash';
import RegexHelpers from '../../helpers/RegexHelpers';
import ValidationHelper from '../../helpers/ValidationHelper';
import { AddressPromptingForm } from '../Address/AddressPromptingForm';

const personalDataValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Pole jest wymagane'),
  lastName: Yup.string().required('Pole jest wymagane'),
  pesel: Yup.string()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
  userMainAddress: Yup.object().shape({
    street: Yup.string().required('Pole jest wymagane'),
    houseNumber: Yup.string().required('Pole jest wymagane'),
    postCode: Yup.string().required('Pole jest wymagane'),
    place: Yup.string().required('Pole jest wymagane'),
  }),
});

const defaultAddress: UserAddress = {
  id: 0,
  postCode: '',
  place: '',
  commune: '',
  post: '',
  street: '',
  houseNumber: '',
  flatNumber: '',
  district: '',
  country: 0,
  isCorrespondence: false,
};

export interface IPersonalDataForm {
  onSave?: () => void;
}

const PersonalDataForm = observer((props: IPersonalDataForm) => {
  const { userProfileStore } = useStore();

  const [userProfile, setUserProfile] = useState<UserProfileNewModel | undefined>(undefined);

  useEffect(() => {
    agent.UserProfile.getUserProfileNew().then((resp) => {
      setUserProfile(resp);
    });
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: userProfile?.firstName ?? '',
        lastName: userProfile?.lastName ?? '',
        pesel: userProfile?.pesel ?? '',
        userMainAddress: userProfile?.userMainAddress ?? defaultAddress,
        userCorrespondenceAddress: userProfile?.userCorrespondenceAddress ?? defaultAddress,
        canEditPesel: userProfile?.canEditPesel,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const valuesCopy = _.cloneDeep(values);
        valuesCopy.userCorrespondenceAddress = _.cloneDeep(valuesCopy.userMainAddress);
        valuesCopy.userCorrespondenceAddress.isCorrespondence = true;

        agent.UserProfile.editUserProfileNew(valuesCopy)
          .then((resp) => {
            props.onSave?.();
          })
          .finally(() => setSubmitting(false));
      }}
      validationSchema={personalDataValidationSchema}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <div
            className={
              'tw-mx-auto tw-max-w-sm tw-pb-8 tw-text-center tw-text-base tw-text-nau-green-dark sm:tw-max-w-lg'
            }>
            Prosimy o wypełnienie swoich danych osobowych, które pomogą nam zweryfikować, czy jesteś naszym klientem.
            Dla nowych klientów mamy również przygotowane specjalne korzyści.
          </div>
          <div className={'tw-flex tw-justify-center tw-px-2'}>
            <div
              className={
                'tw-flex tw-w-full tw-max-w-sm tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-font-normal'
              }>
              <MyTextInput name='firstName' placeholder='Imię' wrapperClassName={'tw-w-full'} />
              <MyTextInput name='lastName' placeholder='Nazwisko' wrapperClassName={'tw-w-full'} />
              <MyTextInput
                disabled={!!userProfile?.pesel}
                name='pesel'
                placeholder='PESEL'
                wrapperClassName={'tw-w-full'}
              />
              <AddressPromptingForm
                cityFormikName={'userMainAddress.place'}
                flatNoFormikName={'userMainAddress.flatNumber'}
                houseNoFormikName={'userMainAddress.houseNumber'}
                placeholderInsteadOfLabel={true}
                postCodeFormikName={'userMainAddress.postCode'}
                streetFormikName={'userMainAddress.street'}
              />
            </div>
          </div>

          <MyButton className={'tw-mt-8 tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
            Zapisz dane
          </MyButton>
        </Form>
      )}
    </Formik>
  );
});

export default PersonalDataForm;
