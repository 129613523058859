import { useField } from 'formik';
import { Form, Image } from 'react-bootstrap';
import { errorLabel } from '../styles';
import { MyPasswordInputProps } from './types';
import { useState } from 'react';

export default function MyTextInput(props: MyPasswordInputProps) {
  const [field, { error, touched }] = useField(props.name);
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <Form.Group>
      {!props.hiddenLabel && <Form.Label>{props.label}</Form.Label>}
      <Form.Control isInvalid={touched && !!error} type={passwordShown ? 'text' : 'password'} {...field} {...props} />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        {touched && error ? (
          <Image
            onMouseDown={() => setPasswordShown(true)}
            onMouseUp={() => setPasswordShown(false)}
            onTouchEnd={() => setPasswordShown(false)}
            onTouchStart={() => setPasswordShown(true)}
            src={'/assets/show.svg'}
            style={{
              cursor: 'pointer',
              marginTop: '-37px',
              marginRight: '35px',
              width: '25px',
              filter: 'invert(15%) sepia(100%) saturate(3584%) hue-rotate(346deg) brightness(110%) contrast(73%)',
            }}
          />
        ) : (
          <Image
            onMouseDown={() => setPasswordShown(true)}
            onMouseUp={() => setPasswordShown(false)}
            onTouchEnd={() => setPasswordShown(false)}
            onTouchStart={() => setPasswordShown(true)}
            src={'/assets/show.svg'}
            style={{ cursor: 'pointer', marginTop: '-40px', marginRight: '20px', width: '25px' }}
          />
        )}
      </div>
      <Form.Control.Feedback type='invalid'>
        {touched && error ? <label style={errorLabel}>{error}</label> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
