import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import FileHelper from '../../../helpers/FileHelper';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import ReactCodeInput from 'react-code-input';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { DiscountCodeContractStatusE } from '../../../enums/discountCodeContractStatus.enums';
import useResendSmsTimer from '../../../hooks/useResendSmsTimer';

export default observer(() => {
  const { discountCodeStore, fileStore } = useStore();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [pin, setPin] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { remainingSecondsSmsDisabled, setIsResendSmsDisabled, isResendSmsDisabled, enableResendSms } =
    useResendSmsTimer();

  useEffect(() => {
    discountCodeStore.getUserDiscountCodeContractStatus().then((contractStatus) => {
      if (contractStatus !== DiscountCodeContractStatusE.New) {
        history.push(PathRoute.DISCOUNT_CODE);
        return;
      }
      setLoading(false);
      generateSmsToken();
    });
  }, []);

  const generateSmsToken = () => {
    if (isResendSmsDisabled) {
      toast.error(`Proszę poczekać ${remainingSecondsSmsDisabled} sekund na wysłanie kolejnego kodu.`);
      return;
    }
    setIsResendSmsDisabled(true);
    discountCodeStore
      .generateTokenToDiscountCodeContract()
      .then(() => {
        toast.success('Sms został wysłany!');
      })
      .catch(() => {
        enableResendSms();
      });
  };
  const handleChangePin = (e: any) => setPin(e);
  const handlePdf = () => {
    if (!downloading) {
      setDownloading(true);
      fileStore
        .getDiscountCodeContractPdf()
        .then((resp) => {
          const fileName = 'umowa_nau.pdf';
          FileHelper.downloadFileFromBlob(resp, fileName);
          setDownloading(false);
        })
        .catch(() => {
          setDownloading(false);
          toast.error('Wystąpił błąd przy pobieraniu pliku');
        });
    }
  };
  const signContract = () => {
    setIsSubmitting(true);
    discountCodeStore
      .signDiscountCodeContract(pin)
      .then(() => {
        toast.success('Umowa została podpisana. Przekazano prośbę o akceptację.');
        setIsSubmitting(false);

        history.push(PathRoute.DISCOUNT_CODE);
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.error('Wystąpił błąd podczas podpisywania umowy!');
      });
  };

  return (
    <CustomerDashboard>
      {loading ?? <LoadingComponent content='Ładowanie...' />}
      <div className='col-md-6 offset-md-3'>
        <DiscountCodeHeader />
        <div style={styles.containerBackground as React.CSSProperties}>
          <div style={styles.containerConfirm as React.CSSProperties}>
            <div style={styles.flexStart as React.CSSProperties}>
              <p style={styles.confirmTitle as React.CSSProperties}>Potwierdzenie zawarcia Umowy ramowej</p>
            </div>
            <div style={styles.containerCenter as React.CSSProperties}>
              <div style={styles.displayFlex as React.CSSProperties}>
                <p style={styles.marginBottomLeft as React.CSSProperties}>
                  <a onClick={() => handlePdf()} style={styles.hrefLink as React.CSSProperties}>
                    Pobierz Umowę ramową
                  </a>{' '}
                </p>
                <Image src='/assets/arrowRightCash.svg' style={styles.heightCursor as React.CSSProperties} />
              </div>
            </div>

            <div style={styles.flexWeightCenter as React.CSSProperties}>
              <p>Podaj KOD SMS</p>
            </div>
          </div>
          <div style={styles.pinContainer as React.CSSProperties}>
            <ReactCodeInput
              fields={6}
              inputMode='numeric'
              name='numeric'
              onChange={(e) => handleChangePin(e)}
              type='password'
              value={pin}
            />
          </div>
          <div onClick={() => generateSmsToken()} style={styles.textSMS as React.CSSProperties}>
            <p>Wyślij ponownie SMS</p>
          </div>
          <div style={styles.flexCenter as React.CSSProperties}>
            {isSubmitting ? (
              <Button disabled={true} style={styles.widthButton as React.CSSProperties}>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Potwierdzanie…
              </Button>
            ) : (
              <Button onClick={() => signContract()} style={styles.widthButton as React.CSSProperties}>
                Potwierdź zamówienie
              </Button>
            )}
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
});
