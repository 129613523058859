import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { DiscountCodeContractStatusE } from '../../enums/discountCodeContractStatus.enums';
import {
  DiscountCodeCampaignM,
  DiscountCodeContractData,
  DiscountCodeData,
  DiscountCodeLeadM,
} from '../../types/discountCode';
import { UserAvailableBenefits } from '../../types/user';
import { BirthdayCodeCampaignOrderM } from '../../types/birthdayCode';

export default class DiscountCodeStore {
  reaminingLimitDiscountCode = 0;
  reaminingDays = 0;
  limitDiscountCode = 0;
  discountCodeAmount = 0;
  hasDiscountCodePermission = false;
  contractStatus: number | null = null;
  isBlocked = false;
  hasInstitutionalBenefit = false;
  availableBenefits: UserAvailableBenefits[] = [];
  availableBenefitId: number | null = null;
  availableBenefitIsInstitutional: boolean | null = null;
  discountCodeContractId: number | null = null;
  userDiscountCodes: DiscountCodeData[] = [];
  hasApprovals = false;
  constructor() {
    makeAutoObservable(this);
  }
  reset = () => {
    this.reaminingLimitDiscountCode = 0;
    this.reaminingDays = 0;
    this.limitDiscountCode = 0;
    this.discountCodeAmount = 0;
    this.hasDiscountCodePermission = false;
    this.isBlocked = false;
    this.hasInstitutionalBenefit = false;
    this.availableBenefits = [];
    this.availableBenefitId = null;
    this.availableBenefitIsInstitutional = null;
    this.discountCodeContractId = null;
    this.userDiscountCodes = [];
  };
  setApprovals = async (hasApprovals: boolean) => {
    runInAction(() => {
      this.hasApprovals = hasApprovals;
    });
  };
  checkUserHasPermission = async () => {
    try {
      let hasAcceptedContract = false;
      const status = await this.getUserDiscountCodeContractStatus();
      hasAcceptedContract = status == DiscountCodeContractStatusE.Active;
      runInAction(() => {
        // @ts-ignore
        this.hasDiscountCodePermission = hasAcceptedContract;
      });
    } catch (e) {
      console.error(e);
    }
  };
  getUserDiscountCodeContractId = async () => {
    try {
      const response = await agent.DiscountCodes.getUserDiscountCodeContractId();
      runInAction(() => {
        // @ts-ignore
        this.discountCodeContractId = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  getUserDiscountCodeContractStatus = async () => {
    try {
      const response = await agent.DiscountCodes.getUserDiscountCodeContractStatus();
      runInAction(() => {
        this.contractStatus = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  saveDiscountCodeContractUserData = async (discountCodeContractData: DiscountCodeContractData) => {
    try {
      return await agent.DiscountCodes.saveDiscountCodeContractUserData(discountCodeContractData);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveDiscountCodeContractAddressData = async (
    street: string,
    houseNo: string,
    flatNo: string,
    place: string,
    postCode: string,
    correspondenceStreet: string,
    correspondenceHouseNo: string,
    correspondenceFlatNo: string,
    correspondencePlace: string,
    correspondencePostCode: string,
    differentCorrespondece: boolean,
  ) => {
    try {
      return await agent.DiscountCodes.saveDiscountCodeContractAddressData(
        street,
        houseNo,
        flatNo,
        place,
        postCode,
        correspondenceStreet,
        correspondenceHouseNo,
        correspondenceFlatNo,
        correspondencePlace,
        correspondencePostCode,
        differentCorrespondece,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getDiscountCodeContractUserData = async () => {
    try {
      return agent.DiscountCodes.getDiscountCodeContractUserData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getDiscountCodeContractAddressData = async () => {
    try {
      return agent.DiscountCodes.getDiscountCodeContractAddressData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveDiscountCodeContractFile = async (formData: FormData) => {
    return agent.DiscountCodes.sendContractFile(formData);
  };

  signDiscountCodeContract = async (token: string) => {
    try {
      return await agent.DiscountCodes.signDiscountCodeContract(token);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  generateTokenToDiscountCodeContract = async () => {
    try {
      return await agent.DiscountCodes.generateTokenToDiscountCodeContract();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getAvailableDiscountCodeTypes = async (): Promise<DiscountCodeCampaignM[]> => {
    try {
      return agent.DiscountCodes.getAvailableDiscountCodeTypes();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  orderDiscountCodes = async (request: DiscountCodeCampaignM[]): Promise<DiscountCodeCampaignM[] | string> => {
    try {
      return agent.DiscountCodes.orderDiscountCodes(request);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getDiscountCodeOrderData = async (id: string) => {
    try {
      return agent.DiscountCodes.getDiscountCodeOrderData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserDiscountCodeOrders = async () => {
    try {
      return agent.DiscountCodes.getUserDiscountCodeOrders();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  requestDiscountCodeOrderAuthorizationToken = async (id: string) => {
    try {
      return agent.DiscountCodes.requestDiscountCodeOrderAuthorizationToken(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  verifyDiscountCodeOrderToken = async (id: string, token: string) => {
    try {
      return agent.DiscountCodes.verifyDiscountCodeOrderToken(id, token);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserDiscountCodes = async () => {
    try {
      return agent.DiscountCodes.getUserDiscountCodes();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getInstitutionalDiscountCodesByUser = async () => {
    try {
      return agent.DiscountCodes.getInstitutionalDiscountCodesByUser();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkHasInstitutionalDiscountCodesByUser = async () => {
    try {
      return agent.DiscountCodes.checkHasInstitutionalDiscountCodesByUser();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  changeDiscountCodeUsed = async (id: number, used: boolean) => {
    try {
      return agent.DiscountCodes.changeDiscountCodeUsed(id, used);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserBirthdayCodeCampaigns = async () => {
    try {
      return agent.BirthdayCodes.getUserBirthdayCodeCampaigns();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  orderBirthdayCode = async (birthdayCodeCampaignOrder: BirthdayCodeCampaignOrderM): Promise<string> => {
    try {
      return agent.BirthdayCodes.orderBirthdayCode(birthdayCodeCampaignOrder);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserBirthdayCodes = async () => {
    try {
      return agent.BirthdayCodes.getUserBirthdayCodes();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  changeDiscountBirthdayCodeUsed = async (id: number, used: boolean) => {
    try {
      return agent.BirthdayCodes.changeBirthdayCodeUsed(id, used);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  sendBirthdayCodeLead = async (discountCodeLead: DiscountCodeLeadM) => {
    try {
      return agent.BirthdayCodes.sendBirthdayCodeLead(discountCodeLead);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
