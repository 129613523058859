import React from 'react';
import * as styles from './styles';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import DiscountCodesInstructionContent from '../../../components/DiscountCode/DiscountCodesInstruction/DiscountCodesInstructionContent';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import StartDiscountCode from './StartDiscountCode';

const StartDiscountCodeContainer = () => (
  <CustomerDashboard>
    <StartDiscountCode />
  </CustomerDashboard>
);

export default StartDiscountCodeContainer;
