import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import * as styles from './styles';
import _ from 'lodash';

export default observer(
  (props: { isSubmitting: boolean; cancelHandler: () => void; buttonTextSubmit: string; buttonTextCancel: string }) => {
    return (
      <div style={_.merge({}, styles.buttonsStyle as React.CSSProperties)}>
        {props.isSubmitting ? (
          <>
            <Row style={styles.buttonSpacing as React.CSSProperties}>
              <Button disabled type='submit' variant='primary'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                {props.buttonTextSubmit}...
              </Button>
            </Row>

            <Row style={styles.buttonSpacing as React.CSSProperties}>
              <Button className='btn-danger' disabled onClick={props.cancelHandler}>
                {props.buttonTextCancel}
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Row style={styles.buttonSpacing as React.CSSProperties}>
              <Button type='submit' variant='primary'>
                {props.buttonTextSubmit}
              </Button>
            </Row>

            <Row style={styles.buttonSpacing as React.CSSProperties}>
              <Button className='btn-danger' onClick={props.cancelHandler}>
                {props.buttonTextCancel}
              </Button>
            </Row>
          </>
        )}
      </div>
    );
  },
);
