import React from 'react';
import { Button, Image } from 'react-bootstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { UserMarketingData } from '../../../types/user';
import { TradeUnionTypesE } from '../../../enums/tradeUnionTypes.enums';
import { UserPromotionE } from '../../../enums/userPromotion.enums';

interface IWalletNauSpecialOfferCardProps {
  userMarketingData: UserMarketingData;
  handleClickNext: () => void;
}

const WalletNauSpecialOfferCard = (props: IWalletNauSpecialOfferCardProps) => {
  const getSpecialOfferText = () => {
    if (props.userMarketingData.isNauWorker) {
      return (
        <div>
          Jako <span className={styles.bold}>pracownik NAU</span> otrzymujesz:
          <br />• oprocentowanie <span className={styles.bold}>0%</span> przez pierwsze 3 miesiące +{' '}
          <span className={styles.bold}>prowizję 0%</span>
          <br />• E-bon do Biedronki o wartości 30zł za pierwszą wypłatę w czasie trwania promocji
          <br />• oprocentowanie <span className={styles.bold}>9,90%</span> po zakończeniu okresu promocyjnego +{' '}
          <span className={styles.bold}>prowizję 5%</span>
        </div>
      );
    }
    if (props.userMarketingData.isZnp || props.userMarketingData.tradeUnionId == TradeUnionTypesE.ZnpRybnik) {
      return (
        <div className={'text-center'}>
          Jako <span className={styles.bold}>członek ZNP</span> otrzymujesz niższe, promocyjne oprocentowanie w
          wysokości <span className={styles.bold}>9,90%</span>
        </div>
      );
    }
    if (props.userMarketingData.hasBr) {
      return (
        <div className={'text-center'}>
          Jako <span className={styles.bold}>ubezpieczony w programie "Bezpieczna rodzina"</span> otrzymujesz niższe,
          promocyjne oprocentowanie w wysokości <span className={styles.bold}>9,90%</span>
        </div>
      );
    }
    if (
      props.userMarketingData.tradeUnionId == TradeUnionTypesE.ElectroMachinist ||
      props.userMarketingData.tradeUnionId == TradeUnionTypesE.Miners ||
      props.userMarketingData.tradeUnionId == TradeUnionTypesE.SolidarityPodbeskidzie ||
      props.userMarketingData.tradeUnionId == TradeUnionTypesE.Opzz,
      props.userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolStuff)
    ) {
      return (
        <div className={'text-center'}>
          Otrzymujesz niższe, promocyjne oprocentowanie w wysokości <span className={styles.bold}>9,90%</span>
        </div>
      );
    }
    if (props.userMarketingData.tradeUnionId == TradeUnionTypesE.JusticeSystem) {
      return <div className={'text-center'}>Otrzymujesz promocyjne oprocentowanie w wysokości 0% do 31.01.2024.</div>;
    }
    if (props.userMarketingData.isTeacherPromo) {
      return (
        <div className={'text-center'}>
          Jako <span className={styles.bold}>Nauczyciel VIP</span> otrzymujesz promocyjne oprocentowanie w wysokości 0%
          do 31.01.2024.
        </div>
      );
    }
  };

  return (
    <div className={styles.specialOfferContainer}>
      <div className={classNames(styles.headingTextBold)}>Dziękujemy za skorzystanie z benefitu "Portfel NAU".</div>
      <div className={styles.imageWrapper}>
        <Image src='assets/walletGreen.svg' style={{ height: '80px' }} />
      </div>
      <div className={styles.headingText}>{getSpecialOfferText()}</div>
      <div className={styles.lightText}> Standardowe oprocentowanie to 19,99%</div>
      <Button className='btn-full-width' onClick={props.handleClickNext}>
        Dalej
      </Button>
    </div>
  );
};

export default WalletNauSpecialOfferCard;
