import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import * as style from './styles';
import { history } from '../../../index';
import { useStore } from '../../../store/store';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import WalletNauHeader from '../../../components/WalletNau/WalletNauHeader/DiscountCodeHeader';
import EarningDocumentsForm from '../../../components/EarningDocumentsForm/EarningDocumentsForm';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { WalletNauNewContractStatuses } from '../../../enums/WalletNauContractStatus.enums';

const UserFileContractFormWalletNau = () => {
  const { walletNauStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    walletNauStore.getWalletContractStatus().then((contractStatus) => {
      if (!WalletNauNewContractStatuses.includes(contractStatus ?? 0)) {
        history.push(PathRoute.WALLET_NAU);
        return;
      }
      setLoading(false);
    });
  }, []);

  const uploadFile = async (file: string | Blob, filename: string | Blob) => {
    const formData = new FormData();
    await formData.append('formFile', file);
    await formData.append('fileName', filename);
    await walletNauStore
      .saveWalletNauContractFile(formData)
      .then(() => {
        history.push(PathRoute.WALLET_NAU);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <CustomerDashboard>
      {loading && <LoadingComponent content='Ładowanie...' />}
      <div className='col-md-6 offset-md-3' style={style.container}>
        <WalletNauHeader />
        <EarningDocumentsForm onFileUpload={uploadFile} />
      </div>
    </CustomerDashboard>
  );
};

export default UserFileContractFormWalletNau;
