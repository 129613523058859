import { useEffect, useState } from 'react';

function useResendSmsTimer(initialDelaySeconds = 30) {
  const [isResendSmsDisabled, setIsResendSmsDisabled] = useState(false);
  const [remainingSecondsSmsDisabled, setRemainingSecondsSmsDisabled] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (isResendSmsDisabled) {
      // Start a timer to update the remaining seconds
      setRemainingSecondsSmsDisabled(initialDelaySeconds);
      timer = setInterval(() => {
        setRemainingSecondsSmsDisabled((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            setIsResendSmsDisabled(false); // Enable the button when the timer expires
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, [isResendSmsDisabled]);

  // Function to explicitly enable the button
  const enableResendSms = () => {
    setIsResendSmsDisabled(false);
    setRemainingSecondsSmsDisabled(0);
  };

  return {
    isResendSmsDisabled,
    remainingSecondsSmsDisabled: remainingSecondsSmsDisabled,
    enableResendSms: enableResendSms, // Function to explicitly enable the button
    setIsResendSmsDisabled: setIsResendSmsDisabled, // Function to set the disabled state externally
  };
}

export default useResendSmsTimer;
