import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { useStore } from '../../../store/store';
import WalletNauInstructionFooter from '../WalletNauInstruction/WalletNauInstructionFooter/WalletNauInstructionFooter';
import { WalletSpecialOfferTypeE } from '../../../enums/walletSpecialOfferType.enums';
import WalletNauInstructionBankFooter from '../WalletNauInstruction/WalletNauInstructionBankFooter/WalletNauInstructionBankFooter';

export default observer(() => {
  const { walletNauStore, userStore } = useStore();

  const isSpecialOffer = useMemo(() => {
    return [
      WalletSpecialOfferTypeE.NauWorker,
      WalletSpecialOfferTypeE.Znp,
      WalletSpecialOfferTypeE.Br,
      WalletSpecialOfferTypeE.TeacherPromo,
      WalletSpecialOfferTypeE.TradeUnionLikeZnpTerms,
      WalletSpecialOfferTypeE.TradeUnionLikeBrTerms,
      WalletSpecialOfferTypeE.TradeUnionLikeTeacherPromoTerms,
      WalletSpecialOfferTypeE.CinemaVouchersSchoolStuff,
    ].includes(walletNauStore.walletLimits.specialOfferType ?? 0);
  }, [walletNauStore.walletLimits.specialOfferType]);

  const getMarginValue = () => {
    if (walletNauStore.walletLimits.margin === 0) {
      return 0;
    }

    return (walletNauStore.walletLimits.margin * 100).toFixed(2);
  };

  return (
    <div>
      <p style={styles.textStyle}>
        <strong>Portfel NAU</strong> to środki na nieprzewidziane wydatki.
      </p>
      <p style={styles.textStyle}>
        ● miesięczna kwota wypłaty do 2 500 zł
        <br />● oprocentowanie {getMarginValue()}%{isSpecialOffer && '*'}
        <br />● opłata w wysokości {walletNauStore.walletLimits.commission * 100}% za każdą dokonaną wypłatę
        <br />● realizacja wypłat 24/7**
        <br />● jednorazowa wypłata przyznanych środków lub wypłaty częściowe do wysokości przyznanego limitu
        <br />● każda wypłata w ramach limitu to odrębna umowa
        <br />● spłata należności w okresach miesięcznych
        <br />● termin spłaty należności (za wypłatę oraz opłaty za wypłatę) do 7 dni następnego okresu transakcyjnego
        <br />● limit odnawiany na kolejny okres transakcyjny następnego dnia roboczego po zaksięgowaniu spłaty
        należności
      </p>
      <p style={styles.textStyle}>
        <strong>Okres transakcyjny</strong> - czas na dokonywanie wypłat środków w ramach przyznanego limitu, trwający
        od pierwszego do ostatniego dnia danego miesiąca. Po spłaceniu należności (do 7 dni), przywracana jest możliwość
        kolejnych wypłat (rozpoczyna się kolejny cykl transakcyjny).
      </p>

      <p style={styles.headingTextStyle}>Jak skorzystać z wypłaty?</p>
      <p style={styles.textStyle}>
        1. Wybierz kwotę do wypłaty
        <br />
        2. Potwierdź warunki
        <br />
        3. NAU zweryfikuje Twój wniosek
        <br />
        4. Po pozytywnej weryfikacji zostanie udostępniony limit w kwocie, o którą wnioskowałeś
        <br />
        5. Po złożeniu wniosku o wypłatę, zlecimy przelew środków (bez dodatkowej weryfikacji)
        <br />
        6. Gotowe – korzystaj z nowych środków!
      </p>

      <p style={styles.headingTextStyle}>Jak spłacić należność?</p>
      <p style={styles.textStyle}>
        Wykonaj przelew z należną kwotą do spłaty na dedykowany rachunek bankowy w ciągu 7 dni od daty zakończenia
        okresu transakcyjnego.
        <br />
        Numer rachunku znajdziesz w umowie oraz podsumowaniu w aplikacji.
        <br />
        Każdorazowo otrzymasz drogą elektroniczną potwierdzenie zawarcia umowy wraz z dokumentem umowy.
      </p>
      {isSpecialOffer && (
        <WalletNauInstructionFooter
          isBrTermVisible={walletNauStore.walletLimits.specialOfferType === WalletSpecialOfferTypeE.Br}
          isNauWorkersTermVisible={walletNauStore.walletLimits.specialOfferType === WalletSpecialOfferTypeE.NauWorker}
          isRepresentativeVisible={false}
          isTeacherPromoTermVisible={
            walletNauStore.walletLimits.specialOfferType === WalletSpecialOfferTypeE.TeacherPromo
          }
          isTradeUnionVisible={[
            WalletSpecialOfferTypeE.TradeUnionLikeZnpTerms,
            WalletSpecialOfferTypeE.TradeUnionLikeBrTerms,
            WalletSpecialOfferTypeE.TradeUnionLikeTeacherPromoTerms,
          ].includes(walletNauStore.walletLimits.specialOfferType ?? 0)}
          isZnpTermVisible={walletNauStore.walletLimits.specialOfferType === WalletSpecialOfferTypeE.Znp}
        />
      )}
      <WalletNauInstructionBankFooter prefix='**' />
    </div>
  );
});
