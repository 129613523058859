import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import CircleKMyCost from '../../components/CircleK/CircleKMyCost/CircleKMyCost';
import * as styles from './styles';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import CircleKCard from '../../components/CircleK/CircleKCard/CircleKCard';
import { toast } from 'react-toastify';
import CircleKApplicationForCard from '../../components/CircleK/CircleKApplicationForCard/CircleKApplicationForCard';
import { Benefit } from '../../enums/benefits.enums';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import { Image } from 'react-bootstrap';
import MyButton from '../../components/Buttons/MyButton/MyButton';

export default observer(() => {
  const { circleKStore, userStore, menuStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasApprovals, setApprovals] = useState<boolean>(true);

  useEffect(() => {
    if (userStore.isActive) {
      userStore.hasRequiredApprovalsForBenefit(Benefit.CircleK).then((resp) => {
        setApprovals(resp);
        if (resp) {
          circleKStore
            .getCircleKCardsByUserId()
            .then(() => {
              circleKStore
                .getCircleKMyExpensesByUserId()
                .then(() => {
                  circleKStore
                    .getCircleKSettlements()
                    .then(() => {
                      setLoading(false);
                      userStore.checkEmailIsConfirmed();
                      circleKStore.getCircleKBalanceByUserId();
                    })
                    .catch((error) => {
                      console.log(`Error in promises ${error}`);
                      setLoading(false);
                      toast.error('Wystąpił błąd przy pobieraniu rozliczeń');
                    });
                })
                .catch((error) => {
                  console.log(`Error in promises ${error}`);
                  setLoading(false);
                  toast.error('Wystąpił błąd przy pobieraniu transakcji');
                });
            })
            .catch((error) => {
              console.log(`Error in promises ${error}`);
              setLoading(false);
              toast.error('Wystąpił błąd przy pobieraniu kart');
            });
        } else {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <>
      {userStore.isLoggedIn ? (
        userStore.isActive ? (
          <>
            <LoadingComponent content='Ładowanie...' visible={loading} />
            <CustomerDashboard>
              {!hasApprovals ? (
                <div className='col-md-6 offset-md-3'>
                  <SubpageHeader headerClassName={'tw-text-3xl'} title={'Zgody'} />
                  <div className={'tw-rounded-b-lg tw-bg-white tw-p-2'}>
                    <ApprovalsForm benefitId={Benefit.CircleK} hideHeader={true} />
                  </div>
                </div>
              ) : (
                <div className='col-md-6 offset-md-3'>
                  <div
                    className='d-flex flex-column align-items-center justify-content-between w-100'
                    style={styles.mainDivEmptyCard as React.CSSProperties}>
                    {circleKStore.circleKCard.length === 0 ? (
                      <div style={styles.container}>
                        <CircleKApplicationForCard inMainView={true} />
                      </div>
                    ) : (
                      <div className='circleK w-100'>
                        {circleKStore.circleKCard.filter((card) => card.isInstitutional).length > 0 &&
                          circleKStore.circleKCard.filter((card) => !card.isInstitutional).length > 0 && (
                            <h6>Karty prywatne</h6>
                          )}
                        {circleKStore.circleKCard
                          .filter((card) => !card.isInstitutional)
                          .map((item, i) => (
                            <div className='justify-content-md-center' key={i} style={styles.mainDivEmptyCard}>
                              <CircleKCard card={item} />
                            </div>
                          ))}
                        {circleKStore.circleKCard.filter((card) => card.isInstitutional).length > 0 ? (
                          <div>
                            <h6>Karty firmowe</h6>

                            {circleKStore.circleKCard
                              .filter((card) => card.isInstitutional)
                              .map((item, i) => (
                                <div className='justify-content-md-center' key={i} style={styles.mainDivEmptyCard}>
                                  <CircleKCard card={item} />
                                </div>
                              ))}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='circleK-cost'>
                          {circleKStore.circleKMyExpenses?.transactions[0] &&
                            !circleKStore.circleKMyExpenses?.transactions[0].hideAmounts && (
                              <CircleKMyCost myExpenses={circleKStore.circleKMyExpenses} />
                            )}
                        </div>
                        {/* <div className='circleK-payment'> */}
                        {/*   <HistoryPaymentsTransaction /> */}
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                  <div className={'tw-flex tw-flex-col tw-gap-4'}>
                    {!circleKStore.hasNewContract && circleKStore.hasAnyNauProfitCard && (
                      <div className='tw-flex tw-justify-center'>
                        <MyButton
                          onClick={() =>
                            modalStore.openModal(<CircleKApplicationForCard />, 'lg', 'Wniosek o nową kartę!')
                          }
                          variant={'greenDark'}>
                          <Image className='tw-h-6 tw-w-6' src={'/assets/card.svg'} />
                          Wnioskuj o kolejną kartę
                        </MyButton>
                      </div>
                    )}
                    {/* <div className='tw-flex tw-justify-center'> */}
                    {/*   <MyButton onClick={() => history.push(PathRoute.STATION_NEAR_HOME)} variant={'greenDark'}> */}
                    {/*     Znajdź stację najbliżej domu */}
                    {/*   </MyButton> */}
                    {/* </div> */}
                  </div>
                </div>
              )}
            </CustomerDashboard>
          </>
        ) : (
          history.push(PathRoute.AUTHORIZE_TOKEN)
        )
      ) : (
        history.push(PathRoute.LOGIN)
      )}
    </>
  );
});
