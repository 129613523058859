import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function NauIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='18' viewBox='2 0 20.834 24' width='20.834'>
      <path
        d='M13677.525-5331.146c-.062-.018-.125-.032-.187-.047-.222-.056-.444-.107-.672-.147-.129-.024-.261-.041-.392-.06-.178-.024-.356-.046-.538-.062-.061,0-.119-.017-.18-.021-.2-.012-.4-.019-.6-.02h-.1c-.2,0-.4.008-.6.02-.062,0-.119.016-.18.021-.182.016-.36.038-.538.062-.131.019-.263.036-.392.06-.228.04-.45.091-.672.147-.062.016-.124.03-.187.047a9.912,9.912,0,0,0-7.3,9.121l.031.059c-.014.215-.031.43-.031.648v11.716a.564.564,0,0,0,.563.563h5.587a.562.562,0,0,0,.562-.563v-11.918a3.334,3.334,0,0,1,2.239-3.12l.105-.03a3,3,0,0,1,.508-.1c.063-.006.125-.009.188-.012s.105-.006.158-.006.105,0,.158.006.126.006.188.012a3,3,0,0,1,.508.1l.1.03a3.333,3.333,0,0,1,2.24,3.12v11.918a.562.562,0,0,0,.562.563h5.587a.564.564,0,0,0,.563-.563v-11.716c0-.218-.018-.433-.031-.648l.031-.059a9.912,9.912,0,0,0-7.3-9.121'
        data-name='N+'
        fill='currentColor'
        id='N_'
        stroke='currentColor'
        strokeMiterlimit='10'
        strokeWidth='1'
        transform='translate(-13664.494 5332.004)'
      />
    </svg>
  );
}
