export const container = {
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
};

export const containerNews = {
  height: '220px',
  width: '100%',
  borderRadius: '20px',
  marginBottom: '20px',
};

export const containerNewsText = {
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  height: 'fit-content',
  marginBottom: '5px',
};
export const containerNewsTittle = {
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  height: 'fit-content',
  marginBottom: 0,
  fontWeight: '600',
  padding: '5px',
  color: '#00635c',
};
export const textStyles = {
  fontSize: '16px',
  color: '#00635C',
  fontWeight: '600',
};

export const marginStyles = { marginBottom: '10px' };

export const restStylesImage = {
  height: '100%',
  width: '100%',
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
};

export const restStylesMobileImage = {
  height: '100%',
  width: '100%',
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
};
export const restStylesImageDiv = {
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
  border: '1px solid #fff',
  borderBottom: 'none',
};

export const restStylesMobileImageDiv = {
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
  border: '1px solid #fff',
  borderBottom: 'none',
};
export const titleStyles = {
  fontSize: '24px',
  padding: '10px',
  color: '#0BC268',
};

export const shortContentStyles = {
  padding: '10px',
  fontSize: '12px',
  color: '#00635c',
  margin: 0,
};

export const checkStyles = {
  color: '#fff',
  fontSize: '16px',
  fontWeight: '300',
};
export const containerFeatures = {
  backgroundColor: 'white',
  borderRadius: '15px',
  color: 'black',
  marginTop: '25px',
  boxShadow: '0px 12px 30px #0000001a',
  WebkitBoxShadow: '0px 12px 30px #0000001a',
  height: '100%',
};

export const containerBenefit = {
  height: '115px',
  width: '50%',
  borderRadius: '36px',
  backgroundImage: 'linear-gradient(to right,#00635c, #0bc268)',
};
