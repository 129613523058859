export const mainContainer = { display: 'flex', flexDirection: 'column', padding: '20px' };

export const goBackIcon = {
  alignSelf: 'baseline',
  transform: 'rotateY(180deg)',
  cursor: 'pointer',
  padding: '5px',
};

export const centerFlexContainer = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const verifyText = {
  marginBottom: 0,
  alignSelf: 'center',
  color: '#00635C',
};

export const photoStyles = {
  height: '100px',
  marginTop: '2rem',
  marginBottom: '2rem',
};

export const heightSecondPhoto = {
  height: '100px',
  marginBottom: '1rem',
  marginTop: '1rem',
  width: '100%',
};
export const positionRelative = {
  position: 'relative',
};

export const cordovaFileBox = {
  position: 'relative',
  width: '50%',
};

export const inputHidden = {
  position: 'absolute',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100px',
  height: '115px',
  opacity: 0,
};

export const uploadFile = {
  margin: '0 auto',
  width: '50%',
  textAlign: 'center',
  lineHeight: '1.3',
  color: '#00635C',
  marginBottom: '2rem',
};

export const greyDot = {
  margin: '0 auto',
  width: '90%',
  border: '1px solid #b0b6ba',
  marginBottom: '10px',
};

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  color: '#00635C',
  marginBottom: '2rem',
  marginTop: '2rem',
};

export const flexStart = {
  display: 'flex',
  justifyContent: 'flex-start',
  color: '#00635C',
  marginBottom: '2rem',
  marginTop: '2rem',
};
