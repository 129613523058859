import * as React from 'react';
import * as styles from './styles';
import { Image } from 'react-bootstrap';
import loansStore from '../../../store/loanStore/loansStore';

export function GetViewForZeroLimit(loading: boolean, loansStore: loansStore): React.ReactNode {
  return (
    <div>
      <div style={styles.containerFirstSlider as React.CSSProperties}>
        <p style={styles.Loan as React.CSSProperties}>Wypłata od ręki</p>
      </div>

      <p style={styles.availableText as React.CSSProperties}>dostępne</p>
      <div>
        <p style={styles.limitValue as React.CSSProperties}>
          {!loading ? loansStore.reaminingLimitLoan : ''}
          <span style={styles.currencyStyles as React.CSSProperties}>PLN</span>
        </p>
      </div>
      <div className='row col-12'>
        <Image
          src='assets\calendar.svg'
          style={{
            marginBottom: '40px',
            marginTop: '40px',
            height: '200px',
            textAlign: 'center',
          }}
        />
      </div>
      <div className='row col-12'>
        <h1 style={{ textAlign: 'center', color: 'green' }}>Limit wypłat w tym miesiącu został wykorzystany.</h1>
      </div>
      <div className='row col-12'>
        <div style={{ textAlign: 'center' }}>
          <p style={styles.lastMonth as React.CSSProperties}>
            Obecnie nie posiadasz wymaganej kwoty minimalnej, umożliwiającej wypłatę środków w wysokości 100 zł. Po
            okresie rozliczeniowym przywrócimy Ci możliwość wypłat. Niewykorzystane środki nie przechodzą na kolejny
            miesiąc.
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p style={styles.lastMonth as React.CSSProperties}>
            Do odnowienia limitu zostało <span style={{ fontWeight: 'bold' }}>{loansStore.reaminingDays} dni</span>
          </p>
        </div>
      </div>
    </div>
  );
}
