import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Image } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { Form, Formik } from 'formik';
import { CircleKCardModel } from '../../../types/circleK';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import MyTextArea from '../../FormControls/MyTextArea/MyTextArea';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';

export default observer((props: { card: CircleKCardModel }) => {
  const { modalStore, circleKStore } = useStore();
  const handleClose = () => modalStore.closeModal();

  return (
    <div style={_.merge({}, styles.divSpacing as React.CSSProperties)}>
      <Formik
        initialValues={{
          message: '',
          error: null,
        }}
        onSubmit={({ message }, { setSubmitting }) => {
          circleKStore
            .circleKUnblockCardRequest(props.card.cardId, message)
            .then(() => {
              setSubmitting(false);
              toast.success('Prośba została wysłana.');
              handleClose();
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}>
        {({ handleSubmit, isSubmitting }) => (
          <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
            <div style={_.merge({}, styles.center as React.CSSProperties)}>
              <Image className='greenIcon' src={'/assets/unblock.svg'} style={styles.imageStyle} />
              <p className='greenColor' style={styles.title}>
                Odblokuj Kartę
              </p>
            </div>

            <div style={_.merge({}, styles.center as React.CSSProperties, styles.contentStyle as React.CSSProperties)}>
              <p style={{ fontSize: '30px', color: '#626D80', lineHeight: 'normal' }}>
                Prosimy o kontakt z działem obsługi klienta.
                <br /> <br />
                Numer karty: {props.card.cardNumber}
                <br />
                Rejestracja: {props.card.licensePlateNumber}
                <br />
              </p>
            </div>

            <div>
              <MyTextArea name={'message'} placeholder={'Treść wiadomości'} rows={6} />
            </div>

            <ButtonsSubmitCancel
              buttonTextCancel='Anuluj'
              buttonTextSubmit='Wyślij'
              cancelHandler={handleClose}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
});
