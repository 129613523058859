import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { history } from '../../index';
import { InstitutionRole } from '../../enums/roles.enums';
import {
  CustomerRegisterFormValues,
  User,
  UserApprovals,
  UserAvailableBenefits,
  UserFormValues,
  UserInstitution,
  UserInstitutionSearchModel,
  UserListModel,
  VerifyNewPasswordFormValues,
} from '../../types/user';
import { ContactFormValues, DeleteAccountFormValues } from '../../types/contactForm';
import agent from '../../api/agent';
import { store } from '../store';
import { PathRoute } from '../../constants/pathRoute/Route';
import { Buffer } from 'buffer';
import Cookies from 'js-cookie';
import { TradeUnionTypesE } from '../../enums/tradeUnionTypes.enums';
import {
  CASHBACK_POLICY_DATA,
  DISABLE_APPROVALS,
  INSTITUTION_TOKEN,
  PROMO_CODE_KEY,
} from '../../constants/sessionStorageKeys';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';

export default class UserStore {
  user: User | null = null;

  userInstitution: UserInstitution | null = null;
  isAuthorizedToInst = false;

  institutionUsers: UserListModel[] = new Array<UserListModel>();

  updateRequired = false;
  technicalBreak = false;

  userId: any;
  userApprovals: UserApprovals[] = [];
  emailConfirmed = true;
  hasApprovals: boolean | null = null;
  hasPesel: boolean | null = null;
  isDemoUser?: boolean = undefined;
  isLoggedIn: boolean = window.localStorage.getItem('jwt') != null;
  isLoggedInToInst: boolean = window.localStorage.getItem('inst') != null;

  constructor() {
    makeAutoObservable(this);
  }
  setIsLoggedToInst = () => {
    runInAction(() => {
      this.isLoggedInToInst = window.localStorage.getItem('inst') != null;
    });
  };

  setIsDemoUser = () => {
    runInAction(() => {
      const user = this.getUserData();
      if (user && user?.username === 'demoklubnau@nau.pl') {
        this.isDemoUser = true;
      }
    });
  };

  parseJwt = (token: string) => {
    if (token) {
      const base64String = token.split('.')[1];
      const decodedPayload = JSON.parse(Buffer.from(base64String, 'base64').toString('utf8'));

      // Parse tradeUnionTypes claim as an array of numbers
      const tradeUnionTypes = decodedPayload.tradeUnionTypes ? JSON.parse(decodedPayload.tradeUnionTypes) : [];

      // Return parsed payload with tradeUnionTypes as an array
      return {
        ...decodedPayload,
        tradeUnionTypes,
      };
    }
    return null;
  };
  get userData() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData;
  }
  get isActive() {
    return this.checkUserIsActive();
  }
  get isEmailConfirmed() {
    return this.emailConfirmed;
  }

  get isLoggedInstAdmin() {
    let inst: UserInstitution;
    if (this.userInstitution != null) {
      return this.userInstitution.roleId == InstitutionRole.Admin;
    }
    const instString = window.localStorage.getItem('inst');
    if (instString != null) {
      inst = JSON.parse(instString.toString());
      return inst.roleId == InstitutionRole.Admin;
    }
    return false;
  }

  get getUserId() {
    if (this.userId) return this.userId;

    return '';
  }

  get institutionUsersList() {
    return this.institutionUsers;
  }

  login = async (values: UserFormValues) => {
    if (values.rememberMe) {
      Cookies.set('rememberMe', 'true', { expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365) });
    }
    const user = await agent.Account.login(values);
    sessionStorage.removeItem(PROMO_CODE_KEY);
    sessionStorage.removeItem(CASHBACK_POLICY_DATA);
    sessionStorage.removeItem(INSTITUTION_TOKEN);
    store.commonStore.setToken(user.token);
    store.commonStore.setRefreshToken(user.refreshToken);
    runInAction(() => {
      this.user = user;
      this.hasRequiredApprovals();
      this.getUserApprovalsFromAPI().then();

      if (Capacitor.isNativePlatform()) {
        this.registerPushNotifications();
      }
    });
  };

  registerPushNotifications = async () => {
    try {
      // Request permission to use push notifications
      const permStatus = await PushNotifications.requestPermissions();
      if (permStatus.receive === 'granted') {
        // Register with the Push Notification service
        await PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
          this.addUserDevice(token.value);
        });

        // // Some error occurred while registering
        // PushNotifications.addListener('registrationError', (error: any) => {
        //   console.log('Error on registration: ' + JSON.stringify(error));
        // });

        // // Show us the notification payload if the app is open on our device
        // PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
        //   console.log('Push received: ' + JSON.stringify(notification));
        // });

        // // Method called when tapping on a notification
        // PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
        //   console.log('Push action performed: ' + JSON.stringify(notification));
        // });
      } else {
        console.log('Push notification permission not granted');
      }
    } catch (e) {
      console.log('Error registering push notifications', e);
    }
  };

  requestTokenToSetNewPassword = async (email: string, phone: string, firstLogin: boolean) => {
    return await agent.Account.requestTokenToSetNewPassword(email, phone, firstLogin).then((resp) => {
      if (resp != 0) {
        this.userId = resp;
        history.push(PathRoute.VERIFY_NEW_PASSWORD);
      }
    });
  };

  authorizeToken = async (token: string) => {
    const user = await agent.Account.authorizeToken(token);
    store.commonStore.setToken(user.token);
    store.commonStore.setRefreshToken(user.refreshToken);
    history.push(PathRoute.PUSTY);
  };
  requestAdminAuthorizatonToken = async (institutionId: number, phoneId: number) => {
    return agent.Account.requestAdminAuthorizatonToken(institutionId, phoneId);
  };
  authorizeInstitutionAdmin = async (token: string, institutionId: number, authGuid: string) => {
    await agent.Account.authorizeInstitutionAdmin(token, institutionId, authGuid).then((resp) => {
      runInAction(() => {
        store.commonStore.setAuthorized(resp.toString());
        this.isAuthorizedToInst = true;
      });
    });
    history.push(PathRoute.ADMIN_DASHBOARD);
  };
  verifyNewPassword = async (params: VerifyNewPasswordFormValues) => {
    const authResult = await agent.Account.verifyNewPassword(params);
    store.commonStore.setToken(authResult.token);
    const userData = this.parseJwt(authResult.token);
    if (userData && userData.active === 'True') {
      await this.hasRequiredApprovals();
    } else {
      history.push(PathRoute.AUTHORIZE_TOKEN);
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    store.commonStore.setAuthorized(null);
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('inst');
    sessionStorage.removeItem(DISABLE_APPROVALS);
    sessionStorage.removeItem(PROMO_CODE_KEY);
    Cookies.remove('auth');
    Cookies.remove('referralCode');
    Cookies.remove('rememberMe');
    this.user = null;
    this.hasPesel = null;
    this.userInstitution = null;
    this.isAuthorizedToInst = false;
    this.hasApprovals = null;
    store.loansStore.reset();
    history.push(PathRoute.LOGIN);
  };
  logoutFromInstitution = () => {
    window.localStorage.removeItem('inst');
    Cookies.remove('auth');
    store.commonStore.setAuthorized(null);
    this.userInstitution = null;
    this.isAuthorizedToInst = false;
    history.push(PathRoute.PUSTY);
  };

  getUserData = () => {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    const refreshToken: string = window.localStorage.getItem('refreshToken') ?? '';
    const userData = this.parseJwt(jwt);

    if (userData) {
      const user: User = {
        username: userData.email,
        role: userData.role,
        firstName: userData.firstName,
        lastName: userData.lastName,
        token: jwt,
        refreshToken,
        active: userData.active,
        emailConfirmed: userData.emailConfirmed,
        tradeUnionTypes: userData.tradeUnionTypes,
      };

      return user;
    }
  };

  checkUserIsActive = () => {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData ? userData.active === 'True' : false;
  };

  checkEmailIsConfirmed = async () => {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    const userData = this.parseJwt(jwt);
    let isConfirmed = userData ? userData.emailConfirmed === 'True' : false;
    if (!isConfirmed) {
      isConfirmed = await this.getCurrentUser();
    }
    runInAction(() => {
      this.emailConfirmed = isConfirmed;
    });
  };

  getUserApprovalsFromAPI = async () => {
    try {
      const response = await agent.Users.getUserApprovals();
      runInAction(() => {
        this.userApprovals = response;
      });
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserApprovalsForBenefit = async (benefitTypeId: number) => {
    try {
      return await agent.Users.getUserApprovalsForBenefit(benefitTypeId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  hasRequiredApprovals = async () => {
    try {
      return await agent.Users.hasRequiredApprovals().then((resp) =>
        runInAction(() => {
          this.hasApprovals = resp;
        }),
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkUserApprovals = () => {
    if (!this.isLoggedIn) return true;
    const approvals = toJS(this.userApprovals);
    if (approvals == undefined || !Array.isArray(approvals)) {
      this.hasApprovals = false;
    }
    const requiredApprovals = approvals.filter(
      (item) =>
        (item.content.includes('regulamin serwisu') || item.content.includes('politykę prywatności')) && item.value,
    );
    this.hasApprovals = requiredApprovals.length == 2;
  };

  saveUserApprovals = async (approvals: UserApprovals[]) => {
    await agent.Users.saveUserApprovals(approvals)
      .then((resp) => {
        store.commonStore.setToken(resp.token);
        store.commonStore.setRefreshToken(resp.refreshToken);
        this.user = resp;
        return this.getUserApprovalsFromAPI().then(() => {
          this.checkUserApprovals();
        });
      })
      .catch((resp: Error) => {
        throw resp;
      });
  };
  saveUserApprovalsForBenefit = async (approvals: UserApprovals[], benefitTypeId: number) => {
    await agent.Users.saveUserApprovalsForBenefit(approvals, benefitTypeId)
      .then((resp) => {
        store.commonStore.setToken(resp.token);
        store.commonStore.setRefreshToken(resp.refreshToken);
        this.user = resp;
      })
      .catch((resp: Error) => {
        throw resp;
      });
  };
  getCurrentUser = async () => {
    await agent.Account.getCurrentUser()
      .then((resp) => {
        store.commonStore.setToken(resp.token);
        store.commonStore.setRefreshToken(resp.refreshToken);
        this.user = resp;
        return resp.emailConfirmed;
      })
      .catch((resp: Error) => {
        throw resp;
      });
    return false;
  };
  sendContactForm = async (message: ContactFormValues) => {
    await agent.Email.sendContactForm(message).catch((resp: Error) => {
      throw resp;
    });
  };
  sendDeleteAccountForm = async (message: DeleteAccountFormValues) => {
    await agent.Email.sendDeleteAccountForm(message).catch((resp: Error) => {
      throw resp;
    });
  };
  sendVerificationEmail = async () => {
    await agent.Account.sendVerificationEmail().catch((resp: Error) => {
      throw resp;
    });
  };

  setUserInstitution = async (institution: UserInstitution) => {
    try {
      window.localStorage.setItem('inst', JSON.stringify(institution));
      this.userInstitution = institution;
      this.setIsLoggedToInst();
      setTimeout(() => {
        this.setIsLoggedToInst();
      }, 50);
    } catch (error) {
      console.log(error);
    }
  };

  getUserInstitution = () => {
    const inst = window.localStorage.getItem('inst');
    let userInstitution: UserInstitution | null = null;
    if (inst != null) {
      userInstitution = JSON.parse(inst);
    }
    this.setIsLoggedToInst();
    return userInstitution;
  };

  verifyToken = async () => {
    await agent.Account.verifyToken();
  };

  registerCustomer = async (creds: CustomerRegisterFormValues) => {
    await Promise.all([
      agent.Users.registerCustomer(creds)
        .then(() => {
          history.push('/uzytkownicy');
        })
        .catch((resp: any) => {
          throw resp;
        }),
    ]);
  };

  editCustomer = async (creds: CustomerRegisterFormValues) => {
    await agent.Users.editCustomer(creds)
      .then(() => {
        history.push('/uzytkownicy');
      })
      .catch((resp: Error) => {
        throw resp;
      });
  };

  setImage = (image: string) => {
    if (this.user) {
      this.user.image = image;
    }
  };

  getInstitutionUsers = (request: UserInstitutionSearchModel) => {
    return agent.Users.institutionUsers(request);
  };

  customerFormData = (userInstitutionId: number) => {
    return agent.Users.customerFormData(userInstitutionId);
  };

  checkUpdateRequired = async () => {
    if (Capacitor.isNativePlatform()) {
      const platformName = Capacitor.getPlatform();
      const appInfo = await App.getInfo();
      const versionFromApi = await agent.Account.getAppVersion(platformName);
      if (versionFromApi.technicalBreak) {
        this.technicalBreak = true;
        return true;
      }
      if (compareVersions(appInfo.version, versionFromApi.version) === -1) {
        this.updateRequired = true;
        return true;
      }
    }
    this.updateRequired = false;
    return false;
  };

  usersImport = async (formData: FormData): Promise<Array<string>> => {
    return agent.Users.usersImport(formData);
  };

  usersImportWithLoanBenefit = async (formData: FormData): Promise<Array<string>> => {
    return agent.Users.usersImportWithLoanBenefit(formData);
  };

  getUserAvailableBenefits = async (): Promise<UserAvailableBenefits[]> => {
    return agent.Users.getUserAvailableBenefits();
  };
  getUserInstitutions = async (): Promise<UserInstitution[]> => {
    return agent.Users.getUserInstitutions();
  };
  hasRequiredApprovalsForBenefit = async (benefitId: number) => {
    try {
      return await agent.Users.hasRequiredApprovalsForBenefit(benefitId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkHasPesel = async () => {
    try {
      return await agent.Users.hasPesel().then((resp) =>
        runInAction(() => {
          this.hasPesel = resp;
        }),
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  updatePesel = async (pesel: string) => {
    try {
      return await agent.Users.updatePesel(pesel).then((resp) =>
        runInAction(() => {
          this.hasPesel = resp;
        }),
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  checkInstitutionAdminAuthorization = async () => {
    try {
      return agent.Account.checkInstitutionAdminAuthorization()
        .then((resp) => {
          runInAction(() => {
            this.isAuthorizedToInst = resp;
          });
        })
        .catch(() => {
          runInAction(() => {
            this.isAuthorizedToInst = false;
          });
        });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  addUserDevice = async (token: string) => {
    try {
      return agent.Account.addUserDevice(token);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getUserMarketingData = async () => {
    try {
      return await agent.Users.getUserMarketingData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  registerUserActivity = async (activityCode: number) => {
    try {
      return agent.Users.registerUseActivity(activityCode);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  getUserPesel = async () => {
    try {
      return await agent.Users.getUserPesel();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  get isUserFirefighter() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.Firefighter);
  }

  get isUserElectroMachinist() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.ElectroMachinist);
  }

  get isUserJusticeSystem() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.JusticeSystem);
  }

  get isUserSolidarityPodbeskidzie() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.SolidarityPodbeskidzie);
  }

  get isUserMiner() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.Miners);
  }

  get isZnpRybnik() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.ZnpRybnik);
  }
  get isOpzz() {
    const jwt: string = window.localStorage.getItem('jwt') ?? '';
    if (jwt == '') {
      return false;
    }
    const userData = this.parseJwt(jwt);
    return userData && userData.tradeUnionTypes.includes(TradeUnionTypesE.Opzz);
  }
}

function compareVersions(version1: string, version2: string): number {
  const parts1 = version1.split('.').map(Number);
  const parts2 = version2.split('.').map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] ?? 0; // Treat undefined parts as 0
    const part2 = parts2[i] ?? 0; // Same here

    if (part1 > part2) return 1; // version1 is greater
    if (part1 < part2) return -1; // version2 is greater
  }

  return 0; // versions are equal
}
