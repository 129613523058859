import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import { useStore } from '../../store/store';
import * as styles from './styles';
import { history } from '../../index';
import { FuelCardCompany } from '../../enums/fuelCardCompany.enums';

interface ISearchStationByCityProps {
  fuelCardCompany: FuelCardCompany;
}
export default observer((props: ISearchStationByCityProps) => {
  const { circleKStore } = useStore();

  return (
    <Formik
      initialValues={{
        city: '',
        error: null,
      }}
      onSubmit={({ city }, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        circleKStore
          .getCircleStationByCity(city)
          .then(() => {
            history.push('/stacje-blisko-domu');
          })
          .catch((error) => {
            setErrors({ error: error });
          });
      }}>
      {({ handleSubmit, isSubmitting, dirty, errors }) => (
        <Form autoComplete='off' className='ui form error col-md-6 offset-md-3' onSubmit={handleSubmit}>
          <MyTextInput name='city' placeholder='Miasto' />
          <ErrorMessage
            name='error'
            render={() => <label style={styles.errorStyles as React.CSSProperties}>{errors.error}</label>}
          />
          {isSubmitting ? (
            <Button active={dirty} className='btn-full-width mt-3' type='submit'>
              <span className='m-1'>
                <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
              </span>
              Przetwarzanie...
            </Button>
          ) : (
            <Button className='btn-full-width mt-3' type='submit'>
              Szukaj stacji paliw w mieście
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
});
