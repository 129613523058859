import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { errorLabel } from '../styles';
import { MyDateInputProps } from '../MyTextInput/types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import pl from 'date-fns/locale/pl';
import InputMask from 'react-input-mask';
import MaskHelpers from '../../../helpers/MaskHelpers';

registerLocale('pl', pl);

export default function MyDateInput(props: MyDateInputProps) {
  const [field, { error, touched }, helpers] = useField(props.name!);
  const [date, setDate] = useState<Date | null>(null);
  const { setValue } = helpers;

  useEffect(() => {
    setDate(props.value ? new Date(props.value) : field.value ? new Date(field.value) : null);
  }, [props.value, field.value]);

  const handleChange = (value: Date | null) => {
    setDate(value);
    setValue(value);
    props.onChange && props.onChange(value);
  };
  return (
    <Form.Group>
      {props.type && props.type == 'hidden' ? null : !props.hiddenLabel && <Form.Label>{props.label}</Form.Label>}
      <DatePicker
        className={
          touched && !!error ? `form-control is-invalid ${props.className}` : `form-control ${props.className}`
        }
        customInput={<InputMask mask={MaskHelpers.fullDate} placeholder='rrrr-MM-dd' />}
        dateFormat='yyyy-MM-dd'
        disabled={props.disabled}
        locale='pl'
        onChange={(date) => handleChange(date)}
        placeholderText={props.placeholder ?? 'rrrr-MM-dd'}
        selected={date}
      />
      {props.type && props.type == 'hidden' ? null : (
        <div>
          <span className={touched && !!error ? `is-invalid` : ``}></span>
          <Form.Control.Feedback type='invalid'>
            {touched && !!error ? <label style={errorLabel}>{error}</label> : null}
          </Form.Control.Feedback>
        </div>
      )}
    </Form.Group>
  );
}
