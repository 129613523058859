import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { MySelectInputOptions } from '../../components/FormControls/MySelectInput/types';
import { LoanContractStatusE } from '../../enums/loanContractStatus.enums';
import {
  LoanData,
  LoanDataCashmir,
  LoanDataFromCurrentMonth,
  MonthlyLoanApplicationForInstitutionM,
} from '../../types/loans';
import { TableResultM } from '../../types/tableResult';
import { LoanBenefitManagment, UserAvailableBenefits } from '../../types/user';

export default class LoansStore {
  loanMainLoadings = false;
  reaminingLimitLoan = 0;
  reaminingDays = 0;
  limitLoan = 0;
  loanAmount = 0;
  hasLoanPermission = false;
  isBlocked = false;
  isInProgress = false;
  isInReckoning = false;
  hasApprovals = false;
  hasInstitutionalBenefit = false;
  availableBenefits: UserAvailableBenefits[] = [];
  availableBenefitId: number | null = null;
  availableBenefitIsInstitutional: boolean | null = null;
  loanContractId: number | null = null;
  userLoans: LoanData[] = [];
  userLoansFromCurrentMonth: LoanDataFromCurrentMonth | null = null;
  userLoanApplications: LoanDataCashmir[] = new Array<LoanDataCashmir>();

  constructor() {
    makeAutoObservable(this);
  }
  reset = () => {
    this.loanMainLoadings = false;
    this.reaminingLimitLoan = 0;
    this.reaminingDays = 0;
    this.limitLoan = 0;
    this.loanAmount = 0;
    this.hasLoanPermission = false;
    this.isBlocked = false;
    this.isInProgress = false;
    this.isInReckoning = false;
    this.hasApprovals = false;
    this.hasInstitutionalBenefit = false;
    this.availableBenefits = [];
    this.availableBenefitId = null;
    this.availableBenefitIsInstitutional = null;
    this.loanContractId = null;
    this.userLoans = [];
    this.userLoansFromCurrentMonth = null;
    this.userLoanApplications = new Array<LoanDataCashmir>();
  };
  createLoan = async (limit: number) => {
    try {
      await agent.Loans.createLoan(limit);
    } catch (e) {
      console.error(e);
    }
  };

  getLoggedUserLimit = async (availableBenefitId: number) => {
    try {
      const response = await agent.Loans.getLoggedUserLimit(availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.limitLoan = response.limit;
        this.reaminingLimitLoan = response.remainingLimit;
        this.reaminingDays = response.remainingDays;
      });
    } catch (e) {
      console.error(e);
    }
  };
  getUserLoansApplications = async (availableBenefitId: number) => {
    try {
      const response = await agent.Loans.getUserLoansApplications(availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.userLoanApplications = response;
      });
    } catch (e) {
      console.error(e);
    }
  };
  getUserLoansFromCurrentMonth = async (availableBenefitId: number) => {
    try {
      const response = await agent.Loans.getLoansApplicationsFromCurrentMonth(availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.userLoansFromCurrentMonth = response;
      });
    } catch (e) {
      console.error(e);
    }
  };
  getLoanApplicationUserData = async (loanApplicationId: number) => {
    try {
      return await agent.Loans.getLoanApplicationUserData(loanApplicationId);
    } catch (e) {
      console.error(e);
    }
  };
  checkUserHasPermission = async () => {
    try {
      let hasAcceptedContract = false;
      hasAcceptedContract = (await this.getUserLoanContractStatus()) == LoanContractStatusE.Active;
      runInAction(() => {
        // @ts-ignore
        this.hasLoanPermission = hasAcceptedContract;
        this.loanMainLoadings = false;
      });
    } catch (e) {
      this.loanMainLoadings = false;
      console.error(e);
    }
  };
  getUserLoanContractId = async (availableBenefitId: number) => {
    try {
      const response = await agent.Loans.getUserLoanContractId(availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.loanContractId = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  hasInstitutionalLoanBenefit = async () => {
    try {
      const response = await agent.Loans.hasInstitutionalLoanBenefit();
      runInAction(() => {
        // @ts-ignore
        this.hasInstitutionalBenefit = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  checkHasAcceptedContract = async () => {
    try {
      const response = await agent.Loans.hasAcceptedContract(this.availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.hasAcceptedContract = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  hasWaitingContract = async () => {
    try {
      const response = await agent.Loans.hasWaitingContract(this.availableBenefitId);
      runInAction(() => {
        // @ts-ignore
        this.isInProgress = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  getUserLoanContractStatus = async () => {
    try {
      const response = await agent.Loans.getUserLoanContractStatus(this.availableBenefitId);
      runInAction(() => {
        switch (response) {
          case LoanContractStatusE.Reckoning:
            this.isInReckoning = true;
            this.isInProgress = false;
            break;
          case LoanContractStatusE.Waiting:
            this.isInReckoning = false;
            this.isInProgress = true;
            break;
          default:
            this.isInReckoning = false;
            this.isInProgress = false;
            break;
        }
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  checkHasLoanApprovals = async () => {
    try {
      const response = await agent.Loans.checkHasLoanApprovals();
      runInAction(() => {
        // @ts-ignore
        this.hasApprovals = response;
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };
  saveLoanContractUserData = async (
    id: number | undefined,
    availableBenefitId: number | null,
    firstName: string,
    lastName: string,
    pesel: string,
    phone: string,
    email: string,
    documentNumber: string,
    documentExpirationDate: string,
    bankAccountNumber: string,
  ) => {
    try {
      return await agent.Loans.saveLoanContractUserData(
        id,
        availableBenefitId,
        firstName,
        lastName,
        pesel,
        phone,
        email,
        documentNumber,
        documentExpirationDate,
        bankAccountNumber,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveLoanContractAddressData = async (
    id: number,
    street: string,
    houseNo: string,
    flatNo: string,
    place: string,
    postCode: string,
    correspondenceStreet: string,
    correspondenceHouseNo: string,
    correspondenceFlatNo: string,
    correspondencePlace: string,
    correspondencePostCode: string,
    differentCorrespondece: boolean,
  ) => {
    try {
      return await agent.Loans.saveLoanContractAddressData(
        id,
        street,
        houseNo,
        flatNo,
        place,
        postCode,
        correspondenceStreet,
        correspondenceHouseNo,
        correspondenceFlatNo,
        correspondencePlace,
        correspondencePostCode,
        differentCorrespondece,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveLoanContractEmploymentData = async (
    id: number,
    employmentType: number,
    employmentDate: string,
    contractStartDate: string,
    contractEndDate: string,
    monthlyNetIncome: string,
    companyName: string,
    profession: string,
    isInstitutional: boolean,
  ) => {
    try {
      return await agent.Loans.saveLoanContractEmploymentData(
        id,
        employmentType,
        employmentDate,
        contractStartDate,
        contractEndDate,
        monthlyNetIncome,
        companyName,
        profession,
        isInstitutional,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  saveLoanContractAdditionalData = async (
    id: number,
    monthlyLoanInstallments: string,
    creditLimitsGranted: string,
    numberOfDependents: string,
    monthlyExpenses: string,
  ) => {
    try {
      return await agent.Loans.saveLoanContractAdditionalData(
        id,
        monthlyLoanInstallments,
        creditLimitsGranted,
        numberOfDependents,
        monthlyExpenses,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getLoanContractUserData = async (id: number) => {
    try {
      return agent.Loans.getLoanContractUserData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getLoanContractAddressData = async (id: number) => {
    try {
      return agent.Loans.getLoanContractAddressData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getLoanContractEmploymentData = async (id: number) => {
    try {
      return agent.Loans.getLoanContractEmploymentData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  getLoanContractAdditionalData = async (id: number) => {
    try {
      return agent.Loans.getLoanContractAdditionalData(id);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  createLoanApplication = async (availableBenefitId: number, amount: number) => {
    try {
      return agent.Loans.createLoanApplication(availableBenefitId, amount);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  verifyLoanApplicationToken = async (loanApplicationId: number, pin: string) => {
    try {
      return agent.Loans.verifyLoanApplicationToken(loanApplicationId, pin);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  requestLoanAuthorizationToken = async (loanApplicationId: number) => {
    try {
      return agent.Loans.requestLoanAuthorizationToken(loanApplicationId);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  saveLoanApprovals = async (
    regulationsAccepted?: boolean,
    privacyPolicyAccepted?: boolean,
    analyticsApproval?: boolean,
    marketingApproval?: boolean,
    eMessageApproval?: boolean,
    phoneContactApproval?: boolean,
    loanEmploymentVerificationApproval?: boolean,
    loanBIGApproval?: boolean,
    loanKRDApproval?: boolean,
    loanDataCorrectnessStatement?: boolean,
    loanPEPStatement?: boolean,
  ) => {
    try {
      return await agent.Loans.saveLoanApprovals(
        regulationsAccepted,
        privacyPolicyAccepted,
        analyticsApproval,
        marketingApproval,
        eMessageApproval,
        phoneContactApproval,
        loanEmploymentVerificationApproval,
        loanBIGApproval,
        loanKRDApproval,
        loanDataCorrectnessStatement,
        loanPEPStatement,
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  saveLoanContractFile = async (formData: FormData) => {
    return agent.Loans.sendContractFile(formData);
  };

  addLoanBenefit = async (userId: number, institutionId: number, salary: number, loanPaymentDateId: number) => {
    return agent.Loans.addLoanBenefit(userId, institutionId, salary, loanPaymentDateId);
  };

  blockLoanBenefit = async (userId: number, institutionId: number, isEmployeeFired: boolean) => {
    return agent.Loans.blockLoanBenefit(userId, institutionId, isEmployeeFired);
  };
  unlockLoanBenefit = async (userId: number, institutionId: number) => {
    return agent.Loans.unlockLoanBenefit(userId, institutionId);
  };
  changeLoanUserSalary = async (userId: number, institutionId: number, salary: number) => {
    return agent.Loans.changeLoanUserSalary(userId, institutionId, salary);
  };
  setLoanAmount = async (amount: number) => {
    try {
      runInAction(() => {
        if (amount) {
          this.loanAmount = amount;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
  setAvailableBenefitId = async (availableBenefitid: number) => {
    try {
      runInAction(() => {
        this.availableBenefitId = availableBenefitid;
      });
      //this.checkUserHasPermission();
    } catch (e) {
      console.error(e);
    }
  };

  setAvailableBenefitIsInstitutional = async (availableBenefitIsInstitutional: boolean) => {
    try {
      runInAction(() => {
        this.availableBenefitIsInstitutional = availableBenefitIsInstitutional;
      });
    } catch (e) {
      console.error(e);
    }
  };

  setAvailableBenefits = async (availableBenefits: UserAvailableBenefits[]) => {
    try {
      runInAction(() => {
        this.availableBenefits = availableBenefits;
      });
    } catch (e) {
      console.error(e);
    }
  };
  setIsBlocked = async (isBlocked: boolean) => {
    try {
      runInAction(() => {
        this.isBlocked = isBlocked;
      });
    } catch (e) {
      console.error(e);
    }
  };
  getLoanBenefitManagmentData = async (
    userId: number,
    benefitId: number,
    institutionId: number,
  ): Promise<LoanBenefitManagment> => {
    return agent.Loans.getLoanBenefitManagmentData(userId, benefitId, institutionId);
  };
  checkBenefitIsInstitutionalByContractId = async (contractId: number) => {
    return agent.Loans.checkBenefitIsInstitutionalByContractId(contractId);
  };
  getLoanPaymentDates = async (institutionId: number): Promise<MySelectInputOptions[]> => {
    return agent.Loans.getLoanPaymentDates(institutionId);
  };
  getUserLoanPaymentDate = async (userId: number, institutionId: number): Promise<number | null> => {
    return agent.Loans.getUserLoanPaymentDate(userId, institutionId);
  };
  saveUserLoanPaymentDate = async (id: number, userId: number, institutionId: number): Promise<number | null> => {
    return agent.Loans.saveUserLoanPaymentDate(id, userId, institutionId);
  };
  getAuthorizationPhones = async (institutionId: number): Promise<MySelectInputOptions[]> => {
    return agent.Loans.getAuthorizationPhones(institutionId);
  };
  addLoanPaymentDate = async (institutionId: number, dayOfMonth: number): Promise<number> => {
    return agent.Loans.addLoanPaymentDate(institutionId, dayOfMonth);
  };
  deleteLoanPaymentDate = async (institutionId: number, loanPaymentDateId: number): Promise<number> => {
    return agent.Loans.deleteLoanPaymentDate(institutionId, loanPaymentDateId);
  };
  getLoanApplicationsForInstitution = async (
    institutionId: number,
    loanApplicationMonth?: string,
    firstName?: string,
    lastName?: string,
    pesel?: string,
    sorting?: string,
    offset?: number,
    limit?: number,
  ): Promise<TableResultM<MonthlyLoanApplicationForInstitutionM>> => {
    return agent.Loans.getLoanApplicationsForInstitution(
      institutionId,
      loanApplicationMonth,
      firstName,
      lastName,
      pesel,
      sorting,
      offset,
      limit,
    );
  };
  getLoanApplicationsSumForInstitution = async (
    institutionId: number,
    loanApplicationMonth?: string,
  ): Promise<string> => {
    return agent.Loans.getLoanApplicationsSumForInstitution(institutionId, loanApplicationMonth);
  };
  getLoanApplicationsMonthSelect = async (institutionId: number): Promise<MySelectInputOptions[]> => {
    return agent.Loans.getLoanApplicationsMonthSelect(institutionId);
  };
  getUserLoanApplicationsPerMonthForAdmin = async (
    institutionId: number,
    userId: number,
    month: string,
  ): Promise<LoanDataCashmir[] | undefined> => {
    try {
      return await agent.Loans.getUserLoanApplicationsPerMonthForAdmin(institutionId, userId, month);
    } catch (e) {
      console.error(e);
    }
  };
  downloadLoanApplicationsForInstitution = async (
    institutionId: number,
    loanApplicationMonth?: string,
    firstName?: string,
    lastName?: string,
    pesel?: string,
    sorting?: string,
    offset?: number,
    limit?: number,
  ): Promise<Blob> => {
    return agent.Loans.downloadLoanApplicationsForInstitution(
      institutionId,
      loanApplicationMonth,
      firstName,
      lastName,
      pesel,
      sorting,
      offset,
      limit,
    );
  };
}
