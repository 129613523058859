import React from 'react';
import { Image } from 'react-bootstrap';
import styles from './styles.module.scss';
import FileHelper from '../../../../helpers/FileHelper';
import { PathRoute } from '../../../../constants/pathRoute/Route';

interface IWalletNauInstructionFooterProps {
  isRepresentativeVisible: boolean;
  isNauWorkersTermVisible: boolean;
  isBrTermVisible: boolean;
  isZnpTermVisible: boolean;
  isTradeUnionVisible: boolean;
  isTeacherPromoTermVisible: boolean;
}

const WalletNauInstructionFooter = (props: IWalletNauInstructionFooterProps) => {
  return (
    <>
      <div className={styles.regulationsContainer}>
        {(props.isNauWorkersTermVisible ||
          props.isBrTermVisible ||
          props.isTradeUnionVisible ||
          props.isZnpTermVisible ||
          props.isTeacherPromoTermVisible) && (
          <div className={styles.asteriskExplanation}>* Aktualne oferty promocyjne</div>
        )}

        {props.isNauWorkersTermVisible && (
          <div
            className={styles.regulationItem}
            onClick={() => FileHelper.downloadFile(PathRoute.WALLET_NAU_WORKERS_TERMS_PDF, 'nau_workers.pdf')}>
            <div>Regulamin dla pracowników NAU</div>
            <div className={styles.arrowButtonWrapper}>
              <Image
                className={styles.arrowButton}
                src='/assets/arrowRightCash.svg'
                style={styles.heightCursor as React.CSSProperties}
              />
            </div>
          </div>
        )}

        {props.isBrTermVisible && (
          <div
            className={styles.regulationItem}
            onClick={() => FileHelper.downloadFile(PathRoute.WALLET_NAU_BR_TERMS_PDF, 'nau_br.pdf')}>
            <div>Regulamin dla ubezpieczonych w Programie "Bezpieczna Rodzina"</div>
            <div className={styles.arrowButtonWrapper}>
              <Image
                className={styles.arrowButton}
                src='/assets/arrowRightCash.svg'
                style={styles.heightCursor as React.CSSProperties}
              />
            </div>
          </div>
        )}

        {props.isZnpTermVisible && (
          <div
            className={styles.regulationItem}
            onClick={() => FileHelper.downloadFile(PathRoute.WALLET_NAU_ZNP_TERMS_PDF, 'nau_znp.pdf')}>
            <div>Regulamin dla członków ZNP</div>
            <div className={styles.arrowButtonWrapper}>
              <Image
                className={styles.arrowButton}
                src='/assets/arrowRightCash.svg'
                style={styles.heightCursor as React.CSSProperties}
              />
            </div>
          </div>
        )}
        {props.isTradeUnionVisible && (
          <div
            className={styles.regulationItem}
            onClick={() =>
              FileHelper.downloadFile(PathRoute.WALLET_NAU_TRADE_UNIONS_TERMS_PDF, 'nau_zwiazki_zawodowe.pdf')
            }>
            <div>Regulamin dla Zwiazków Zawodowych</div>
            <div className={styles.arrowButtonWrapper}>
              <Image
                className={styles.arrowButton}
                src='/assets/arrowRightCash.svg'
                style={styles.heightCursor as React.CSSProperties}
              />
            </div>
          </div>
        )}
        {props.isTeacherPromoTermVisible && (
          <div
            className={styles.regulationItem}
            onClick={() =>
              FileHelper.downloadFile(PathRoute.PROMO_TEACHER_TERMS_PDF, 'Regulamin-Promocji-Nauczyciel-VIP.pdf')
            }>
            <div>Regulamin Nauczyciel VIP</div>
            <div className={styles.arrowButtonWrapper}>
              <Image
                className={styles.arrowButton}
                src='/assets/arrowRightCash.svg'
                style={styles.heightCursor as React.CSSProperties}
              />
            </div>
          </div>
        )}
      </div>

      {props.isRepresentativeVisible && !props.isTeacherPromoTermVisible && (
        <div className={styles.representativeExample}>
          Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty Pożyczek pn. „Portfel NAU”
          wynosi 116,35 %. Okres obowiązywania umowy: 30 dni; całkowita kwota pożyczki 2000,00 zł; oprocentowanie stałe
          19,99 %; całkowity koszt zobowiązania spłacanego w jednej racie: 132,85 zł (w tym prowizja: 100,00 zł,
          odsetki: 32,85 zł); całkowita kwota do zapłaty: 2132,85 zł. Kalkulacja została dokonana na dzień 21.02.2023
          roku na reprezentatywnym przykładzie. Przyznanie pożyczki zależy od wyniku oceny zdolności kredytowej
          wnioskodawcy. Założenia przyjęte do obliczenia RRSO - umowa pożyczki będzie obowiązywała przez czas, na który
          została zawarta, a Pożyczkodawca i Pan/Pani wypełnią zobowiązania wynikające z umowy pożyczki w terminach w
          niej określonych.
        </div>
      )}

      {props.isTeacherPromoTermVisible && (
        <div className={styles.representativeExample}>
          *Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty Pożyczek pn. „Portfel
          NAU” wynosi 0,00 %. Okres obowiązywania umowy: 30 dni; całkowita kwota pożyczki 2000,00 zł; oprocentowanie
          stałe 0,00 %; całkowity koszt zobowiązania spłacanego w jednej racie: 0,00 zł (w tym prowizja: 0,00 zł,
          odsetki: 0,00 zł); całkowita kwota do zapłaty: 2000,00 zł. Kalkulacja została dokonana na dzień 21.02.2023
          roku na reprezentatywnym przykładzie. Przyznanie pożyczki zależy od wyniku oceny zdolności kredytowej
          wnioskodawcy. Założenia przyjęte do obliczenia RRSO - umowa pożyczki będzie obowiązywała przez czas, na który
          została zawarta, a Pożyczkodawca i Pan/Pani wypełnią zobowiązania wynikające z umowy pożyczki w terminach w
          niej określonych.
        </div>
      )}
    </>
  );
};

export default WalletNauInstructionFooter;
