export const containerMarginBottom = {
  marginBottom: '15px',
};

export const paginationStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
};
