export const myMarginTop = { marginTop: '10px' };
export const contentBody = {
  background: '#FFF',
  borderRadius: '10px',
  marginTop: '10px',
  padding: '20px',
} as React.CSSProperties;

export const innerContentContainer = {
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
  color: '#00635C',
} as React.CSSProperties;

export const iconWrapper = {
  width: '70px',
  display: 'flex',
  justifyContent: 'center',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '22px',
  lineHeight: '1.5',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '12px',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '20px',
  color: '#00635C',
} as React.CSSProperties;
