import React, { useEffect, useState } from 'react';
import { motion, useMotionValue } from 'framer-motion';

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
  type: 'spring',
  mass: 3,
  stiffness: 400,
  damping: 50,
};

interface GenericCarouselProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  withGradientEdges?: boolean;
  autoScroll?: boolean;
}

export const GenericCarousel = <T,>({ items, renderItem, withGradientEdges, autoScroll }: GenericCarouselProps<T>) => {
  const [itemIndex, setItemIndex] = useState(0);
  const dragX = useMotionValue(0);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }
    const intervalRef = setInterval(() => {
      const x = dragX.get();

      if (x === 0) {
        setItemIndex((prevIndex) => {
          if (prevIndex === items.length - 1) {
            return 0;
          }
          return prevIndex + 1;
        });
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
  }, [dragX, items.length, autoScroll]);

  const onDragEnd = () => {
    const x = dragX.get();

    if (x <= -DRAG_BUFFER && itemIndex < items.length - 1) {
      setItemIndex((prevIndex) => prevIndex + 1);
    } else if (x >= DRAG_BUFFER && itemIndex > 0) {
      setItemIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className='tw-relative tw-overflow-hidden '>
      <motion.div
        animate={{
          translateX: `-${itemIndex * 100}%`,
        }}
        className='tw-flex tw-cursor-grab tw-items-center active:tw-cursor-grabbing'
        drag='x'
        dragConstraints={{
          left: 0,
          right: 0,
        }}
        onDragEnd={onDragEnd}
        style={{
          x: dragX,
        }}
        transition={SPRING_OPTIONS}>
        {items.map((item, index) => (
          <motion.div
            animate={{
              scale: itemIndex === index ? 0.95 : 0.85,
            }}
            className=' tw-w-full tw-shrink-0 tw-rounded-xl tw-bg-neutral-800 tw-object-cover'
            key={index}
            transition={SPRING_OPTIONS}>
            {renderItem(item, index)}
          </motion.div>
        ))}
      </motion.div>

      <Dots itemIndex={itemIndex} setItemIndex={setItemIndex} totalItems={items.length} />
      {withGradientEdges && <GradientEdges />}
    </div>
  );
};

interface DotsProps {
  itemIndex: number;
  setItemIndex: (index: number) => void;
  totalItems: number;
}

const Dots: React.FC<DotsProps> = ({ itemIndex, setItemIndex, totalItems }) => {
  return (
    <div className='tw-mt-4 tw-flex tw-w-full tw-justify-center tw-gap-2'>
      {Array.from({ length: totalItems }).map((_, idx) => (
        <button
          className={`tw-h-3 tw-w-3 tw-rounded-full tw-transition-colors ${
            idx === itemIndex ? 'tw-bg-neutral-500' : 'tw-bg-neutral-300'
          }`}
          key={idx}
          onClick={() => setItemIndex(idx)}
        />
      ))}
    </div>
  );
};

const GradientEdges = () => {
  return (
    <>
      <div className='tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-top-0 tw-w-[10vw] tw-max-w-[100px] tw-bg-gradient-to-r tw-from-neutral-950/50 tw-to-neutral-950/0' />
      <div className='tw-pointer-events-none tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-w-[10vw] tw-max-w-[100px] tw-bg-gradient-to-l tw-from-neutral-950/50 tw-to-neutral-950/0' />
    </>
  );
};
