import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStore } from '../../../store/store';
import { DiscountCodeM } from '../../../types/discountCode';
import { toast } from 'react-toastify';
import FileHelper from '../../../helpers/FileHelper';
import DiscountCodeListElement from './DiscountCodeListElement';

interface IDiscountCodesListProps {
  codesList: DiscountCodeM[];
  changeDiscountCodeUsed: (id: number, isUsed: boolean) => void;
  birthdayCodes?: boolean;
}

export default observer((props: IDiscountCodesListProps) => {
  const { fileStore } = useStore();

  const activeCodes = useMemo(() => props.codesList.filter((code) => !code.isUsed), [props.codesList]);
  const usedCodes = useMemo(() => props.codesList.filter((code) => code.isUsed), [props.codesList]);

  const changeDiscountCodeUsed = (code: DiscountCodeM) => {
    props.changeDiscountCodeUsed(code.id, !code.isUsed);
  };
  const downloadDiscountCodeBarcode = (id: number, codeNumber: string) => {
    fileStore
      .getDiscountCodeBarcode(id)
      .then((resp) => {
        const fileName = 'E-bon_' + codeNumber + '.pdf';
        return FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .catch(() => {
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  const downloadBirthdayCodeBarcode = (id: number, codeNumber: string) => {
    fileStore
      .getBirthdayCodeBarcode(id)
      .then((resp) => {
        const fileName = 'E-bon_' + codeNumber + '.pdf';
        return FileHelper.downloadFileFromBlob(resp, fileName);
      })
      .catch(() => {
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  return (
    <div>
      {activeCodes.map((code) => (
        <DiscountCodeListElement
          changeDiscountCodeUsed={changeDiscountCodeUsed}
          discountCode={code}
          downloadDiscountCodeBarcode={props.birthdayCodes ? downloadBirthdayCodeBarcode : downloadDiscountCodeBarcode}
          key={code.id}
        />
      ))}
      {usedCodes.length > 0 && (
        <>
          <p style={{ color: '#00635C', fontSize: '26px', padding: '16px 0' } as React.CSSProperties}>Wykorzystane</p>
          {usedCodes.map((code) => (
            <DiscountCodeListElement
              changeDiscountCodeUsed={changeDiscountCodeUsed}
              discountCode={code}
              downloadDiscountCodeBarcode={
                props.birthdayCodes ? downloadBirthdayCodeBarcode : downloadDiscountCodeBarcode
              }
              key={code.id}
            />
          ))}
        </>
      )}
      {}
      {props.codesList.length == 0 && (
        <div className='d-flex justify-content-center'>Nie masz kodów do wyświetlenia</div>
      )}
    </div>
  );
});
