import { Col, Container, Image, Row } from 'react-bootstrap';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { CircleKTransactionsModel } from '../../../types/circleK';
import DateHelpers from '../../../helpers/DateHelpers';
import _ from 'lodash';

export default observer((props: { transaction: CircleKTransactionsModel }) => {
  return (
    <div style={styles.containerMarginBottom as React.CSSProperties}>
      <div style={styles.transactionItem as React.CSSProperties}>
        <Image src={'/assets/fuel-green.svg'} />
        <Container style={{ paddingRight: '0px' }}>
          <Row>
            <Col>
              <div style={styles.containerFlex as React.CSSProperties}>
                <p
                  style={_.merge(
                    {},
                    styles.fontSize as React.CSSProperties,
                    styles.fontBold as React.CSSProperties,
                    styles.toLeft as React.CSSProperties,
                  )}>
                  {props.transaction.productName}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={styles.containerFlex as React.CSSProperties}>
                <p style={_.merge({}, styles.fontSize as React.CSSProperties, styles.toRight as React.CSSProperties)}>
                  {DateHelpers.formatDate(props.transaction.transactionDate)}
                </p>
              </div>
            </Col>
            {!props.transaction.hideAmounts &&
            <Col>
              <div
                style={_.merge({}, styles.containerFlex as React.CSSProperties, styles.toRight as React.CSSProperties)}>
                <p style={_.merge({}, styles.fontSize as React.CSSProperties, styles.fontBold as React.CSSProperties)}>
                  {props.transaction.price} zł
                </p>
              </div>
            </Col>
            }
          </Row>
          <Row>
            <Col>
              <div
                style={_.merge({}, styles.containerFlex as React.CSSProperties, styles.toLeft as React.CSSProperties)}>
                {props.transaction.gasStationName}
              </div>
            </Col>
          </Row>
          {!props.transaction.hideAmounts &&
            <Row>
              <Col>
                <div style={_.merge({}, styles.mySavings as React.CSSProperties, styles.toRight as React.CSSProperties)}>
                  Oszczędności {props.transaction.savings} zł
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </div>
  );
});
