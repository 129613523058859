import { Accordion, Button } from 'react-bootstrap';
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { myMarginTop } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import DiscountCodesInstructionContent from './DiscountCodesInstructionContent';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';

export default observer(() => {
  return (
    <Accordion alwaysOpen={true} style={myMarginTop}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          Jak korzystać z E-bonów?
          <FontAwesomeIcon color='#0BC268' icon={faCaretRight} size='2x' />
        </Accordion.Header>
        <Accordion.Body style={styles.accordionBody}>
          <DiscountCodesInstructionContent />
          <div className={'px-lg-4'}>
            <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.NAU_CLUB)}>
              Sprawdź pozostałe benefity
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
});
