export const container = {
  borderBottomLeftRadius: '10px',
  borderTopLeftRadius: '10px',
  border: 'none',
  width: '250px',
} as React.CSSProperties;

export const mobileContainer = {
  width: '80%',
} as React.CSSProperties;

export const imageContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '70px',
};

export const imageDiv = {
  margin: 12,
  width: '120px',
  height: '50px',
};

export const lowerMenu = {
  position: 'absolute',
  bottom: '50px',
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  marginLeft: '20px',
  borderTop: '1px solid #00635C',
  borderBottom: '1px solid #00635C',
} as React.CSSProperties;

export const upperMenu = {
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  marginTop: '20px',
} as React.CSSProperties;

export const menuItem = {
  display: 'flex',
  justifyContent: 'center',
} as React.CSSProperties;

export const firstMenuItem = {
  display: 'flex',
  justifyContent: 'center',
} as React.CSSProperties;

export const navLink = {
  color: '#0bc268',
  fontWeight: '400',
  fontSize: '20px',
  textDecoration: 'none',
  lineHeight: 'auto',
  margin: '10px 0',
} as React.CSSProperties;

export const userEmail = {
  color: '#00635C',
  fontWeight: '400',
  fontSize: '14px',
  textDecoration: 'none',
  lineHeight: 'auto',
  margin: '10px 0',
} as React.CSSProperties;

export const userName = {
  color: '#00635C',
  fontWeight: '400',
  fontSize: '18px',
  textDecoration: 'none',
  lineHeight: 'auto',
  margin: '10px 0',
} as React.CSSProperties;

export const logoutButton = {
  background: '#0bc268',
  color: '#fff',
  fontWeight: '400',
  border: 'none',
  fontSize: '18px',
  textDecoration: 'none',
  lineHeight: 'auto',
  padding: '20px',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  borderBottomLeftRadius: '10px',
} as React.CSSProperties;
