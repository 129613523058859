export const textStyle = {
  fontSize: '14px',
  fontWeight: 400,
  textAlign: 'justify',
} as React.CSSProperties;

export const btnTextStyle = {
  fontSize: '14px',
  fontWeight: 400,
  marginTop: '15px',
  marginBottom: '15px',
  minWidth: '50%',
} as React.CSSProperties;
export const label = {
  display: 'flex',
  alignItems: 'flex-end',
  margin: '10px 0',
} as React.CSSProperties;

export const labelApproval = {
  display: 'flex',
  margin: '10px',
};
export const labelText = {
  fontSize: '14px',
  paddingLeft: '10px',
  paddingTop: '4px',
} as React.CSSProperties;

export const labelTextPhoneMsg = {
  fontSize: '14px',
  paddingLeft: '25px',
  paddingTop: '4px',
} as React.CSSProperties;
