import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function BlockIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      data-name='Group 479'
      height='19'
      id='Group_479'
      viewBox='0 0 16.625 19'
      width='16.625'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 250' fill='currentColor' height='19' id='Rectangle_250' width='16.625' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Group 478' id='Group_478'>
        <path
          d='M16.106,10.019A1.717,1.717,0,0,0,14.842,9.5h-.888V5.641a5.412,5.412,0,0,0-1.67-3.99A5.48,5.48,0,0,0,8.293,0,5.384,5.384,0,0,0,4.323,1.67a5.544,5.544,0,0,0-1.65,4.044V9.5H1.781a1.71,1.71,0,0,0-1.26.519A1.714,1.714,0,0,0,0,11.281v5.938a1.711,1.711,0,0,0,.521,1.262A1.71,1.71,0,0,0,1.781,19H14.842a1.771,1.771,0,0,0,1.781-1.781V11.281a1.717,1.717,0,0,0-.518-1.262M5.641,5.678a2.637,2.637,0,0,1,.777-1.911,2.506,2.506,0,0,1,1.857-.8,2.637,2.637,0,0,1,1.91.779,2.549,2.549,0,0,1,.8,1.894V9.5H5.641Z'
          data-name='Path 3540'
          fill='currentColor'
          id='Path_3540'
        />
      </g>
    </svg>
  );
}
