import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import * as styles from './styles';
import { PathRoute } from '../../constants/pathRoute/Route';
import { Button, Image } from 'react-bootstrap';
import ButtonOpenLinkNewTab from '../../components/Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';
import { history } from '../../index';

const NauClubGeneralInformations = () => (
  <CustomerDashboard>
    <div className='col-md-6 offset-md-3' style={styles.container}>
      <div style={styles.tabsBox}>
        <div style={styles.flexCenter as React.CSSProperties}>
          <Image
            src='/assets/viewMoreWhite.svg'
            style={{ marginLeft: '5px', marginRight: '15px', height: '35px' } as React.CSSProperties}
          />
          <h1 style={{ color: '#fff', textAlign: 'left', fontSize: '28px', fontWeight: '500' } as React.CSSProperties}>
            Benefity NAU
          </h1>
        </div>
      </div>
      <div style={styles.flexCenter as React.CSSProperties}>
        <div>
          <div style={styles.headingTextStyle}>
            Aplikacja Klub NAU to szereg unikalnych ofert.
            <br />
            <br />
            Karta paliwowa Circle K – tankuj swobodnie przez cały miesiąc, zapłać później.
          </div>
          <p style={styles.textStyle}>
            ● rabaty na paliwo nawet do -20gr/l
            <br />● rabat 15% na myjnię, olej silnikowy, płyn do spryskiwaczy
            <br />● limit do wykorzystania do 900 zł/mies.
            <br />● bezgotówkowe tankowanie
            <br />● karta za darmo
            <br />● wybór najkorzystniejszej oferty spośród dostępnych na rynku
          </p>
          <div className={'px-lg-4'}>
            <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.FUEL_CARDS)}>
              Złóż wniosek
            </Button>
          </div>

          <p style={styles.headingTextStyle}>E-bony - robisz zakupy teraz, płacisz później.</p>
          <p style={styles.textStyle}>
            ● E-bony na zakupy m.in. do Biedronki, Hebe, Lidla i Rossmanna
            <br />● łączny limit do wykorzystania do [do ustalenia - 300 zł czy 800 zł]
            <br />● bez prowizji i oprocentowania
            <br />● bez dodatkowych kosztów
            <br />● 100% online
          </p>
          <div className={'px-lg-4'}>
            <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.DISCOUNT_CODE)}>
              Złóż wniosek
            </Button>
          </div>

          <p style={styles.headingTextStyle}>Trzynastka to mini pożyczka gotówkowa.</p>
          <p style={styles.textStyle}>
            ● kwota pożyczki do 2 500 zł
            <br />● oprocentowanie 0% ● prowizja 12%
            <br />● dogodny termin spłaty – do 31.03.2023
            <br />● 100% online
          </p>
          <div className={'px-lg-4'}>
            <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.THIRTEEN_START)}>
              Złóż wniosek
            </Button>
          </div>

          <p style={styles.headingTextStyle}>Ubezpieczenie komunikacyjne – ochrona finansowa pojazdu mechanicznego.</p>
          <p style={styles.textStyle}>
            ● wybór najkorzystniejszej oferty spośród dostępnych na rynku
            <br />● rabat -5% na OC lub pakiet OC/AC
            <br />● 100% online
          </p>
          <div className={'px-lg-4'}>
            <ButtonOpenLinkNewTab buttonText={'Porównaj cenę OC/AC'} link={PathRoute.CAR_INSURANCE_EXTERNAL_LINK} />
          </div>

          <p style={styles.headingTextStyle}>Portfel NAU to środki na nieprzewidziane wydatki.</p>
          <p style={styles.textStyle}>
            ● miesięczna kwota wypłaty do 2 500 zł
            <br />● oprocentowanie 19,99%
            <br />● opłata w wysokości 5% za każdą dokonaną wypłatę*
            <br />● realizacja wypłat 24/7**
            <br />● jednorazowa wypłata przyznanych środków lub wypłaty częściowe do wysokości przyznanego limitu
            <br />● spłata należności w okresach miesięcznych
          </p>
          <div className={'px-lg-4'}>
            <Button className='btn-full-width mt-2' onClick={() => history.push(PathRoute.THIRTEEN_START)}>
              Złóż wniosek
            </Button>
          </div>
        </div>
      </div>
    </div>
  </CustomerDashboard>
);

export default NauClubGeneralInformations;
