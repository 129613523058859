import * as React from 'react';
import * as styles from './styles';
import CashOut from '../CashOut/CashOut';
import loansStore from '../../../store/loanStore/loansStore';

export function UserLoansFromCurrentMonth(loansStore: loansStore): React.ReactNode {
  return (
    <div>
      <div style={styles.paddingTop as React.CSSProperties}>
        <p style={styles.lastMonth as React.CSSProperties}>Ostatni miesiąc</p>
        <p style={styles.colorWeight as React.CSSProperties}>
          {loansStore.userLoansFromCurrentMonth?.currentMonth} {loansStore.userLoansFromCurrentMonth?.currentYear}
        </p>
      </div>
      {loansStore.userLoansFromCurrentMonth?.loanApplication != null &&
        loansStore.userLoansFromCurrentMonth?.loanApplication.map((loan) => (
          <CashOut key={loan.loanCashmirId.toString()} loan={loan} />
        ))}
    </div>
  );
}
