export const mainContainer = {
  display: 'flex',
  padding: '20px',
  borderTop: '1px solid #B0B6BA',
};

export const containerDate = { width: '58%', marginLeft: '10px' };

export const textDate = { marginBottom: 0, fontSize: '16px', color: '#626D80' };

export const schoolText = {
  marginBottom: 0,
  fontSize: '12px',
  color: '#626D80',
};

export const inProgress = { color: '#0BC268', fontWeight: '400', marginBottom: '10px' };

export const amountCash = {
  color: '#0BC268',
  fontWeight: '600',
  fontSize: '24px',
  marginBottom: '10px',
};
export const amountPayment = {
  color: '#626d80',
  fontWeight: '400',
  fontSize: '14px',
};

export const initialCostCash = {
  color: 'red',
  fontWeight: '600',
  fontSize: '24px',
};
