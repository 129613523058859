import { Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IMyAccordionProps {
  title: string | (() => ReactNode);
  children?: React.ReactNode;
  className?: string;
}

export default function MyAccordion(props: IMyAccordionProps) {
  const renderTitle = () => {
    if (typeof props.title === 'function') {
      return props.title();
    }
    return (
      <>
        {props.title}
        <FontAwesomeIcon color='#0BC268' icon={faCaretRight} size='2x' />
      </>
    );
  };

  return (
    <Accordion alwaysOpen={true} className={twMerge('my-accordion', props.className)}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>{renderTitle()}</Accordion.Header>
        <Accordion.Body className={'tw-bg-white tw-rounded-b-[10px]'}>{props.children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
