import * as React from 'react';
import SubpageHeader from '../../SubpageHeader/SubpageHeader';
import { twMerge } from '../../../index';

interface ICinemaVoucherHeaderProps {
  className?: string;
}

const CinemaVoucherHeader = (props: ICinemaVoucherHeaderProps) => (
  <SubpageHeader
    headerClassName={twMerge('tw-text-3xl sm:tw-text-4xl', props.className)}
    iconSrc={'/assets/cinemaVoucher.svg'}
    title={'Kupony do kina'}
  />
);

export default CinemaVoucherHeader;
