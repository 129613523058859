import * as React from 'react';
import { Image } from 'react-bootstrap';
import WalletNauHeader from '../WalletNauHeader/DiscountCodeHeader';
import * as styles from './styles';

const ReckoningWallet = () => (
  <div style={styles.containerBackground as React.CSSProperties}>
    <WalletNauHeader />
    <div className='w-100 d-flex justify-content-center'>
      <Image
        src='assets\calendar.svg'
        style={{
          marginBottom: '40px',
          marginTop: '40px',
          height: '200px',
          textAlign: 'center',
        }}
      />
    </div>
    <div className='w-100 d-flex justify-content-center ' style={{ padding: '10px' }}>
      <h1 style={{ textAlign: 'center', color: '#0bc268' }}>Trwa okres rozliczeniowy.</h1>
    </div>
    <div className='row'>
      <div className={'col-md-8 mx-auto'} style={{ textAlign: 'center' }}>
        <p style={styles.lastMonth as React.CSSProperties}>
          <span style={{}}>
            Gdy zaksięgujemy płatność za poprzedni miesiąc, przywrócimy Ci możliwość wypłaty środków w ramach
            przyznanego limitu. Prosimy o cierpliwość.
          </span>
        </p>
      </div>
    </div>
  </div>
);

export default ReckoningWallet;
