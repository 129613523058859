export const center = {
  textAlign: 'center',
};

export const divSpacing = {
  margin: '15px',
};

export const marginBottomField = {
  marginBottom: '20px',
};

export const labelError = {
  marginTop: 5,
  color: 'red',
};

export const imageStyle = {
  width: '50pt',
  height: '50pt',
  marginBottom: '25px',
  marginTop: '15px',
};

export const title = {
  fontSize: '25px',
  color: '#00635C',
  lineHeight: 'normal',
};
