import { Spinner } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import { LoadingComponentProps } from './types';

export default function LoadingComponentPartial({
  content = 'Ładowanie...',
  visible = true,
  variant = 'dark',
}: LoadingComponentProps) {
  return (
    <Fade in={visible} unmountOnExit={true}>
      <div className='d-flex justify-content-center'>
        <Spinner animation='grow' role='status' variant={variant}>
          <span className='visually-hidden'>{content}</span>
        </Spinner>
      </div>
    </Fade>
  );
}
