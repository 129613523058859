import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

interface Modal {
  open: boolean;
  body: JSX.Element | null;
  size: 'sm' | 'lg' | 'xl';
  headerTitle: string | (() => ReactNode) | null;
  onClose?: () => void;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    body: null,
    size: 'lg',
    headerTitle: null,
  };

  constructor() {
    makeAutoObservable(this);
  }
  openModal = (
    content: JSX.Element,
    size: 'sm' | 'lg' | 'xl',
    headerTitle: string | (() => ReactNode) | null,
    onClose?: () => void,
  ) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.size = size;
    this.modal.headerTitle = headerTitle;
    this.modal.onClose = onClose;
  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
    this.modal.size = 'lg';
    this.modal.headerTitle = null;
    this.modal.onClose?.();
  };
}
