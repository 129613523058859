import { observer } from 'mobx-react-lite';
import React from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import CinemaVoucher from './CinemaVoucher';

export default observer(() => {
  return (
    <>
      <CustomerDashboard>
        <CinemaVoucher />
      </CustomerDashboard>
    </>
  );
});
