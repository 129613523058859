import React from 'react';
import { Button } from 'react-bootstrap';
import { KlOrderFormM, KlProduct } from '../../types/kl';
import { useStore } from '../../store/store';
import { center } from '../../components/AdminAuthorization/styles';

const KlVariantChoice = function (props: {
  idx?: number;
  variantOptions: KlProduct[];
  order: KlOrderFormM;
  setVariant: (...params: any[]) => void;
}) {
  const { modalStore } = useStore();
  const handleClose = () => modalStore.closeModal();
  const setVariant = (variant: KlProduct) => {
    if (props.idx != undefined) {
      props.setVariant(props.order, variant, props.idx);
    } else {
      props.setVariant(props.order, variant);
    }
    handleClose();
  };
  return (
    <>
      <div style={{ textAlign: 'left', padding: '20px' } as React.CSSProperties}>
        Szanowny Kliencie,
        <br />
        raz w roku masz możliwość skorzystania z darmowego ubezpieczenia podróżnego na okres 14 dni. Zakres terytorialny
        obejmuje Europę oraz basen Morza Śródziemnego. Czy chcesz skorzystać z tej oferty teraz? Jeśli zdecydujesz się
        nie skorzystać z darmowego ubezpieczenia, naliczymy Ci standardową opłatę za ubezpieczenie, a bezpłatny pakiet
        zostanie zarezerwowany na przyszły wyjazd.
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' } as React.CSSProperties}>
        {props.variantOptions.map((variant, index) => (
          <Button
            key={index}
            onClick={() => setVariant(variant)}
            className={
              variant.id === 1 || variant.id === 5 ? 'btn-primary btn-full-width mx-2' : 'btn-white btn-full-width mx-2'
            }>
            {variant.id === 1 || variant.id === 5 ? 'Tak' : 'Nie'}
          </Button>
        ))}
      </div>
    </>
  );
};

export default KlVariantChoice;
