import { observer } from 'mobx-react-lite';
import React from 'react';
import ProgressDiscountCodeContract from '../../components/DiscountCode/ProgressDiscountCodeContract/ProgressDiscountCodeContract';
import DiscountCodeTabs from '../../components/DiscountCode/DiscountCodeTabs/DiscountCodeTabs';
import StartDiscountCode from './StartDiscountCode/StartDiscountCode';
import { DiscountCodeContractStatusE } from '../../enums/discountCodeContractStatus.enums';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import RejectedDiscountCodeContract from '../../components/DiscountCode/RejectedDiscountCodeContract/RejectedDiscountCodeContract';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { useSwrAgent } from '../../api/useSwrAgent';
import { DiscountCodeContractStatus } from '../../types/discountCode';

export default observer(() => {
  const {
    data: contractStatuses,
    isLoading,
    isValidating,
  } = useSwrAgent().DiscountCodes.GetAllUserDiscountCodeContracts();

  const renderSwitch = (contractStatuses?: DiscountCodeContractStatus[]) => {
    const rejectedStatuses: number[] = [
      DiscountCodeContractStatusE.Rejected,
      DiscountCodeContractStatusE.Blocked,
      DiscountCodeContractStatusE.Canceled,
    ];
    if (!contractStatuses || contractStatuses.length == 0) return <StartDiscountCode />;
    if (contractStatuses?.some((x) => x.status == DiscountCodeContractStatusE.Active)) {
      return <DiscountCodeTabs />;
    } else if (contractStatuses?.some((x) => rejectedStatuses.includes(x.status))) {
      return <RejectedDiscountCodeContract />;
    } else if (
      contractStatuses?.some((x) => x.versionNumber === 2 && x.status == DiscountCodeContractStatusE.Waiting)
    ) {
      return <ProgressDiscountCodeContract />;
    } else {
      return <StartDiscountCode />;
    }
  };
  return (
    <>
      <LoadingComponent visible={isLoading} />
      <CustomerDashboard>{renderSwitch(contractStatuses)}</CustomerDashboard>
    </>
  );
});
