import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import ValidationHelper from '../../helpers/ValidationHelper';
import { useStore } from '../../store/store';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';

export default observer(() => {
  const { userStore } = useStore();
  const phoneRegExp = /^\d{9}$/;
  const peselRegExp = /^\d{11}$/;
  const userInstitution: number | undefined = userStore.getUserInstitution()?.institutionId;
  useEffect(() => {
    userStore.getUserInstitution();
  }, []);
  return (
    <AdminDashboard>
      <Formik
        initialValues={{
          userInstitutionId: 0,
          firstName: '',
          lastName: '',
          pesel: '',
          email: '',
          phone: '',
          institutionId: userInstitution ?? 0,
          isActive: false,
          isFired: false,
          error: null,
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          values.institutionId = userInstitution ?? 0;
          userStore.registerCustomer(values).catch((error) => {
            setErrors({ error });
            setSubmitting(false);
          });
        }} // == {error} shourtcut
        validationSchema={Yup.object({
          firstName: Yup.string().required('Pole jest wymagane'),
          lastName: Yup.string().required('Pole jest wymagane'),
          pesel: Yup.string()
            .required('Pole jest wymagane')
            .matches(peselRegExp, 'Nieprawidłowy PESEL')
            .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
          email: Yup.string().required('Pole jest wymagane').email('Adres email jest niepoprawny'),
          phone: Yup.string().required('Pole jest wymagane').matches(phoneRegExp, 'Numer telefonu jest niepoprawny'),
        })}>
        {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
          <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
            <h2 style={{ color: 'teal', textAlign: 'center' }}>Rejestracja użytkownika</h2>
            <MyTextInput name='firstName' placeholder='Imię' />
            <MyTextInput name='lastName' placeholder='Nazwisko' />
            <MyTextInput name='pesel' placeholder='PESEL' />
            <MyTextInput name='email' placeholder='E-mail' />
            <MyTextInput name='phone' placeholder='Numer telefonu' />
            <ErrorMessage
              name='error'
              render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
            />
            {isSubmitting ? (
              <Button className='btn-full-width mt-3' disabled={!isValid || !dirty || isSubmitting} type='submit'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Rejestracja…
              </Button>
            ) : (
              <Button className='btn-full-width mt-3' disabled={!isValid || !dirty || isSubmitting} type='submit'>
                Zarejestruj
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </AdminDashboard>
  );
});

// <Form className="ui form error" clasa error jest wymagana
