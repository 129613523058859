export default class ValidationHelper {
  static checkPeselControlSum(pesel: string | undefined): boolean {
    if (pesel) {
      pesel = pesel.replace('_', '');

      const p: string[] = pesel.split('');
      const pInt: number[] = p.map((x) => parseInt(x));
      const weightMatrix: number[] = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];

      let checksum = 0;
      for (let i = 0; i < pInt.length - 1; i++) {
        checksum += pInt[i] * weightMatrix[i];
      }
      checksum %= 10;

      return pInt[10] == checksum;
    }
    return true;
  }

  static checkDate(date: string | undefined): boolean {
    if (date) {
      const parts = date.split('-');
      if (parts.length < 3) {
        return false;
      }
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]);
      const day = parseInt(parts[2]);
      if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return false;
      }
      if (month > 12 || month <= 0) {
        return false;
      }
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (day > 31 || day <= 0) return false;
          break;
        case 2:
          if (day > 29 || day <= 0) return false;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          if (day > 30 || day <= 0) return false;
          break;
        default:
          break;
      }
    }
    return true;
  }
  static checkEmail(email: string | undefined): boolean {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return emailRegex.test(email);
    }
    return false;
  }
}
