import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, FormCheck, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextMaskedInput from '../../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import { Form, Formik } from 'formik';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import * as styles from './styles';
import FileHelper from '../../../helpers/FileHelper';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import { validationSchema, validationSchemaV2 } from '.';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { PathRoute } from '../../../constants/pathRoute/Route';
import Config from '../../../Config';

export default observer((props: { contractId: number; onSave?: () => void }) => {
  const { modalStore, circleKStore, fileStore } = useStore();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [version, setVersion] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const handleClose = () => modalStore.closeModal();
  const handleAccept = () => {
    setSent(false);
    circleKStore.generateTokenToCircleKContract(props.contractId).then(() => {
      setAccepted(true);
    });
    setTimeout(() => {
      setSent(true);
    }, 20000);
  };
  const handlePdf = () => {
    setDownloading(true);
    fileStore
      .getCircleKPdf(props.contractId)
      .then((resp) => {
        const fileName = 'umowa_nau_' + props.contractId + '.pdf';
        FileHelper.downloadFileFromBlob(resp, fileName);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.log(`Error in promises ${error}`);
        toast.error('Wystąpił błąd przy pobieraniu pliku');
      });
  };
  useEffect(() => {
    circleKStore
      .getUserCircleKContractVersion(props.contractId)
      .then((resp) => {
        setVersion(resp);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        token: '',
        contractId: props.contractId,
        regulations: null,
        error: null,
      }}
      onSubmit={({ token, contractId }, { setErrors }) =>
        circleKStore
          .signCircleKContract(contractId, token)
          .then(() => {
            handleClose();
            props.onSave?.();
            toast.success('Umowa została podpisana. Przekazano prośbę o aktywację karty.');
          })
          .catch(() => setErrors({ error: 'Wystąpił błąd podczas podpisywania umowy!' }))
      }
      validationSchema={version && version >= 2 ? validationSchemaV2 : validationSchema}>
      {({ values, errors, handleSubmit, isSubmitting, handleChange, validateForm, setFieldError, setFieldTouched }) => (
        <>
          {loading ? (
            <LoadingComponentPartial />
          ) : (
            <div>
              <div style={styles.textStyle}>
                Pobierz umowę, aby skorzystać z rabatów.
                <br />
                Zapoznaj się z treścią umowy i podpisz ją za pomocą hasła z sms.
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {downloading ? (
                  <Button disabled={downloading} style={styles.btnTextStyle}>
                    <FontAwesomeIcon icon={faFilePdf} />
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    <span style={{ marginLeft: '10px' }}>Pobieranie umowy...</span>
                  </Button>
                ) : (
                  <Button onClick={() => handlePdf()} style={styles.btnTextStyle}>
                    <FontAwesomeIcon icon={faFilePdf} />
                    <span style={{ marginLeft: '10px' }}>Pobierz umowę</span>
                  </Button>
                )}
                {version && version >= 2 && (
                  <div>
                    <div className='d-flex align-items-start' style={styles.label}>
                      <MyCheckbox name={`regulations`} value={values.regulations} />
                      <FormCheck.Label htmlFor={`regulations`} style={styles.labelText}>
                        Oświadczam, że zapoznałem/zapoznałam się z treścią
                        <span
                          className='approvals-link'
                          onClick={() =>
                            window.open(
                              `${Config.appUrl.toString().replace('api/', '') + PathRoute.REGULATIONS_CIRCLE_K}`,
                              '_system',
                              'location=yes',
                            )
                          }>
                          {' '}
                          „Regulaminu Karta Paliwowa zniżka na paliwo”
                        </span>
                      </FormCheck.Label>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <div style={styles.textStyle}>Hasło do pobrania umowy to Twój numer PESEL</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Button
                  disabled={!sent}
                  onClick={() =>
                    validateForm().then((err) => {
                      if (err.regulations) {
                        setFieldError('regulations', err.regulations);
                        setFieldTouched('regulations');
                      } else {
                        handleAccept();
                      }
                    })
                  }
                  style={styles.btnTextStyle}
                  type='button'
                  variant='primary'>
                  Wygeneruj hasło sms do podpisania umowy
                </Button>
                {accepted && (
                  <div style={styles.textStyle}>
                    Hasło zostało wysłane na numer telefonu zawarty w umowie, powinieneś otrzymać je w ciągu kilkunastu
                    sekund.
                  </div>
                )}
              </div>
              {accepted && (
                <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                  <MyTextMaskedInput
                    mask={MaskHelpers.token}
                    name={`token`}
                    onChange={handleChange}
                    placeholder='Kod z sms'
                    style={{}}
                  />
                  <div
                    style={{
                      marginTop: '15px',
                    }}>
                    <div className={'d-flex flex-column flex-md-row justify-content-md-end gap-2'}>
                      <Button onClick={handleClose} variant='secondary'>
                        Anuluj
                      </Button>
                      {isSubmitting ? (
                        <Button type='submit' variant='primary'>
                          <span className='m-1'>
                            <Spinner
                              animation='grow'
                              aria-hidden='true'
                              as='span'
                              role='status'
                              size='sm'
                              variant='light'
                            />
                          </span>
                          Zapisywanie...
                        </Button>
                      ) : (
                        <Button type='submit' variant='primary'>
                          Zapisz
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </>
      )}
    </Formik>
  );
});
