import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { CinemaVoucherCompaniesM } from '../../../types/cinemaVoucher';
import { toast } from 'react-toastify';
import { twMerge } from '../../../index';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import PlusButtonIcon from '../../Icons/PlusButtonIcon';
import MinusButtonIcon from '../../Icons/MinusButtonIcon';
import { CinemaCompanyId } from '../../../enums/cinemaCompanyId.enum';
import CustomBlueToast from '../../Toast/CustomBlueToast';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import { UserPromotionE } from '../../../enums/userPromotion.enums';

export default observer(() => {
  const { cinemaVoucherStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<CinemaVoucherCompaniesM[]>([]);
  const [sum, setSum] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittingCompanies, setSubmittingCompanies] = useState<number[]>([]);
  const [showMaxQuantityToast, setShowMaxQuantityToast] = useState<boolean>(false);

  const { data: isUserAssignedToInstitutionAsDirector, isLoading: isLoadingIsUserAssignedToInstitutionAsDirector } =
    useSwrAgent().Users.IsUserAssignedToInstitutionAsDirector();

  const { data: hasUserCollectedFreeCinemaVouchers, isLoading: isLoadingHasUserCollectedFreeCinemaVouchers } =
    useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
      CinemaVoucherOrderPromo.InstitutionDirectorPromo,
    );
  const {
    data: hasUserCollectedFreeTeachersDayCinemaVouchers,
    isLoading: isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers,
  } = useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
    CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo,
  );
  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();

  const showDirectorPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);

  const showDirectorTeachersDayPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectorsTeachersDay);

  const isDirectorPromoLayout =
    isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeCinemaVouchers && showDirectorPromo;
  const isDirectorTeachersDayPromoLayout =
    isUserAssignedToInstitutionAsDirector &&
    !hasUserCollectedFreeTeachersDayCinemaVouchers &&
    showDirectorTeachersDayPromo;
  const serviceCharge = isDirectorPromoLayout ? 0 : 1;

  useEffect(() => {
    cinemaVoucherStore
      .getAvailableCinemaVoucherTypes()
      .then((resp) => {
        setCompanies(resp);
        let companiesSum = 0;
        resp.forEach((element) => {
          if (cinemaVoucherStore.companies.length > 0) {
            let elementSum = 0;
            element.types.forEach((type) => {
              const typeFromStore = cinemaVoucherStore.companies
                .find((x) => x.id === element.id)
                ?.types.find((x) => x.id === type.id);
              type.quantity = typeFromStore?.quantity ?? 0;
              elementSum += type.amount * type.quantity;
            });
            element.sum = elementSum;
          }
          companiesSum += element.sum;
        });
        setSum(companiesSum + serviceCharge);
        setCompaniesInStore(resp);
      })
      .catch((err) => {
        for (let index = 0; index < err.length; index++) {
          toast.error(err[index]);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const setCompaniesInStore = (companies: CinemaVoucherCompaniesM[]) => {
    cinemaVoucherStore.setCompanies(companies);
  };
  const increment = (idx: number, companyId: number) => {
    if (isDirectorPromoLayout && cinemaVoucherStore.cinemaVoucherCartQuantity >= 2) {
      setShowMaxQuantityToast(true);
      return;
    }
    if (cinemaVoucherStore.cinemaVoucherCartQuantity >= 50) {
      setShowMaxQuantityToast(true);
      return;
    }
    const newCompanies = [...companies];
    const company = newCompanies.filter((x) => x.id == companyId)[0];
    let amount = company.sum;
    amount += company.types[idx].amount;
    if (company.types[idx].quantity >= company.types[idx].availableQuantity) {
      toast.error('Nie można wybrać więcej kodów tego typu');
    } else {
      company.types[idx].quantity += 1;
      company.sum = amount;
      setCompanies(newCompanies);
    }
    let companiesSum = 0;
    newCompanies.forEach((element) => {
      companiesSum += element.sum;
    });
    setSum(companiesSum + serviceCharge);
    setCompaniesInStore(newCompanies);
  };
  const decrement = (idx: number, companyId: number) => {
    const newCompanies = [...companies];
    const company = newCompanies.filter((x) => x.id == companyId)[0];
    let amount = company.sum;
    amount -= company.types[idx].amount;
    if (company.types[idx].quantity >= 1) {
      company.types[idx].quantity -= 1;
      company.sum = amount;
      setCompanies(newCompanies);
    }
    let companiesSum = 0;
    newCompanies.forEach((element) => {
      companiesSum += element.sum;
    });

    setSum(companiesSum + serviceCharge);
    setCompaniesInStore(newCompanies);
  };

  const sendOrder = () => {
    if (
      (isDirectorPromoLayout || isDirectorTeachersDayPromoLayout) &&
      cinemaVoucherStore.cinemaVoucherCartQuantity > 2
    ) {
      setShowMaxQuantityToast(true);
      return;
    }
    if (cinemaVoucherStore.cinemaVoucherCartQuantity > 50) {
      setShowMaxQuantityToast(true);
      return;
    }
    if (sum > 1) {
      setIsSubmitting(true);
      let promoId: number | undefined = undefined;
      if (isDirectorTeachersDayPromoLayout) {
        promoId = CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo;
      } else if (isDirectorPromoLayout) {
        promoId = CinemaVoucherOrderPromo.InstitutionDirectorPromo;
      }
      cinemaVoucherStore
        .sendOrder(false, promoId)
        .catch(() => {
          toast.error('Brak kodów do zamówienia.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      toast.error('Należy wybrać kody do zamówienia.');
    }
  };

  const addCinemaVoucherAvailabilityNotification = (cinemaCompanyId: number) => {
    setSubmittingCompanies((prevState) => [...prevState, cinemaCompanyId]);
    cinemaVoucherStore
      .addCinemaVoucherAvailabilityNotification(cinemaCompanyId)
      .then(() => {
        toast.success('Powiadomimy Cię, gdy kupony będą znowu dostępne');
      })
      .finally(() => setSubmittingCompanies((prevState) => prevState.filter((id) => id !== cinemaCompanyId)));
  };

  const getInfoBox = (companyId: number) => {
    if (
      ![CinemaCompanyId.Helios, CinemaCompanyId.MultikinoOnlyWarsaw, CinemaCompanyId.CinemaCityWithWarsaw].some(
        (x) => x === companyId,
      )
    ) {
      return <></>;
    }
    return (
      <div className='tw-my-2 tw-flex tw-gap-2 tw-rounded-xl tw-bg-[#f5f5f5] tw-p-2 tw-text-sm tw-text-nau-green-dark'>
        <div>
          <div className={'tw-h-[18px] tw-w-[18px]'}>
            <Image src='/assets/infoGreen.svg' />
          </div>
        </div>
        <div className={'tw-text-left tw-font-normal tw-leading-snug'}>
          {companyId === CinemaCompanyId.Helios && (
            <>
              <div className=''>
                Kupon = 1 bilet na dowolny seans wyświetlany w okresie jego ważności, dla seansu 3D obejmuje opłatę za
                okulary zgodnie z cennikiem kin, dla seansów w salach DREAM wymagana dopłata zgodnie z cennikiem kin.
              </div>
            </>
          )}
          {(companyId === CinemaCompanyId.Multikino || companyId === CinemaCompanyId.MultikinoOnlyWarsaw) && (
            <>
              <div className=''>
                *Kupon = 1 bilet na dowolny seans wyświetlany w okresie jego ważności, dla seansu 3D obejmuje opłatę za
                okulary (zgodnie z cennikiem kin), dla miejsc VIP wymagana dopłata (zgodnie z cennikiem kin).
              </div>
            </>
          )}
          {(companyId === CinemaCompanyId.CinemaCity || companyId === CinemaCompanyId.CinemaCityWithWarsaw) && (
            <>
              <div className=''>Kupon = 1 bilet obowiązuje na wszystkie seanse 2D w standardowych salach kinowych.</div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div id='CinemaVouchers'>
      {loading ||
      isLoadingHasUserCollectedFreeCinemaVouchers ||
      isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers ||
      areMarketingDataLoading ||
      isLoadingIsUserAssignedToInstitutionAsDirector ? (
        <div className={'tw-rounded-b-[10px] tw-rounded-b-[10px] tw-bg-white tw-py-4'}>
          <LoadingComponentPartial />
        </div>
      ) : (
        <>
          <div
            className={'tw-flex tw-flex-col tw-gap-2'}
            style={
              {
                background: '#fff',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                marginTop: '0',
                padding: '10px',
              } as React.CSSProperties
            }>
            {showMaxQuantityToast && (
              <CustomBlueToast
                onClose={() => setShowMaxQuantityToast(false)}
                text={
                  isDirectorPromoLayout || isDirectorTeachersDayPromoLayout
                    ? 'Możesz odebrać 2 darmowe kupony'
                    : 'Limit wynosi maks. 50 sztuk \nprzy jednorazowym zamówieniu.'
                }
              />
            )}

            {companies.length > 0 &&
              companies.map((company) => (
                <div key={company.id}>
                  {company.id != CinemaCompanyId.MultikinoOnlyWarsaw &&
                    company.id != CinemaCompanyId.CinemaCityWithWarsaw && (
                      <div className={'tw-flex tw-justify-center'}>
                        <Image
                          alt='logo'
                          className={twMerge(
                            'tw-opacity-100',
                            company.types.every((type) => !type.availableQuantity) && 'tw-opacity-20',
                          )}
                          height={195}
                          src={
                            '/assets/cinema_company' +
                            company.id +
                            (company.id == CinemaCompanyId.CinemaCity ? '.svg' : '.png')
                          }
                          width={305}></Image>
                      </div>
                    )}

                  {company.types.length > 0 &&
                    company.types.map((code, idx) => (
                      <div
                        className='tw-my-2.5 tw-rounded-lg tw-bg-white tw-px-2 tw-text-center tw-text-gray-500'
                        key={idx}>
                        {!code.availableQuantity ? (
                          <div>
                            {code.description && (
                              <p
                                className='tw--mt-2 tw-mb-2 tw-leading-snug tw-text-teal-700'
                                dangerouslySetInnerHTML={{ __html: code.description }}></p>
                            )}
                            <div className='tw-flex-1 tw-text-center tw-text-teal-700 '>Dostępne wkrótce</div>
                            {submittingCompanies.includes(code.companyId) ? (
                              <Button className='tw-mt-2' disabled={true}>
                                <span className='m-1'>
                                  <Spinner
                                    animation='grow'
                                    aria-hidden='true'
                                    as='span'
                                    role='status'
                                    size='sm'
                                    variant='light'
                                  />
                                </span>
                                Zapisywanie…
                              </Button>
                            ) : (
                              <Button
                                className='tw-mt-2'
                                onClick={() => addCinemaVoucherAvailabilityNotification(code.companyId)}>
                                Powiadom mnie o dostępności
                              </Button>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className='tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between tw-text-teal-700'>
                              <div>
                                {code.description && (
                                  <p
                                    className='tw-mb-2 tw-leading-snug tw-text-teal-700'
                                    dangerouslySetInnerHTML={{ __html: code.description }}></p>
                                )}

                                <div
                                  className={
                                    'tw-flex tw-justify-center tw-gap-2 tw-text-2xl tw-font-bold tw-text-teal-700'
                                  }>
                                  <div className='tw-relative tw-flex tw-gap-2 tw-text-center '>
                                    {code.amount} zł
                                    {(isDirectorPromoLayout || isDirectorTeachersDayPromoLayout) && (
                                      <>
                                        <div
                                          className={
                                            'tw-rotate tw-absolute tw-left-0 tw-right-0 tw-top-1/2 tw-h-[3px] tw--rotate-12 tw-bg-red-600'
                                          }></div>
                                      </>
                                    )}
                                  </div>
                                  {(isDirectorPromoLayout || isDirectorTeachersDayPromoLayout) && <div>0 zł</div>}
                                </div>
                              </div>
                              <div className='tw-mt-2 tw-flex tw-w-full tw-items-center tw-gap-3'>
                                <div className='tw-flex-1 tw-text-center tw-text-teal-700 '>Ilość</div>
                                <div className='tw-flex tw-justify-center'>
                                  <button
                                    className='tw-border-none tw-bg-transparent tw-text-teal-700'
                                    onClick={() => decrement(idx, company.id)}>
                                    <MinusButtonIcon className={'tw-h-8 tw-w-8'} />
                                  </button>
                                  <div className='tw-mx-2 tw-flex tw-h-[35px] tw-w-[75px] tw-items-center tw-justify-center tw-rounded-lg tw-border-[3px] tw-border-[#B0B6BA]'>
                                    {code.quantity}
                                  </div>
                                  <button
                                    className='tw-border-none tw-bg-transparent tw-text-teal-700'
                                    onClick={() => increment(idx, company.id)}>
                                    <PlusButtonIcon className={'tw-h-8 tw-w-8'} />
                                  </button>
                                </div>
                                <div className={'tw-flex-1'}></div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  {getInfoBox(company.id)}
                </div>
              ))}
          </div>
          <div
            className={'tw-mt-4'}
            style={
              {
                background: '#5A6268',
                borderRadius: '10px',
                color: '#fff',
                padding: '20px',
              } as React.CSSProperties
            }>
            <div className={'tw-mb-3 tw-text-xl tw-font-normal'}>Podsumowanie zamówienia</div>
            <div className={''}>
              {companies.length > 0 &&
                companies
                  .filter((company) => company.types.length > 0)
                  .map((company) => (
                    <div className={'tw-mb-6'} key={company.id}>
                      {company.types.length > 0 &&
                        company.types
                          .filter((x) => x.quantity > 0)
                          .map((code, idx) => (
                            <div key={idx}>
                              <div className={'tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2'}>
                                <div>
                                  <div className={'tw-font-semibold tw-leading-snug'}>{code.companyName}</div>
                                </div>
                                <div className='tw-flex tw-whitespace-nowrap'>
                                  {code.quantity} x{' '}
                                  {isDirectorPromoLayout || isDirectorTeachersDayPromoLayout ? 0 : code.amount} zł
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  ))}
              <div>
                <div className={'tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2'}>
                  <div></div>
                  <div className='tw-flex tw-whitespace-nowrap'>
                    {!(isDirectorPromoLayout || isDirectorTeachersDayPromoLayout) && <i>+ Opłata serwisowa 1 zł</i>}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={twMerge(
                'tw-my-3 tw-flex tw-w-full tw-justify-between tw-text-xl tw-font-normal ',
                sum && sum > 0 && 'tw-border-t tw-border-white tw-pt-2',
              )}>
              <span>Suma:</span>
              <span>{isDirectorPromoLayout || isDirectorTeachersDayPromoLayout ? 0 : sum} zł</span>
            </div>
            {isSubmitting ? (
              <Button className='btn-full-width' disabled={true}>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Potwierdzanie zamówienia…
              </Button>
            ) : (
              <Button className='btn-full-width' onClick={() => sendOrder()}>
                Zamów
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
});
