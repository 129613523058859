import React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './styles';
import { Button, Spinner } from 'react-bootstrap';
import { ErrorMessage, Form, Formik } from 'formik';
import MyDateInput from '../../FormControls/MyDateInput/MyDateInput';
import { DiscountCodeLeadM } from '../../../types/discountCode';
import { BirthdayCodeCampaignM, BirthdayCodeVerificationStatus } from '../../../types/birthdayCode';
import * as Yup from 'yup';

export interface IBirthdayCodeFreeCodeContentProps {
  bk3?: BirthdayCodeCampaignM;
  bk4?: BirthdayCodeCampaignM;
  onSendLeadForm: (discountCodeLead: DiscountCodeLeadM) => Promise<string | number | void>;
}

export default observer((props: IBirthdayCodeFreeCodeContentProps) => {
  return (
    <div>
      <p style={styles.headingTextStyle}>Podaj dane i otrzymaj dodatkowe E-bony do Biedronki!</p>
      <br />
      <p style={styles.textStyle}>
        1 data + zakup 1 ubezpieczenia = E-bon o wartości 30 zł
        <br />
        2 daty + zakup 2 ubezpieczeń = E-bon o wartości 60 zł
        <br />
        <br />
        Jak odebrać E-bony?
        <br />
        1. Wpisz datę zakończenia okresu ubezpieczenia komunikacyjnego i/lub majątkowego.
        <br />
        2. Nasz konsultant skontaktuje się z Tobą przed końcem okresu ubezpieczenia w celu przedstawienia oferty.
        <br />
        3. Kup ubezpieczenie komunikacyjne i/lub majątkowe.
        <br />
        4. Gotowe - dodatkowe E-bony aktywowane!
      </p>
      <Formik
        initialValues={{
          endOfMotorInsurance: '',
          endOfPropertyInsurance: '',
          error: null,
        }}
        onSubmit={(values, { setSubmitting }) => {
          const discountCodeLead: DiscountCodeLeadM = {
            endOfPropertyInsurance: values.endOfPropertyInsurance,
            endOfMotorInsurance: values.endOfMotorInsurance,
          };
          props.onSendLeadForm(discountCodeLead).finally(() => setSubmitting(false));
        }}
        validationSchema={Yup.object().shape({
          endOfMotorInsurance: Yup.date().min(new Date(), `Minimalna data to: ${new Date().toLocaleDateString()}`),
          endOfPropertyInsurance: Yup.date().min(new Date(), `Minimalna data to: ${new Date().toLocaleDateString()}`),
        })}>
        {({ handleSubmit, isSubmitting, errors, isValid, values }) => (
          <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
            {props.bk3?.verificationStatus === BirthdayCodeVerificationStatus.NotSent && (
              <>
                <p className={'mb-2'} style={styles.textSmall}>
                  Koniec ubezpieczenia komunikacyjnego:
                </p>
                <div className={'col-12 col-lg-6'}>
                  <MyDateInput name='endOfMotorInsurance' value={values.endOfMotorInsurance} />
                </div>
                <div className={'mb-3'} />
              </>
            )}

            {props.bk4?.verificationStatus === BirthdayCodeVerificationStatus.NotSent && (
              <>
                <p className={'mb-2'} style={styles.textSmall}>
                  Koniec ubezpieczenia majątkowego:
                </p>
                <div className={'col-12 col-lg-6'}>
                  <MyDateInput name='endOfPropertyInsurance' value={values.endOfPropertyInsurance} />
                </div>
              </>
            )}

            <ErrorMessage
              name='error'
              render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
            />
            {isSubmitting ? (
              <Button className='btn-full-width btn-orange mt-3' disabled={!isValid || isSubmitting} type='submit'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Zapisywanie…
              </Button>
            ) : (
              (values.endOfMotorInsurance || values.endOfPropertyInsurance) && (
                <Button className='btn-full-width mt-3 btn-orange' disabled={!isValid || isSubmitting} type='submit'>
                  Odbierz E-bon {values.endOfMotorInsurance && values.endOfPropertyInsurance ? '60 zł' : '30 zł'}
                </Button>
              )
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
});
