export const limitCardArray = [
  { label: '400', value: 400 },
  { label: '500', value: 500 },
  { label: '600', value: 600 },
  { label: '700', value: 700 },
  { label: '800', value: 800 },
  { label: '900', value: 900 },
];
export const cardCompanyArray = [
  { label: 'Circle K', value: 1 },
  { label: 'BP', value: 2 },
  { label: 'Shell', value: 3 },
];
export const benefitStatusArray = [
  { label: 'Wszystkie', value: 0 },
  { label: 'Aktywowany', value: 1 },
  { label: 'Nieaktywowany', value: 2 },
  { label: 'Zablokowany', value: 3 },
];

export const UserIsFiredArray = [
  { label: 'Wszystkie', value: '' },
  { label: 'Zwolniony', value: true },
  { label: 'Zatrudniony', value: false },
];
