export const heading = {
  color: 'teal',
  textAlign: 'left',
  fontWeight: 600,
} as React.CSSProperties;

export const phoneNumber = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#0bc268',
} as React.CSSProperties;
