import { App } from '@capacitor/app';
import { useEffect, useState } from 'react';
import { PathRoute } from '../constants/pathRoute/Route';
import { toast } from 'react-toastify';

export default function BackButtonProvider() {
  const [backPressedOnce, setBackPressedOnce] = useState(false);

  useEffect(() => {
    App.addListener('backButton', ({ canGoBack }) => {
      if (window.location.pathname === PathRoute.PUSTY || window.location.pathname === PathRoute.LOGIN || !canGoBack) {
        if (!backPressedOnce) {
          setBackPressedOnce(true);
          toast.info('Cofnij ponownie, aby zamknąć aplikację');

          // Reset the back button press count after 2 seconds
          setTimeout(() => {
            setBackPressedOnce(false);
          }, 2000);
        } else {
          App.exitApp();
        }
      } else {
        window.history.back();
      }
    });
  }, [backPressedOnce]);

  return null;
}
