export const flexColumnContainer = { display: 'flex', flexDirection: 'column' };

export const flexContainer = { display: 'flex' };

export const iconTransform = {
  alignSelf: 'baseline',
  transform: 'rotateY(180deg)',
};

export const flexWidthColumn = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const labelStyle = {
  alignSelf: 'center',
  fontSize: '36px',
  color: '#00635C',
  marginBottom: '25px',
};

export const selfStyles = {
  marginBottom: 0,
  alignSelf: 'center',
  textAlign: 'center',
  fontWeight: '300',
  lineHeight: '1',
  fontSize: '23px',
  color: '#00635C',
};

export const heightTop = { height: '150px', marginTop: '6rem' };

export const textStyle = {
  marginBottom: 0,
  alignSelf: 'center',
  textAlign: 'center',
  fontWeight: '400',
  lineHeight: '1.3',
  fontSize: '23px',
  color: '#00635C',
  width: '70%',
  marginTop: '2rem',
};

export const top = { marginTop: '10rem' };
