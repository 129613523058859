import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';

export const initialValues = {
  firstName: '',
  lastName: '',
  pesel: '',
  phone: '',
  email: '',
  documentNumber: '',
  documentExpirationDate: '',
  street: '',
  houseNo: '',
  flatNo: '',
  place: '',
  postCode: '',
  correspondenceStreet: '',
  correspondenceHouseNo: '',
  correspondenceFlatNo: '',
  correspondencePlace: '',
  correspondencePostCode: '',
  differentCorrespondece: false,
  error: null,
  nauWorker: false,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('Pole jest wymagane'),
  lastName: Yup.string().nullable().required('Pole jest wymagane'),
  documentNumber: Yup.string().nullable().required('Pole jest wymagane'),
  documentExpirationDate: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .test('date-test', 'Nieprawidłowa data', (value) => ValidationHelper.checkDate(value ?? '')),
  email: Yup.string().nullable().required('Pole jest wymagane').email('Podaj poprawny adres email'),
  phone: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
    .required('Pole jest wymagane'),
  pesel: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value ?? '')),
  street: Yup.string().nullable().required('Pole jest wymagane'),
  houseNo: Yup.string().nullable().required('Pole jest wymagane'),
  place: Yup.string().nullable().required('Pole jest wymagane'),
  postCode: Yup.string().nullable().required('Pole jest wymagane'),
  correspondenceStreet: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondenceHouseNo: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePlace: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
  correspondencePostCode: Yup.string()
    .nullable()
    .when('differentCorrespondece', {
      is: true,
      then: Yup.string().nullable().required('Pole jest wymagane'),
    }),
});
