import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Image, Nav, Offcanvas } from 'react-bootstrap';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { informationsRoutes, insuranceSubroutes, mainDashboardRoutes } from '../../routes/routes';
import { Route } from '../../types/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { container, imageDiv, lowerMenu, mobileContainer, nameText, navLink, upperMenu, webContainer } from './styles';
import _ from 'lodash';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import CircleKApplicationForCard from '../CircleK/CircleKApplicationForCard/CircleKApplicationForCard';
import FileHelper from '../../helpers/FileHelper';
import { RouteTypeE } from '../../enums/routeTypes.enums';
import { Benefit } from '../../enums/benefits.enums';
import useSWR from 'swr';
import { UserAvailableBenefits } from '../../types/user';
import getUrls from '../../api/getUrls';
import LoadingComponentPartial from '../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { NavbarRouteName } from '../../constants/navbarRouteName/navbarRouteName';
import MyDisclosure from '../Disclosure/MyDisclosure';
import { twMerge } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import { UserPromotionE } from '../../enums/userPromotion.enums';
import { useSwrAgent } from '../../api/useSwrAgent';
import NauIcon from '../Icons/NauIcon';
import ShieldIcon from '../Icons/ShieldIcon';

export default observer(() => {
  const { menuStore, userStore, modalStore, circleKStore } = useStore();
  const { data: userAvailableBenefits } = useSWR<UserAvailableBenefits[]>(getUrls.User.getUserAvailableBenefits);
  const [areCircleKCardsLoading, setAreCircleKCardsLoading] = useState<boolean>(false);
  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();
  const isFromNnwLink =
    userMarketingData && userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.NnwKidsCashback);

  useEffect(() => {
    if (userStore.isActive) {
      setAreCircleKCardsLoading(true);
      circleKStore.getCircleKCardsByUserId().finally(() => setAreCircleKCardsLoading(false));
    }

    userStore.checkEmailIsConfirmed();
  }, []);

  const getMainRoutes = () => {
    let dashboardRoutesCopy = _.cloneDeep(mainDashboardRoutes);
    if (isFromNnwLink) {
      dashboardRoutesCopy = [
        {
          name: 'Moje benefity',
          to: '',
          active: true,
          exact: true,
          iconComponent: NauIcon,
          subroutes: [
            {
              name: 'Zwrot składki',
              to: PathRoute.CASHBACK,
              active: true,
              imageUrl: '/assets/cashbackIcon.svg',
            },
          ],
          hiddenOnDemo: false,
        },
        {
          name: 'Ubezpieczenia',
          to: '',
          active: true,
          exact: true,
          iconComponent: ShieldIcon,
          subroutes: insuranceSubroutes,
          hiddenOnDemo: false,
        },
      ];
    }
    return dashboardRoutesCopy;
  };

  const isLoanVisible = userAvailableBenefits?.some((b) => {
    return b.benefitTypeId == Benefit.Loan && b.isInstitutional;
  });

  const lowerRoutes: Route[] = informationsRoutes;
  const handleClose = () => {
    menuStore.setClosedMainCustomer();
  };

  const areMainRoutesLoading = !userAvailableBenefits || areCircleKCardsLoading || areMarketingDataLoading;

  const isRouteVisible = (route: Route) => {
    if (!isLoanVisible && ['Wypłata od ręki', 'Historia wniosków'].includes(route.name)) {
      return false;
    }
    if (userStore.isOpzz && route.to == PathRoute.BIRTHDAY_CODES) {
      return false;
    }
    return !userStore.isDemoUser || !route.hiddenOnDemo;
  };
  return (
    <Offcanvas
      onHide={handleClose}
      show={menuStore.checkIsMainCustomerToggled}
      style={menuStore.isMobileView ? _.merge({}, container, mobileContainer) : _.merge({}, container, webContainer)}>
      <div className='d-flex justify-content-start'>
        <Image alt='logo' className='logo' src='/assets/NAU_LOGO_WHITE.svg' style={imageDiv} />
      </div>
      <div className='d-flex justify-content-start' style={nameText}>
        Nauczycielska Agencja Ubezpieczeniowa S.A.
      </div>
      <div
        className='navbar-vertical-content scrollbar d-flex flex-column'
        id='MainMenu'
        style={{ height: 'calc(100vh - 150px)', justifyContent: 'space-between' } as React.CSSProperties}>
        <Nav as='ul' className='flex-column' style={upperMenu}>
          {areMainRoutesLoading ? (
            <LoadingComponentPartial variant={'light'} />
          ) : (
            getMainRoutes().map((route, index) => (
              <Fragment key={route.name}>
                <Nav.Item
                  as='li'
                  className={twMerge(index != 0 && 'tw-border-t tw-border-nau-green-dark')}
                  key={route.name}>
                  {route.subroutes ? (
                    <>
                      <MyDisclosure
                        defaultOpen={false}
                        header={(open) => (
                          <div
                            className={twMerge(
                              'tw-flex  tw-items-center tw-justify-between tw-px-4 tw-py-2',
                              open && 'tw-bg-white',
                            )}>
                            <div className={twMerge('tw-text-xl tw-text-white', open && 'tw-text-nau-green-dark')}>
                              <div className='d-flex align-items-center'>
                                {route.icon && (
                                  <FontAwesomeIcon className='tw-mr-[15px] tw-h-5 tw-w-5' icon={route.icon} />
                                )}
                                {route.imageUrl && (
                                  <Image className={'tw-mr-[15px] tw-h-5 tw-w-5'} src={route.imageUrl} />
                                )}
                                {route.iconComponent && (
                                  <div className={'tw-mr-[15px]'}>
                                    <route.iconComponent
                                      className={twMerge(
                                        'tw-h-6 tw-w-6 tw-text-white',
                                        open && 'tw-text-nau-green-dark',
                                      )}
                                    />
                                  </div>
                                )}
                                <span className={twMerge('tw-font-medium')}>{route.name}</span>
                              </div>
                            </div>
                            <div
                              className={twMerge(
                                'tw-text-white tw-transition',
                                open && 'tw-rotate-90 tw-text-nau-green-dark',
                              )}>
                              <FontAwesomeIcon icon={faCaretRight} size='2x' />
                            </div>
                          </div>
                        )}
                        renderBody={(open) => (
                          <div className={twMerge('tw-flex tw-flex-col tw-pl-4', open && 'tw-bg-black/10')}>
                            {route.subroutes?.map((subroute) => (
                              <>
                                {isRouteVisible(subroute) && (
                                  <div key={subroute.name}>
                                    <Nav.Item as='li' key={subroute.name}>
                                      <NavLink
                                        className={'tw-group tw-py-2'}
                                        onClick={handleClose}
                                        style={navLink}
                                        to={subroute.to}>
                                        <div className='d-flex align-items-center group-hover:tw-font-medium '>
                                          {subroute.icon && (
                                            <span className='nav-link-icon'>
                                              <FontAwesomeIcon icon={subroute.icon} />
                                            </span>
                                          )}
                                          {subroute.imageUrl && (
                                            <Image
                                              src={subroute.imageUrl}
                                              style={{ width: '25px', marginRight: '10px' } as React.CSSProperties}
                                            />
                                          )}
                                          {subroute.iconComponent && (
                                            <div className={'tw-mr-[10px]'}>
                                              <subroute.iconComponent
                                                className={twMerge('tw-h-6 tw-w-6 tw-text-white')}
                                              />
                                            </div>
                                          )}
                                          <span className='tw-text-base'>{subroute.name}</span>
                                        </div>
                                      </NavLink>
                                    </Nav.Item>
                                  </div>
                                )}
                                {!circleKStore.hasNewContract &&
                                  circleKStore.hasAnyNauProfitCard &&
                                  subroute.name == NavbarRouteName.FUEL_CARDS && (
                                    <div key={subroute.name + '_newCard'}>
                                      <Nav.Item as='li'>
                                        <div style={navLink}>
                                          <div className='d-flex align-items-center'>
                                            <span className='nav-link-icon'>
                                              <Image
                                                src={'/assets/card.svg'}
                                                style={{ height: '20px' } as React.CSSProperties}
                                              />
                                            </span>
                                            <span
                                              className='nav-link-text ps-1'
                                              onClick={() => {
                                                handleClose();
                                                modalStore.openModal(
                                                  <CircleKApplicationForCard />,
                                                  'lg',
                                                  'Wniosek o nową kartę!',
                                                );
                                              }}
                                              style={{ cursor: 'pointer' } as React.CSSProperties}>
                                              Wnioskuj o nową kartę
                                            </span>
                                          </div>
                                        </div>
                                      </Nav.Item>
                                    </div>
                                  )}
                              </>
                            ))}
                          </div>
                        )}
                        wrapperClassName={'tw-w-full'}
                      />
                    </>
                  ) : (
                    <div>
                      {isRouteVisible(route) && (
                        <div
                          className={twMerge(
                            'tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-text-xl tw-text-white',
                          )}>
                          <NavLink onClick={handleClose} to={route.to}>
                            <div className='d-flex align-items-center'>
                              {route.icon && (
                                <FontAwesomeIcon className='tw-mr-[15px] tw-h-5 tw-w-5' icon={route.icon} />
                              )}
                              {route.imageUrl && (
                                <Image className={'tw-mr-[15px] tw-h-5 tw-w-5'} src={route.imageUrl} />
                              )}
                              {route.iconComponent && (
                                <div className={'tw-mr-[15px]'}>
                                  <route.iconComponent className={twMerge('tw-h-6 tw-w-6 tw-text-white')} />
                                </div>
                              )}
                              <span className={twMerge('tw-font-medium')}>{route.name}</span>
                            </div>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  )}
                </Nav.Item>
              </Fragment>
            ))
          )}
        </Nav>
        <Nav as='ul' className='flex-column' style={lowerMenu}>
          {lowerRoutes.map((route, index) => (
            <Fragment key={route.name}>
              <Nav.Item
                as='li'
                className={twMerge('tw-border-t tw-border-nau-green-dark  tw-text-white')}
                key={route.name}>
                {route.type == RouteTypeE.File ? (
                  <div
                    className={'tw-cursor-pointer tw-px-4 tw-py-2 '}
                    onClick={() => FileHelper.downloadFile(route.to, route.fileName ?? '')}>
                    <div className='d-flex align-items-center'>
                      <span className=''>{route.name}</span>
                    </div>
                  </div>
                ) : (
                  <NavLink className={'tw-flex tw-cursor-pointer tw-px-4 tw-py-2'} onClick={handleClose} to={route.to}>
                    <div className=''>
                      <span>{route.name}</span>
                    </div>
                  </NavLink>
                )}
              </Nav.Item>
            </Fragment>
          ))}
        </Nav>
      </div>
    </Offcanvas>
  );
});
