export const fontBold = {
  fontWeight: '600',
};

export const toLeft = {
  justifyContent: 'Left',
};

export const toRight = {
  justifyContent: 'right',
};

export const fontSize = {
  fontSize: '16px',
};

export const mySavings = {
  display: 'flex',
  justifyContent: 'end',
  fontSize: '16px',
  color: '#0BC268',
  marginTop: '10px',
};

export const transactionItem = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const containerFlex = {
  display: 'flex',
};

export const containerMarginBottom = {
  marginBottom: '15px',
};
