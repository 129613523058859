import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';

export const validationSchemaV2 = Yup.object().shape({
  regulations: Yup.boolean()
    .nullable()
    .oneOf([true], 'Potwierdzenie jest wymagane')
    .required('Potwierdzenie jest wymagane'),
  token: Yup.string()
    .nullable()
    .matches(RegexHelpers.tokenRegExp, 'Nieprawidłowe hasło')
    .length(6, 'Nieprawidłowa długość hasła')
    .required('Hasło jest wymagane'),
});
export const validationSchema = Yup.object().shape({
  token: Yup.string()
    .nullable()
    .matches(RegexHelpers.tokenRegExp, 'Nieprawidłowe hasło')
    .length(6, 'Nieprawidłowa długość hasła')
    .required('Hasło jest wymagane'),
});
