import React, { useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { useStore } from '../../../store/store';
import CallBooking from '../../../components/CallBooking/CallBooking';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { Image } from 'react-bootstrap';
import { twMerge } from 'tailwind-merge';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import NauMobileIcon from '../../../components/Icons/NauMobileIcon';
import { openLink } from '../../../utils/linkUtils';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { LeadCampaignType } from '../../../types/leads';

export const NauMobilePage = () => {
  const { userStore } = useStore();
  const [isCallBookView, setIsCallBookView] = useState<boolean>(false);

  return (
    <CustomerDashboard>
      <div className='tw-mx-auto md:tw-w-1/2'>
        <div
          className={
            'tw-h-[100px] tw-rounded-t-[10px] tw-bg-gradient-to-r tw-from-nau-green-dark tw-to-nau-green-light'
          }>
          <div
            className={twMerge(
              'tw-flex tw-h-full tw-items-center tw-justify-center tw-px-1 tw-text-center tw-text-white',
            )}>
            <PhoneIcon className={'tw-mr-6'} />
            <NauMobileIcon className={'tw-h-[70px] sm:tw-h-[90px]'} />
          </div>
        </div>

        <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6  tw-shadow-md'>
          {isCallBookView ? (
            <CallBooking leadCampaignType={LeadCampaignType.NauMobile} />
          ) : (
            <>
              <p className='tw-text-base'>
                <span className={'tw-font-bold tw-text-nau-sea-green'}>Naumobile</span> to sieć telefonii komórkowej
                stworzona dla pracowników oświaty oraz dla wszystkich osób, które lubią długo i tanio rozmawiać przez
                telefon.
              </p>

              <div
                className={
                  'tw-my-8 tw-rounded-xl tw-bg-gradient-to-r tw-from-nau-sea-green tw-to-nau-green-light tw-p-1'
                }>
                <div
                  className={
                    'tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-lg tw-bg-white tw-p-4 tw-text-nau-sea-green'
                  }>
                  <div className={'tw-font-semibold'}>Jesteś pracownikiem oświaty?</div>
                  <div
                    className={'tw-cursor-pointer tw-underline'}
                    onClick={() => openLink(PathRoute.NAU_MOBILE_SPECIAL_OFFER)}>
                    Dowiedz się więcej o rabacie {'>>'}
                  </div>
                </div>
              </div>

              <div className={'tw-mx-auto tw-my-8 tw-flex tw-max-w-[350px] tw-flex-col tw-items-center tw-gap-8'}>
                {[...Array(5)].map((x, i) => (
                  <Image className={'tw-bg-white'} key={i} src={`/assets/nauMobile/nau_mobile_tile_${i + 1}.png`} />
                ))}
              </div>
              <MyButton
                className={' tw-w-full'}
                onClick={() => setIsCallBookView(true)}
                type='button'
                variant={'greenDark'}>
                Zamów rozmowę
              </MyButton>
            </>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
};
