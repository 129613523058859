import * as React from 'react';
import { Form } from 'react-bootstrap';

interface CheckboxProps {
  checked: boolean;
  handleChange: (e: any) => void;
  id: string;
  text: string;
  type: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, handleChange, id, text, type }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Form.Check
        checked={checked && id === type}
        id={id}
        onChange={(id) => handleChange(id)}
        style={{ alignSelf: 'center' }}
        type='checkbox'
      />
      <label
        style={{
          marginLeft: '10px',
          textAlign: 'center',
          lineHeight: '1.3',
          fontWeight: '300',
        }}>
        {text}
      </label>
    </div>
  );
};

export default Checkbox;
