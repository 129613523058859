import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';
import { Button, Spinner } from 'react-bootstrap';
import CinemaVoucherHeader from '../CinemaVoucherHeader/CinemaVoucherHeader';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../store/store';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { CinemaVoucherOrderClientDataM } from '../../../types/cinemaVoucher';
import { Form, Formik } from 'formik';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import * as Yup from 'yup';

export default observer(() => {
  const { id }: any = useParams();
  const { cinemaVoucherStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [clientData, setClientData] = useState<CinemaVoucherOrderClientDataM>({
    name: '',
    surname: '',
    city: '',
    street: '',
    postCode: '',
  });

  useEffect(() => {
    cinemaVoucherStore
      .getUserCinemaVoucherPersonalData()
      .then((resp) => {
        const respCopy = {
          name: resp.name ?? '',
          surname: resp.surname ?? '',
          city: resp.city ?? '',
          street: resp.street ?? '',
          postCode: resp.postCode ?? '',
        };
        setClientData(respCopy);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <CustomerDashboard>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <div className='col-md-6 offset-md-3 tw-h-full tw-rounded-3xl tw-bg-white tw-text-black tw-shadow-lg'>
          <CinemaVoucherHeader />
          <div className='tw-px-6 tw-py-4'>
            <Formik
              enableReinitialize
              initialValues={clientData}
              onSubmit={(values, { setSubmitting }) => {
                cinemaVoucherStore
                  .saveCinemaVouchersOrderClientData(values)
                  .then(() => {
                    history.push(PathRoute.CINEMA_VOUCHER_ORDER_SUMMARY + '/' + id);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Pole jest wymagane'),
                surname: Yup.string().required('Pole jest wymagane'),
                city: Yup.string().required('Pole jest wymagane'),
                street: Yup.string().required('Pole jest wymagane'),
                postCode: Yup.string().required('Pole jest wymagane'),
              })}>
              {({ isSubmitting, setFieldValue, values }) => (
                <Form className='tw-p-4'>
                  <div>
                    <p className='tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-green-dark'>
                      Dane do faktury
                    </p>
                  </div>
                  <div className={'tw-flex tw-flex-col tw-gap-3'}>
                    <MyTextInput label='Imię' name='name' />
                    <MyTextInput label='Nazwisko' name='surname' />
                    <MyTextInput label='Ulica i nr' name='street' />
                    <MyTextInput label='Miasto' name='city' />
                    <MyTextInput label='Kod pocztowy' name='postCode' />
                  </div>

                  {isSubmitting ? (
                    <Button className='btn-full-width mt-4' disabled={isSubmitting} type='submit'>
                      <span className='m-1'>
                        <Spinner
                          animation='grow'
                          aria-hidden='true'
                          as='span'
                          role='status'
                          size='sm'
                          variant='light'
                        />
                      </span>
                      Zapisywanie…
                    </Button>
                  ) : (
                    <Button className='btn-full-width mt-4' type='submit'>
                      Zapisz
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </CustomerDashboard>
  );
});
