import React from 'react';
import { observer } from 'mobx-react-lite';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import UserPersonalDataLayout from '../../../components/UserPersonalData/UserPersonalDataLayout';
import BirthdayCodeCampaigns from './BirthdayCodeCampaigns';

export default observer(() => {
  return (
    <CustomerDashboard>
      <UserPersonalDataLayout
        wrapperClassName={'tw-mx-auto tw-max-w-2xl tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
        <BirthdayCodeCampaigns />
      </UserPersonalDataLayout>
    </CustomerDashboard>
  );
});
