export interface BirthdayCodeCampaignM {
  campaignCode: string;
  name: string;
  description: string;
  amount: number;
  available: boolean;
  taken: boolean;
  hoverText?: string;
  policyId?: number;
  plate?: string;
  verificationStatus: BirthdayCodeVerificationStatus;
}

export interface BirthdayCodeCampaignOrderM {
  campaignCode: string;
  amount: number;
  policyId?: number;
  plate?: string;
}

export enum BirthdayCodeVerificationStatus {
  NotSent = 0,
  InProgress = 1,
  Verified = 2,
}
