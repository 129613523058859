import React from 'react';
import { openLink } from '../../../utils/linkUtils';
import { twMerge } from '../../../index';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';

interface SubscriptionPlan {
  goFit: string;
  fitAndMore: string;
  aqua: string;
  junior: string;
}

interface TableData {
  title: string;
  forYou: SubscriptionPlan;
  forFriend: SubscriptionPlan;
  forStudent: SubscriptionPlan;
  forChild: SubscriptionPlan;
}

const tables: TableData[] = [
  {
    title: 'Miesięczna cena abonamentu Medicover (2x w tygodniu):',
    forYou: { goFit: '72,50 zł', fitAndMore: '74,50 zł', aqua: '', junior: '' },
    forFriend: { goFit: '119,00 zł', fitAndMore: '129,00 zł', aqua: '', junior: '' },
    forStudent: { goFit: '95,00 zł', fitAndMore: '105,00 zł', aqua: '', junior: '' },
    forChild: { goFit: '', fitAndMore: '', aqua: '45,00 zł', junior: '75,00 zł' },
  },
  {
    title: 'Miesięczna cena abonamentu Medicover (Raz dziennie):',
    forYou: { goFit: '121,00 zł', fitAndMore: '125,00 zł', aqua: '', junior: '' },
    forFriend: { goFit: '159,00 zł', fitAndMore: '169,00 zł', aqua: '', junior: '' },
    forStudent: { goFit: '119,00 zł', fitAndMore: '129,00 zł', aqua: '', junior: '' },
    forChild: { goFit: '', fitAndMore: '', aqua: '49,00 zł', junior: '89,00 zł' },
  },
];

export const SportCardMedicoverSubscriptionInfo: React.FC = () => {
  const formatPrice = (price: string) => {
    const [whole, fraction] = price.split(',');
    if (price === '') {
      return <></>;
    }
    return (
      <>
        <strong>{whole}</strong>
        {fraction && <sup>{fraction.replaceAll('zł', '')}</sup>}zł
      </>
    );
  };

  return (
    <div className='tw-bg-white'>
      <ul className='tw-list-inside tw-list-disc tw-pb-10 tw-text-nau-sea-green'>
        <li>Ponad 5 500 obiektów sportowych w całej Polsce.</li>
        <li>Prawie 40 różnorodnych form aktywności.</li>
        <li>
          Dostępne obiekty rekreacyjno-sportowe można wyszukiwać na stronie:{' '}
          <span
            className='tw-cursor-pointer tw-text-blue-500'
            onClick={() => openLink('https://medicoversport.pl/wyszukiwarka')}>
            medicoversport.pl/wyszukiwarka
          </span>
        </li>
      </ul>

      {tables.map((table, tableIndex) => (
        <div className='tw-mb-8' key={tableIndex}>
          <div className='tw-rounded-xl tw-bg-white tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'>
            <SubpageHeader headerClassName={twMerge('tw-text-xl ')} title={table.title} />
            <div className='tw-overflow-x-auto'>
              <table className='tw-w-full tw-table-auto tw-text-nau-sea-green'>
                <thead>
                  <tr className='tw-divide-x tw-divide-gray-300'>
                    <th className='tw-px-4 tw-py-2 tw-text-left'></th>
                    <th className='tw-bg-nau-sea-green/10 tw-px-4 tw-py-2 tw-text-left'>Go FIT</th>
                    <th className='tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-left'>Fit & More</th>
                    <th className='tw-bg-gray-50 tw-px-4 tw-py-2 tw-text-left'>Aqua</th>
                    <th className='tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-left'>Junior</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='tw-divide-x tw-divide-gray-300 tw-border-t'>
                    <td className='n tw-px-4 tw-py-2'>Dla Ciebie</td>
                    <td className='tw-whitespace-nowrap tw-bg-nau-sea-green/10 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forYou.goFit)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forYou.fitAndMore)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-50 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forYou.aqua) || '-'}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forYou.junior) || '-'}
                    </td>
                  </tr>
                  <tr className='tw-divide-x tw-divide-gray-300 tw-border-t'>
                    <td className='tw-px-4 tw-py-2 tw-text-nau-sea-green'>Dla Osoby Towarzyszącej</td>
                    <td className='tw-whitespace-nowrap tw-bg-nau-sea-green/10 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forFriend.goFit)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forFriend.fitAndMore)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-50 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forFriend.aqua) || '-'}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forFriend.junior) || '-'}
                    </td>
                  </tr>
                  <tr className='tw-divide-x tw-divide-gray-300 tw-border-t'>
                    <td className='tw-px-4 tw-py-2 tw-text-nau-sea-green'>Dla Studenta</td>
                    <td className='tw-whitespace-nowrap tw-bg-nau-sea-green/10 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forStudent.goFit)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forStudent.fitAndMore)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-50 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forStudent.aqua) || '-'}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forStudent.junior) || '-'}
                    </td>
                  </tr>
                  <tr className='tw-divide-x tw-divide-gray-300 tw-border-t'>
                    <td className='tw-px-4 tw-py-2 tw-text-nau-sea-green'>Dla Dziecka</td>
                    <td className='tw-whitespace-nowrap tw-bg-nau-sea-green/10 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forChild.goFit)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forChild.fitAndMore)}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-50 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forChild.aqua) || '-'}
                    </td>
                    <td className='tw-whitespace-nowrap tw-bg-gray-100 tw-text-center tw-text-nau-sea-green'>
                      {formatPrice(table.forChild.junior) || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
