import * as React from 'react';
import * as styles from './styles';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { WalletDataCashmir } from '../../../types/walletNau';

export default observer((props: { wallet: WalletDataCashmir }) => {
  return (
    <div style={styles.mainContainer as React.CSSProperties}>
      <div>
        <Image src='assets/inProgress.svg' />
      </div>
      <div style={styles.containerDate as React.CSSProperties}>
        <p style={styles.textDate as React.CSSProperties}>{props.wallet.walletLoanDate}</p>
        <p style={styles.schoolText as React.CSSProperties}>{props.wallet.contracNbr}</p>
      </div>
      <div>
        <p style={styles.inProgress as React.CSSProperties}>{props.wallet.walletLoanStatusName}</p>
        <p style={styles.amountCash as React.CSSProperties}>+{props.wallet.payOutAmount} zł</p>
        <p style={styles.amountPayment as React.CSSProperties}>
          Całkowita opłata za wypłatę: {props.wallet.initialCost} zł
        </p>
      </div>
    </div>
  );
});
