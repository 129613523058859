export const heightSecondPhoto = {
  height: '100px',
  marginBottom: '1rem',
  marginTop: '1rem',
  width: '100%',
};

export const positionRelative = {
  position: 'relative',
};

export const inputHidden = {
  position: 'absolute',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100px',
  height: '115px',
  opacity: 0,
};

export const uploadFile = {
  margin: '0 auto',
  width: '50%',
  textAlign: 'center',
  lineHeight: '1.3',
  color: '#00635C',
  marginBottom: '2rem',
};
