export const containerCenter = {
  marginTop: '20px',
  marginLeft: '10px',
  fontSize: '14px',
  display: 'flex',
  marginBottom: 0,
  justifyContent: 'center',
};

export const displayFlex = { display: 'flex' };

export const marginBottomLeft = { marginBottom: 0, marginLeft: '15px' };

export const heightCursor = {
  height: '23px',
  marginLeft: '10px',
  marginTop: '-2px',
  cursor: 'pointer',
};

export const flexWeightCenter = {
  display: 'flex',
  fontWeight: '100',
  justifyContent: 'center',
};

export const pinContainer = {
  display: 'flex',
  justifyContent: 'center',
  background: '#0BC068',
  padding: '10px',
};

export const textSMS = {
  marginTop: '10px',
  display: 'flex',
  fontWeight: '100',
  justifyContent: 'center',
  color: '#0BC268',
  cursor: 'pointer',
};

export const autoContainerHeight = {
  height: 'auto',
  width: '90%',
  borderRadius: '16px',
  border: '1px solid #0bc268',
  margin: '0 auto',
  padding: '15px',
};

export const fontColor = { fontWeight: '600', color: '#00635C' };

export const colorBottomWeight = {
  marginBottom: 7,
  fontWeight: '300',
  color: '#626D80',
};

export const accountMember = {
  fontSize: '14px',
  fontWeight: '700',
  color: '#626D80',
  marginLeft: '3px',
};

export const flexWrap = { display: 'flex', flexWrap: 'wrap' };

export const accountNumber = {
  marginBottom: 7,
  fontWeight: '300',
  color: '#626D80',
  fontSize: '14px',
};

export const containerTime = {
  marginBottom: 5,
  fontWeight: '300',
  color: '#626D80',
  display: 'flex',
};

export const autoInfo = {
  height: 'auto',
  width: '90%',
  borderRadius: '10px',
  background: '#f5f5f5',
  margin: '0 auto',
  marginTop: '10px',
  padding: '15px',
  display: 'flex',
};

export const flexGreen = { alignSelf: 'flex-start', marginRight: '10px' };

export const textInfo = {
  fontSize: '12px',
  color: '#626D80',
  fontWeight: '500',
  marginBottom: '0',
};
export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const flexStart = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '10px 20px',
};

export const widthButton = {
  margin: '0 20px',
};
export const flexCenterContent = {
  display: 'flex',
  alignItems: 'center',
};
export const hrefLink = {
  color: 'green',
  textDecoration: 'underline',
  cursor: 'pointer',
};

export const tabsBox = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  paddingTop: '20px',
  paddingBottom: '20px',
  background: 'linear-gradient(to right,#00635c, #0bc268)',
} as React.CSSProperties;

export const container = {
  borderRadius: '10px',
  background: '#fff',
} as React.CSSProperties;

export const myMarginTop = { marginTop: '10px' };

export const accordionBody = {
  background: '#FFF',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
} as React.CSSProperties;

export const textStyle = {
  fontSize: '16px',
  color: '#00635C',
  marginBottom: '10px',
  lineHeight: '1.5',
} as React.CSSProperties;

export const headingTextStyle = {
  fontSize: '20px',
  color: '#00635C',
  fontWeight: 600,
  marginBottom: '10px',
  lineHeight: '1.2',
  paddingTop: '20px',
  paddingBottom: '20px',
} as React.CSSProperties;

export const font = {
  fontSize: '16px',
} as React.CSSProperties;

export const displayBetween = {
  display: 'flex',
  justifyContent: 'space-between',
} as React.CSSProperties;

export const fontHigh = {
  fontWeight: '700',
};

export const heightSecondPhoto = {
  height: '100px',
  marginBottom: '1rem',
  marginTop: '1rem',
  width: '100%',
};

export const positionRelative = {
  position: 'relative',
};

export const inputHidden = {
  position: 'absolute',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100px',
  height: '115px',
  opacity: 0,
};

export const uploadFile = {
  margin: '0 auto',
  width: '50%',
  textAlign: 'center',
  lineHeight: '1.3',
  color: '#00635C',
  marginBottom: '2rem',
};
