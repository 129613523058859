import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import ValidationHelper from '../../helpers/ValidationHelper';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import { CustomerRegisterFormValues } from '../../types/user';
import { useStore } from '../../store/store';
import RegexHelpers from '../../helpers/RegexHelpers';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import MyCheckbox from '../../components/FormControls/MyCheckbox/MyCheckbox';
import FormCheck from 'react-bootstrap/FormCheck';
import { labelText } from '../../components/ApprovalsForm/styles';
import { row } from './styles';

export default observer(() => {
  const { userStore } = useStore();
  const [userInstitution, setUserInstitution] = useState<number>();
  const params: any = useParams();
  const [user, setUser] = useState<CustomerRegisterFormValues>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const userInst = userStore.getUserInstitution();
    if (userInst != null) {
      setUserInstitution(userInst.institutionId);
      userStore.customerFormData(parseInt(params.id)).then((resp) => {
        setLoading(false);
        return setUser(resp);
      });
    }
  }, []);
  return (
    <AdminDashboard>
      {loading && <LoadingComponent />}
      <Formik
        enableReinitialize
        initialValues={{
          userInstitutionId: parseInt(params.id),
          firstName: user ? user.firstName : '',
          lastName: user ? user.lastName : '',
          pesel: user ? user.pesel : '',
          email: user ? user.email : '',
          phone: user ? user.phone : '',
          institutionId: userInstitution ?? 0,
          isActive: user ? user.isActive : false,
          isFired: user ? user.isFired : false,
          error: null,
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          values.institutionId = userInstitution ?? 0;
          userStore.editCustomer(values).catch((error: any) => {
            setErrors({ error });
            setSubmitting(false);
          });
        }} // == {error} shourtcut
        validationSchema={Yup.object({
          firstName: Yup.string().required('Pole jest wymagane'),
          lastName: Yup.string().required('Pole jest wymagane'),
          pesel: Yup.string()
            .required('Pole jest wymagane')
            .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
            .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
          email: Yup.string().required('Pole jest wymagane').email('Adres email jest niepoprawny'),
          phone: Yup.string()
            .required('Pole jest wymagane')
            .matches(RegexHelpers.phoneRegExp, 'Numer telefonu jest niepoprawny')
            .nullable(),
          isFired: Yup.bool(),
        })}>
        {({ handleSubmit, isSubmitting, errors, isValid, dirty, values }) => (
          <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
            <h2 style={{ color: 'teal', textAlign: 'center' }}>Edycja użytkownika</h2>
            <MyTextInput name='firstName' placeholder='Imię' value={values ? values.firstName : ''} />
            <MyTextInput name='lastName' placeholder='Nazwisko' value={values ? values.lastName : ''} />
            <MyTextInput
              disabled={user?.isActive}
              name='pesel'
              placeholder='PESEL'
              value={values ? values.pesel : ''}
            />
            <MyTextInput name='email' placeholder='E-mail' value={values ? values.email : ''} />
            <MyTextInput name='phone' placeholder='Numer telefonu' value={values ? values.phone : ''} />
            <div className='d-flex align-items-start' style={row}>
              <MyCheckbox
                checked={values ? values.isFired : false}
                name='isFired'
                value={values ? values.isFired : false}
              />
              <FormCheck.Label htmlFor={'isFired'} style={labelText}>
                <div className='approval-content'>Czy zwolniony</div>
              </FormCheck.Label>
            </div>

            <ErrorMessage
              name='error'
              render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
            />
            {isSubmitting ? (
              <Button className='btn-full-width mt-3' disabled={!isValid || !dirty || isSubmitting} type='submit'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Zapisywanie
              </Button>
            ) : (
              <Button className='btn-full-width mt-3' disabled={!isValid || !dirty || isSubmitting} type='submit'>
                Zapisz
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </AdminDashboard>
  );
});

// <Form className="ui form error" clasa error jest wymagana
