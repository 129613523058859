import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function CopyClipboardIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge('tw-h-5 tw-w-5', props.className)}
      height='18.264'
      viewBox='0 0 14.863 18.264'
      width='14.863'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 417' fill='none' height='18.264' id='Rectangle_417' width='14.863' />
        </clipPath>
      </defs>
      <g data-name='Group 959' id='Group_959' opacity='0.5'>
        <g clipPath='url(#clip-path)' data-name='Group 958' id='Group_958'>
          <path
            d='M10.459,57.35H2.479A2.481,2.481,0,0,1,0,54.872V43.06a2.481,2.481,0,0,1,2.479-2.479h7.98a2.481,2.481,0,0,1,2.479,2.479V54.872a2.481,2.481,0,0,1-2.479,2.479M2.479,41.5A1.562,1.562,0,0,0,.918,43.06V54.872a1.562,1.562,0,0,0,1.561,1.561h7.98a1.562,1.562,0,0,0,1.561-1.561V43.06A1.562,1.562,0,0,0,10.459,41.5Z'
            data-name='Path 3943'
            fill='currentColor'
            id='Path_3943'
            transform='translate(0 -39.087)'
          />
          <path
            d='M99.468,12.466h-8.9a1.1,1.1,0,0,0-1.1,1.1V26.013a1.1,1.1,0,0,0,1.1,1.1h8.9a1.1,1.1,0,0,0,1.1-1.1V13.571a1.1,1.1,0,0,0-1.1-1.1'
            data-name='Path 3944'
            fill='#fff'
            id='Path_3944'
            transform='translate(-86.169 -12.007)'
          />
          <path
            d='M87.188,15.57H78.832A1.838,1.838,0,0,1,77,13.734V1.836A1.838,1.838,0,0,1,78.832,0h8.356a1.838,1.838,0,0,1,1.836,1.836v11.9a1.838,1.838,0,0,1-1.836,1.836M78.832.918a.919.919,0,0,0-.918.918v11.9a.919.919,0,0,0,.918.918h8.356a.919.919,0,0,0,.918-.918V1.836a.919.919,0,0,0-.918-.918Z'
            data-name='Path 3945'
            fill='currentColor'
            id='Path_3945'
            transform='translate(-74.161)'
          />
          <path
            d='M148.715,295.939h-6.328a.459.459,0,1,1,0-.918h6.328a.459.459,0,0,1,0,.918'
            data-name='Path 3946'
            fill='currentColor'
            id='Path_3946'
            transform='translate(-136.702 -284.158)'
          />
          <path
            d='M148.715,199.886h-6.328a.459.459,0,1,1,0-.918h6.328a.459.459,0,0,1,0,.918'
            data-name='Path 3947'
            fill='currentColor'
            id='Path_3947'
            transform='translate(-136.702 -191.642)'
          />
          <path
            d='M148.715,103.833h-6.328a.459.459,0,1,1,0-.918h6.328a.459.459,0,0,1,0,.918'
            data-name='Path 3948'
            fill='currentColor'
            id='Path_3948'
            transform='translate(-136.702 -99.126)'
          />
        </g>
      </g>
    </svg>
  );
}
