export enum SportCardSubscriptionPlan {
  OptimalEmployee = 1,
  ComfortEmployee = 2,
  OptimalCompanion = 3,
  ComfortCompanion = 4,
  OptimalChild = 5,
  ComfortChild = 6,
  OptimalChildPool = 7,
  ComfortChildPool = 8,
}

export enum SportCardStatus {
  Active = 1,
  Resignation = 2,
}
