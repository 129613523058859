import React, { ChangeEvent, useEffect, useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { Button, Image, Spinner } from 'react-bootstrap';
import * as style from './styles';
import { history } from '../../../index';
import { TypeVerify } from './types';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useStore } from '../../../store/store';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { toast } from 'react-toastify';
import { CameraSource } from '@capacitor/camera';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import DiscountCodeHeader from '../../../components/DiscountCode/DiscountCodeHeader/DiscountCodeHeader';
import { DiscountCodeContractStatusE } from '../../../enums/discountCodeContractStatus.enums';
import { Capacitor } from '@capacitor/core';
import { openCamera } from '../../../utils/cameraUtils';

const CustomerFileContractFormDiscountCode = () => {
  const { discountCodeStore, userStore } = useStore();
  const [checked, setChecked] = useState(true);
  const [file, setFile] = useState<string | Blob>('');
  const [fileName, setFileName] = useState<string | Blob>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [type, setType] = useState('');

  useEffect(() => {
    discountCodeStore.getUserDiscountCodeContractStatus().then((contractStatus) => {
      if (contractStatus !== DiscountCodeContractStatusE.New) {
        history.push(PathRoute.DISCOUNT_CODE);
        return;
      }
      setLoading(false);
    });
  }, []);

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      setFileName(file.name);
    }
  };

  const setFileFromCamera = (blob: Blob) => {
    setFile(blob);
    setFileName(`photo_${Date.now()}.jpg`);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    await formData.append('formFile', file);
    await formData.append('fileName', fileName);
    setIsSubmitting(true);
    await discountCodeStore
      .saveDiscountCodeContractFile(formData)
      .then(() => {
        userStore.getCurrentUser().then(() => {
          history.push(PathRoute.SIGN_CONTRACT_DISCOUNT_CODE);
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        toast.error(error.message);
      });
  };

  const handleChange = (e: any) => {
    setType(e.target.id);
    if (e.target.id !== TypeVerify.BANK) {
      setChecked(true);
      setFile('');
    } else if (e.target.id !== TypeVerify.STATEMENT) {
      setChecked(true);
      setFile('');
    } else {
      setChecked(!checked);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3' style={style.container}>
            <DiscountCodeHeader />
            <div style={style.mainContainer as React.CSSProperties}>
              <div style={style.flexContainer as React.CSSProperties}>
                <Image
                  onClick={() => history.goBack()}
                  src='/assets/arrow-Left.svg'
                  style={style.transformIcon as React.CSSProperties}
                />
                <div style={style.centerFlexContainer as React.CSSProperties}>
                  <p style={style.verifyText as React.CSSProperties}>Weryfikacja zatrudnienia</p>
                </div>
              </div>
              <Image src='/assets/personDocument.svg' style={style.photoStyles as React.CSSProperties} />
              <div className={'discountCode-bank'} style={style.flexStart as React.CSSProperties}>
                <Checkbox checked={checked} handleChange={handleChange} id={TypeVerify.STATEMENT} text='' type={type} />
                <label>Weryfikacja za pomocą dokumentu Zaświadczenie o dochodach.</label>
              </div>
              {type === TypeVerify.STATEMENT ? (
                <div>
                  {Capacitor.isNativePlatform() ? (
                    <div className='d-flex'>
                      <div style={style.cordovaFileBox as React.CSSProperties}>
                        <Image
                          onClick={() => openCamera(CameraSource.Camera, setFileFromCamera)}
                          src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                          style={style.heightSecondPhoto as React.CSSProperties}
                        />
                        <div style={style.uploadFile as React.CSSProperties}>Wykonaj zdjęcie</div>
                      </div>
                      <div style={style.cordovaFileBox as React.CSSProperties}>
                        <input
                          capture
                          onChange={saveFile}
                          style={style.inputHidden as React.CSSProperties}
                          type='file'
                        />
                        <Image
                          src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
                          style={style.heightSecondPhoto as React.CSSProperties}
                        />
                        <div style={style.uploadFile as React.CSSProperties}>Załącz zdjęcie</div>
                      </div>
                    </div>
                  ) : (
                    <div style={style.positionRelative as React.CSSProperties}>
                      <input capture onChange={saveFile} style={style.inputHidden as React.CSSProperties} type='file' />
                      <Image
                        src={!file ? '/assets/greenPhoto.svg' : '/assets/photo.svg'}
                        style={style.heightSecondPhoto as React.CSSProperties}
                      />
                      <div style={style.uploadFile as React.CSSProperties}>Wykonaj lub załącz zdjęcie Oświadczenia</div>
                      <div style={style.greyDot} />
                    </div>
                  )}
                </div>
              ) : null}
              <div style={style.flexCenter as React.CSSProperties}> lub</div>
              <div className={'discountCode-bank'} style={style.flexStart as React.CSSProperties}>
                <Checkbox checked={checked} handleChange={handleChange} id={TypeVerify.BANK} text='' type={type} />
                <label>Weryfikacja za pomocą Wyciągu z konta bankowego z ostatnich 3 miesięcy</label>
              </div>
              {type === TypeVerify.BANK ? (
                <>
                  <div style={style.positionRelative as React.CSSProperties}>
                    <input
                      accept='application/pdf'
                      capture
                      onChange={saveFile}
                      style={style.inputHidden as React.CSSProperties}
                      type='file'
                    />
                    <Image
                      src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
                      style={style.heightSecondPhoto as React.CSSProperties}
                    />
                  </div>
                  <div style={style.uploadFile as React.CSSProperties}>Załącz plik PDF z Wyciągiem z konta</div>
                </>
              ) : null}
              {isSubmitting ? (
                <Button style={{ marginTop: '2rem' }} type='submit' variant='primary'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Trwa zapisywanie pliku...
                </Button>
              ) : (
                <Button
                  disabled={!file}
                  onClick={uploadFile}
                  style={{ marginTop: '2rem' }}
                  type='submit'
                  variant='primary'>
                  Chcę złożyć wniosek
                </Button>
              )}
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
};

export default CustomerFileContractFormDiscountCode;
