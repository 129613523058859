export const containerNotification = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
};

export const ReadNotification = {
  backgroundColor: '#0bc268 !important',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  minWidth: '80%',
} as React.CSSProperties;

export const container = {
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  background: '#fff',
  overflow: 'hidden',
} as React.CSSProperties;

export const title = { fontSize: '14px', fontWeight: '300', paddingTop: '5px' } as React.CSSProperties;
export const unreadTitle = { fontSize: '16px', fontWeight: '600', paddingTop: '5px' } as React.CSSProperties;
