import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import WalletNauHeader from '../../../components/WalletNau/WalletNauHeader/DiscountCodeHeader';
import WalletNauInstructionContent from '../../../components/WalletNau/WalletNauInstruction/WalletNauInstructionContent';
import styles from './styles.module.scss';
import classNames from 'classnames';
import WalletNauInstructionFooter from '../../../components/WalletNau/WalletNauInstruction/WalletNauInstructionFooter/WalletNauInstructionFooter';
import { useStore } from '../../../store/store';
import { UserMarketingData } from '../../../types/user';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import WalletNauSpecialOfferCard from '../../../components/WalletNau/WalletNauSpecialOfferCard/WalletNauSpecialOfferCard';
import WalletNauInstructionBankFooter from '../../../components/WalletNau/WalletNauInstruction/WalletNauInstructionBankFooter/WalletNauInstructionBankFooter';
import { UserPromotionE } from '../../../enums/userPromotion.enums';
import { TradeUnionTypesE } from '../../../enums/tradeUnionTypes.enums';

const goToContract = () => history.push(PathRoute.WALLET_NAU_APPROVALS);

const StartWalletNau = () => {
  const { userStore } = useStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [specialOfferCardUsed, setSpecialOfferCardUsed] = useState<boolean>(false);
  const [userMarketingData, setUserMarketingData] = useState<UserMarketingData>({
    hasBr: false,
    isNauWorker: false,
    isZnp: false,
    isTeacherPromo: false,
  });

  useEffect(() => {
    userStore
      .getUserMarketingData()
      .then((resp) => {
        setUserMarketingData(resp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const isSpecialOfferCardVisible = () => {
    return (
      !specialOfferCardUsed &&
      (userMarketingData.isZnp ||
        userMarketingData.hasBr ||
        userMarketingData.tradeUnionId ||
        userMarketingData.isNauWorker ||
        userMarketingData.isTeacherPromo ||
        userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolStuff))
    );
  };

  const handleCloseSpecialOfferCard = async () => {
    setSpecialOfferCardUsed(true);
  };

  return (
    <div>
      {loading && <LoadingComponent content='Ładowanie...' />}
      <div className={classNames('col-md-6 offset-md-3', styles.container)}>
        <WalletNauHeader />
        <div className={styles.bodyContainer}>
          {isSpecialOfferCardVisible() ? (
            <WalletNauSpecialOfferCard
              handleClickNext={handleCloseSpecialOfferCard}
              userMarketingData={userMarketingData}
            />
          ) : (
            <>
              <WalletNauInstructionContent userMarketingData={userMarketingData} />
              <div className={'px-lg-4 py-4'}>
                <Button className='btn-full-width' onClick={() => goToContract()}>
                  Złóż wniosek
                </Button>
              </div>
              <WalletNauInstructionFooter
                isBrTermVisible={userMarketingData.hasBr}
                isNauWorkersTermVisible={userMarketingData.isNauWorker}
                isRepresentativeVisible={false}
                isTeacherPromoTermVisible={userMarketingData.isTeacherPromo}
                isTradeUnionVisible={[
                  TradeUnionTypesE.ElectroMachinist,
                  TradeUnionTypesE.JusticeSystem,
                  TradeUnionTypesE.SolidarityPodbeskidzie,
                  TradeUnionTypesE.Miners,
                  TradeUnionTypesE.Opzz,
                ].includes(userMarketingData.tradeUnionId ?? 0)}
                isZnpTermVisible={
                  userMarketingData.isZnp || userMarketingData.tradeUnionId == TradeUnionTypesE.ZnpRybnik
                }
              />
              <WalletNauInstructionBankFooter prefix='**' />
            </>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default StartWalletNau;
