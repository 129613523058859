import React, { ReactNode } from 'react';

import clsx from 'clsx';
import MyDisclosure from './MyDisclosure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

interface IMyLightGrayArrowsDisclosureProps {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
}

const MyLightGrayArrowsDisclosure = (props: IMyLightGrayArrowsDisclosureProps) => {
  return (
    <MyDisclosure
      defaultOpen={props.defaultOpen}
      header={(open: boolean) => (
        <div
          className={clsx(
            'tw-relative tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-nau-green-light  tw-px-2 tw-py-1',
          )}>
          <div className='tw-text-md tw-font-semibold tw-leading-snug tw-text-white'>{props.title}</div>
          <FontAwesomeIcon
            className={twMerge('tw-w-[25px] tw-transition-all', open ? 'tw-rotate-0' : 'tw-rotate-180')}
            color='white'
            icon={faCaretUp}
            size='2x'
          />
        </div>
      )}>
      {props.children}
    </MyDisclosure>
  );
};

export default MyLightGrayArrowsDisclosure;
