import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { CountryEnum } from '../../../enums/country.enum';
import { FuelCardCompany } from '../../../enums/fuelCardCompany.enums';

export const initialValues = {
  name: '',
  surname: '',
  bankAccountNumber: '',
  bankAccountNumberForReturns: '',
  pesel: '',
  postCode: '',
  place: '',
  street: '',
  houseNumber: '',
  flatNumber: '',
  country: CountryEnum.Polska,
  phone: '',
  email: '',
  contractId: 0,
  cards: [
    {
      limit: 0,
      licensePlateNumber: '',
      companyId: FuelCardCompany.BP,
      confirmed: false,
    },
  ],
  versionNumber: 3,
  carInsuranceEndDate: '',
  maxLimit: 900,
  sopInstitutionId: undefined,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pole jest wymagane'),
  surname: Yup.string().required('Pole jest wymagane'),
  postCode: Yup.string().required('Pole jest wymagane').matches(RegexHelpers.postCodeRegExp, 'Nieprawidłowy kod'),
  place: Yup.string().required('Pole jest wymagane'),
  street: Yup.string().required('Pole jest wymagane'),
  houseNumber: Yup.string().required('Pole jest wymagane'),
  bankAccountNumberForReturns: Yup.string().required('Pole jest wymagane'),
  phone: Yup.string().matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu').required('Pole jest wymagane'),
  pesel: Yup.string()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
  cards: Yup.array(
    Yup.object({
      limit: Yup.number().integer('Należy wskazać limit').min(1, 'Należy wskazać limit').required('Pole jest wymagane'),
      licensePlateNumber: Yup.string()
        .required('Pole jest wymagane')
        .test('len', 'Numer rejestracyjny może mieć maksymalnie 10 znaków.', (val) => (val ? val?.length <= 10 : true)),
      companyId: Yup.number()
        .integer('Należy wskazać dostawcę')
        .oneOf(
          [FuelCardCompany.CircleK, FuelCardCompany.Shell, FuelCardCompany.BP],
          'Należy wybrać dostępnego dostawcę karty',
        )
        .required('Pole jest wymagane'),
    }),
  ),
  carInsuranceEndDate: Yup.date()
    .nullable()
    .required('Pole jest wymagane')
    .min(new Date(), `Minimalna data to: ${new Date().toLocaleDateString()}`),
});
