import * as React from 'react';
import { Image, Modal } from 'react-bootstrap';
import { useStore } from '../../store/store';
import NewBenefitTile from '../../containers/NewBenefits/NewBenefitTile';
import CinemaVoucherTileLoginPageModal from '../CinemaVoucher/CinemaVoucherLoginModal/CinemaVoucherTileLoginPageModal';
import CinemaVoucherHeader from '../CinemaVoucher/CinemaVoucherHeader/CinemaVoucherHeader';

export const LoginBenefitsSection = () => {
  const { modalStore } = useStore();

  return (
    <>
      <div className='tw-mb-4 tw-text-center tw-font-semibold tw-text-nau-green-dark '>Czekają na Ciebie benefity:</div>
      <div className='gap-2 tw-flex tw-overflow-auto sm:tw-grid sm:tw-grid-cols-3'>
        <NewBenefitTile
          iconSrc={'/assets/fuelWhite.svg'}
          text={'Karty paliwowe NAU Profit'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconSrc={'/assets/bony.svg'}
          text={'E-bony'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconSrc={'/assets/car2.svg'}
          text={'Ubezpieczenie komunikacyjne'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconHeight={30}
          iconSrc={'/assets/wallet.svg'}
          text={'Portfel NAU'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconHeight={30}
          iconSrc={'/assets/13.svg'}
          text={'Pożyczka NAU'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconHeight={30}
          iconSrc={'/assets/birthdayCodes.svg'}
          text={'Odbierz 30 zł'}
          theme={'orange'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          customBody={() => (
            <div className={'tw-flex tw-max-h-full tw-items-center tw-justify-center '}>
              <Image className={'tw-h-[120%] tw-w-auto'} src={'/assets/nauMobileLogo.svg'} />
            </div>
          )}
          iconSrc={'/assets/nauMobileLogo.svg'}
          text={'Nau Mobile'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconHeight={30}
          iconSrc={'/assets/cashbackIcon.svg'}
          text={'Zwrot składki'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
        <NewBenefitTile
          iconHeight={30}
          iconSrc={'/assets/cinemaVoucher.svg'}
          onClick={() =>
            modalStore.openModal(<CinemaVoucherTileLoginPageModal />, 'lg', () => (
              <div className={'tw-relative tw-overflow-hidden tw-rounded-t-[36px]'}>
                <CinemaVoucherHeader className={'tw-text-2xl'} />
                <div className={'tw-absolute tw-inset-x-3 tw-inset-y-0'}>
                  <Modal.Header className={'tw-h-full tw-border-none tw-p-0'} closeButton></Modal.Header>
                </div>
              </div>
            ))
          }
          text={'Kupony do kina'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none cursor-pointer'}
        />
        <NewBenefitTile
          iconHeight={35}
          iconSrc={'/assets/sportMan.svg'}
          text={'Karty sportowe'}
          theme={'green'}
          wrapperClassName={'p-0 sm:tw-w-full tw-w-5/12 tw-flex-none'}
        />
      </div>
    </>
  );
};
