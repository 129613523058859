import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { DiscountCodeM } from '../../../types/discountCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { Image } from 'react-bootstrap';
import * as styles from './styles';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [codes, setCodes] = useState<DiscountCodeM[]>([]);

  useEffect(() => {
    getCodes()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
  const getCodes = async () => {
    const codes = await discountCodeStore.getInstitutionalDiscountCodesByUser();
    setCodes(codes);
  };
  return (
    <CustomerDashboard>
      <div>
        <div className='col-md-6 offset-md-3'>
          <div style={styles.tabsBox}>
            <div>
              <h1 style={{ color: '#fff', textAlign: 'center' } as React.CSSProperties}>
                <Image
                  src='/assets/bony.svg'
                  style={{ marginLeft: '5px', marginRight: '15px', height: '25px' } as React.CSSProperties}
                />
                E-bony od pracodawcy
              </h1>
            </div>
            <div
              style={
                {
                  paddingTop: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                } as React.CSSProperties
              }>
              <span style={styles.tabLink}></span>
            </div>
          </div>
          <div
            id='DiscountCodes'
            style={
              {
                background: '#fff',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                marginTop: '0',
                padding: '10px',
              } as React.CSSProperties
            }>
            {loading ? (
              <LoadingComponentPartial />
            ) : (
              <div>
                {codes.length > 0 &&
                  codes.map((code) => (
                    <div
                      key={code.id}
                      style={
                        {
                          border: '2px solid #00635C',
                          padding: '20px',
                          borderRadius: '10px',
                          color: '#fff',
                          margin: '10px 0',
                        } as React.CSSProperties
                      }>
                      {code.institutionName && (
                        <div
                          style={
                            {
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              width: '100%',
                              color: '#00635C',
                            } as React.CSSProperties
                          }>
                          <div style={{ color: '#00635C', fontSize: '12px' } as React.CSSProperties}>
                            {code.institutionName}
                          </div>
                        </div>
                      )}
                      <div
                        style={
                          {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            color: '#00635C',
                          } as React.CSSProperties
                        }>
                        <div style={{ color: '#00635C', display: 'flex', alignItems: 'center' } as React.CSSProperties}>
                          <FontAwesomeIcon color='#00635C' icon={faMoneyBill} size='lg' />
                          {code.pin != '' && (
                            <p style={{ marginLeft: '10px', marginBottom: 0 } as React.CSSProperties}>
                              PIN: {code.pin}
                            </p>
                          )}
                        </div>
                        <div style={{ color: '#00635C' } as React.CSSProperties}>
                          <div style={{ color: '#00635C' } as React.CSSProperties}>{code.number}</div>
                        </div>
                      </div>
                      <div
                        style={
                          {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            color: '#00635C',
                          } as React.CSSProperties
                        }>
                        <div style={{ color: '#00635C', fontSize: '26px', padding: '16px 0' } as React.CSSProperties}>
                          {code.companyName}
                        </div>
                        <div style={{ color: '#00635C', fontSize: '26px', padding: '16px 0' } as React.CSSProperties}>
                          {code.amount} zł
                        </div>
                      </div>
                      <div
                        style={
                          {
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            color: '#00635C',
                          } as React.CSSProperties
                        }>
                        <div
                          style={{ color: '#00635C', fontSize: '10px', marginBottom: '-15px' } as React.CSSProperties}>
                          termin ważności: {code.expirationDate}
                        </div>
                      </div>
                    </div>
                  ))}
                {codes.length == 0 && (
                  <div className='d-flex justify-content-center'>Nie masz kodów do wyświetlenia</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
});
