import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../../store/store';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { toast } from 'react-toastify';
import DiscountCodesList from '../../DiscountCode/DiscountCodesList/DiscountCodesList';
import { useSwrAgent } from '../../../api/useSwrAgent';
import _ from 'lodash';

export default observer(() => {
  const { discountCodeStore } = useStore();
  const { data: codes, mutate } = useSwrAgent().BirthdayCodes.GetUserBirthdayCodeCampaigns();
  const loading = codes === undefined;

  const changeDiscountCodeUsed = (id: number, isUsed: boolean) => {
    const codesCopy = _.cloneDeep(codes);
    const codeToModify = codesCopy?.find((x) => x.id === id);
    if (codeToModify) {
      codeToModify.isUsed = isUsed;
    }
    mutate(
      () => {
        return discountCodeStore.changeDiscountBirthdayCodeUsed(id, isUsed).then((resp) => {
          toast.success(resp);
          return codesCopy;
        });
      },
      {
        optimisticData: codesCopy,
        rollbackOnError: true,
        revalidate: false,
      },
    ).then();
  };

  return (
    <div
      id='DiscountCodes'
      style={
        {
          background: '#fff',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          marginTop: '0',
          padding: '10px',
        } as React.CSSProperties
      }>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <DiscountCodesList birthdayCodes={true} changeDiscountCodeUsed={changeDiscountCodeUsed} codesList={codes} />
      )}
    </div>
  );
});
