import { Fragment } from 'react';
import { Image, Nav, Offcanvas } from 'react-bootstrap';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { profileRoutes } from '../../routes/routes';
import { User } from '../../types/user';
import { Route } from '../../types/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  container,
  firstMenuItem,
  imageContainer,
  imageDiv,
  logoutButton,
  menuItem,
  mobileContainer,
  navLink,
  upperMenu,
  userEmail,
  userName,
} from './styles';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { mutate } from 'swr';

export default observer(() => {
  const { userStore, menuStore } = useStore();
  const user: User | undefined = userStore.getUserData();
  const mainRoutes: Route[] = profileRoutes;
  const handleClose = () => {
    menuStore.setClosedProfile();
  };
  const clearCache = () => mutate(() => true, undefined, { revalidate: false });
  const logout = () => {
    handleClose();
    clearCache().then(() => userStore.logout());
  };
  return (
    <Offcanvas
      onHide={handleClose}
      placement='end'
      show={menuStore.checkIsProfileToggled}
      style={menuStore.isMobileView ? _.merge({}, container, mobileContainer) : container}>
      <div style={imageContainer}>
        <Image alt='logo' className='logo' src='/assets/logo.svg' style={imageDiv} />
      </div>
      <div className='d-flex justify-content-center'>
        <div style={userName}>{user ? user.firstName + ' ' + user.lastName : ''}</div>
      </div>
      <div className='d-flex justify-content-center'>
        <div style={userEmail}>{user ? user.username : ''}</div>
      </div>
      <div className='navbar-vertical-content scrollbar '>
        <Nav as='ul' className='flex-column' style={upperMenu}>
          {mainRoutes.map((route, index) => (
            <Fragment key={route.name}>
              <Nav.Item as='li' key={route.name} style={index == 0 ? firstMenuItem : menuItem}>
                <NavLink onClick={handleClose} style={navLink} to={route.to}>
                  <div className='d-flex'>
                    {route.icon && (
                      <span className='nav-link-icon'>
                        <FontAwesomeIcon icon={route.icon} />
                      </span>
                    )}
                    <span className='nav-link-text ps-1'>{route.name}</span>
                  </div>
                </NavLink>
              </Nav.Item>
            </Fragment>
          ))}
        </Nav>
      </div>
      <button onClick={logout} style={logoutButton}>
        <FontAwesomeIcon icon={faRightFromBracket} />
        <span> Wyloguj się</span>
      </button>
    </Offcanvas>
  );
});
