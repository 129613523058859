import * as React from 'react';
import { Image } from 'react-bootstrap';

export function GetViewForBlocked(): React.ReactNode {
  return (
    <div>
      <div className='row col-12'>
        <Image
          src='assets\block.svg'
          style={{
            marginBottom: '40px',
            marginTop: '40px',
            height: '200px',
            textAlign: 'center',
          }}
        />
      </div>
      <div className='row col-12'>
        <h1 className='text-danger' style={{ textAlign: 'center' }}>
          Benefit Portfel NAU został zablokowany
        </h1>
      </div>
    </div>
  );
}
