import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { UserInstitution } from '../../../types/user';
import { Form, Formik } from 'formik';
import * as styles from './styles';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ButtonsSubmitCancel from '../../Buttons/ButtonsSubmitCancel/ButtonsSubmitCancel';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Capacitor } from '@capacitor/core';

export default observer((props: { userId: number }) => {
  const { userStore, modalStore, loansStore } = useStore();
  const [userInst, setUserInst] = useState<UserInstitution | null>(null);

  useEffect(() => {
    const userInst = userStore.getUserInstitution();
    setUserInst(userInst);
  }, [userStore]);
  const handleClose = () => modalStore.closeModal();
  return (
    <Formik
      initialValues={{
        error: null,
      }}
      //validationSchema={ChangeLimitSchema}
      onSubmit={(_, { setSubmitting }) => {
        loansStore
          .unlockLoanBenefit(props.userId, userInst?.institutionId ?? 0)
          .then(() => {
            setSubmitting(false);
            toast.success('Benefit został odblokowany.');
            handleClose();
            if (Capacitor.isNativePlatform()) {
              const path = history.location.pathname;
              history.push(PathRoute.PUSTY);
              history.push(path);
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            toast.error(error);
            setSubmitting(false);
          });
      }}>
      {({ handleSubmit, isSubmitting }) => (
        <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
          <div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}>
              <Image
                src='assets\unblock.svg'
                style={{
                  marginBottom: '40px',
                  marginTop: '40px',
                  height: '100px',
                  textAlign: 'center',
                }}
              />
            </div>
            <p
              style={_.merge(
                {},
                styles.marginBottomZero as React.CSSProperties,
                styles.applicationText as React.CSSProperties,
                { lineHeight: 'normal' },
              )}>
              <span>Czy na pewno chcesz odblokować benefit dla użytkownika?</span>
            </p>
          </div>

          <ButtonsSubmitCancel
            buttonTextCancel='Nie'
            buttonTextSubmit='Tak'
            cancelHandler={handleClose}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
});
